import * as React from 'react';
import { useTranslations } from '../TranslationsProvider';
import { Message } from '../../translations';

export const Translate = (props: { text: Message|React.ReactElement<{}>; values?: { [key: string]: string|number } }) => {
  const translate = useTranslate();

  if (React.isValidElement(props.text)) {
    return (
      <>
        {props.text}
      </>
    );
  }

  const translation = translate(props.text, props.values);

  return (
    <>
      {translation}
    </>
  )
}

export const useTranslate = () => {
  const translations = useTranslations();

  return (text: Message, values?: { [key: string]: string|number }) => {
    let translation = translations[text] || text;
    if (values) {
      const replacements = Object.keys(values);
      replacements.forEach(key => {
        const replacement = values[key] ?? '';
        translation = translation.replace(`{${key}}`, replacement.toString());
      });
    }

    return translation;
  }
}

export type { Message }
