import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useHistory, useQuery } from '../../hooks';
import { ListButton } from '../../components/ListButton';
import { useAcceptInvite } from '../../auth';
import { ListTextField } from '../../components/ListTextField';

export function ChangePasswordPage(){
  const history = useHistory();
  const query = useQuery<{ userId: string; token: string }>();
  const [password, setPassword] = React.useState('');
  const { acceptInvite } = useAcceptInvite();

  return (
    <Panes backgroundColor="light">
      <Pane title="Change Password">
        <ListTextField type="password" label="Password" value={password} onChange={setPassword} />
        <ListButton 
          label="Change Password" 
          onPress={async () => {
            if (!query.token || !query.userId) {
              return null;
            }

            await acceptInvite({ 
              userId: query.userId, 
              inviteToken: query.token,
              password
            });

            history.push('/sites');
          }} 
        />
      </Pane>
    </Panes>
  )
}
  
