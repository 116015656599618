import styled from 'styled-components/native';

/**
 * Hide elements without removing them from the DOM
 */
export const Hider = styled.View<{ hidden: boolean }>`
  ${(props) => {
    if (props.hidden) {
      return 'opacity: 0;height: 0px;overflow: hidden;'
    }
    return ''
  }}
`;
