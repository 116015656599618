import moment from 'moment';
import * as React from 'react';
import { useDeviceType } from '../../hooks';
import { Form } from '../../components/Form';
import { PaneButton } from '../../components/PaneButton';
import { Pane, Panes } from '../../components/Panes';
import { SubmitButton } from '../../components/SubmitButton';
import { DeficiencyForm } from '../../forms/DeficiencyForm';
import { useHistory, useParams, useQuery } from '../../hooks';
import { useAddDeficiency } from '../../data/deficiencies';
import { useOrgId, useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';
import { useLocation } from '../../data/locations';

export function AddDeficiencyPage() {
  
  const history = useHistory();
  const orgId = useOrgId();
  const params = useParams<{ locationId: string; equipmentId: string }>();
  const query = useQuery<{ serviceTypeId: string }>();
  const canWrite = useHasPermission(Permissions.DEFICIENCY_WRITE);
  const deviceType = useDeviceType();
  const { addDeficiency } = useAddDeficiency();
  const { location } = useLocation(params.locationId);

  if (!canWrite) {
    return <></>;
  }

  if (!location) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        orgId: orgId || '',
        description: '',
        dateOpened: moment().toISOString(),
        equipmentId: params.equipmentId,
        serviceTypeId: query.serviceTypeId || '',
        happenedAtLocationId: params.locationId,
        locationId: params.locationId,
      }}
      onSubmit={async (deficiency) => {
        await addDeficiency(deficiency, params.locationId)
        history.goBack();
      }}
    >
      <Panes 
        backgroundColor="light"
        leftButton={<BackButton />}
      >
        <Pane 
          title="Add Deficiency"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
          leftButton={ deviceType !== 'desktop' && <BackButton /> }
        >
          <DeficiencyForm timeZone={location.timeZone} />
        </Pane>
      </Panes>
    </Form>
  )
}
  
