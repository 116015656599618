import * as React from 'react';
import styled from 'styled-components/native';

const Image = styled.Image`
  width: 50px;
  height: 50px;
`;

export const PreviewImage = (props: { file: Blob|string }) => {
  const [src, setSrc] = React.useState<string|ArrayBuffer>();
  React.useEffect(() => {
    if (typeof props.file === 'string') {
      return () => {}; // eslint-disable-line @typescript-eslint/no-empty-function
    }

    const reader = new FileReader();
      
    reader.onload = function(e) {
      if (e.target && e.target.result) {
        setSrc(e.target.result);
      }
    }
    
    reader.readAsDataURL(props.file); 
  }, [props.file]);

  const uri = typeof props.file === 'string' ? props.file : src;
  return (
    <>
      {uri && (
        <Image 
          source={{
            uri: uri.toString()
          }}
        />
      )}
    </>
  )
}
