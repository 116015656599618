import React from 'react';
import Iframe from 'react-iframe'
import { useDeviceType } from '../../hooks';
import styled from 'styled-components/native';

interface Props {
  youtubeId: string;
  width: string;
  height: string;
}

const YoutubeThing = styled(Iframe)`
  border: none;
`;


export function Youtube(props: Props) {
  const device = useDeviceType();

  if (device !== 'desktop') {
    // Currently this is not a react native compatible thing
    return null;
  }

  return (
    <YoutubeThing 
      url={`http://www.youtube.com/embed/${props.youtubeId}`}
      width={props.width}
      height={props.height}
      styles={{ border: 'none', outline: 'none' }}
    />
  )
}
