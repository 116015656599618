import differenceBy from 'lodash.differenceby';

export function mergeObjects<T extends { id: string; lastUpdated: string; deleted: boolean }>(storedObjects: T[], loadedObjects: T[]) {
  if (loadedObjects.length === 0) {
    return storedObjects;
  }

  let unchanged = true;

  // 1. Given the loaded objects, find any that are brand new
  const newlyAddedObjects = differenceBy(loadedObjects, storedObjects, 'id');  
  if (newlyAddedObjects.length > 0) {
    unchanged = false;
  }

  // 2. Concat the brand new objects to the stored objects
  const newStoredObjects = storedObjects.concat(newlyAddedObjects);

  // 3. Loop over the loaded locations and see if any have been updated more recently than the stored version
  loadedObjects.forEach((loadedObject) => {
    const storedLocationIndex = storedObjects.findIndex((location) => location.id === loadedObject.id);
    if (storedLocationIndex === -1) {
      return;
    }

    const storedLocation = storedObjects[storedLocationIndex];
    const storedIsMoreRecent = storedLocation.lastUpdated > loadedObject.lastUpdated;
    const storedIsUpToDate = storedLocation.lastUpdated === loadedObject.lastUpdated;

    if (storedIsMoreRecent || storedIsUpToDate) {
      return;
    }

    // 4. Replace stored location with loaded location
    newStoredObjects[storedLocationIndex] = loadedObject;
    unchanged = false;
  });


  // 4. Remove any deleted or transferred locations
  for (let i = newStoredObjects.length - 1; i >= 0; i--) {
    if (newStoredObjects[i].deleted) {
      newStoredObjects.splice(i, 1);
      unchanged = false;
    }
  }

  // If the objects are unchanged, we should return the original stored objects
  // instead of the new objects. This is so we don't unnecessarily return a new
  // array reference, which will cause re-renders and re-calculations and
  // whatnot
  if (unchanged) {
    return storedObjects;
  }

  return newStoredObjects;
}

/**
 * Can be used to convert an array of strings to an object.  This can be helpful
 * for creating faster algorithms, because object lookup is O(1) and array
 * lookup is O(n)
 *
 * @param arr 
 * @param params 
 */
export function convertStrArrToMap(arr: string[]): { [key: string]: boolean } {
  return arr.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {} as { [key: string]: boolean });
}

type KeysMatching<T, V> = {[K in keyof T]: T[K] extends V ? K : never}[keyof T];

/**
 * Can be used to convert an array to an object by using a specified property as
 * the key.  This can be helpful for creating faster algorithms, because object
 * lookup is O(1) and array lookup is O(n)
 * @param arr 
 * @param params 
 */
export function convertArrayToMap<T extends {}, K extends KeysMatching<T, string|number>, O extends T[K] extends string ? { [key: string]: T } : { [key: number]: T }>(arr: T[], params: { idKey: K }): O {
  const itemMap = arr.reduce((acc: any, curr: T) => {
    acc[curr[params.idKey]] = curr;
    return acc;
  }, {} as O);

  return itemMap;
}

