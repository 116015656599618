import * as React from 'react';
import { Form } from '../../components/Form';
import { ListButton } from '../../components/ListButton';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { PaneButton } from '../../components/PaneButton';
import { Pane, Panes } from '../../components/Panes';
import { SubmitButton } from '../../components/SubmitButton';
import { LocationForm } from '../../forms/LocationForm';
import { useParams } from '../../hooks';
import { useDeleteLocation, useLocation, useUpdateLocation } from '../../data/locations';
import { useHasPermission } from '../../auth';
import { FeatureList, Permissions, Customer } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { useIsFeatureEnabled } from '../../data/organization';
import { ServiceMonthDrawer } from '../../containers/ServiceMonthDrawer';
import { BackButton } from '../../components/BackButton';
import { useAddCustomer, useCustomer, useCustomers } from '../../data/customers';
import { Text } from '../../components/Text';
import { Box } from '../../components/Box';
import { useGenerateAccountNumber } from '../../data/accountNumbers';
import { shortUUID } from '../../utils/misc';

export function EditLocationPage() {
  const params = useParams<{ locationId: string }>();
  const { location } = useLocation(params.locationId);
  const { customer } = useCustomer(location?.customerId || undefined)
  const hasCustomer = Boolean(customer)
  const { updateLocation } = useUpdateLocation();
  const { deleteLocation } = useDeleteLocation();
  const { addCustomer } = useAddCustomer();
  const canWrite = useHasPermission(Permissions.LOCATION_WRITE);
  const locationOverridesEnabled = useIsFeatureEnabled(FeatureList.LOCATION_OVERRIDES);
  const serviceMonthsEnabled = useIsFeatureEnabled(FeatureList.SERVICE_MONTHS);
  const billingEnabled = useIsFeatureEnabled(FeatureList.BILLING);
  const { tryGenerateAccountNumber, accountNumberStatus } = useGenerateAccountNumber();

  async function createCustomer() {
    if (!location) {
      return
    }

    const accountNumber = await tryGenerateAccountNumber(location.orgId);
    const customer: Customer = {
      id: shortUUID(),
      orgId: location.orgId,
      name: location.name,
      accountNumber,

      contact: location.contact,
      phone: location.phone,
      cell: location.cell,
      email: location.email,
      fax: location.fax,

      address: location.address,
      addressTwo: location.addressTwo,
      city: location.city,
      country: location.country,
      province: location.province,
      postalCode: location.postalCode,

      lastUpdated: '', 
      deleted: false
    }
  
    addCustomer(customer)
    updateLocation(location.id, {
      customerId: customer.id
    })
  }

  function removeCustomer() {
    if (!location) {
      return
    }
    
    updateLocation(location.id, {
      customerId: null
    })
  }

  if (!location) {
    return null; // TODO: show loading state?
  }

  return (
    <>
    <Form
      initialValues={location}
      onSubmit={(values) => {
        updateLocation(params.locationId, {
          ...values,
          // @ts-ignore
          siteNumber: values.customerNumber
        })
      }}
    >
    <Panes backgroundColor="light" leftButton={<BackButton />}>
      <Pane 
        title="Site Information"
        rightButton={<SubmitButton as={PaneButton} label={'Save'} showFeedback disabled={!canWrite} />}
      >

          <iframe
            src={`https://maps.google.com/maps?q=${location.address} ${location.city} ${location.country} ${location.province}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
            width="100%"
            height="250"
            frameBorder="0"
            style={{ border: 0 }}
            aria-hidden="false"
          />

        <ListSectionTitle>General</ListSectionTitle>

        {location && (
          <LocationForm />
        )}

        {billingEnabled && (
          <>
            <ListSectionTitle>Customer</ListSectionTitle>

            {billingEnabled && !hasCustomer && (
              <ListButton 
                label="Assign Existing Customer" 
                to={`/sites/${params.locationId}/customer-select`}
              />
            )}
            {billingEnabled && !hasCustomer && (
              <ListButton 
                label="Create Customer" 
                onPress={createCustomer} 
                loading={accountNumberStatus === 'loading'}
              />
            )}
            {billingEnabled && hasCustomer && (
              <ListButton 
                label="Edit Customer" 
                to={`/customers/${location.customerId}`}
              />
            )}

            {hasCustomer && (
              <Box padding="md" paddingBottom={'xxs'}>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>Account Number</Text>
                  <Text color={customer?.accountNumber ? undefined : "secondary"} size='sm'>{customer?.accountNumber || 'N/A'}</Text>
                </Box>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>Name</Text>
                  <Text color={customer?.name ? undefined : "secondary"} size='sm'>{customer?.name || 'N/A'}</Text>
                </Box>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>Country</Text>
                  <Text color={customer?.country ? undefined : "secondary"} size='sm'>{customer?.country || 'N/A'}</Text>
                </Box>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>Province</Text>
                  <Text color={customer?.province ? undefined : "secondary"} size='sm'>{customer?.province || 'N/A'}</Text>
                </Box>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>City</Text>
                  <Text color={customer?.city ? undefined : "secondary"} size='sm'>{customer?.city || 'N/A'}</Text>
                </Box>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>Address</Text>
                  <Text color={customer?.address ? undefined : "secondary"} size='sm'>{customer?.address || 'N/A'}</Text>
                </Box>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>Address Line 2</Text>
                  <Text color={customer?.addressTwo ? undefined : "secondary"} size='sm'>{customer?.addressTwo || 'N/A'}</Text>
                </Box>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>Contact</Text>
                  <Text color={customer?.contact ? undefined : "secondary"} size='sm'>{customer?.contact || 'N/A'}</Text>
                </Box>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>Email</Text>
                  <Text color={customer?.email ? undefined : "secondary"} size='sm'>{customer?.email || 'N/A'}</Text>
                </Box>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>Phone</Text>
                  <Text color={customer?.phone ? undefined : "secondary"} size='sm'>{customer?.phone || 'N/A'}</Text>
                </Box>

                <Box paddingBottom='md'>
                  <Text weight='strong' size='sm'>Cell</Text>
                  <Text color={customer?.cell ? undefined : "secondary"} size='sm'>{customer?.cell || 'N/A'}</Text>
                </Box>

                <Box>
                  <Text weight='strong' size='sm'>Fax</Text>
                  <Text color={customer?.fax ? undefined : "secondary"} size='sm'>{customer?.fax || 'N/A'}</Text>
                </Box>

              </Box>
            )}

          </>
        )}

        {canWrite && (
          <>
            <ListSectionTitle>Options</ListSectionTitle>

            <ListButton
              label={'Floor Plans'}
              to={`/sites/${params.locationId}/settings/floor-plans`}
            />

            {serviceMonthsEnabled && (<ServiceMonthDrawer />)}

            {locationOverridesEnabled && (
              <ListButton
                label={'Site Service Overrides'}
                to={`/sites/${params.locationId}/settings/location-overrides`}
              />
            )}

            {billingEnabled && hasCustomer && (
              <ListButton 
                label="Remove Customer" 
                onPress={removeCustomer} 
              />
            )}

          </>
        )}

        <ListSectionTitle>Care</ListSectionTitle>
        <DeleteButton 
          name="location"
          deleteItemNew={deleteLocation}
          itemId={params.locationId}
          label="Delete Site"
          yesMessage="Yes, Delete Site"
          question="Delete Site?"
          successMessage="Site Deleted"
        />
      </Pane>
    </Panes>
    </Form>

    </>
  )
}
  
