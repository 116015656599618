import { FrequencyType } from '@rivison-inc/ft-types';
import * as React from 'react';
import { Form } from '../../components/Form';
import { PaneButton } from '../../components/PaneButton';
import { Pane, Panes } from '../../components/Panes';
import { SubmitButton } from '../../components/SubmitButton';
import { ServiceTypesForm } from '../../forms/ServiceTypesForm';
import { useHistory, useParams } from '../../hooks';
import { useOrgId } from '../../auth';
import { useAddServiceType } from '../../data/serviceTypes';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddServiceTypePage() {
  const history = useHistory();
  const orgId = useOrgId();
  const params = useParams<{ equipmentTypeId: string  }>();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);
  const { addServiceType } = useAddServiceType();

  if (!canWrite) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        name: '',
        orgId: orgId || '',
        equipmentTypeId: params.equipmentTypeId,
        frequencyType: FrequencyType.MONTH,
      } as any} // TODO: remove as any
      onSubmit={async (serviceType) => {
        const newItem = await addServiceType(serviceType)
        history.replace(`/equipment-types/${params.equipmentTypeId}/service-types/${newItem.id}`);
      }}
    >
      <Panes 
        backgroundColor="light"
        leftButton={<BackButton />}
      >
        <Pane 
          title="Add Service Type"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <ServiceTypesForm />
        </Pane>
      </Panes>
    </Form>
  )
}
  
