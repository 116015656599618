import * as React from 'react';
import moment from 'moment';
import { Box } from '../../components/Box';
import { useLocation } from '../../data/locations';
import { useDeviceType, useRouteMatch } from '../../hooks';
import { Message } from '../../components/Message';
import { Translate } from '../../components/Translate';
import { Text } from '../../components/Text';

export function TimeZoneWarning() {
  const currentTimeZone = moment.tz.guess();
  const locationPageMatch = useRouteMatch<{ locationId: string }>({ path: '/sites/:locationId' });
  const locationId = locationPageMatch?.params.locationId;
  const { location } = useLocation(locationId);
  const device = useDeviceType();

  if (device === 'desktop' && location && location?.timeZone !== currentTimeZone && location.timeZone) {
    return (
      <Box padding='sm'>
        <Text size='xs' alignX='center' color='secondary'>
          <Translate text={'Dates are displayed in {timeZone} time'} values={{ timeZone: location.timeZone }} />
        </Text>   
      </Box>
    )
  }
  return <></>;
}
