/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { Picker } from 'react-native';
import styled from 'styled-components/native';
import { useDeviceType } from '../../hooks';
import { Tooltip } from '../Tooltip';
import { Message, Translate } from '../Translate';
import { Text } from '../Text';

export interface ListDropdownFieldProps {
  label: string;
  value?: string;
  onChange?: (newValue: string|null, markClean?: boolean) => void;
  options: {
    value: string|null;
    label: string;
  }[];
  required?: boolean;
  hasEmptyOption?: boolean;
  noOptionsHintText?: Message;
  tooltip?: {
    title: Message;
    text: Message;
    youtubeId?: string;
  };
  annotation?: Message;
}

const ListDropdownFieldView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom-color: ${(props) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
`;

const FieldLabelText = styled.Text`
  padding: ${(props) => props.theme.spacing.sm};
  display: flex;
  min-width: 120px;
`;

const DropContainer = styled.View`
  padding: ${(props) => props.theme.spacing.md};
  display: flex;
  flex: 1;
  /* outline-style:none; */
  box-shadow:none;
  border-color:transparent;
  background-color: rgba(0,0,0,0.03);
  border-left-width: 1px;
  border-left-color: ${(props) => props.theme.palette.lineColor};
`;

const RedStar = styled.Text`
  color: ${(props) => props.theme.palette.error};
`;

export const ListDropdownField = (props: ListDropdownFieldProps) => {
  const deviceType = useDeviceType();

  // Don't use React.useEffect here, it will cause issues where it wont always set the xth time, example opening reports 
  // set value to first in list
  if (!props.value && !props.hasEmptyOption) {
    const firstOption = props.options.length && props.options[0];
    if (firstOption && firstOption.value) {
      if (props.onChange) props.onChange(firstOption.value, true);
    }
  }

  return (
    <ListDropdownFieldView>
      <FieldLabelText>
        {props.label}
        {props.required ? <RedStar>*</RedStar> : null}
        {props.tooltip && (
          <Tooltip title={props.tooltip.title} text={props.tooltip.text} youtubeId={props.tooltip.youtubeId} />
        )}
      </FieldLabelText>

      <DropContainer>
        <Picker
          // TODO: change to accessibilityLabelledBy after upgrading react-native / react-native-wb
          accessibilityLabel={props.label}
          selectedValue={props.value}
          style={{height: deviceType !== 'desktop' ? 170 : 30, width: '100%' }}
          onValueChange={(itemValue, itemIndex) => {
            if(props.onChange){
              // react-native for some reason calls onValueChange with the label
              // instead of the value when picking the '--' option. Probably
              // because the value is null
              if (props.hasEmptyOption && itemIndex === 0) {
                props.onChange(null);
              } else {
                props.onChange(itemValue);
              }
            } 
          }}
        >
          {props.hasEmptyOption && <Picker.Item label={!!props.noOptionsHintText && props.options.length === 0 ? props.noOptionsHintText : '--'} value={null} />}
          {props.options.map((option, idx) => <Picker.Item key={idx} label={option.label} value={option.value} />)}
        </Picker>
        {props.annotation && (
          <Text size="sm" marginTop='sm'>
            <Translate 
              text={props.annotation}
            />
          </Text>
        )}
      </DropContainer>
    </ListDropdownFieldView>
  )
}
