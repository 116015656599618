import { ServiceDef } from "@rivison-inc/ft-types";
import { useMemo } from "react";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
  SyncStatusMessage,
} = makeDataHooks<ServiceDef>({
  dataName: "ServiceTypes",
});

export function useServiceType(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    serviceType: item,
  }
}

export function useServiceTypes() {
  const { data, isLoading } = useData();

  return {
    serviceTypes: data || [],
    isLoading
  }
}

export function useServiceTypesByEquipmentTypeId(equipmentTypeId: string|undefined) {
  const { data } = useData();

  const filteredData = useMemo(() => {
    if (!equipmentTypeId) {
      return [];
    }

    return data.filter((item) => item.equipmentTypeId === equipmentTypeId);
  }, [data, equipmentTypeId]);

  return {
    serviceTypes: filteredData || [],
  }
}

export function useAddServiceType() {
  const { addItem } = useAddData();

  return {
    addServiceType: addItem,
  }
}

export function useDeleteServiceType() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteServiceType: deleteItem,
  }
}

export function useUpdateServiceType() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateServiceType: updateItem,
  }
}

export const ServiceTypesSyncStatusMessage = SyncStatusMessage;
