import * as React from 'react';
import { ActivityIndicator as ActivityIndicatorNative } from "react-native";
import { theme } from '../../theme';

interface ActivityIndicatorProps {
  size: 'small' | 'large';
  color?: string;
}

export const ActivityIndicator = (props: ActivityIndicatorProps) => {
  return (
    <ActivityIndicatorNative size={props.size} color={props.color || theme.palette.primary} />
  );
}
