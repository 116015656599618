import React from 'react';
import { Form, FormProps } from '../Form';
import { createPortal } from '../Portal';
import { DrawerBase, DrawerProps } from './Drawer';

export interface DrawerFormProps<T extends {}> extends DrawerProps, FormProps<T> {

}

function DrawerFormBase<T extends {}>(props: DrawerFormProps<T>) {
  return (
    <Form
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
    >
      <DrawerBase
        title={props.title}
        onRequestClose={props.onRequestClose}
        rightButton={props.rightButton}
        open={props.open}
      >
        {props.children}
      </DrawerBase>
    </Form>
  )
}

const portalSystem = createPortal({
  defaultProps: {},
  component: DrawerFormBase as any,
});

export const DrawerFormProvider = portalSystem.PortalProvider;
export const DrawerForm = portalSystem.PortalTrigger as <T extends {}>(props: DrawerFormProps<T>) => null;
