import styled from 'styled-components/native';
import { Platform } from 'react-native';

/**
 * This is a wrapper that will hide it's children while still letting them take
 * up space. This is useful if you're trying to center something but want an
 * element on one side that doesn't effect the centering
 */
export const Ghost = styled.View.attrs({ 
  // @see: https://stackoverflow.com/a/50748656/3006989
  accessibilityElementsHidden: true,
  importantForAccessibility: 'no-hide-descendants'
 })`
  opacity: 0;
  transform: scale(0); /** Scale to 0 so children are not clickable */
  ${Platform.OS === 'web' ? 'visibility: hidden;' : ''}
  ${Platform.OS === 'web' ? 'pointer-events: none;' : ''}
`;
