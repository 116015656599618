import * as React from 'react';
import { useId } from '../../hooks/useId';


interface PortalContextValue<Props extends object> {
  raiseProps: (portalId: string, portalProps: Props) => void;
  removeProps: (portalId: string) => void;
}
/**
 * Creates a portal system that can be used to render popovers, dialogs, etc
 *
 * @param options.defaultProps The default props to raise
 * @param options.component The visual component to render
 */
export function createPortal<Props extends {}>(options: { defaultProps: Props; component: React.ComponentType<Props> }) {
  const PortalContext = React.createContext<PortalContextValue<Props>>({ raiseProps: () => null, removeProps: () => null });

  const PortalProvider = (props: { children: React.ReactNode }) => {
    const [portalProps, setPortalProps] = React.useState<Props>(options.defaultProps);
    const contextValue = React.useMemo(() => ({
      raiseProps: (portalId: string, newProps: Props) => {
        setPortalProps((oldProps) => ({
          ...oldProps,
          [portalId]: newProps
        }));
      },
      removeProps: (portalId: string) => {
        setPortalProps((oldProps) => {
          const newProps = { ...oldProps };
          delete (newProps as any)[portalId];

          return newProps;
        })
      }
    }), []);
  
    return (
      <PortalContext.Provider value={contextValue}>
        {props.children}
        {Object.keys(portalProps).map((portalId) => (
          <options.component key={portalId} {...(portalProps as any)[portalId]} />
        ))}
      </PortalContext.Provider>
    )
  }

  const PortalTrigger = (props: Props) => {
    const portalId = useId({ prefix: 'portal' });
    const portalContext = React.useContext(PortalContext);
    
    React.useEffect(() => {
      portalContext.raiseProps(portalId, props);

      return () => {
        portalContext.removeProps(portalId);
      }
    }, [props]);
  
    return null;
  }

  return { PortalProvider, PortalTrigger };
}
