import * as React from 'react';
import { useQuery } from 'react-query';
import { api } from '../../api';
import { useOrgId } from '../../auth';
import { Import } from '@rivison-inc/ft-types';
import moment from 'moment';

export function ImportsPage() {
    const orgId = useOrgId();
    const { data: response } = useQuery('imports', () => api.get(`/imports?orgId=${orgId}`).then((response) => response.data));
    const imports = (response?.data || []) as Import[];

    return (
        <div style={{ height: "100%", overflow: "auto" }}>
            <table style={{ backgroundColor: "white" }}>
                <thead>
                    <tr>
                        <td style={{ padding: "8px", border: "1px solid grey" }}>Date Started</td>
                        <td style={{ padding: "8px", border: "1px solid grey" }}>Date Completed</td>
                        <td style={{ padding: "8px", border: "1px solid grey" }}>Duration</td>
                        <td style={{ padding: "8px", border: "1px solid grey" }}>Context ID</td>
                    </tr>
                </thead>
                <tbody>
                    {imports.map((importInfo) => {
                        return (
                            <tr key={importInfo.id}>
                                <td style={{ padding: "8px", border: "1px solid grey" }}>{formatDate(importInfo.dateStarted)}</td>
                                <td style={{ padding: "8px", border: "1px solid grey" }}>{importInfo.dateCompleted ? formatDate(importInfo.dateCompleted) : 'Not Completed'}</td>
                                <td style={{ padding: "8px", border: "1px solid grey" }}>{importInfo.dateCompleted ? `${moment(importInfo.dateCompleted).diff(importInfo.dateStarted, 'seconds')} seconds` : 'Not Completed'}</td>
                                <td style={{ padding: "8px", border: "1px solid grey" }}>
                                    {importInfo.contextId}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

const formatDate = (date: string) => `${moment(date).format("MMMM Do YYYY, h:mm:ss a")} (${moment(date).fromNow()})`;
