import * as React from 'react';

/**
 * This can be used as a perf optimization for context providers.
 *
 * See: https://twitter.com/sophiebits/status/1228955237655834624
 */
export const MemoizedWrapper = React.memo(({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {children}
    </>
  )
});
