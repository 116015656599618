import * as React from 'react';
import { ListTextField } from '../../components/ListTextField';
import { Field } from '../../components/Form';
import { useServiceMonthCategories } from '../../data/serviceMonthsCategories';
import { ListDropdownField } from '../../components/ListDropdownField';
import { useIsFeatureEnabled } from '../../data/organization';
import { FeatureList } from '@rivison-inc/ft-types';

export const EquipmentTypesForm = () => {
  const { serviceMonthCategories } = useServiceMonthCategories();
  const serviceMonthCategoriesFormatted = serviceMonthCategories.map((serviceMonthCategory) => ({ value: serviceMonthCategory.id, label: serviceMonthCategory.name }));
  const serviceMonthsEnabled = useIsFeatureEnabled(FeatureList.SERVICE_MONTHS);

  return (
    <>
      <Field 
        as={ListTextField} 
        name="name" 
        label="Name" 
        type="text"
        required
      />

      {serviceMonthsEnabled && (
        <Field 
          as={ListDropdownField} 
          name="serviceMonthCategoryId" 
          label="Service Month Category" 
          options={serviceMonthCategoriesFormatted || []}
          hasEmptyOption
        />
      )}
    </>
  )
}
  
