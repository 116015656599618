import moment from "moment";
import { api } from "../api";
import { Storage } from "../storage";
import { groupByKey } from "../utils/array";
import { LocalData } from "./localData";

interface Definition {
  apiKey: string;
  localData: InstanceType<typeof LocalData>;
  isGlobal: boolean;
}

export class Downloader<T> {

  private endpoint: string = ''
  private definitions: Definition[] = []

  public setEndpoint(endpoint: string) {
    this.endpoint = endpoint;
  }

  public addDefinition(definition: Definition) {
    this.definitions.push(definition)
  }

  public async getLastSuccessfulDownloadDate(): Promise<string> {
    const lastSuccessfulSyncDate = await Storage.getItem('last-successful-download-date');
    return lastSuccessfulSyncDate
  }

  private async updateLastSuccessfulDownloadDate(now: string): Promise<void> {
    await Storage.setItem('last-successful-download-date', now);
  }

  public async download(): Promise<void> {
    const now = moment().toISOString()

    const updatedSince = await this.getLastSuccessfulDownloadDate();

    const updatedSinceWithBuffer = updatedSince ? moment(updatedSince).subtract(10, 'minutes').toISOString() : null

    const results = await api.get(this.endpoint, { params: { updatedSince: updatedSinceWithBuffer } });

    for (const definition of this.definitions) {
      const definitionResults = results.data[definition.apiKey] || []
      if (definition.isGlobal) {
        await definition.localData.upsert(definitionResults, false);
      }else{
        const groupedByLocationId = groupByKey<any>(definitionResults, 'locationId')
        for (const [locationId, values] of Object.entries(groupedByLocationId)) {
          await definition.localData.upsert(values, false, locationId);
        }
      }
    }

    await this.updateLastSuccessfulDownloadDate(now)
  }

}
