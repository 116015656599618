import { ReportDef } from '../types';
import moment from 'moment';
import { Message } from '../../../translations';

export const equipmentDueConsolidatedReport: ReportDef = {
  reportName: 'Equipment Due Consolidated Report',
  description: 'Summary of services at a site separated by model information. Example 5 Monthly Inspections with model information 6LB DRY ABC.',
  layout: 'landscape',
  dialog: {
    title: 'Equipment Due Consolidated Report',
    message: 'Fill out the options below to prepare your report.',
    form: ({ modelFields }) => {
      
      const searchFields = modelFields.map((modelField) => ({
        type: 'checkbox' as 'checkbox',
        label: 'Consolidate on ' + modelField.label as Message,
        id: `consolidateOn.${modelField.value}`,
        default: Boolean(modelField.default || false),
      }));

      return [
        {
          type: 'date',
          label: 'Start Date',
          id: 'startDate',
        },
        {
          type: 'date',
          label: 'End Date',
          id: 'endDate',
          default: moment().endOf('year').toISOString(),
        },
        ...searchFields,
      ]
    },
  },
  endpoint: '/reports/equipmentDueConsolidatedReport',
  type: 'location',
};
