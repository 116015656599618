import * as React from 'react';
import { useParams } from '../../hooks';
import { Drawer } from '../../components/Drawer';
import { FloorPlanShower } from '../../components/FloorPlanShower';
import { ListButton } from '../../components/ListButton';
import { useFloorPlan } from '../../data/floorPlans';
import { userTracker } from '../../controllers/userTracker';
import { Message } from '../../translations';
import { TouchableOpacityCentered } from '../../components/Nav/styles';
import { TouchableOpacity, ScrollView } from 'react-native';
import { ListDateField } from '../../components/ListDateField';
import { ScheduledEventForm } from '../../forms/ScheduledEventForm';
import { Form } from '../../components/Form';
import { ScheduledEvent } from '../../../../Types/lib';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { useDeleteScheduledEvent, useUpdateScheduledEvent } from '../../data/scheduledEvents';
import { DeleteButton } from '../DeleteButton';

export function ScheduleDrawer(props: { scheduledEvent: ScheduledEvent; children: React.ReactNode }) {
  const [isAdding, setIsAdding] = React.useState(false);
  const { updateScheduledEvent } = useUpdateScheduledEvent();
  const { deleteScheduledEvent } = useDeleteScheduledEvent();
  
  return (
    <>
      <TouchableOpacity onPress={() => setIsAdding(true)}>
        {props.children}
      </TouchableOpacity>
      
      
        <Drawer 
          open={isAdding}
          title={'Scheduled Event'}
          // rightButton={<SubmitButton as={PaneButton} label="Save" loadingLabel="Saving" />}
          onRequestClose={() => {
            setIsAdding(false);
          }}
        >
          <Form 
            initialValues={props.scheduledEvent}
            onSubmit={async (updatedScheduledEvent) => {
              updateScheduledEvent(updatedScheduledEvent.id, updatedScheduledEvent);
              setIsAdding(false);
            }}
          >
            
            <ScheduledEventForm id={props.scheduledEvent.id} />

            <SubmitButton as={ListButton} label="Save" />

            <ListButton label="Delete" onPress={() => {
              deleteScheduledEvent(props.scheduledEvent.id);
              setIsAdding(false);
            }} />
            
          </Form>
        </Drawer>
    </>
  )
} 
