import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useDeleteEquipmentField, useEquipmentField, useUpdateEquipmentField } from '../../data/equipmentFields';
import { useParams, useHistory } from '../../hooks';
import { EquipmentFieldForm } from '../../forms/EquipmentFieldForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';

export function EditEquipmentFieldsPage() {
  const history = useHistory();
  const params = useParams<{ equipmentFieldId: string }>();
  const { deleteEquipmentField } = useDeleteEquipmentField();
  const { updateEquipmentField } = useUpdateEquipmentField();
  const { equipmentField } = useEquipmentField(params.equipmentFieldId);
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);

  if (!equipmentField) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={equipmentField}
      onSubmit={async (updates) => {
        await updateEquipmentField(equipmentField.id, updates)
        history.goBack();
      }}
    >
      <Panes backgroundColor="light">
        <Pane 
          title="Equipment Field Information"
          rightButton={<SubmitButton as={PaneButton} label="Save" showFeedback disabled={!canWrite} />}
        >
          {equipmentField && (
            <EquipmentFieldForm />
          )}

          <DeleteButton
            name="equipment-field"
            deleteItemNew={deleteEquipmentField}
            itemId={equipmentField.id}
            question="Delete Equipment Field?"
            yesMessage="Yes, Delete Equipment Field"
            successMessage="Deleted Equipment Field"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
