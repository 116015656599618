import * as React from 'react';

export interface DelayUnmountProps {
  delay: number;
  mounted?: boolean;
  children: React.ReactNode;
}

export function DelayUnmount(props: DelayUnmountProps) {
  const [shouldRender, setShouldRender] = React.useState(props.mounted);
  const ref = React.useRef<ReturnType<typeof setTimeout>|null>();
  
  React.useEffect(() => {
    if (ref.current) {
      clearTimeout(ref.current);
    }

    if (props.mounted) {
      setShouldRender(true);
    } else {
      ref.current = setTimeout(() => setShouldRender(false), props.delay);
    }

  }, [props.mounted]);

  return shouldRender ? <>{props.children}</> : <>{null}</>;
}
