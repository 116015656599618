import * as React from 'react';
import styled from 'styled-components/native';
import { Message } from '../Translate';
import { PrimaryButton } from '../PrimaryButton';

export interface PaneButtonProps {
  label: Message;
  disabled?: boolean;
  onPress: () => void;
  showFeedback?: boolean;
}

const ContainerView = styled.View`
  /** 
   * This is some hacky shit that's needed to prevent the pane header bar from
   * looking too tall. There's other solutions to that but they cause issues
   * with phones with ears (e.g iPhone X) 
   */
  margin-top: -${(props) => props.theme.spacing.sm}; 
  margin-bottom: -${(props) => props.theme.spacing.sm};
`;

export const PaneButton = (props: PaneButtonProps) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <ContainerView>
      <PrimaryButton
        label={props.label}
        onPress={() => {
          setLoading(true)
          setTimeout(() => {
            setLoading(false)
          }, 2000);
          props.onPress();
        }}
        disabled={props.disabled}
        loading={loading}
      />
    </ContainerView>
  )
}
