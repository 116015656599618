import * as React from 'react';
import { Field } from '../../components/Form';
import { ListSwitch } from '../../components/ListSwitch';
import { Row } from '../../components/Row';

export const ServiceMonthForm = () => {
  return (
    <>      
      <Row>
        <Field 
          as={ListSwitch} 
          name="january" 
          label="January" 
        />
        <Field 
          as={ListSwitch} 
          name="february" 
          label="February" 
        />
        <Field 
          as={ListSwitch} 
          name="march" 
          label="March" 
        />
        <Field 
          as={ListSwitch} 
          name="april" 
          label="April" 
        />
      </Row>
      <Row>
        <Field 
          as={ListSwitch} 
          name="may" 
          label="May" 
        />
        <Field 
          as={ListSwitch} 
          name="june" 
          label="June" 
        />
        <Field 
          as={ListSwitch} 
          name="july" 
          label="July" 
        />
        <Field 
          as={ListSwitch} 
          name="august" 
          label="August" 
        />
      </Row>
      <Row>
        <Field 
          as={ListSwitch} 
          name="september" 
          label="September" 
        />
        <Field 
          as={ListSwitch} 
          name="october" 
          label="October" 
        />
        <Field 
          as={ListSwitch} 
          name="november" 
          label="November" 
        />
        <Field 
          as={ListSwitch} 
          name="december" 
          label="December" 
        />
      </Row>


    </>
  )
}
