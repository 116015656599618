import * as React from 'react';

import { Platform, StatusBar } from 'react-native';
/** routes */
import { Routes } from './routes';
import { Router, DeepLinking } from './components/Router';

/** providers */
import { ThemeProvider } from './components/ThemeProvider';
import { TranslationsProvider } from './components/TranslationsProvider';
import { DialogProvider } from './containers/Dialog';
import { SafeAreaProvider } from './components/SafeAreaProvider';
import { PopoverProvider } from './components/Popover';
import { DrawerProvider, DrawerFormProvider } from './components/Drawer';
import { IdProvider } from './hooks/useId';
import { UnsavedChangesDetector } from './components/UnsavedChangesDetector';
import { MetaProvider } from './components/MetaProvider';
import { UnsavedChangesDialog } from './containers/UnsavedChangesDialog';
import { SnackbarProvider } from './containers/Snackbar';
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from './auth';
import { BillingBlocker } from './containers/BillingBlocker';

/** controlls */
import { userTracker } from './controllers/userTracker';
import { TopErrorBoundary } from './containers/TopErrorBoundary';
import { config } from './config';
import { CompletingSignUp } from './containers/CompletingSignUp';
import { TermsAgreement } from './containers/TermAgreement';
import { SearchSaver } from './containers/SearchSaver';
import { SearchSaverEquipment } from './containers/SearchSaverEquipment';

// uncomment this to clear storage
// Storage.clear()

// Ignore annoying warnings taking up half the screen in simulator 
// YellowBox.ignoreWarnings(['Warning: ReactNative.createElement']);
console.disableYellowBox = true;

const queryClient = new QueryClient()

export const App = () => {
  React.useEffect(() => {
    // Use Tracking code from property with "Create a universal app property" turned on, not a analytics 4 measurement id
    userTracker.init(config.analytics.google.trackingCode);
  }, []);
 
  return ( 
    <QueryClientProvider client={queryClient}>
      <StatusBar barStyle={'dark-content'}/>
      <SafeAreaProvider>
        <IdProvider>
          <UnsavedChangesDetector>
            <AuthProvider>
              <TopErrorBoundary>
                <MetaProvider>
                  <ThemeProvider>
                    <SearchSaver>
                      <SearchSaverEquipment>
                        <TranslationsProvider>
                          <SnackbarProvider>
                            <Router>
                              <>
                                <DeepLinking />
                                <PopoverProvider>
                                  <DialogProvider>
                                    <DrawerProvider>
                                      <DrawerFormProvider>
                                        <Routes />
                                        <BillingBlocker />
                                        <CompletingSignUp />
                                        <TermsAgreement />
                                      </DrawerFormProvider>
                                    </DrawerProvider>
                                  </DialogProvider>
                                </PopoverProvider>
                              </>
                            </Router>
                          </SnackbarProvider>
                        </TranslationsProvider>
                      </SearchSaverEquipment>
                    </SearchSaver>
                  </ThemeProvider>
                </MetaProvider>
              </TopErrorBoundary>
            </AuthProvider>
          </UnsavedChangesDetector>
        </IdProvider>
      </SafeAreaProvider>
      {Platform.OS === 'web' && <ReactQueryDevtools />}
    </QueryClientProvider>
  )
}
