import { StorageInterface } from './types';


class StorageClass implements StorageInterface {
  db: IDBDatabase|null = null;
  destroyed = false;

  constructor() {
    window.addEventListener('storage', (e) => {
      const oldDestroyedValue = this.destroyed;
      if (e.key === "message" && e.newValue === "storage_destroyed") {
        this.destroyed = true;
      }

      if (oldDestroyedValue === false) {
        window.location.reload();
      }
    });
  }

  async initDb() {
    return new Promise<void>((resolve, reject) => {
      const request = window.indexedDB.open('FlameTask', 3);
      let upgradeNeeded = false;

      request.onerror = (event) => {
        reject();
      };

      request.onsuccess = (event) => {
        if (!upgradeNeeded) {
          const db = request.result;
          this.db = db;

          resolve();
        }
      };
  
      request.onupgradeneeded = (event) => {
        upgradeNeeded = true;
        const db = request.result;
        const objectStore = db.createObjectStore('key_value_store');
  
        objectStore.transaction.oncomplete = () => {
          this.db = db;
          resolve();
        }
      }
    });
  }

  /**
   * @param options.persistPriority Set to `true` to persist data unless explicitly cleared by the user
   */
  async setItem(key: string, obj: {}, options: { persist?: boolean } = {}): Promise<void> {
    if (this.destroyed) {
      throw new Error("database is destroyed");
    }
  
    if (options.persist) {
      window.localStorage.setItem(key, JSON.stringify(obj));
      return;
    }
    
    if (!this.db) {
      await this.initDb();
    }

    await new Promise<void>((resolve, reject) => {
      if (!this.db || this.destroyed) {
        reject();
        return;
      }

      const transaction = this.db.transaction('key_value_store', 'readwrite').objectStore('key_value_store');  
      const request = transaction.put(obj, key);
      request.onsuccess = () => {  
        resolve();
      }

      request.onerror = () => { 
        reject();
      }
    });
  }

  /**
   * @param options.persistPriority Set to `true` to persist data unless explicitly cleared by the user
   */
  async bulkSet(data: { key: string, data: any[] }[]) {
    if (this.destroyed) {
      throw new Error("database is destroyed");
    }
    
    if (!this.db) {
      await this.initDb();
    }

    await new Promise<void>(async (resolve, reject) => {
      if (!this.db || this.destroyed) {
        reject();
        return;
      }

      const transaction = this.db.transaction('key_value_store', 'readwrite').objectStore('key_value_store');  

      const set = (store: IDBObjectStore, data: { key: string, data: any[] }[]) =>
      Promise.all(
        data.map(
          (row) =>
            new Promise((resolve, reject) => {
              const request = store.put(row.data, row.key);
              request.onsuccess = (event: any) => resolve(event.target.result);
              request.onerror = (event: any) => reject(event.target.error);
            })
        )
      );
      await set(transaction, data);

    });
  }

  async getKeys(): Promise<string[]> {    
    if (!this.db) {
      await this.initDb();
    }

    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject();
        return;
      }

      const transaction = this.db.transaction('key_value_store', 'readonly').objectStore('key_value_store');
      const request = transaction.getAllKeys()
      request.onsuccess = () => {
        const items = request.result;
        resolve((items || []).map(key => key.toString()));
      }
      request.onerror = () => { 
        reject();
      }
    });
  }

  async bulkGet<T = any>(keys: string[]): Promise<T[]> {
    if (!this.db) {
      await this.initDb();
    }

    return new Promise(async (tresolve, treject) => {
      if (!this.db) {
        treject();
        return;
      }

      const transaction = this.db.transaction('key_value_store', 'readonly').objectStore('key_value_store');

      // Chrome is extremely slow, we need to get multiple keys in one transaction or it will take 14+ seconds per sync 
      const get = (store: IDBObjectStore, keys: string[]) =>
      Promise.all(
        keys.map(
          (key) =>
            new Promise((resolve, reject) => {
              const request = store.get(key);
              request.onsuccess = (event: any) => resolve(event.target.result);
              request.onerror = (event: any) => reject(event.target.error);
            })
        )
      ).then((values) =>
        keys.reduce(
          (result, key, index) => ((result[key] = values[index]), result),
          {} as any
        )
      );
      const results = await get(transaction, keys);

      let response: string[] = [];
      for (const r of Object.values(results) as string[]) {
        response = [...response,...r];
      }

      tresolve(response as T[])

    });
  }

  // https://web.dev/persistent-storage/
  // https://web.dev/storage-for-the-web/
  async getItem<T = any>(key: string, options: { persist?: boolean } = {}): Promise<T|null> {
    if (options.persist && typeof key === 'string') {
      const localItem = window.localStorage.getItem(key);
      if (typeof localItem === 'string') {
        return JSON.parse(localItem);
      }  
    }
    
    if (!this.db) {
      await this.initDb();
    }

    return new Promise(async (tresolve, treject) => {
      if (!this.db) {
        treject();
        return;
      }

      const transaction = this.db.transaction('key_value_store', 'readonly').objectStore('key_value_store');

      // Chrome is extremely slow, we need to get multiple keys in one transaction or it will take 14+ seconds per sync 
      if (Array.isArray(key)) {
        const get = (store: IDBObjectStore, keys: string[]) =>
        Promise.all(
          keys.map(
            (key) =>
              new Promise((resolve, reject) => {
                const request = store.get(key);
                request.onsuccess = (event: any) => resolve(event.target.result);
                // request.onsuccess = ({ target: { result } }) => resolve(result);
                // request.onerror = ({ target: { error } }) => reject(error);
                request.onerror = (event: any) => reject(event.target.error);
              })
          )
        ).then((values) =>
          keys.reduce(
            (result, key, index) => ((result[key] = values[index]), result),
            {} as any
          )
        );
        const results = await get(transaction, key);

        let response: string[] = [];
        for (const r of Object.values(results) as string[]) {
          response = [...response,...r];
        }

        tresolve(response as T)
      }

      const request = transaction.get(key)
      request.onsuccess = () => {
        const item = request.result;
        if (item === null || typeof item === 'undefined') {
          tresolve(null);
          return;
        }

        tresolve(item);
      }

      request.onerror = () => { 
        treject();
      }
    });
  }

  async destroy(): Promise<void> {
    this.destroyed = true;
    window.localStorage.setItem('message', 'storage_destroyed');
    window.localStorage.clear();
   
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return resolve();
      }
      
      const transaction = this.db.transaction('key_value_store', 'readwrite').objectStore('key_value_store');
      const request = transaction.clear();
      request.onsuccess = () => {
        resolve();
        window.location.reload();
      }

      request.onerror = () => {
        reject();
      }
    });
  }

  async clear(): Promise<void> {
    window.localStorage.clear();
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return resolve();
      }
      
      const transaction = this.db.transaction('key_value_store', 'readwrite').objectStore('key_value_store');
      const request = transaction.clear();
      request.onsuccess = () => {
        resolve();
      }

      request.onerror = () => {
        reject();
      }
    });
  }
}

export const Storage: StorageInterface = new StorageClass();
