import * as React from 'react';
import styled from 'styled-components/native';
import { TextSizingCode, SizingCode, Theme, WeightSizingCode } from '../../theme';
import { getPaddingStyles, getMarginStyles } from '../../utils/style';
import { withDeviceType } from '../../hooks';

interface DeviceTypeProps {
  deviceType: 'mobile' | 'tablet' | 'desktop';
}

export interface TextProps {
  size?: TextSizingCode;
  weight?: WeightSizingCode;
  color?: 'primary' | 'secondaryOnDark' | 'secondary' | 'white' | 'black' | 'error' | 'success' | 'faded'; 
  children: React.ReactNode;
  bold?: boolean;
  underline?: boolean;
  italics?: boolean;

  /** padding */
  padding?: SizingCode;
  paddingTop?: SizingCode;
  paddingBottom?: SizingCode;
  paddingLeft?: SizingCode;
  paddingRight?: SizingCode;

  /** margin */
  margin?: SizingCode;
  marginTop?: SizingCode;
  marginBottom?: SizingCode;
  marginLeft?: SizingCode;
  marginRight?: SizingCode;

  /** align */
  alignX?: 'center';
}

interface StyledTextProps extends Omit<TextProps, 'padding' | 'paddingTop' | 'paddingBottom' | 'paddingLeft' | 'paddingRight'> {
  /** padding */
  paddingCode?: SizingCode;
  paddingTopCode?: SizingCode;
  paddingBottomCode?: SizingCode;
  paddingLeftCode?: SizingCode;
  paddingRightCode?: SizingCode;

  /** margin */
  marginCode?: SizingCode;
  marginTopCode?: SizingCode;
  marginBottomCode?: SizingCode;
  marginLeftCode?: SizingCode;
  marginRightCode?: SizingCode;

  /** align */
  alignX?: 'center';
  underline?: boolean;
  italics?: boolean;
}

const StyledText = withDeviceType(styled.Text<StyledTextProps & DeviceTypeProps>`
  text-align: ${(props) => props.alignX === 'center' ? 'center' : 'left'};
  font-size: ${(props) => props.theme.typography.textSizes[props.size || 'md']};
  font-weight: ${(props) => props.theme.typography.weights[props.weight || 'normal']};
  color: ${getTextColor};
  text-decoration: ${props => props.underline ? 'underline' : 'none'};
  font-style: ${props => props.italics ? 'italic' : 'normal'};
  text-decoration-color: ${getTextColor};

  ${getPaddingStyles};
  ${getMarginStyles};
  ${(props: DeviceTypeProps & { theme: Theme }) => props.deviceType === 'desktop' ? 'line-height: 150%;' : ''};
`);

function getTextColor(props: StyledTextProps & { theme: Theme }) {
  if (props.color === 'secondary') {
    return 'grey';
  }

  if (props.color === 'secondaryOnDark') {
    return 'rgba(255,255,255,0.6)';
  }

  if (props.color === 'white') {
    return 'white';
  }

  if (props.color === 'error') {
    return props.theme.palette.error;
  }

  if (props.color === 'success') {
    return '#579535'; // props.theme.palette.primary is too light for a white background
  }

  if (props.color === 'primary') {
    return props.theme.palette.primary;
  }

  if (props.color === 'faded') {
    return props.theme.palette.fadedText;
  }

  return 'black';
}

export const Text = (props: TextProps) => {
  return (
    <StyledText 
      size={props.size || 'md'}
      color={props.color}
      paddingCode={props.padding}
      paddingTopCode={props.paddingTop}
      paddingBottomCode={props.paddingBottom}
      paddingLeftCode={props.paddingLeft}
      paddingRightCode={props.paddingRight}
      marginCode={props.margin}
      marginTopCode={props.marginTop}
      marginBottomCode={props.marginBottom}
      marginLeftCode={props.marginLeft}
      marginRightCode={props.marginRight}
      alignX={props.alignX}
      weight={props.weight}
      underline={props.underline}
      italics={props.italics}
    >
      {props.children}
    </StyledText>
  );
}
