import { useState, useEffect, useDebugValue } from 'react';

export function useNetworkStatus(): 'online' | 'offline' {
  const [networkStatus, setNetworkStatus] = useState<'online' | 'offline'>(navigator.onLine ? 'online' : 'offline');

  useEffect(() => {
    window.addEventListener('offline', () => {
      setNetworkStatus('offline');
    });
  
    window.addEventListener('online', () => { 
      setNetworkStatus('online');
    });
  }, []);

  useDebugValue(networkStatus);

  return networkStatus;
}
