/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { RightArrowIcon } from '../../icons/RightArrow';
import { theme } from '../../theme';
import { ButtonLink } from '../Router';
import * as s from './styles';
import { userTracker, trackingCategory } from '../../controllers/userTracker';

interface NavItem {
  title: string;
  icon: any;
  selected: boolean;
  visible: boolean;
  path?: string;
}

interface NavProps {
  items: NavItem[][];
}

export const Nav = (props: NavProps) => {
  return (
    <s.NavContainer>
      {props.items.map((section, itemIdx) => section[0].visible && (
        <s.NavItem key={itemIdx}>
          {section.map((item, idx) => item.visible && (
            <React.Fragment key={idx}>
              {idx !== 0 && (
                <s.RightArrowContainer>
                  <RightArrowIcon color={'rgba(255,255,255,0.2)'} />
                </s.RightArrowContainer>
              )}
              <ButtonLink to={item.path || ''} onPress={
                () => {
                  userTracker.trackEvent(trackingCategory.NAVIGATION, 'Nav Press', { additionalInfo: item.title })
                }
              }>
                <s.ButtonPadding>
                  <item.icon color={item.selected ? theme.palette.primary : theme.palette.fadedIconColor} />
                  <s.NavItemText selected={item.selected}>{item.title}</s.NavItemText>
                </s.ButtonPadding>
              </ButtonLink>

            </React.Fragment>
          ))}
        </s.NavItem>
      ))}
    </s.NavContainer>
  )
}
