import * as React from 'react';
import { useWindowDimensions } from 'react-native';

export const useDeviceType = (): 'mobile' | 'tablet' | 'desktop' => {
  const { width } = useWindowDimensions();
  if (width < 600) {
    return 'mobile';
  }

  if (width < 900) {
    return 'tablet';
  }

  return 'desktop';
}

export const withDeviceType = <T extends { deviceType: 'mobile' | 'tablet' | 'desktop' }>(component: React.ComponentType<T>): React.ComponentType<Omit<T, 'deviceType'>> => {
  return function WithDeviceType(props: Omit<T, 'deviceType'>) {
    const deviceType = useDeviceType();
    return React.createElement(component, { deviceType, ...props } as any);
  }
}
