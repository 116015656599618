/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { useControlDialog, useDeviceType } from '../../hooks';
import { TooltipIcon } from '../../icons/Tooltip';
import { Box } from '../Box';
import { Row } from '../Row';
import { Message } from '../Translate';
import { Youtube } from '../Youtube';

interface Props {
  title: Message;
  text: Message;
  youtubeId?: string;
}

export const Tooltip = (props: Props) => {
  const { openDialog } = useControlDialog();
  return (
    <>
      <TouchableOpacity onPress={() => {
        // might not be best practice to call a container from a component but yolo
        openDialog({ 
          name: 'tooltip', 
          state: { 
            title: props.title, 
            subtitle: props.text,
            content: props.youtubeId ? <Row alignX="center"><Youtube youtubeId={props.youtubeId} width="99%" height="270px" /></Row> : null
          } 
        });
      }}>
        <TooltipIcon />
      </TouchableOpacity>
    </>
  )
}
