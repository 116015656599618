/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { SnackbarComponent } from '../../components/Snackbar';
import { SnackBarContext } from '../../hooks/useQueueMessage';

const defaultTimeout = setTimeout(() => {}, 0);

function Snackbar() {
  const [, forceRender] = React.useReducer((s) => s + 1, 0);
  const snackBarInfo = React.useContext(SnackBarContext);
  snackBarInfo.forceRender = forceRender;

  const [message, setMessage] = React.useState<{ message: string, type: "error" | "message" | "warning", action?: "link-to-settings" }|null>()
  const timeoutRef = React.useRef(defaultTimeout);

  React.useEffect(() => {
    if (snackBarInfo.messages.length > 0 && !message) {
      setMessage(snackBarInfo.messages[0]);

      function kickOffTimeout() {
        timeoutRef.current = setTimeout(() => {
          snackBarInfo.messages.shift();
          if (snackBarInfo.messages.length > 0) {
            setMessage(snackBarInfo.messages[0]);

            kickOffTimeout();
          } else {
            setMessage(null);
          }
        }, 5000);
      }

      kickOffTimeout();
    }
  }, [snackBarInfo.messages]);

  return (
    <SnackbarComponent
      type={message?.type}
      message={message?.message}
      closeMessage={() => {
        clearTimeout(timeoutRef.current);
        snackBarInfo.messages.shift();
        
        if (snackBarInfo.messages.length > 0) {
          function kickOffTimeout() {
            timeoutRef.current = setTimeout(() => {
              snackBarInfo.messages.shift();
              if (snackBarInfo.messages.length > 0) {
                setMessage(snackBarInfo.messages[0]);
    
                kickOffTimeout();
              } else {
                setMessage(null);
              }
            }, 5000);
          }
    
          setMessage(snackBarInfo.messages[0]);
          kickOffTimeout();
        } else {
          setMessage(null);
        }
      }}
      showing={!!message}
      actionLabel={message?.action === 'link-to-settings' ? 'More Details' : undefined}
      actionLink={message?.action === 'link-to-settings' ? '/settings' : undefined}
    />
  )
}

export function SnackbarProvider(props: { children: React.ReactNode }) {
  const snackBarInfo = React.useRef({ messages: [], forceRender: () => null }).current;
  return (
    <SnackBarContext.Provider value={snackBarInfo}>
      {props.children}
      <Snackbar />
    </SnackBarContext.Provider>
  )
}
