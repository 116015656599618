import * as React from 'react';
import styled from 'styled-components/native';
import { Panes, Pane } from '../../components/Panes';
import { ListButton } from '../../components/ListButton';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { useLogout } from '../../auth';
import { Storage } from '../../storage';
import { useDeviceType, useControlDialog, useHistory } from '../../hooks';
import { Permissions, FeatureList } from '@rivison-inc/ft-types';
import { useHasPermission } from '../../auth';
import { Platform, Linking } from 'react-native';
import { BackButton } from '../../components/BackButton';
import { api } from '../../api';
import { useOrgId } from '../../auth';
import moment from 'moment';
import { downloadJSON } from '../../utils/file';
import { Dialog } from '../../containers/Dialog';
import { ListTextField } from '../../components/ListTextField';
import { Form, Field } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { Text } from '../../components/Text';
import { useOrganization, useIsFeatureEnabled } from '../../data/organization';
import { Row } from '../../components/Row';
import { CheckIcon } from '../../icons/Check';
import { XIcon } from '../../icons/X';
import { useNetworkStatus } from '../../hooks/useNetworkStatus';
import { ClockIcon } from '../../icons/Clock';
import { ListFileUpload } from '../../components/ListFileUpload';
import { ListDropdownField } from '../../components/ListDropdownField';
import { useSync } from '../../data/syncHooks';
import { Box } from '../../components/Box';
import { useGetEquipmentBySerialNumber } from '../../data/equipment';

const SyncStatusWrapper = styled.View`
  padding: ${(props) => props.theme.spacing.md};
  border-radius: ${(props) => props.theme.borderRadius};
  margin: ${(props) => props.theme.spacing.md};
  margin-bottom: 0px;
`;

const InnerWrapper = styled.View`
  padding: ${(props) => props.theme.spacing.md};
  border-radius: ${(props) => props.theme.borderRadius};
  margin: ${(props) => props.theme.spacing.sm};
  align-items: center;
  justify-content: center;
`;

const PreventLayoutShift = styled.View`
  min-height: 105px; /** height of three lines of text to prevent layout shift */
`;

async function getExportData(orgId: string) {
  const response = await api.get(`/organizations/${orgId}/export`);
  const date = moment().format('LL');
  const fileName = `FlameTask Data Export ${date}.json`;

  downloadJSON(fileName, response.data);
}

async function getExportBillingData(orgId: string, startDate: string, endDate: string) {
  const response = await api.get(`/organizations/${orgId}/export/billing`, { params: { startDate, endDate } });
  const date = moment().format('LL');
  const fileName = `FlameTask Billing Data Export ${date}.json`;

  downloadJSON(fileName, response.data);
}


export function FindEquipmentBySerialDialog() {
  const orgId = useOrgId();
  const { closeDialog } = useControlDialog();
  const { getEquipmentBySerialNumber } = useGetEquipmentBySerialNumber();
  const history = useHistory();
  const device = useDeviceType();

  return (
    <Dialog 
      name="find-by-serial"
      title={'Find equipment by serial number'}
      subtitle={'Enter a serial number'}
      dontStickToBottomOnMobile
    >
      <Form 
        initialValues={{
          serialNumber: '',
        }}
        onSubmit={async (values) => {
          const serialNumber = values.serialNumber
          const result = await getEquipmentBySerialNumber(serialNumber);
          if (!result) {
            alert('Equipment not found');
            return;
          }
          history.push(`/sites/${result.locationId}`);
          if (device==='desktop') {
            history.push(`/sites/${result.locationId}/equipment/${result.id}`);
          } else {
            history.push(`/sites/${result.locationId}/equipment-tag/${result.id}`);
          }

          closeDialog();
        }}
      >
        <Field
          autofocus
          as={ListTextField}
          name={'serialNumber'}
          label={'Serial Number'}
        />
        
        <SubmitButton 
          as={ListButton} 
          label={'Find'} 
        />
      </Form>
      <ListButton 
        label="Cancel"
        onPress={() => {
          closeDialog();
        }}
      />
    </Dialog>);
}

export function ExportBillingDialog() {
  const orgId = useOrgId();
  const { closeDialog } = useControlDialog();

  return (
    <Dialog 
      name="export-billing"
      title={'Export Billing Data'}
      subtitle={'Select a date range'}
    >
      <Form 
        initialValues={{
          startDate: moment().startOf('month').format('YYYY-MM-DD'),
          endDate: moment().endOf('month').format('YYYY-MM-DD'),
        }}
        onSubmit={(values) => {
          getExportBillingData(orgId || '', values.startDate, values.endDate);
          closeDialog();
        }}
      >
        <Field
          as={ListTextField}
          name={'startDate'}
          label={'Start Date'}
        />
        <Field
          as={ListTextField}
          name={'endDate'}
          label={'End Date'}
        />

        <SubmitButton 
          as={ListButton} 
          label={'Export'} 
        />
      </Form>
      <ListButton 
        label="Cancel"
        onPress={() => {
          closeDialog();
        }}
      />
    </Dialog>);
}

export function ImportDialog() {
  const orgId = useOrgId();
  const { closeDialog } = useControlDialog();

  return (
    <Dialog 
      name="import-data"
      title={'Import Data'}
      subtitle={'Select the application you are importing from below. If it is not listed, contact customer support and see if your data can be imported.'}
    >
      <Form 
        initialValues={{}}
        onSubmit={async (updates) => {
          console.log('updates',updates)
        }}
      >
        <Field 
          as={ListFileUpload} 
          name="dataFile" 
          label="Data File" 
        />
        <Field 
          as={ListDropdownField} 
          name="source" 
          label="Data Origin" 
          options={[{ label: 'Building Reports - Safety Scan', value: 'building-reports-safety-scan' }]}
        />
      </Form>

      <SubmitButton 
        as={ListButton}
        label="Import"
      />
      <ListButton 
        label="Cancel"
        onPress={() => {
          closeDialog();
        }}
      />
    </Dialog>);
}

export function SyncStatus() {
  const networkStatus = useNetworkStatus();
  const { isUploading, lastSuccessfulDownloadDate, lastSuccessfulUploadDate } = useSync()

  return (
    <SyncStatusWrapper>
      <PreventLayoutShift>
        <Row alignY="center" alignX='center'>
          {networkStatus === 'online' && 
            <>
              <CheckIcon color="success" /> 
              <Box paddingLeft='xs'>
                <Text color="success" weight='medium' size='md'>Online</Text>
              </Box>
            </>
          }
          {networkStatus === 'offline' && 
            <>
              <XIcon color="error" /> 
              <Box paddingLeft='xs'>
                <Text color="error" weight='medium' size='md'>Offline</Text>
              </Box>
            </>
          }
        </Row>

        
        <Box alignX='center' flexDirection='row'>
          <InnerWrapper>
            <Text size='sm' weight='medium'>
              Last Download
            </Text>
            <Text size='xl' weight='medium'>
              {lastSuccessfulDownloadDate ? moment(lastSuccessfulDownloadDate).format('h:mma') : 'Never'}
            </Text>
            <Text size='xs' color='secondary'>
              {lastSuccessfulDownloadDate ? moment(lastSuccessfulDownloadDate).format('MMMM DD') : 'Never'}
            </Text>            
          </InnerWrapper>

          <InnerWrapper>
            <Text size='sm' weight='medium'>
              Last Upload
            </Text>
            <Text size='xl' weight='medium'>
              {lastSuccessfulUploadDate ? moment(lastSuccessfulUploadDate).format('h:mma') : 'Never'}
            </Text>
            <Text size='xs' color='secondary'>
              {lastSuccessfulUploadDate ? moment(lastSuccessfulUploadDate).format('MMMM DD') : 'Never'}
            </Text> 
          </InnerWrapper>

          
        </Box>

      </PreventLayoutShift>
    </SyncStatusWrapper>
  )
}

export function SettingsPage() {
  const canRead = useHasPermission(Permissions.SETTINGS_READ);  
  const canReadEquipment = useHasPermission(Permissions.EQUIPMENT_READ);  
  const deviceType = useDeviceType();
  const orgId = useOrgId();
  const serviceMonthsEnabled = useIsFeatureEnabled(FeatureList.SERVICE_MONTHS);
  const siteTypesEnabled = useIsFeatureEnabled(FeatureList.SITE_TYPES);
  const billingEnabled = useIsFeatureEnabled(FeatureList.BILLING);
  const { organization } = useOrganization();
  const [billingPortalUrl, setBillingPortalUrl] = React.useState('');
  const { logout } = useLogout();
  const { openDialog } = useControlDialog();
  const { upload, refetch, isUploading, isDownloading } = useSync()

  React.useEffect(() => {
    if (organization?.id && !organization.manualBilling) {
      api.post(`/organizations/${orgId}/payments/customer-portal-session`, { })
        .then((response) => {
          const portalUrl = response.data.session.url;
          setBillingPortalUrl(portalUrl);
        });
    }
  }, [organization?.id]);

  if (!canRead) {
    return <></>;
  }

  return (
    <>
      <ExportBillingDialog />
      <FindEquipmentBySerialDialog />
      <ImportDialog />
      <Panes backgroundColor="light" leftButton={deviceType !== 'desktop' && <BackButton />}>
        <Pane title="Settings">

          <ListButton
            onPress={() => {
              if (!isUploading && !isDownloading) {
                upload();
                refetch();
              }
            }}
            label={isUploading || isDownloading ? 'Syncing...' : 'Sync Now'}
          />

          <SyncStatus />

          {Platform.OS === 'web' && canRead && (
            <>

              <ListSectionTitle>General</ListSectionTitle>

              <ListButton
                to="/profile"
                label={'Profile'}
              />

              {!organization?.manualBilling && (
                <ListButton 
                  onPress={() => Linking.openURL(billingPortalUrl) }
                  label="Billing Portal"
                />
              )}
              
              <ListButton
                to="/organization"
                label={'Organization'}
              />
              <ListButton
                to="/preferences"
                label={'Preferences'}
              />
              
              
              <ListSectionTitle>Setup</ListSectionTitle>
              <ListButton
                to="/users"
                label={'Users'}
              />
              <ListButton 
                to="/custom-fields"
                label="Custom Fields"
              />
              <ListButton
                to="/equipment-types"
                label={'Equipment Types'}
              />
              {siteTypesEnabled && (
                <ListButton
                  to="/site-types/"
                  label={'Site Types'}
                />
              )}
              {serviceMonthsEnabled && (
                <ListButton
                  to="/service-month-categories"
                  label={'Service Month Categories'}
                />
              )}
            </>
          )}

          <>

            <ListSectionTitle>Global Search</ListSectionTitle>
              
            {canReadEquipment && (
              <ListButton
                label="Find equipment by serial number"
                onPress={() => {
                  openDialog({ name: 'find-by-serial', state: { orgId } });
                }}
              />
            )}
            
          </>

          {Platform.OS === 'web' && canRead && (
            <>

              <ListSectionTitle>Data</ListSectionTitle>

              <ListButton
                label="Export Data"
                onPress={() => {
                  getExportData(orgId || '');
                }}
              />

              {billingEnabled && (
                <ListButton
                  label="Export Billing Data"
                  onPress={() => {
                    openDialog({ name: 'export-billing', state: { orgId } });
                  }}
                />
              )}

              {/* <ListButton
                label="Import Data"
                onPress={() => {
                  openDialog({ name: 'import-data', state: { orgId } });
                }}
              /> */}

            </>
          )}
          
          <ListSectionTitle>Other</ListSectionTitle>

          {deviceType === 'desktop' && (
            <>
              <ListButton 
                onPress={() => Linking.openURL('mailto:customersupport@rivison.com?subject=Barcode Order&body=Hello, I am looking for barcodes for use with FlameTask. %0D%0A%0D%0A First/Last Name: %0D%0A%0D%0A Phone Number: %0D%0A%0D%0A Thank You.') }
                label="Purchase Barcodes"
              />
              
            </>
          )}

          <ListButton
            to="/debug"
            label={'Debug'}
          />

          <ListButton
            label="Logout"
            onPress={() => {
              Storage.destroy();
            }}
          />
          
        </Pane>
      </Panes>
    </>
  )
}
