import * as React from 'react';
import { useParams } from '../../hooks';
import groupBy from 'lodash/groupBy';

/** components */
import { ListTextField } from '../../components/ListTextField';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { Field, useFormValues } from '../../components/Form';
import { ListAutocomplete } from '../../components/ListAutocomplete';
import { AddIcon } from '../../icons/Add';
import { Row } from '../../components/Row';
import { Text } from '../../components/Text';
import { ListDropdownField } from '../../components/ListDropdownField';
import { Drawer } from '../../components/Drawer';
import { Form } from '../../components/Form';
import { ListButton } from '../../components/ListButton';
import { SubmitButton } from '../../components/SubmitButton';
import { ListDateField } from '../../components/ListDateField';
import { PaneButton } from '../../components/PaneButton';
import { BarCodeScanner } from '../../components/BarCodeScanner';
import { FloorPlanDrawer } from '../../containers/FloorPlanDrawer';

/** nested forms */
import { ModelForm } from '../ModelForm';

/** data */
import { Model, Equipment } from '@rivison-inc/ft-types';
import { useModels, useModel, useAddModel } from '../../data/models';
import { useOrgId } from '../../auth';
import { Platform } from 'react-native';
import { useFloorPlans } from '../../data/floorPlans';
import { useEquipmentFields } from '../../data/equipmentFields';
import { sortByString } from '../../utils/sort';
import { useEquipmentTypes } from '../../data/equipmentTypes';
import { getStatusProps } from 'react-query/types/core/utils';
import { useLocation } from '../../data/locations';

export function EquipmentForm() {
  const params = useParams<{ locationId: string }>();
  const formValues = useFormValues() as any as Equipment;

  const { floorPlans } = useFloorPlans(params.locationId);
  const { model } = useModel(formValues.modelId);
  const { location } = useLocation(params.locationId);
  const { equipmentFields } = useEquipmentFields({ equipmentTypeId: model?.equipmentTypeId });

  if (!location) {
    return null;
  }

  return (
    <>
      <ListSectionTitle>General</ListSectionTitle>
      <Field 
        as={ModelField} 
        name="modelId" 
      />
      <Field 
        as={ListTextField} 
        name="name" 
        label="Location" 
        type="text"
        required
      />
      <Field 
        as={ListDateField} 
        name="manufactureDate" 
        label="Manufactured" 
        required
        granularity={'day'}
        timeZone={location.timeZone}
      />
      <Field 
        as={BarcodeField} 
        name="barcode" 
      />
      <Field 
        as={SerialNumberField} 
        name="serialNumber" 
      />
      {/* <Field 
        as={ListTextField} 
        name="partNumber" 
        label="Part #" 
        type="text"
      /> */}
      <Field 
        as={ListTextField} 
        name="note" 
        label="Note" 
        type="text"
      />
      <Field 
        as={ListDropdownField} 
        name="floorId" 
        label="Floor" 
        options={[
          ...floorPlans.map((plan) => ({ label: plan.name, value: plan.id })),
        ]}
        hasEmptyOption
      />
      {!!formValues.floorId && (
        <Field
          as={FloorPlanDrawer} 
          name="floorPlanLocation" 
          floorId={formValues.floorId}
        />
      )}
      
      {Boolean(equipmentFields?.length) && (
        <>
          <ListSectionTitle>Custom Fields</ListSectionTitle>
          {equipmentFields.map((equipmentField, idx) => (
            <Field 
              key={idx}
              as={ListTextField} 
              name={`fieldData.${equipmentField.id}`} 
              label={equipmentField.name} 
              type="text"
            />
          ))}
        </>
      )}
    </>
  )
}

function BarcodeField(props: { value: string; onChange: (newValue: string) => void }) {
  const [isScanning, setIsScanning] = React.useState(false);
  return (
    <>
      <ListTextField
        label="Barcode"
        type="text"
        value={props.value}
        onChange={props.onChange}
        rightComponent={(
          Platform.OS === 'web' ? undefined : 
            <PaneButton 
              label="Scan" 
              onPress={() => {
                setIsScanning(true);
              }} 
            />
        )}
      />
      {isScanning && (
        <BarCodeScanner 
          onCancel={() => {
            setIsScanning(false);
          }}
          onScan={({ data }) => {
            props.onChange(data);
            setIsScanning(false);
          }}
        />
      )}
    </>
  )
}
  
function SerialNumberField(props: { value: string; onChange: (newValue: string) => void }) {
  const formValues = useFormValues() as any as Equipment;
  return (
    <ListTextField
      label="Serial Number"
      type="text"
      value={props.value}
      onChange={props.onChange}
      rightComponent={(
        Platform.OS === 'web' ? undefined : 
          <PaneButton 
            label="Copy Barcode" 
            onPress={() => {
              props.onChange(formValues.barcode || '');
            }} 
          />
      )}
    />
  )
}
  
function ModelField(props: { value: string; onChange: (newValue: string) => void }) {
  const [isAdding, setIsAdding] = React.useState(false);
  const { models } = useModels();
  const { equipmentTypes } = useEquipmentTypes();
  const { addModel } = useAddModel();
  const orgId = useOrgId();
  const equipmentTypesObj = groupBy(equipmentTypes, 'id');

  const modelsFormatted = (models || []).map((model) => {
    let sublabel = '';
    const fieldData = model.fieldData;
    if (fieldData) {
      Object.values(fieldData).forEach((fieldValue) => {
        sublabel += `${fieldValue || ''} `;
      });
    }

    const equipmentType = model.equipmentTypeId ? equipmentTypesObj[model.equipmentTypeId] : [];
    
    return { 
      value: model.id, 
      label: `${model.name} ${(equipmentType && equipmentType[0]?.name) || ''}`,
      sublabel,
    }
  });

  const modelsSorted = sortByString(modelsFormatted, 'label', 'asc');

  const emptyModel: Omit<Model, 'id' | 'deleted' | 'lastUpdated'> = {
    name: '',
    orgId: orgId || '',
  }

  return (
    <>
      <ListAutocomplete 
        value={props.value}
        onChange={(newValue) => {
          props.onChange(newValue);
        }}
        label="Model"
        helperText="Search Results"
        options={modelsSorted}
        bottomOption={{
          label: (
            <Row alignY="center">
              <AddIcon />
              <Text paddingLeft="sm" size="sm">Add New Model</Text>
            </Row>
          ),
          onPress: () => {
            setIsAdding(true);
          }
        }}
      />
      <Drawer 
        open={isAdding}
        title={'Add Model'}
        onRequestClose={() => {
          setIsAdding(false);
        }}
      >
        <Form 
          initialValues={emptyModel} 
          onSubmit={async (model) => {
            const newItem = await addModel(model);
            props.onChange(newItem.id);
            setIsAdding(false);
          }}
        >
          <ModelForm />
          <SubmitButton as={ListButton} label="Add Model" />
        </Form>
      </Drawer>
    </>
  )
}
