/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import styled from 'styled-components/native';
import { getBackgroundStyles, getMarginStyles, getPaddingStyles, getAlignStyles } from '../../utils/style';
import { SizingCode, Theme } from '../../theme';
import { useDeviceType } from '../../hooks';

export interface FullScreenMessageProps {
  opened: boolean;
  title: string;
  subtitle: string;
  component?: React.ReactNode;
}

const Container = styled.View`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0,0.8);
  z-index: 99;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.Text`
  color: white;
  font-size: ${(props: { theme: Theme }) => props.theme.typography.textSizes.xxl };
`;
const SubHeading = styled.Text`
  color: rgba(255,255,255,0.5);
  padding-top: ${(props: { theme: Theme }) => props.theme.spacing.md };
  padding-bottom: ${(props: { theme: Theme }) => props.theme.spacing.lg };
  font-size: ${(props: { theme: Theme }) => props.theme.typography.textSizes.md };
`;

export function FullScreenMessage(props: FullScreenMessageProps) {
  const deviceType = useDeviceType();
  // todo: support mobile
  if (deviceType !== 'desktop') return null;
  if (!props.opened) return null; 
  return (
    <Container>
      <Heading>{props.title}</Heading>
      <SubHeading>{props.subtitle}</SubHeading>
      {props.component}
    </Container>
  )
}
