/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { Platform, CheckBox, View } from 'react-native';
import styled from 'styled-components/native';
import { SizingCode, Theme } from '../../theme';
import { getMarginStyles } from '../../utils/style';
import { Box } from '../Box';
import { Row } from '../Row';
import { Tooltip } from '../Tooltip';
import { TextBox } from './TextBox';
import { RequiredIcon } from '../../icons/Required';
import { Message } from '../Translate';
import { LoadingSpinner } from '../LoadingSpinner';
import { Text } from '../Text';

export interface ListTextFieldProps {
  label: string;
  type?: 'text' | 'number' | 'password' | 'email' | 'name' | 'checkbox';
  value?: string;
  onChange?: (newText: string) => void;
  onEnter?: () => void;
  autofocus?: boolean;
  rightComponent?: React.ReactNode;
  required?: boolean;
  placeholder?: string;
  tooltip?: {
    title: Message;
    text: Message;
    youtubeId?: string;
  };
  numberOfLines?: number;
  disabled?: boolean;
  loading?: boolean;
  errorText?: string;
}

const ListTextFieldView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom-color: ${(props: { theme: Theme }) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
  position: relative;
`;

const FieldLabelText = styled.Text`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  display: flex;
  min-width: 120px;
`;

const RequiredContainer = styled.View`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const RequiredText = styled.Text`
  color: ${(props: { theme: Theme }) => props.theme.palette.snackbar.error};
  font-size: 10px;
`;

interface CheckBoxProps {
  marginLeftCode?: SizingCode;
}

const CheckBoxContainer = styled.View<CheckBoxProps>`
  ${getMarginStyles}
`;

const InputBox = styled.View`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  background-color: rgb(247, 247, 247);
  border-left-width: 1px;
  border-left-color: ${(props) => props.theme.palette.lineColor};
`;

export const ListTextField = (props: ListTextFieldProps) => {
  const type = props.type || 'text';
  const textboxRef = React.useRef<any>(null);

  React.useEffect(() => {
    if(props.autofocus){
      const textBox = textboxRef!.current!;
      textBox.focus();
    }
  }, []);

  const isText = (type === 'text' || type === 'number' || type === 'email' || type === 'password' || type === 'name');
  return (
    <ListTextFieldView>

      {type === 'checkbox' && (
        <CheckBoxContainer marginLeftCode={'sm'}>
          <CheckBox />
        </CheckBoxContainer>
      )}

      {/** Hides this label since we use `accessibilityLabel` below and don't want it read twice */}
      <FieldLabelText 
        accessibilityElementsHidden={true}
        importantForAccessibility="no-hide-descendants" 
      >
        {props.label}
        {props.tooltip && (
          <Tooltip title={props.tooltip.title} text={props.tooltip.text} youtubeId={props.tooltip.youtubeId} />
        )}
      </FieldLabelText>

      {(props.required && !props.value) && (
        <RequiredContainer>
          {/* <RequiredText>Required</RequiredText> */}
          <RequiredIcon />
        </RequiredContainer>
      )}

      {isText && (
        <InputBox>
          <Row justifyContent="space-between" alignY="center">
            <TextBox 
              editable={!props.disabled}
              autoCapitalize={(type === 'email' || type === 'password') ? 'none' : undefined}
              autoCompleteType={getAutoCompleteType(type)}
              onChangeText={(text) => {
                if (props.type === 'number') {
                  text = parseFloat(text) ? parseFloat(text).toString() : '';
                }
                if (props.onChange) {
                  props.onChange(text)
                }
              }}
              onKeyPress={(e) => {
                if(e.nativeEvent.key == "Enter"){
                  if (props.onEnter) { 
                    props.onEnter();
                  }
                }
              }}
              value={props.value || ''}
              ref={textboxRef}
              keyboardType={type === 'email' ? 'email-address' : undefined}
              secureTextEntry={type === 'password'}
              accessibilityLabel={props.label}
              placeholder={props.placeholder}
              multiline={(props.numberOfLines || 0) > 1}
              numberOfLines={props.numberOfLines || 1}
            />
            {(props.rightComponent || props.loading) && (
              <Box paddingRight="md">
                {props.rightComponent}
                {props.loading && <LoadingSpinner />}
              </Box>
            )}
          </Row>
          {props.errorText && (
            <Box padding="sm">
              <Text color="error">{props.errorText}</Text>
            </Box>
          )}          
        </InputBox>
      )}

    </ListTextFieldView>
  )
}

function getAutoCompleteType(type?: ListTextFieldProps['type']) {
  if (type === 'email') {
    return 'email';
  }

  if (type === 'password') {
    return 'password';
  }

  if (type === 'name') {
    return 'name';
  }

  // Android will have a fit if this isnt here
  if (Platform.OS === 'android') {
    return null as any as 'name';
  }

  return 'nope' as 'name'; /** Bug in chrome ignores "no", must set to something else like "nope" */
}
