import { ScheduledEvent } from "@rivison-inc/ft-types";
import { useMemo } from "react";
import { useToday } from "../hooks";
import { makeDataHooks } from "./utils";
import moment from 'moment';

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
} = makeDataHooks<ScheduledEvent>({
  dataName: "ScheduledEvents",
});

export function useScheduledEvent(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    scheduledEvent: item,
  }
}

export function useScheduledEvents() {
  const { data } = useData();

  return {
    scheduledEvents: data,
  }
}

export function useAddScheduledEvent() {
  const { addItem } = useAddData();

  return {
    addScheduledEvent: addItem,
  }
}

export function useDeleteScheduledEvent() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteScheduledEvent: deleteItem,
  }
}

export function useUpdateScheduledEvent() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateScheduledEvent: updateItem,
  }
}

export function useScheduledEventsForToday() {
  const today = useToday();
  const { scheduledEvents } = useScheduledEvents();
  return useMemo(() => scheduledEvents.filter((record) => moment(record.date).isSame(today, 'day')), [scheduledEvents, today]);
}

export function useScheduledEventsForThisMonth() {
  const today = useToday();
  const { scheduledEvents } = useScheduledEvents();
  return useMemo(() => scheduledEvents.filter((record) => moment(record.date).isSame(today, 'month')), [scheduledEvents, today]);
}
