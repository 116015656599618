import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useAddLocation } from '../../data/locations';
import { useHistory } from '../../hooks';
import { LocationForm } from '../../forms/LocationForm';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useOrgId, useHasPermission } from '../../auth';
import { useOrganization } from '../../data/organization';
import { Location } from '@rivison-inc/ft-types';
import { PaneButton } from '../../components/PaneButton';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';
import moment from 'moment';
import 'moment-timezone';
import csc from 'country-state-city'
import { useParams } from '../../hooks';

export function AddLocationPage() {
  const history = useHistory();
  const orgId = useOrgId();
  const { organization: org } = useOrganization();
  const { addLocation } = useAddLocation();
  const params = useParams<{ customerId: string }>();

  const countries = csc.getAllCountries();
  const country = org?.country || '';
  const countryInfo = countries.find(c => c.name === country);
  const timezoneGuess = moment.tz.guess();
  const possibleTimeZones = (countryInfo?.sortname && moment.tz.zonesForCountry(countryInfo.sortname)) || [];
  const currentTimeZone = possibleTimeZones.includes(timezoneGuess) ? timezoneGuess : '';
  const [initialValues, setInitialValues] = React.useState<Omit<Location, 'id' | 'lastUpdated' | 'deleted'>>({
    name: '',
    zone: '',
    address: '',
    city: '',
    orgId: orgId || '',
    status: 'active',
    popupNote: '',
    note: '',
    contact: '',
    phone: '',
    cell: '',
    email: '',
    fax: '',
    addressTwo: '',
    postalCode: '',
    country: country,
    province: org?.province || '',
    timeZone: currentTimeZone,
    customerId: params.customerId,
  });

  React.useEffect(() => {
    if (org) {
      setInitialValues({
        ...initialValues,
        country,
        province: org.province || '',
        timeZone: currentTimeZone
      });
    }
  }, [!org]);

  const canWrite = useHasPermission(Permissions.LOCATION_WRITE);

  if (!canWrite || !org) {
    return <></>;
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (location) => {
        const newLocation = await addLocation({
          ...location,
          // @ts-ignore
          siteNumber: location.customerNumber
        });
        history.replace(`/sites/${newLocation.id}`);
      }}
    >
      <Panes 
        backgroundColor="light"
        leftButton={<BackButton />}
      >
        <Pane 
          title="Add Site"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <LocationForm 
            autoGenerateSiteNumber
          />
        </Pane>
      </Panes>
    </Form>
  )
}
