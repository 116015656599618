/// <reference path="../react-native-test-flight.d.ts" />

import RNTestFlight from 'react-native-test-flight';
import { Platform } from 'react-native';

export function useEnviroment() {
    let isBeta = false;
    if (Platform.OS === 'ios') {
        isBeta = RNTestFlight.isTestFlight;
    } 

    return {
        isBeta
    }
}

