import { HistoryRecord } from "@rivison-inc/ft-types";
import { useMemo } from "react";
import { makeDataHooks } from "./utils";

const {
  useUpdateDataItem,
  useDeleteDataItem,
  useAddData,
  useDataById,
  useData
} = makeDataHooks<HistoryRecord>({
  dataName: "History",
});

export function useHistoryRecord(params: { id: string|undefined; locationId: string|undefined }) {
  const { item } = useDataById(params.id, params.locationId);

  return {
    historyRecord: item,
  }
}

export function useHistoryRecords(locationId: string) {
  const { data, isLoading } = useData(undefined, locationId);

  return {
    historyRecords: data,
    isLoading
  }
}

export function useHistoryRecordsForEquipment(params: { locationId: string; equipmentId: string }) {
  const { data } = useData(undefined, params.locationId);
  const filteredData = useMemo(() => {
    return data.filter((d) => d.equipmentId === params.equipmentId);
  }, [data]);

  return {
    historyRecords: filteredData,
  }
}

export function useAddHistoryRecord() {
  const { addItem } = useAddData();

  return {
    addHistoryRecord: addItem,
  }
}

export function useDeleteHistoryRecord() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteHistoryRecord: deleteItem,
  }
}

export function useUpdateHistoryRecord() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateHistoryRecord: updateItem,
  }
}
