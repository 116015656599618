import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useAddServiceMonthCategory } from '../../data/serviceMonthsCategories';
import { useHistory } from '../../hooks';
import { ServiceMonthCategoriesForm } from '../../forms/ServiceMonthCategoriesForm';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { useOrgId } from '../../auth';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddServiceMonthCategoryPage() {
  const history = useHistory();
  const orgId = useOrgId();
  const canWrite = useHasPermission(Permissions.LOCATION_WRITE);
  const { addServiceMonthCategory } = useAddServiceMonthCategory();

  if (!canWrite) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        name: '',
        orgId: orgId || '',
      }}
      onSubmit={async (serviceMonthCategory) => {
        await addServiceMonthCategory(serviceMonthCategory);
        history.goBack();
      }}
    >
      <Panes 
        backgroundColor="light"
        leftButton={<BackButton />}
      >
        <Pane 
          title="Add Service Month Category"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <ServiceMonthCategoriesForm />
        </Pane>
      </Panes>
    </Form>
  )
}
  
