import moment from 'moment';
import * as React from 'react';
import { Form, Field, useFormValues } from '../../components/Form';
import { ListButton } from '../../components/ListButton';
import { ListItem } from '../../components/ListItem';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListTextField } from '../../components/ListTextField';
import { Pane, Panes } from '../../components/Panes';
import { ListSwitch } from '../../components/ListSwitch';
import { ButtonLink } from '../../components/Router';
import { SubmitButton } from '../../components/SubmitButton';
import { Dialog } from '../../containers/Dialog';
import { useControlDialog, useOpenDialogName, useRouteMatch } from '../../hooks';
import { ReportDef } from './types';
import { Message } from '../../translations';
import { reports } from './reports';
import { useUsers } from '../../data/users';
import { useModelFields } from '../../data/modelFields';
import { useLocation } from '../../data/locations';
import { useParams } from '../../hooks';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { api } from '../../api';
import { userTracker, trackingCategory } from '../../controllers/userTracker';
import { downloadBlob } from '../../utils/file';
import { useStableMemo } from '../../hooks/useStableMemo';
import { ListDateField } from '../../components/ListDateField';
import { Accordion } from '../../components/Accordion';
import { Row } from '../../components/Row';
import { View } from 'react-native';
import { Box } from '../../components/Box';
import { Hider } from '../../components/Hider';
import { UploadedAsOf } from '../../containers/UploadedAsOf';
interface ReportPageProps {
  type: 'global' | 'location';
}

function ReportField(props: { formElement: FormElement; formValues:  { [fieldName: string]: unknown }; timeZone: string; type: 'advanced' | 'normal' }) {
  if (props.formElement.advanced && props.type !== 'advanced') {
      return <></>;
  }
  if (!props.formElement.advanced && props.type === 'advanced') {
    return <></>;
  }
  if (props.formElement.showIfEnabled) {
    if (!props.formValues[props.formElement.showIfEnabled]) {
      return <></>;
    }
  }
  if (props.formElement.type === 'dropdown') {
    return (
      <Field
        as={ListDropdownField}
        name={props.formElement.id}
        label={props.formElement.label}
        options={props.formElement.options || []}
      />
    );
  }
  if (props.formElement.type === 'checkbox') {
    return (
      <Field
        as={ListSwitch}
        name={props.formElement.id}
        label={props.formElement.label}
      />
    );
  }

  if (props.formElement.type === 'date') {
    return (
      <Field
        as={ListDateField}
        name={props.formElement.id}
        label={props.formElement.label}
        granularity='day'
        timeZone={props.timeZone}
      />
    );
  }

  return (
    <Field
      as={ListTextField}
      name={props.formElement.id}
      label={props.formElement.label}
    />
  );
}

function FormContent(props: DynamicFormProps) {
  const formValues = useFormValues();
  const currentTimeZone = moment.tz.guess() || 'America/Toronto';

  const locationPageMatch = useRouteMatch<{ locationId: string }>({ path: '/sites/:locationId' });
  const locationId = locationPageMatch?.params.locationId;
  const { location } = useLocation(locationId);

  return (<>
    {/* Use Hider here instead of removing elements from the page #1718 */}
    {<Hider hidden={props.loading || false}>

      {props.form?.map((formElement, idx) => {
        return <ReportField formElement={formElement} formValues={formValues} key={idx} timeZone={location?.timeZone || currentTimeZone} type={'normal'} />
      })}

      <Accordion closedTitle="Show Advanced" openedTitle="Hide Advanced">

        {props.form?.map((formElement, idx) => {
          return <ReportField formElement={formElement} formValues={formValues} key={idx} timeZone={location?.timeZone || currentTimeZone} type={'advanced'} />
        })}

        <Field
          as={ListDropdownField}
          name={'reportType'}
          label={'Type'}
          options={[
            { label: 'PDF', value: 'pdf', },
            { label: 'CSV', value: 'csv', },
          ]}
        />

        <Field
          as={ListDropdownField}
          name={'layout'}
          label={'Layout'}
          options={[
            { label: 'Portrait', value: 'portrait', },
            { label: 'Landscape', value: 'landscape', },
          ]}
        />

      </Accordion>

    </Hider>}

    <Box flexDirection="row" width='full'>
      {props.buttons}
    </Box>
  </>)
}

export function ReportsPage(props: ReportPageProps) {
  const canRead = useHasPermission(Permissions.REPORT_READ);
  const [loading, setLoading] = React.useState(false)

  const currentReportDefName = useOpenDialogName();
  const { openDialog, closeDialog } = useControlDialog();
  const params = useParams<{ locationId: string }>();

  const { location } = useLocation(params.locationId);

  const { users } = useUsers();
  const { modelFields } = useModelFields();

  const prepareReport = async (reportDef: ReportDef) => {
    setLoading(false);
    openDialog({ name: reportDef.reportName, state: {} });
  };

  const currentReportDef = useStableMemo(() => reports.find((report) => report.reportName === currentReportDefName), [reports, currentReportDefName]);
  const filteredReports = reports.filter((r) => r.type === props.type);

  const form = useStableMemo(() => {
    const userOptions = users.map(user => ({ value: user.id, label: user.name }));
    const modelFieldOptions = modelFields.map(modelField => ({ value: modelField.id, label: modelField.name, default: modelField.includeInReports }));
    const formInputs = { users: userOptions, modelFields: modelFieldOptions };

    return typeof currentReportDef?.dialog?.form === 'function' ? currentReportDef.dialog?.form(formInputs) : currentReportDef?.dialog?.form;
  }, [users, modelFields, currentReportDef]);

  if (!canRead) {
    return <></>;
  }

  return (
    <Panes
      backgroundColor="light"
    >
      <Pane
        title={props.type === 'global' ? 'Company-Wide Reports' : 'Site Reports'}
      >
        <>
          <UploadedAsOf />
          {filteredReports.map((report, idx) => !report.disabled && (
            <>
              <ButtonLink
               onPress={() => {
                  prepareReport(report)
                  userTracker.trackEvent(trackingCategory.REPORTS, 'Prep Report', { additionalInfo: report.reportName })
               }}
              >
                <ListItem
                  key={idx} 
                  title={report.reportName}
                  subtitle={`${report.description}`}
                />
              </ButtonLink>
            </>
          ))}
          {currentReportDef && currentReportDefName && (
            <Dialog 
              name={currentReportDef.reportName}
              title={currentReportDef.dialog?.title}
              subtitle={currentReportDef.dialog?.message}
            >
              <DynamicForm 
                key={currentReportDef.reportName}
                buttons={(
                  <>
                    <ListButton 
                      label={'Cancel'} 
                      onPress={closeDialog}
                      grow
                    />
                    <SubmitButton 
                      as={ListButton} 
                      label={loading ? 'Loading...' : 'Create'} 
                      grow
                    />
                  </>
                )}
                loading={loading}
                layout={currentReportDef.layout}
                form={form}
                onSubmit={async (formValues: { [ key: string]: string | number }) => {
                  setLoading(true);
                  // timeout will make loading text change faster
                  setTimeout(async () => {
                    const reportName = currentReportDef.reportName;
                    const currentLocationName = location?.name || '';
                    const formattedDate = moment().format('LL')
                    const fileType = formValues.reportType;

                    const response = await api.get(
                      currentReportDef.endpoint, 
                      {
                        params: {
                          ...formValues,
                          locationId: params.locationId || undefined,
                        },
                      }, 
                      { responseType: 'blob' }
                    );

                    const blob = response.data;

                    const fileName = `${reportName} ${currentLocationName ? ` - ${currentLocationName}` : ''} - ${formattedDate}.${fileType}`;

                    downloadBlob(fileName, blob);

                    closeDialog();

                    userTracker.trackEvent(trackingCategory.REPORTS, 'Open Report', { additionalInfo: reportName })
                  }, 0);
                }}
              />
            </Dialog>
          )}
        </>
      </Pane>
    </Panes>
  )
}

interface FormElement {
  type: 'text' | 'date' | 'dropdown' | 'checkbox';
  label: Message;
  id: string;
  default?: string | number | boolean;
  options?: { value: string; label: string }[];
  showIfEnabled?: string;
  advanced?: boolean;
}

interface DynamicFormProps {
  buttons: React.ReactNode;
  loading?: boolean;
  form?: FormElement[];
  layout: 'portrait' | 'landscape';
  onSubmit: (formValues: { [ key: string]: string | number }) => void;
}

function DynamicForm(props: DynamicFormProps) {
  const initialValues = useStableMemo(() => {
    return props?.form?.reduce((acc, formElement) => {
      acc[formElement.id] = formElement.default;
      return acc;
    }, {} as { [key: string]: any });
  }, [props?.form]);

  if (props?.layout && initialValues) {
    initialValues.layout = props.layout;
  }

  return (
    <Form 
      initialValues={initialValues}
      onSubmit={props.onSubmit}
    >
      <FormContent {...props} />
    </Form>
  )
}
