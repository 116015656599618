import * as React from 'react';
import { Form } from '../../components/Form';
import { PaneButton } from '../../components/PaneButton';
import { Pane, Panes } from '../../components/Panes';
import { SubmitButton } from '../../components/SubmitButton';
import { OrganizationForm } from '../../forms/OrganizationForm';
import { useHistory } from '../../hooks';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';
import { useOrganization, useSaveOrganization } from '../../data/organization';
import { PreferencesForm } from '../../forms/PreferencesForm';

export function PreferencesPage() {
  const canRead = useHasPermission(Permissions.SETTINGS_READ);
  if (!canRead) {
    return <></>;
  }
  
  const history = useHistory();
  const { organization: organizationData } = useOrganization();
  const { saveOrganization } = useSaveOrganization();

  if (!organizationData) {
    return null;
  }

  return (
    <Form 
      initialValues={organizationData}
      onSubmit={(updates) => {
        saveOrganization(updates, {
          onSuccess: () => {
            history.goBack();
          }
        })
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Preferences"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" />}
        >
          <PreferencesForm />
        </Pane>
      </Panes>
    </Form>
  )
}
  
