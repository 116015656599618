import * as React from 'react';
import { Form } from '../../components/Form';
import { PaneButton } from '../../components/PaneButton';
import { Pane, Panes } from '../../components/Panes';
import { SubmitButton } from '../../components/SubmitButton';
import { FloorPlansForm } from '../../forms/FloorPlansForm';
import { useHistory, useParams } from '../../hooks';
import { useAddFloorPlan } from '../../data/floorPlans';
import { useOrgId, useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddFloorPlansPage() {
  const history = useHistory();
  const orgId = useOrgId();
  const params = useParams<{ locationId: string }>();
  const canWrite = useHasPermission(Permissions.LOCATION_WRITE);
  const { addFloorPlan, isAddingFloorPlan } = useAddFloorPlan();

  if (!canWrite) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        name: '',
        locationId: params.locationId,
        image: '',
        orgId: orgId || '',
      }}
      onSubmit={async (floorPlan) => {
        await addFloorPlan(floorPlan)
        history.goBack();
      }}
    >
      <Panes 
        backgroundColor="light"
        leftButton={<BackButton />}
      >
        <Pane 
          title="Add Floor Plan"
          rightButton={<SubmitButton as={PaneButton} label="Add" />}
        >
          <FloorPlansForm />
        </Pane>
      </Panes>
    </Form>
  )
}
  
