/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import * as s from './styles';
import { TouchableWithoutFeedback, Animated } from 'react-native';
import { useDeviceType } from '../../hooks';
import { ListButton } from '../../components/ListButton';
import { SafeAreaBottomPadding } from '../../components/SafeAreaBottomPadding';

export interface DialogProps {
  closeDialog: () => void;
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  open?: boolean;
  dontStickToBottomOnMobile?: boolean;
}

export function DialogComponent(props: DialogProps) {
  const animationDuration = 150; 
  const closedMarginTop = 200;
  const deviceType = useDeviceType();

  const [title, setTitle] = React.useState<string | null>(null);
  const [subtitle, setSubtitle] = React.useState<string | null>(null);
  const [children, setChildren] = React.useState<React.ReactNode | null>(null);
  const [marginTopAnimation] = React.useState(new Animated.Value(closedMarginTop)) 
  const [opacityAnimation] = React.useState(new Animated.Value(0)) 

  // Remember after closed so we can animate
  React.useEffect(() => {
    if (props.open) setTitle(props.title || '');
    if (props.open) setSubtitle(props.subtitle || ''); 
    if (props.open) setChildren(props.children); 
  }, [props.open, props.title, props.subtitle, props.children]);

  React.useEffect(() => {
    if (props.open) {
      Animated.timing(
        marginTopAnimation,
        {
          toValue: 0,
          duration: animationDuration,
          useNativeDriver: true
        }
      ).start();
      Animated.timing(
        opacityAnimation,
        {
          toValue: 1,
          duration: animationDuration,
          useNativeDriver: true
        }
      ).start();
    } else {
      Animated.timing(
        marginTopAnimation,
        {
          toValue: closedMarginTop,
          duration: animationDuration,
          useNativeDriver: true
        }
      ).start();
      Animated.timing(
        opacityAnimation,
        {
          toValue: 0,
          duration: animationDuration,
          useNativeDriver: true
        }
      ).start();
    }
  }, [props.open])

  return (
    <TouchableWithoutFeedback onPress={() => props.closeDialog()}>
      <s.Container
        as={Animated.View} 
        pointerEvents={props.open ? 'auto' : 'none'}
        style={{ opacity: opacityAnimation }}
        fromBottom={deviceType !== 'desktop' && !props.dontStickToBottomOnMobile}
      >
        <TouchableWithoutFeedback onPress={(e) => { e.stopPropagation(); }}>
          <s.DialogWindows
            as={Animated.View} 
            style={{ transform: [ { translateY: marginTopAnimation } ] }}
          >

            <s.TitleContainer>
              {!!title && <s.Title>{title}</s.Title>}
              {!!subtitle && <s.Message>{subtitle}</s.Message>}
            </s.TitleContainer>

            <s.DialogOptions>
              {!!children && children}
            </s.DialogOptions>

            <SafeAreaBottomPadding />
          </s.DialogWindows>
        </TouchableWithoutFeedback>
      </s.Container>
    </TouchableWithoutFeedback>
  )
}
