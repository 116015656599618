import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useParams, useDeviceType } from '../../hooks';
import { BackButton } from '../../components/BackButton';
import { FloorPlanShower } from '../../components/FloorPlanShower';
import { useFloorPlans } from '../../data/floorPlans';
import { useEquipmentItem } from '../../data/equipment';

export function ReadOnlyFloorPlanPage() {
  const params = useParams<{ locationId: string; equipmentId: string }>();
  const deviceType = useDeviceType();

  const { equipmentItem } = useEquipmentItem({ locationId: params.locationId, id: params.equipmentId });
  const { floorPlans } = useFloorPlans(params.locationId);

  const floorId = equipmentItem?.floorId;
  const floorPlan = floorPlans.find((floorPlan) => floorPlan.id === floorId);
  if (!floorPlan) {
    return null;
  }

  return (
    <Panes backgroundColor="light">
      <Pane 
        title="Floor Plan Location"
        leftButton={ deviceType !== 'desktop' && <BackButton /> }
      >
        <FloorPlanShower
          readonly
          value={equipmentItem!.floorPlanLocation!}
          floorPlan={floorPlan}
        />
      </Pane>
    </Panes>
  )
}
