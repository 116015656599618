import { ReportDef } from './types';

import { inspectionReport } from './defs/inspectionReport';
import { selectiveEquipmentReport } from './defs/selectiveEquipmentReport';
import { equipmentDueConsolidatedReport } from './defs/equipmentDueConsolidatedReport';
import { equipmentReport } from './defs/equipmentReport';
import { deficiencyReport } from './defs/deficiencyReport';
import { locationReport } from './defs/locationReport';
import { timeAtLocation } from './defs/timeAtLocation';
import { questionnaireReport } from './defs/questionnaireReport';

export const reports: ReportDef[] = [
  inspectionReport,
  equipmentReport,
  deficiencyReport,
  locationReport,
  selectiveEquipmentReport,
  equipmentDueConsolidatedReport,
  timeAtLocation,
  questionnaireReport,
]
