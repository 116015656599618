import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useModelField, useUpdateModelField, useDeleteModelField } from '../../data/modelFields';
import { useParams, useHistory } from '../../hooks';
import { ModelFieldForm } from '../../forms/ModelFieldForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { BackButton } from '../../components/BackButton';

export function EditModelFieldsPage() {
  const history = useHistory();
  const params = useParams<{ modelFieldId: string }>();
  const { modelField } = useModelField(params.modelFieldId);
  const { updateModelField } = useUpdateModelField();
  const { deleteModelField } = useDeleteModelField();

  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);

  if (!modelField) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={modelField}
      onSubmit={async (updates) => {
        await updateModelField(modelField.id, updates)
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Model Field Information"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" disabled={!canWrite} />}
        >
          {modelField && (
            <ModelFieldForm />
          )}

          <DeleteButton
            name="model-field"
            itemId={modelField.id}
            deleteItemNew={deleteModelField}
            question="Delete Model Field?"
            yesMessage="Yes, Delete Model Field"
            successMessage="Deleted Model Field"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
