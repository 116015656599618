/// <reference path="../styled.d.ts" />

import { css } from 'styled-components';
import { SizingCode, Theme } from '../theme';

export function resolveSpacing(spacingCode: SizingCode|undefined, theme: Theme): string {
  if (!spacingCode) {
    return '0px';
  }

  return theme.spacing[spacingCode];
}

export interface PaddingProps {
  paddingCode?: SizingCode;
  paddingTopCode?: SizingCode;
  paddingBottomCode?: SizingCode;
  paddingLeftCode?: SizingCode;
  paddingRightCode?: SizingCode;
}

export interface MarginProps {
  marginCode?: SizingCode;
  marginTopCode?: SizingCode;
  marginBottomCode?: SizingCode;
  marginLeftCode?: SizingCode;
  marginRightCode?: SizingCode;
}

export interface AlignProps {
  align?: 'left' | 'center' | 'right';
  alignY?: 'left' | 'center' | 'right';
  alignX?: 'left' | 'center' | 'right';
}

type Direction = 'top' | 'left' | 'bottom' | 'right';
const directions: Direction[] = ['top', 'left', 'bottom', 'right'];

export function getPaddingStyles(params: PaddingProps & { theme: Theme }) {
  const directionalStyles = directions.map((direction) => {
    const matchingPadding = {
      top: params.paddingTopCode,
      bottom: params.paddingBottomCode,
      left: params.paddingLeftCode,
      right: params.paddingRightCode,
    }
    
    const sizeCode = matchingPadding[direction];
    if (!sizeCode) {
      return '';
    }

    return `
      padding-${direction}: ${resolveSpacing(sizeCode, params.theme)}
    `
  });

  return css`
    padding: ${resolveSpacing(params.paddingCode, params.theme)};
    ${directionalStyles};
  `;
}

export function getMarginStyles(params: MarginProps & { theme: Theme }) {
  const directionalStyles = directions.map((direction) => {
    const matchingMargin = {
      top: params.marginTopCode,
      bottom: params.marginBottomCode,
      left: params.marginLeftCode,
      right: params.marginRightCode,
    }
    
    const sizeCode = matchingMargin[direction];
    if (!sizeCode) {
      return '';
    }

    return `
      margin-${direction}: ${resolveSpacing(sizeCode, params.theme)}
    `
  });

  return css`
    margin: ${resolveSpacing(params.marginCode, params.theme)};
    ${directionalStyles};
  `;
}

export function getBackgroundStyles(params: { backgroundColor?: 'dark' | 'light' | 'grey' | 'negative'; theme: Theme }) {
  if (params.backgroundColor === 'dark') {
    return `background-color: ${params.theme.palette.darkBackground}`;
  }

  if (params.backgroundColor === 'light') {
    return `background-color: ${params.theme.palette.lightBackground}`;
  }

  if (params.backgroundColor === 'grey') {
    return 'background-color: rgb(247, 247, 247)';
  }

  if (params.backgroundColor === 'negative') {
    return `background-color: ${params.theme.palette.negativeBackground}`;
  }

  return '';
}

export function getAlignStyles(props: AlignProps & { theme: Theme }) {
  if (props.align) {
    return css`
      align-items: ${convertDirectionToFlexProperty(props.align)};
      justify-content: ${convertDirectionToFlexProperty(props.align)};
    `
  }

  const alignYCss = props.alignY ? css`align-items: ${convertDirectionToFlexProperty(props.alignY)};` : '';
  const alignXCss = props.alignX ? css`justify-content: ${convertDirectionToFlexProperty(props.alignX)};` : '';

  return css`
    ${alignYCss}
    ${alignXCss}
  `;
}

function convertDirectionToFlexProperty(direction: 'center' | 'left' | 'right') {
  if (direction === 'left') {
    return 'flex-start';
  }

  if (direction === 'right') {
    return 'flex-end';
  }

  return direction;
}
