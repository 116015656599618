import React from 'react';
import moment from 'moment';
import { SafeAreaView, View, TouchableOpacity } from 'react-native';
import { Path, Svg, Defs, Mask, Rect, Ellipse } from 'react-native-svg';
import { useHistory, useParams, useControlDialog, useLastTruthyValue } from '../../hooks';
import styled from 'styled-components/native';
import { Box } from '../../components/Box';
import { Column } from '../../components/Column';
import { Heading } from '../../components/Heading';
import { ButtonLink } from '../../components/Router';
import { Row } from '../../components/Row/styles';
import { Text } from '../../components/Text';
import { ServiceList } from '../../containers/ServiceList';
import { useEquipmentItem } from '../../data/equipment';
import { useModel } from '../../data/models';
import { EquipmentOptions } from '../../containers/EquipmentOptions';
import { Theme } from '../../theme';
import { Message } from '../../components/Message';
import { Animated } from 'react-native';
import { useWindowDimensions } from 'react-native';
import { Equipment } from '@rivison-inc/ft-types'
import { EquipmentNote } from '../../containers/EquipmentNote';
import { useEquipmentFields } from '../../data/equipmentFields';
import { trackingCategory, userTracker } from '../../controllers/userTracker';
import { useLocation } from '../../data/locations';

const Mover = styled.View`
  
`;

const PageContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
`;

const SubtitleContainer = styled.View`
  padding-top: ${(props: { theme: Theme }) => props.theme.spacing.sm};
`;

interface EquipmentTagPageProps {
  readonly?: boolean;
  open?: boolean;
}

export function EquipmentTagPage(props: EquipmentTagPageProps) {
  const params = useParams<{ locationId: string; equipmentId: string }>();
  const equipmentId = useLastTruthyValue(params.equipmentId);

  const { equipmentItem: tmpEquipmentItem } = useEquipmentItem({ id: equipmentId, locationId: params.locationId });

  const equipmentItem = useLastTruthyValue(tmpEquipmentItem) || null;
  const locationId = useLastTruthyValue(params.locationId);
  const { location } = useLocation(locationId);
  const { equipmentFields } = useEquipmentFields({ equipmentTypeId: equipmentItem?.model?.equipmentTypeId });
  

  const { model } = useModel(equipmentItem?.modelId);
  const fieldData = model?.fieldData || [];
  const history = useHistory();
  const { height } = useWindowDimensions();
  const closedMarginTop = (height) * -1;
  const [marginTopAnimation] = React.useState(new Animated.Value(props.open ? 0 : closedMarginTop)) 
  const [opacityAnimation] = React.useState(new Animated.Value(props.open ? 1 : 0)) 

  const modelInfoString = (model?.name || '') + ' ' + Object.values(fieldData).join(' ');

  React.useEffect(() => {
    if (equipmentItem?.note) {
      openDialog({ name: 'equipment-note-dialog', state: { popupNote: equipmentItem.note } });
    }
  }, [equipmentItem?.note]);

  const animationDuration = 275;
  React.useEffect(() => {
    if (props.open) {
      Animated.timing(
        marginTopAnimation,
        {
          toValue: 0,
          duration: animationDuration,
          useNativeDriver: true
        }
      ).start();
      Animated.timing(
        opacityAnimation,
        {
          toValue: 1,
          duration: animationDuration,
          useNativeDriver: true
        }
      ).start();
    } else {
      Animated.timing(
        marginTopAnimation,
        {
          toValue: closedMarginTop,
          duration: animationDuration,
          useNativeDriver: true
        }
      ).start();
      Animated.timing(
        opacityAnimation,
        {
          toValue: 0,
          duration: animationDuration,
          useNativeDriver: true
        }
      ).start();
    }
  }, [props.open])

  const { openDialog } = useControlDialog();
  const equipmentFieldData = equipmentItem?.fieldData;

  return (
    <>
      <PageContainer
        as={Animated.View} 
        style={{ opacity: opacityAnimation }}
        pointerEvents={props.open ? 'auto' : 'none'}
      >
        
          <SafeAreaView style={{ height: '100%' }}>
            <Mover
              as={Animated.View} 
              style={{ transform: [ { translateY: marginTopAnimation } ] }}
            >
              <>
                <Column height="full">
                  <Box>
                    <Trapezoid 
                      height={100}
                      width="100%"
                      label={equipmentItem?.name || ''}
                      sublabel={modelInfoString || 'No Model Information'}
                    />
                  </Box>
                  <Box flexGrow={1} backgroundColor="light" roundBottom="md" marginBottom="sm"> 
                    <Box flexGrow={1} flexDirection="column" >

                      <Box padding="sm" paddingBottom="md" paddingTop="xs" borderColor="grey" hideBorder={'top'}>
                        <TouchableOpacity onPress={() => {
                          if (!props.readonly) {
                            history.push(`/sites/${equipmentItem.locationId}/equipment/${equipmentItem.id}`);
                            userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Edit Equipment From Tag Header');
                          }
                        }}>
                          <Row wrap align={'center'}>
                            <InfoItem label="Barcode" text={equipmentItem?.barcode || 'N/A'} align="center"  />
                            <InfoItem label="Serial Number" text={equipmentItem?.serialNumber || 'N/A'} align="center" />
                            <InfoItem label="Manufactured" text={location && equipmentItem?.manufactureDate ? moment(equipmentItem.manufactureDate).tz(location.timeZone).format('MMMM D YYYY') : 'N/A'} align="center"  />
                          
                            {equipmentFields.map((equipmentField, idx) => (
                                <InfoItem key={idx} label={equipmentField.name} text={(equipmentFieldData && equipmentFieldData[equipmentField.id]) || '?'} align="center"  />
                            ))}

                          </Row>
                        </TouchableOpacity>
                      </Box>

                      {props.readonly && (
                        <Box paddingTop="sm">
                          <Message type="error" message="Scan barcode to make changes" />
                        </Box>
                      )}

                      {Boolean(equipmentId) && props.open && (
                        <ServiceList 
                          readonly={props.readonly} 
                          locationId={locationId} 
                          equipmentId={equipmentId} 
                          performUpdateOnScan
                          mobile 
                        />
                      )}
                    </Box>
                    <Row>
                      {!props.readonly && (
                        <TagButton 
                          label="Options"
                          onPress={() => {
                            openDialog('equipment-tag-options-dialog');
                          }} 
                        />
                      )}
                      <TagButton 
                        label="Done" 
                        hideBorder="left" 
                        onPress={() => {
                          history.goBack();
                        }} 
                      />
                    </Row>
                  </Box>
                  
                </Column>
              </>
            </Mover>
          </SafeAreaView>

      </PageContainer>
      <EquipmentOptions hideOptions={['preview-details']} dialogName="equipment-tag-options-dialog" equipmentItem={equipmentItem} />
      <EquipmentNote />
    </>
  )
}

function TagButton(props: { label: string; hideBorder?: 'left'; onPress?: () => void; to?: string }) {
  return (
    <Box flexGrow={1} borderColor="grey" hideBorder={props.hideBorder}>
      <ButtonLink to={props.to} onPress={props.onPress}>
        <Box padding="md">
          <Text alignX="center" weight="medium">{props.label}</Text>
        </Box>
      </ButtonLink>
    </Box>
  )
}

function InfoItem(props: { label: string; text: string; align: 'left' | 'right' | 'center' }) {
  return (
    <Box rounded={'md'} margin="sm" marginBottom="xxs">
      <Column alignX={props.align}>
        <Text size="xxs" color="secondary">{props.label}</Text>
        <Text size="sm" paddingTop="xxs">{props.text || ' '}</Text>
      </Column>
    </Box>
  )
}

function Trapezoid(props: { width: string|number; height: string|number; label: string; sublabel: string }) {
  const h = 300;
  const a = 300;
  const b = 600;
  const halfB = b / 2;
  const halfA = a / 2;
  
  return (
    <View style={{ height: props.height, width: props.width, position: 'relative' }}>
      <Svg height={'100%'} width={'100%'} viewBox={`0 0 ${b} ${h}`} preserveAspectRatio="none">
        <Defs>
          <Mask id="mask" x="0" y="0" height="100%" width="100%">
              <Rect height="100%" width="100%" fill="#fff" />
              {/* <Ellipse rx="22" ry="40" cx="50%" cy="100" /> */}
          </Mask>
        </Defs>
        <Path
          d={`M 0 ${h} L ${halfB - halfA} 0 L ${halfB + halfA} 0 L ${b} ${h} Z`}
          fill="white"
          stroke="none"
          mask="url(#mask)"
        />
        {/* <Ellipse rx="32" ry="58" cx="50%" cy="100" fill="#F7F7F7" mask="url(#mask)" /> */}
      </Svg>
      <View style={{ position: 'absolute', width: '100%', bottom: 20, left: 0 }}>
        <Row alignX="center">
          <Column alignX="center">
            <Heading level="1" size="lg" weight="medium">{props.label}</Heading>
            <SubtitleContainer>
              <Heading level="2" size="xs" color="subdued">{props.sublabel}</Heading>
            </SubtitleContainer>
          </Column>
        </Row>
      </View>
    </View>
  )
}
