import { equipmentController, serviceController } from '@rivison-inc/ft-logic';
import { Equipment, Location } from '@rivison-inc/ft-types';
import * as React from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { BarCodeScanner, requestCameraPermission } from '../../components/BarCodeScanner';
import { EmptyState } from '../../components/EmptyState';
import { IconButton } from '../../components/IconButton';
import { ListButton } from '../../components/ListButton';
import { ListItem } from '../../components/ListItem';
import { Pane, Panes } from '../../components/Panes';
import { useHistory, useParams, useQueueMessage } from '../../hooks';
import { AddIcon } from '../../icons/Add';
import { CameraIcon } from '../../icons/Camera';
import { useControlDialog, useOpenDialogState } from '../../hooks';
import { useEquipment, useGetEquipmentByBarcode } from '../../data/equipment';
import { useModelFields } from '../../data/modelFields';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { SearchIcon } from '../../icons/Search';
import { EquipmentIcon } from '../../icons/Equipment';
import { WrenchIcon } from '../../icons/Wrench';
import { EquipmentOptions } from '../../containers/EquipmentOptions';
import { BackButton } from '../../components/BackButton';
import { searchData } from '../../utils/filter';
import { sortByString } from '../../utils/sort';
import { useLocation, useLocations } from '../../data/locations';
import { FullScreenActivityIndicator } from '../../components/FullScreenActivityIndicator ';
import { useSearchSaverEquipment } from '../../containers/SearchSaverEquipment';
import { useMemo } from 'react';
import { ListRenderer } from '../../components/ListRenderer';
import { TimeZoneWarning } from '../../containers/TimeZoneWarning';
import { UpToDateAsOf } from '../../containers/UpToDateAsOf';
import { useServiceMonth, useServiceMonths } from '../../data/serviceMonths';

export function EquipmentListPage(props: { showBackButton?: boolean }) {
  const params = useParams<{ locationId: string }>();
  const queueMessage = useQueueMessage();
  const searchSaver = useSearchSaverEquipment();
  const [currentPane,setCurrentPane] = React.useState(0)

  const canRead = useHasPermission(Permissions.EQUIPMENT_READ);
  const history = useHistory();
  const [, setHasPermission] = React.useState<null|boolean>(null);
  const [scanning, setScanning] = React.useState(false);
  const [loadingBarcodeSearch, setLoadingBarcodeSearch] = React.useState(false);
  const { modelFields } = useModelFields();
  const { openDialog } = useControlDialog();
  const dialogState = useOpenDialogState();
  const selectedEquipmentItem = dialogState?.equipmentItem as Equipment;
  const { locations } = useLocations()

  const { equipment } = useEquipment(params.locationId);
  const { location } = useLocation(params.locationId);
  const { serviceMonths } = useServiceMonths(params.locationId)
  
  const equipmentList = useMemo(() => {
    if (!location) {
      return []
    }
    if (currentPane === 1) {
      return equipmentController.getEquipmentDueForInspection(equipment, serviceMonths, location.timeZone, { accountForCouldNotService: true }) as Equipment[]
    }
    if (currentPane === 2) {
      return equipmentController.getEquipmentDueForService(equipment, serviceMonths, location.timeZone, { accountForCouldNotService: true }) as Equipment[]
    }
    return equipment
  }, [equipment, location, currentPane]);


  const [searchText, setSearchText] = React.useState(searchSaver.getSearch());

  const { getEquipmentByBarcode } = useGetEquipmentByBarcode()

  function getSearchData(data: Equipment) {
    const modelData = Object.values(data.model?.fieldData || {});
    const fields = [ 
      data.name || '', 
      data.note || '', 
      data.manufactureDate || '', 
      data.barcode || '', 
      data.serialNumber || '', 
      data.model?.name || '',
      data.equipmentType?.name || '',
      ...modelData
    ];
    return [
      ...fields,
      ...Object.values(data.model?.fieldData || []),
    ];
  }
  const filteredEquipment = React.useMemo(() => {
    const data = searchData(equipmentList, getSearchData, searchText);
    return sortByString(data, 'name', 'asc');
  }, [searchText, equipmentList]);

  React.useEffect(() => {
    (async () => {
      const { status } = await requestCameraPermission();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const scan = (barcode: string) => {
    setScanning(false);

    const equipmentItem = equipment.find((item) => item.barcode === barcode && !item.deleted);
    if (!equipmentItem) {
      setLoadingBarcodeSearch(true);

      getEquipmentByBarcode(barcode)
        .then((fetchedEquipmentItem) => {
          if (fetchedEquipmentItem) {
            if (fetchedEquipmentItem.locationId !== params.locationId) {
              const location = locations.find(location => location.id === params.locationId)
              queueMessage({ message: `Found the equipment at ${location?.name}. Switching locations.`, type: 'message' });
            }
            history.push(`/sites/${fetchedEquipmentItem.locationId}/equipment-tag/${fetchedEquipmentItem.id}`);
          } else {
            history.push(`/sites/${params.locationId}/equipment/new?barcode=${encodeURIComponent(barcode)}`);  
          }
          setLoadingBarcodeSearch(false);
        })
        .catch(() => {
          history.push(`/sites/${params.locationId}/equipment/new?barcode=${encodeURIComponent(barcode)}`);
          setLoadingBarcodeSearch(false);
        });

      return;
    }

    history.push(`/sites/${params.locationId}/equipment-tag/${equipmentItem.id}`);
  }

  const getEquipmentRow = (equipmentItem: Equipment, idx: number) => {
    const modelData = modelFields.map((modelField) => `${(equipmentItem.model?.fieldData && equipmentItem.model?.fieldData[modelField.id]) || ''}`);
    const secondSubtitle = `${equipmentItem.model?.name || ''} ${modelData.join(' ')}`;
    let subtitle = equipmentItem.equipmentType?.name || '';
    if (equipmentItem.serialNumber) {
      subtitle = `${subtitle} (Serial: ${equipmentItem.serialNumber || 'None'})`;
    }
    if (Platform.OS === 'web') {
      return (
        <ListItem 
          key={equipmentItem.id}
          title={equipmentItem.name}
          subtitle={subtitle}
          secondSubtitle={secondSubtitle}
          to={`/sites/${equipmentItem.locationId}/equipment/${equipmentItem.id}`}
        />
      );
    }
    
    return (
      <TouchableOpacity 
        key={equipmentItem.id}
        onPress={() => {
          openDialog({ name: 'equipment-dialog', state: { equipmentItem } });
        }}
      >
        <ListItem 
          key={equipmentItem.id}
          title={equipmentItem.name}
          subtitle={subtitle}
          secondSubtitle={secondSubtitle}
        />
      </TouchableOpacity>
    );
  }

  if (!canRead) {
    return <></>;
  }

  if (scanning) {
    
    return (
      <BarCodeScanner 
        onCancel={() => {
          setScanning(false);
        }}
        onScan={({ data }) => {
          scan(data);
        }}
      />
    );
  }

  if (!location) {
    return null;
  }

  return (
    <>
      {selectedEquipmentItem && <EquipmentOptions simulateScan={scan} hideOptions={['edit', 'open-deficiency']} dialogName="equipment-dialog" equipmentItem={selectedEquipmentItem} />}
      
      {loadingBarcodeSearch && (
        <FullScreenActivityIndicator />
      )}

      <Panes 
        id="equipmentListPane"
        backgroundColor="light"
        title="Equipment"
        paneChanged={setCurrentPane}
        ignoreSafeArea
        bottomComponent={(
          Platform.OS !== 'web' ? 
            <ListButton 
              label="Scan Barcode" 
              fullBorder 
              color="primary" 
              startIcon={<CameraIcon color="white" />}
              onPress={() => setScanning(true)}
              safeArea='bottom'
            />
          : 
            null
        )}
        rightButton={(
          <AddEquipmentButton />
        )}
        leftButton={(
          props.showBackButton && (
            <BackButton />
          )
        )}
      >
        <Pane 
          title="All" 
          icon={<EquipmentIcon />}
          searchText={searchText}
          onSearchChange={(searchText) => {
            setSearchText(searchText);
            searchSaver.saveSearch(searchText);
          }}
          autoFocusSearch={Platform.OS === 'web'}
          
        >
          <TimeZoneWarning />
          <UpToDateAsOf />
          {currentPane === 0 && (
            <>
              <ListRenderer
                render={(equipmentItem: Equipment, idx: number) => getEquipmentRow(equipmentItem, idx)}
                data={filteredEquipment}
              />
              {!filteredEquipment.length && <EmptyState title={'No Equipment'} subtitle={'Equipment can be anything from a fire extinguisher to a treadmill. Set up equipment types in settings > equipment types.'} image={'equipment'} />}
            </>
          )}
        </Pane>
        <Pane 
          title="Inspection Due" 
          icon={<SearchIcon />}
          searchText={searchText}
          onSearchChange={(searchText) => {
            setSearchText(searchText);
            searchSaver.saveSearch(searchText);
          }}
          autoFocusSearch={Platform.OS === 'web'}
        >
          <TimeZoneWarning />
          <UpToDateAsOf />
          {currentPane === 1 && (
            <>
              <ListRenderer
                render={(equipmentItem: Equipment, idx: number) => getEquipmentRow(equipmentItem, idx)}
                data={filteredEquipment}
              />
              {!filteredEquipment.length && <EmptyState title={'Nothing to Inspect'} subtitle={'There is no equipment at this site with this search criteria and due inspections'} image={'inspection'} />}
            </>
          )}
        </Pane>
        <Pane 
          title="Service Due" 
          icon={<WrenchIcon />}
          searchText={searchText}
          onSearchChange={(searchText) => {
            setSearchText(searchText);
            searchSaver.saveSearch(searchText);
          }}
          autoFocusSearch={Platform.OS === 'web'}
        >
          <TimeZoneWarning />
          <UpToDateAsOf />
          {currentPane === 2 && (
            <>
              <ListRenderer
                render={(equipmentItem: Equipment, idx: number) => getEquipmentRow(equipmentItem, idx)}
                data={filteredEquipment}
              />
              {!filteredEquipment.length && <EmptyState title={'Nothing to Service'} subtitle={'There is no equipment at this site with this search criteria and due service'} image={'service'} />}
            </>
          )}
        </Pane>
      </Panes>
    </>
  )
}

function AddEquipmentButton() {
  const params = useParams<{ locationId: string }>();

  return (
    <IconButton label="Add Equipment" to={`/sites/${params.locationId}/equipment/new`}>
      <AddIcon />
    </IconButton>
  )
}
