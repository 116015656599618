import { useCallback } from "react";
import { useMutation } from "react-query";
import { api } from "../api";

export function useGenerateAccountNumber() {
    const {
        mutateAsync: generateAccountNumberAsync,
        mutate: generateAccountNumber,
        status: accountNumberStatus,
        error: accountNumberError
    } = useMutation(async (orgId: string) => {

        const response = await api.post(`/organizations/${orgId}/counters/account-number`);

        const data = response.data as { data: string }
        return data.data;
    });

    const tryGenerateAccountNumber = useCallback(async (orgId: string) => {
        try {
            return generateAccountNumberAsync(orgId);
        } catch {
            return '';
        }
    }, [generateAccountNumberAsync])

    return {
        tryGenerateAccountNumber,
        generateAccountNumber,
        accountNumberStatus,
        accountNumberError
    }
}