import ReactGA from 'react-ga';

export enum trackingCategory {
  NAVIGATION = 'NAVIGATION',
  EDIT = 'EDIT',
  SCHEDULE = 'SCHEDULE',
  REPORTS = 'REPORTS',
  SERVICE = 'SERVICE',
  EQUIPMENT = 'EQUIPMENT',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

class UserTracker {  

  init(projectId: string) {
    ReactGA.initialize(projectId);
  }

  setUser(userId: string) {
    ReactGA.set({ userId });
  }

  trackPage(url: string) {
    ReactGA.pageview(url);
  }

  trackModal(modalName: string) {
    ReactGA.modalview(modalName);
  }

  trackEvent(category: trackingCategory, action: string, options?: { additionalInfo?: string; value?: number; nonInteraction?: boolean }) {
    ReactGA.event({
      category: category as string,
      action,
      label: options?.additionalInfo,
      value: options?.value,
      nonInteraction: options?.nonInteraction,
    });
  }
  

}

export const userTracker = new UserTracker();
