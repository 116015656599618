import { Equipment, HistoryType } from "@rivison-inc/ft-types";
import { useAddHistoryRecord, useHistoryRecords } from "./history";
import { makeDataHooks } from "./utils";
import { useMemo } from "react";
import { useModels } from "./models";
import { useEquipmentTypes } from "./equipmentTypes";
import { useServiceTypes } from "./serviceTypes";
import { useLocationOverrides } from "./locationOverrides";
import { usePerformedServices } from "./performedServices";
import { useServiceMonths } from "./serviceMonths";
import { serviceController } from "@rivison-inc/ft-logic";
import { useDeficiencies } from "./deficiencies";
import { useLocation } from "./locations";
import { createHashMap, groupByKey } from "../utils/array";
import { Storage } from "../storage";

const {
  useUpdateDataItem,
  useDeleteDataItem,
  useAddData,
  useDataById,
  useData
} = makeDataHooks<Equipment>({
  dataName: "Equipment",
  useAddHistoryRecord,
  indexBy: 'barcode',
  events: {
    onAdd: (equipmentItem, { myId, locationId, addHistoryRecord }) => {
      if (!locationId) {
        throw new Error('Location id not set when adding deficiencies');
      }
      addHistoryRecord({
        date: equipmentItem.lastUpdated,
        description: 'Added',
        equipmentId: equipmentItem.id,
        orgId: equipmentItem.orgId,
        happenedAtLocationId: equipmentItem.locationId,
        locationId: equipmentItem.locationId,
        relevantId: equipmentItem.modelId,
        userId: myId,
        type: HistoryType.ADDED,
      }, locationId);
    },
    onDelete: (equipmentItem, { myId, locationId, addHistoryRecord }) => {
      if (!locationId) {
        throw new Error('Location id not set when adding deficiencies');
      }
      addHistoryRecord({
        date: equipmentItem.lastUpdated,
        description: 'Deleted',
        equipmentId: equipmentItem.id,
        orgId: equipmentItem.orgId,
        happenedAtLocationId: equipmentItem.locationId,
        locationId: equipmentItem.locationId,
        userId: myId,
        type: HistoryType.DELETED,
      }, locationId);
    },
    onUpdate: (originalEquipment, updatedEquipment, { myId, locationId, addHistoryRecord }) => {
      const barcodeChanged = updatedEquipment.barcode !== originalEquipment.barcode;
      if (!locationId) {
        throw new Error('Location id not set when adding deficiencies');
      }
      if (barcodeChanged) {
        addHistoryRecord({
          date: new Date().toISOString(),
          description: `Barcode changed from ${originalEquipment.barcode} to ${updatedEquipment.barcode}`,
          equipmentId: updatedEquipment.id,
          orgId: updatedEquipment.orgId,
          happenedAtLocationId: updatedEquipment.locationId,
          locationId: updatedEquipment.locationId,
          userId: myId,
          type: HistoryType.DATA_CHANGE,
          changes: {
            before: {
              barcode: originalEquipment.barcode
            },
            after: {
              barcode: updatedEquipment.barcode,
            }
          }
        }, locationId);
      }
    }
  }
});

function useAddDetailToEquipment(data: Equipment[], locationId: string) {
  const { models, isLoading: isLoadingModels } = useModels();
  const { equipmentTypes, isLoading: isLoadingEquipmentTypes } = useEquipmentTypes();
  const { serviceTypes: allServiceTypes, isLoading: isLoadingServiceTypes } = useServiceTypes();
  const { location } = useLocation(locationId);
  const serviceTypes = serviceController.removeDisabledServiceTypes(allServiceTypes, location?.typeId);

  const { locationOverrides } = useLocationOverrides(locationId);
  const { performedServices: allPerformedServices, isLoading: isLoadingPerformedServices } = usePerformedServices(locationId);
  const { serviceMonths, isLoading: loadingServiceMonths } = useServiceMonths(locationId);
  const { historyRecords, isLoading: loadingHistory } = useHistoryRecords(locationId);
  const { deficiencies, isLoading: loadingDeficiencies } = useDeficiencies(locationId);

  const isLoading = isLoadingEquipmentTypes || loadingHistory || loadingServiceMonths || isLoadingServiceTypes || isLoadingModels || loadingDeficiencies || isLoadingPerformedServices;

  const detailedEquipment = useMemo(() => {
    if (isLoading) {
      return data;
    }
    const serviceDefsWithOverrides = serviceController.applyServiceOverrides(serviceTypes, locationOverrides);

    const performedServicesByEquipmentId = groupByKey(allPerformedServices, 'equipmentId')
    const serviceMonthsByCategoryId = createHashMap(serviceMonths, 'serviceMonthCategoryId')
    const serviceDefsByEquipmentTypeId = groupByKey(serviceDefsWithOverrides, 'equipmentTypeId')
    const historyRecordsEquipmentTypeId = groupByKey(historyRecords, 'equipmentId')
    const deficienciesEquipmentTypeId = groupByKey(deficiencies, 'equipmentId')
    const equipmentTypesById = createHashMap(equipmentTypes, 'id')
    const modelsById = createHashMap(models, 'id');

    const response = data.map((equip) => {
      const model = modelsById[equip.modelId];
      const equipmentType = equipmentTypesById[model?.equipmentTypeId || 'NO ID'];
      const serviceDefs = serviceDefsByEquipmentTypeId[model?.equipmentTypeId || 'NO ID'] || [];
      const performedServices = performedServicesByEquipmentId[equip.id];
      const serviceMonth = serviceMonthsByCategoryId[equipmentType?.serviceMonthCategoryId || 'NO ID'];
      const equipmentHistoryRecords = historyRecordsEquipmentTypeId[equip.id];
      const equipmentDeficiencies = deficienciesEquipmentTypeId[equip.id];
      const newEquipment: Equipment = { ...equip, model, equipmentType, serviceDefs, performedServices, serviceMonth, history: equipmentHistoryRecords, deficiencies: equipmentDeficiencies }
      return newEquipment
    })
   
    return response;
  }, [isLoading, data, models, allServiceTypes, equipmentTypes, allPerformedServices, serviceMonths, historyRecords, deficiencies]);


  return {
    detailedEquipment,
    isLoading
  };
}

export function useEquipmentItem(params: { id: string|undefined; locationId: string }) {
  const { item } = useDataById(params.id, params.locationId);

  const items = item ? [item] : []

  const { detailedEquipment, isLoading } = useAddDetailToEquipment(items, params.locationId);

  return {
    equipmentItem: detailedEquipment[0],
    isLoading
  }
}

export function useEquipment(locationId: string) {
  const { data } = useData(undefined, locationId);

  const { detailedEquipment } =  useAddDetailToEquipment(data, locationId);

  return {
    equipment: detailedEquipment,
  }
}

export function useAddEquipment() {
  const { addItem } = useAddData();

  return {
    addEquipment: addItem,
  }
}

export function useDeleteEquipment() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteEquipment: deleteItem,
  }
}

export function useUpdateEquipment() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateEquipment: updateItem,
  }
}

export function useGetEquipmentByBarcode() {

  async function getEquipmentByBarcode(barcode: string) {
    const keys: string[] = await Storage.getKeys();
    const relevantKeys = keys.filter(key => key.includes(`data-v2-Equipment`))
    for (const key of relevantKeys) {
      const storedData = await Storage.getItem<Equipment[]>(key) || [];
      const foundEquipment = storedData.find((row) => row.barcode === barcode && !row.deleted); 
      if (foundEquipment) {
        return foundEquipment
      }
    }
  }

  return {
    getEquipmentByBarcode,
  }
}

export function useGetEquipmentBySerialNumber() {

  async function getEquipmentBySerialNumber(serialNumber: string) {
    const keys: string[] = await Storage.getKeys();
    const relevantKeys = keys.filter(key => key.includes(`data-v2-Equipment`))
    for (const key of relevantKeys) {
      const storedData = await Storage.getItem<Equipment[]>(key) || [];
      const foundEquipment = storedData.find((row) => row.serialNumber === serialNumber && !row.deleted); 
      if (foundEquipment) {
        return foundEquipment
      }
    }
  }

  return {
    getEquipmentBySerialNumber,
  }
}
