import * as React from "react";
import { ButtonLink } from "../Router";
import { Text } from "../Text";

export function TextLink(props: { to?: string; underline?: boolean; newTab?: boolean; onPress?: () => void; children: React.ReactNode }) {
  return (
    <ButtonLink newTab={props.newTab} to={props.to} onPress={props.onPress}><Text color="primary" underline>{props.children}</Text></ButtonLink>
  )
}

