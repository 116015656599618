import * as React from 'react';
import { EmptyState } from '../../components/EmptyState';
import { IconButton } from '../../components/IconButton';
import { ListItem } from '../../components/ListItem';
import { Pane, Panes } from '../../components/Panes';
import { AddIcon } from '../../icons/Add';
import { useEquipmentTypes } from '../../data/equipmentTypes';
import { useHasPermission } from '../../auth';
import { Permissions, EquipmentType } from '@rivison-inc/ft-types';
import { searchData } from '../../utils/filter';
import { BackButton } from '../../components/BackButton';
import { sortByString } from '../../utils/sort';

function AddEquipmentTypeButton() {  
  return (
    <IconButton to="/equipment-types/new" label="Add Equipment Type">
      <AddIcon />
    </IconButton>
  )
}

export function EquipmentTypeListPage() {
  const canRead = useHasPermission(Permissions.SETTINGS_READ);
  if (!canRead) {
    return <></>;
  }

  const { equipmentTypes } = useEquipmentTypes();
  function getSearchData(data: EquipmentType) {
    return [ 
      data.name || '', 
    ];
  }

  const [searchText, setSearchText] = React.useState('');
  const filteredEquipmentTypes = React.useMemo(() => {
    const ordered = sortByString(equipmentTypes, 'name', 'asc');
    return searchData(ordered, getSearchData, searchText)
  }, [searchText, equipmentTypes]);

  return (
    <Panes backgroundColor="light" leftButton={<BackButton />}>
      <Pane 
        title="Equipment Types" 
        rightButton={<AddEquipmentTypeButton />}
        searchText={searchText}
        onSearchChange={(searchText) => setSearchText(searchText)}
      >
        {filteredEquipmentTypes.map((equipmentType, idx) => (
          <ListItem
            key={idx} 
            to={`/equipment-types/${equipmentType.id}`}
            title={equipmentType.name}
            // subtitle={user.email}
          />
        ))}
        {!filteredEquipmentTypes.length && <EmptyState title={'No Equipment Types'} subtitle={'Equipment types quickly add inspection and service definitions to equipment when a model containing it is selected. Examples: Fire Extinguisher, Treadmill, Truck'} image={'equipment'} />}
      </Pane>
    </Panes>
  )
}
