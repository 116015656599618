import * as React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Text } from '../Text';
import { Row } from '../Row';
import moment from 'moment';
import { Slider } from '../Slider';
import { IconButton } from '../IconButton';
import { LeftArrowIcon } from '../../icons/LeftArrow';
import { RightArrowIcon } from '../../icons/RightArrow';
import { UpArrowIcon } from '../../icons/UpArrow';
import { DownArrow } from '../../icons/DownArrow';
import { Column } from '../Column';
import { Message } from '../Translate';

import { DropDownArrowIcon } from '../../icons/DropDownArrow';
import { Box } from '../Box';
import * as s from './styles';
import { PrimaryButton } from '../PrimaryButton';
import { ListButton } from '../ListButton';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

export interface DatePickerProps {
  value: string|undefined;
  onChange: (newValue: string) => void;
  onDone?: () => void;
  granularity?: 'year' | 'month' | 'day' | 'hour' | 'minute';
  onlyTime?: boolean;
  timeZone: string;
}

export const DatePicker = (props: DatePickerProps) => {
  // TODO: this can probably be removed since timeZone is a required property.
  // There should be a compile-time error if timeZone is not defined
  if (!props.timeZone) {
    return null;
  }

  return (
    <DatePickerImpl 
      {...props}
    />
  )
}

const DatePickerImpl = (props: DatePickerProps) => {
  const timeZone = props.timeZone;

  React.useEffect(() => {
    if (!props.value) {
      const currentDate = moment().tz(timeZone).toISOString();
      props.onChange(currentDate);
    }
  }, []);

  const [currentView, setCurrentView] = React.useState('main');
  const momentValue = React.useMemo(() => moment(props.value || undefined).tz(timeZone), [props.value]);
  const numFakeDays = React.useMemo(() => getNumFakeDays(momentValue.toISOString(), timeZone), [`${momentValue.get("year")}-${momentValue.get("month")}`]);
  const days = React.useMemo(() => getListOfDaysInMonth(momentValue.toISOString(), timeZone), [`${momentValue.get("year")}-${momentValue.get("month")}`]);

  return (
    <View>
      <Box>

        {currentView === 'main' && (
          <Column>

            <Row>
              <ListButton
                label={moment(props.value || undefined).tz(timeZone).year().toString() as Message}
                onPress={() => {
                  setCurrentView('year');
                }}
                grow
              />

              {(!props.onlyTime && (props.granularity === "month" || props.granularity === "day" || props.granularity === "hour" || props.granularity === "minute")) && (
                <ListButton
                  label={months[moment(props.value || undefined).tz(timeZone).month()] as Message}
                  onPress={() => {
                    setCurrentView('month');
                  }}
                  grow
                />
              )}
            </Row>

            {(!props.onlyTime && (props.granularity === "day" || props.granularity === "hour" || props.granularity === "minute")) && (
              <s.DayBoxesContainer>
                <FakeDays numFakeDays={numFakeDays} />
                {days.map((day) => (
                  <s.DayBox 
                    key={day.toISOString()} 
                    onPress={() => {
                      if (props.onDone && (!props.granularity || props.granularity === 'day')) {
                        props.onDone();
                      }

                      const newValue = day
                        .clone()
                        .set("hours", moment(props.value).tz(timeZone).get("hours"))
                        .set("minutes", moment(props.value).tz(timeZone).get("minutes"));
                      
                      props.onChange(newValue.toISOString())
                    }}
                    selected={datesAreSameDay(day, props.value, timeZone)}
                  >
                    <Row alignY="center" alignX="center">
                      <s.FakeHeight />
                      <Text color={datesAreSameDay(day, props.value, timeZone) ? 'white' : 'black'}>{day.get('date')}</Text>
                    </Row>
                  </s.DayBox>
                ))}
              </s.DayBoxesContainer>
            )}

            {(props.granularity === "hour" || props.granularity === "minute") && (
              <Box paddingLeft="md" paddingRight="md" paddingBottom="md">
                {(props.granularity === "hour" || props.granularity === "minute") && (
                  <>
                  <Row>
                    <Text marginBottom="xxs" size="sm">Hour </Text>
                    <Text marginBottom="xxs" size="sm">{momentValue.format('ha')}</Text>
                  </Row>
                    <Slider 
                      min={0}
                      max={23}
                      value={momentValue.get("hours")}
                      onChange={(newHours) => {
                        const newValue = momentValue.set("hours", newHours).toISOString()
                        props.onChange(newValue);
                      }}
                    />
                  </>
                )}
                {props.granularity === "minute" && (
                  <>
                    <Row>
                      <Text marginBottom="xxs" size="sm">Minutes </Text>
                      <Text marginBottom="xxs" size="sm">{momentValue.format('mm')}</Text>
                    </Row>
                    <Slider 
                      min={0}
                      max={59}
                      value={momentValue.get("minutes")}
                      onChange={(newValue) => {
                        props.onChange(momentValue.set("minutes", newValue).toISOString());
                      }}
                    />
                  </>
                )}
              </Box>
            )}

            <Row>
              <ListButton
                label="Clear"
                onPress={() => {
                  props.onChange('')
                  if (props.onDone) {
                    props.onDone();
                  }
                }}
                grow
              />

              <ListButton
                label="Done"
                onPress={() => {
                  props.onChange(moment(props.value || undefined).tz(timeZone).toISOString())
                  if (props.onDone) {
                    props.onDone();
                  }
                }}
                grow
              />
            </Row>

          </Column>
        )}

        {currentView === 'year' && (
          <>
            <YearPicker 
              value={moment(props.value || undefined).tz(timeZone).year()}
              onChange={(newYear) => {
                props.onChange(moment(props.value || undefined).tz(timeZone).year(newYear).toISOString())
                setCurrentView('main');
              }}
            />
            <ListButton
              label="Back"
              onPress={() => {
                setCurrentView('main');
              }}
              grow
            />
          </>
        )}

        {currentView === 'month' && (
          <>
            <MonthPicker 
              value={moment(props.value || undefined).tz(timeZone).month()}
              onChange={(newMonth) => {
                props.onChange(moment(props.value || undefined).tz(timeZone).month(newMonth).toISOString())
                setCurrentView('main');
              }}
            />
            <ListButton
              label="Back"
              onPress={() => {
                setCurrentView('main');
              }}
              grow
            />
          </>
        )}
        
      </Box>
    </View>
  )
}

const YearPicker = (props: { value: number; onChange: (newValue: number) => void }) => {
  const [pageOffset, setPageOffset] = React.useState(0);
  const numYearsPerPage = 12;
  const startYear = (Math.floor(props.value / numYearsPerPage) + pageOffset) * numYearsPerPage;
  const years = generateYears(startYear, numYearsPerPage);

  return (
    <>
      <s.YearButton onPress={() => setPageOffset(pageOffset - 1)}>
        <UpArrowIcon size="lg" />
      </s.YearButton>
      <s.YearsContainer>
        {years.map((year) => (
          <s.YearBox 
            key={year.toString()}
            selected={year === props.value} 
            onPress={() => props.onChange(year)}
            accessibilityRole="button"
          >
            <Text color={year === props.value ? 'white' : 'black'}>{year}</Text>
          </s.YearBox>
        ))}
      </s.YearsContainer>
      <s.YearButton onPress={() => setPageOffset(pageOffset + 1)}>
        <DownArrow size="lg" />
      </s.YearButton>
    </>
  )
}

function generateYears(startYear: number, numYears: number): number[] {
  const years = [];
  for (let i = 0; i < numYears; i++) {
    years.push(startYear + i);
  }

  return years;
}

const MonthPicker = (props: { value: number; onChange: (newValue: number) => void }) => {

  return (
    <>
      <s.MonthsContainer>
        {months.map((month,idx) => (
          <s.MonthBox 
            key={month.toString()}
            selected={idx === props.value} 
            onPress={() => props.onChange(idx)}
          >
            <Text color={idx === props.value ? 'white' : 'black'} size="xs">{month}</Text>
          </s.MonthBox>
        ))}
      </s.MonthsContainer>
    </>
  )
}


function getNumFakeDays(date: string, timeZone: string): number {
  const startOfMonth = moment(date).tz(timeZone).startOf('month');
  return startOfMonth.day();
}

// TODO: add tests for this
function getListOfDaysInMonth(date: string, timeZone: string): moment.Moment[] {
  const startOfMonth = moment(date).tz(timeZone).startOf('month').set('hours', 12);
  const endOfMonth = moment(date).tz(timeZone).endOf('month');
  
  const days: moment.Moment[] = [];
  let dayIterator = startOfMonth;
  while (dayIterator.toISOString() < endOfMonth.toISOString()) {
    days.push(dayIterator.clone());
    dayIterator = dayIterator.add(1, 'day').set('hours', 12);
  }

  return days;
}

function datesAreSameDay(date1: moment.Moment|string|undefined, date2: moment.Moment|string|undefined, timeZone: string): boolean {
  if (!date1 || !date2) {
    return false;
  }

  const moment1 = moment(date1).tz(timeZone);
  const moment2 = moment(date2).tz(timeZone);
  return moment1.date() === moment2.date() && moment1.month() === moment2.month() && moment1.year() === moment2.year();
}

const FakeDays = React.memo(function FakeDays(props: { numFakeDays: number }) {
  return (
    <>
      {[...Array(props.numFakeDays)].map((_v, idx) => (
        <s.FakeDayBox 
          key={idx}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
        >
          <s.FakeHeight />
        </s.FakeDayBox>
      ))}
    </>
  )
});
