import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useEquipmentType, useUpdateEquipmentType, useDeleteEquipmentType } from '../../data/equipmentTypes';
import { useParams, useHistory } from '../../hooks';
import { EquipmentTypesForm } from '../../forms/EquipmentTypesForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { BackButton } from '../../components/BackButton';

export function EditEquipmentTypePage() {
  const history = useHistory();
  const params = useParams<{ equipmentTypeId: string }>();
  const { equipmentType } = useEquipmentType(params.equipmentTypeId);
  const { updateEquipmentType } = useUpdateEquipmentType();
  const { deleteEquipmentType } = useDeleteEquipmentType();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);

  if (!equipmentType) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={equipmentType}
      onSubmit={async (updates) => {
        await updateEquipmentType(equipmentType.id, updates);
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane
          title="Equipment Type"
          rightButton={<SubmitButton as={PaneButton} label="Save" showFeedback disabled={!canWrite} />}
        >
          {equipmentType && (
            <EquipmentTypesForm />
          )}

          <DeleteButton 
            name="equipmentType"
            deleteItemNew={deleteEquipmentType}
            itemId={equipmentType.id}
            label="Delete Equipment Type"
            yesMessage="Yes, Delete Equipment Type"
            question="Delete Equipment Type?"
            successMessage="Equipment Type Deleted"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
