import * as React from 'react';
import { Form } from '../../components/Form';
import { PaneButton } from '../../components/PaneButton';
import { Pane, Panes } from '../../components/Panes';
import { SubmitButton } from '../../components/SubmitButton';
import { QuestionsForm } from '../../forms/QuestionsForm';
import { useHistory, useParams } from '../../hooks';
import { useOrgId } from '../../auth';
import { useAddQuestion } from '../../data/questions';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddQuestionPage() {
  const history = useHistory();
  const orgId = useOrgId();
  const params = useParams<{ serviceTypeId: string  }>();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);
  const { addQuestion } = useAddQuestion();

  if (!canWrite) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        question: '',
        options: '',//'yes,no,n/a',
        allowComments: true,
        serviceTypeId: params.serviceTypeId,
        orgId: orgId || '',
      } as any} // TODO: remove as any
      onSubmit={async (question) => {
        await addQuestion({
          ...question,
        });
        history.goBack();
      }}
    >
      <Panes 
        backgroundColor="light"
        leftButton={<BackButton />}
      >
        <Pane 
          title="Add Question"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <QuestionsForm />
        </Pane>
      </Panes>
    </Form>
  )
}
  
