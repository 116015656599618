import * as React from 'react';
import styled from 'styled-components/native';
import { ButtonLink } from '../Router';

export interface IconButtonProps {
  label: string;
  to?: string;
  onPress?: () => void;
  children: React.ReactNode;
}

const Padder = styled.View`
  padding: 8px; /** Add some padding so it's easier to press */
  border-radius: 5px;
  margin-bottom: -8px;
  margin-top: -8px;
`;

export const IconButton = (props: IconButtonProps) => {
  return (
    <ButtonLink
      accessibilityLabel={props.label} 
      to={props.to}
      onPress={props.onPress}
    >
      <Padder>
        {props.children}
      </Padder>
    </ButtonLink>
  )
}
