import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { EmptyState } from '../../components/EmptyState';
import { ListItem } from '../../components/ListItem';
import { IconButton } from '../../components/IconButton';
import { AddIcon } from '../../icons/Add';
import { useParams } from '../../hooks';
import { Permissions } from '@rivison-inc/ft-types';
import { useHasPermission } from '../../auth';
import { ServiceTypesSyncStatusMessage, useServiceTypesByEquipmentTypeId } from '../../data/serviceTypes';

function AddServiceTypeButton(props: { equipmentTypeId: string }) {  
  return (
    <IconButton to={`/equipment-types/${props.equipmentTypeId}/service-types/new`} label="Add Service Type">
      <AddIcon />
    </IconButton>
  )
}

export function ServiceTypeListPage() {
  const canRead = useHasPermission(Permissions.SETTINGS_READ);
  if (!canRead) {
    return <></>;
  }

  const params = useParams<{ equipmentTypeId: string }>();
  const { serviceTypes } = useServiceTypesByEquipmentTypeId(params.equipmentTypeId);

  return (
    <Panes backgroundColor="light">
      <Pane title="Service Types" rightButton={<AddServiceTypeButton equipmentTypeId={params.equipmentTypeId} />}>
        {serviceTypes.map((serviceType, idx) => (
          <ListItem
            key={idx} 
            to={`/equipment-types/${params.equipmentTypeId}/service-types/${serviceType.id}`}
            title={serviceType.name}
          />
        ))}
        <ServiceTypesSyncStatusMessage />
        {!serviceTypes.length && (
          <EmptyState 
            title={'No Service Types'} 
            subtitle={'Add service types using the plus icon in the top right'} 
          />
        )}
      </Pane>
    </Panes>
  )
}
