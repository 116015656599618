import React, { useContext, useEffect } from 'react';
import { useForm, FormProvider, Controller, useFormContext, useWatch } from 'react-hook-form';
import omit from 'lodash/omit';
import { DevTool } from '@hookform/devtools';
import { Platform } from 'react-native';

const OnSubmitContext = React.createContext<((values: any) => void)|undefined>(undefined);

export function Form2(props: { 
    children: React.ReactNode; 
    onSubmit?: (values: any) => void; 
    defaultValues?: any;
    reinitializeWhenTruthy?: any[];
}) {
    const methods = useForm({
        defaultValues: props.defaultValues,
    });

    const reinitializeWhenTruthy = props.reinitializeWhenTruthy || [];
    useEffect(() => {
        if (reinitializeWhenTruthy.length > 0 && reinitializeWhenTruthy.every((item) => !!item)) {
            methods.reset(props.defaultValues);
        }
    }, reinitializeWhenTruthy);

    return (
        <FormProvider {...methods}>
            <OnSubmitContext.Provider value={props.onSubmit}>
                {props.children}
            </OnSubmitContext.Provider>
            {Platform.OS === 'web' && process.env.NODE_ENV !== 'production' && <DevTool control={methods.control} />}
        </FormProvider>
    )
}

export const SubmitButton2 = <T extends { onPress?: () => void; disabled?: boolean }>(props: { as: React.ComponentType<T>; onPress?: (...props: any[]) => void; disabled?: boolean } & Omit<T, 'onPress'>) => {
    const { as, onPress, ...forwardedProps } = props;
    const { handleSubmit } = useFormContext();
    const onSubmit = useContext(OnSubmitContext);

    return (
        React.createElement(as, {
          ...forwardedProps,
          disabled: props.disabled, // || !isValid,
          onPress: (...pressProps: any[]) => { 
            if (props.onPress) {
              props.onPress(...pressProps);
            }
             
            if (onSubmit) {
                handleSubmit(onSubmit)();
            }
          },
        } as any)
      )
}

export function Field2<T>(props: { name: string; as: React.ComponentType<T> } & Omit<T, "onChange" | "value">) {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            name={props.name}
            render={({ field, fieldState }) => {
                const newProps = omit(props, "name");
                
                // @ts-expect-error
                return React.createElement(props.as, {
                    ...newProps,
                    value: field.value,
                    onChange: field.onChange
                });
            }}
        />
    )
}

export function useFormValue2(fieldName: string): unknown {
    // @ts-expect-error upgrade TS to fix this
    const value = useWatch({
        name: fieldName
    });

    return value;
}

export function useSetFormValue2() {
    const { setValue } = useFormContext();

    return (fieldName: string, fieldValue: unknown) => {
        // @ts-expect-error Upgrade TS to fix this error
        setValue(fieldName, fieldValue)
    }
}
