import moment from 'moment';
import * as React from 'react';
import { EmptyState } from '../../components/EmptyState';
import { IconButton } from '../../components/IconButton';
import { ListItem } from '../../components/ListItem';
import { Pane, Panes } from '../../components/Panes';
import { useParams } from '../../hooks';
import { AddIcon } from '../../icons/Add';
import { useHistoryRecordsForEquipment } from '../../data/history';
import { useHasPermission } from '../../auth';
import { Permissions, HistoryRecord } from '@rivison-inc/ft-types';
import { HistoryIcon } from '../../icons/History';
import { searchData } from '../../utils/filter';
import { sortByString } from '../../utils/sort';
import styled from 'styled-components/native';
import { ProfileImage } from '../../components/ProfilePicture';
import { Box } from '../../components/Box';
import { useUsers } from '../../data/users';
import { useLocation } from '../../data/locations';
import { UpToDateAsOf } from '../../containers/UpToDateAsOf';
import { useSync } from '../../data/syncHooks';
import { ActivityIndicator } from '../../components/ActivityIndicator';

export const ProfilePic = styled.Image`
  width: 46px;
  height: 46px;
  border-radius: 100%;
  margin-bottom: 5px;
  margin-right: 10px;
`;


export function EquipmentHistoryPage() {
  const canRead = useHasPermission(Permissions.HISTORY_READ);
  const params = useParams<{ locationId: string; equipmentId: string  }>();
  const users = useUsers().users;
  const { location } = useLocation(params.locationId);
  const { historyRecords } = useHistoryRecordsForEquipment({ locationId: params.locationId, equipmentId: params.equipmentId });
  const { lastSuccessfulUploadDate } = useSync();

  const [searchText, setSearchText] = React.useState('');

  function getSearchData(data: HistoryRecord) {
    return [ 
      data.description || '', 
      data.date || '', 
    ];
  }

  const filteredHistory = React.useMemo(() => searchData(historyRecords, getSearchData, searchText), [searchText, historyRecords]);
  const sortedHistory = React.useMemo(() => sortByString(filteredHistory, 'date', 'desc'), [filteredHistory]);

  if (!canRead || !location) {
    return <></>;
  }

  return (
    <Panes backgroundColor="light">

      <Pane 
        title="History" 
        icon={<HistoryIcon />}
        rightButton={<AddHistoryButton />}
        searchText={searchText}
        onSearchChange={(searchText) => setSearchText(searchText)}
      >
        <UpToDateAsOf />
        {sortedHistory.map((historyRecord, idx) => {
          const user = users.find(user => user.id === historyRecord.userId);
          return (
            <ListItem
              leftComponent={<Box paddingRight={'sm'}><ProfileImage name={user?.name || ''} image={user?.image} /></Box>}
              key={idx} 
              rightComponent={historyRecord?.hasBeenChangedLocally && moment(historyRecord?.lastUpdated).isAfter(moment(lastSuccessfulUploadDate)) ? <ActivityIndicator size='small' /> : null}
              to={`/sites/${params.locationId}/equipment/${params.equipmentId}/history/${historyRecord.id}`}
              title={historyRecord.description}
              subtitle={moment(historyRecord.date).tz(location.timeZone).format('MMMM D YYYY h:mmA')}
            />
          )
        })}
        {!sortedHistory.length && <EmptyState title={'No History'} subtitle={'Add history by clicking the plus in the top right or by performing a service.'} image={'calendar'} />}
      </Pane>

      
    </Panes>
  )
}

function AddHistoryButton() { 
  const params = useParams<{ locationId: string; equipmentId: string  }>();
 
  return (
    <IconButton to={`/sites/${params.locationId}/equipment/${params.equipmentId}/history/new`} label="Add History">
      <AddIcon />
    </IconButton>
  )
}
