import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { SizingCode, Theme } from '../../theme';
import { getMarginStyles } from '../../utils/style';

const gap = 10;
const size = 6;

const dotPulse = (props: { colorCode?: "white" | "primary", theme: Theme }) => keyframes`
  0% {
    box-shadow: ${9984 + gap}px 0 0 -5px ${props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  }
  30% {
    box-shadow: ${9984 + gap}px 0 0 2px ${props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  }
  60%,
  100% {
    box-shadow: ${9984 + gap}px 0 0 -5px ${props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  }
`;

const dotPulseBefore = (props: { colorCode?: "white" | "primary", theme: Theme }) => keyframes`
  0% {
    box-shadow: 9984px 0 0 -5px ${props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  }
  30% {
    box-shadow: 9984px 0 0 2px ${props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px ${props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  }
`;

const dotPulseAfter = (props: { colorCode?: "white" | "primary", theme: Theme }) => keyframes`
  0% {
    box-shadow: ${9984 + (gap * 2)}px 0 0 -5px ${props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  }
  30% {
    box-shadow: ${9984 + (gap * 2)}px 0 0 2px ${props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  }
  60%,
  100% {
    box-shadow: ${9984 + (gap * 2)}px 0 0 -5px ${props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  }
`;

const Spinner = styled.div<{ colorCode?: "primary" | "white" }>`
  position: relative;
  left: -9984px;
  width: ${size}px;
  height: ${size}px;
  border-radius: 5px;
  background-color: ${props => props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  color: ${props => props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  box-shadow: ${9984 + gap}px 0 0 -5px ${props => props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  animation: ${dotPulse} 1.5s infinite linear;
  animation-delay: .25s;

  &::before, &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: ${size}px;
    height: ${size}px;
    border-radius: 5px;
    background-color: ${props => props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
    color: ${props => props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
  }

  &::before {
    box-shadow: 9984px 0 0 -5px ${props => props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
    animation: ${dotPulseBefore} 1.5s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    box-shadow: ${9984 + (gap * 2)}px 0 0 -5px ${props => props.colorCode === "white" ? "#ffffff" : props.theme.palette.primary};
    animation: ${dotPulseAfter} 1.5s infinite linear;
    animation-delay: .5s;
  }
`;

const SpinnerContainer = styled.div<{ marginTopCode?: SizingCode, marginBottomCode?: SizingCode, marginLeftCode?: SizingCode, marginRightCode?: SizingCode }>`
  width: 26px;
  ${getMarginStyles}
`;

export interface LoadingSpinnerProps {
  color?: "primary" | "white";
  marginTop?: SizingCode;
  marginBottom?: SizingCode;
  marginLeft?: SizingCode;
  marginRight?: SizingCode;
}

export function LoadingSpinner(props: LoadingSpinnerProps) {
  return (
    <SpinnerContainer
      marginTopCode={props.marginTop}
      marginBottomCode={props.marginBottom}
      marginLeftCode={props.marginLeft}
      marginRightCode={props.marginRight}
    >
      <Spinner colorCode={props.color} />
    </SpinnerContainer>
  )
}