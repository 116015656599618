import * as React from 'react';

export function useCheckIsMounted() {
  const isMountedRef = React.useRef(true);
  
  React.useEffect(() => {
    return () => {
      isMountedRef.current = false;
    }
  }, []);

  return React.useRef(() => {
    return isMountedRef.current;
  }).current;
}
