import * as React from 'react';
import { EmptyState } from '../../components/EmptyState';
import { ListItem } from '../../components/ListItem';
import { Pane, Panes } from '../../components/Panes';
import { ListRenderer } from '../../components/ListRenderer';
import { useHasPermission } from '../../auth';
import { Permissions, Customer } from '@rivison-inc/ft-types';
import { CalendarMonthIcon } from '../../icons/CalendarMonth';
import { searchData } from '../../utils/filter';
import { sortByString } from '../../utils/sort';
import { useCustomers } from '../../data/customers';
import { useParams } from '../../hooks/useParams';
import { useHistory } from '../../hooks/useHistory';
import { LocationsSyncStatusMessage, useUpdateLocation } from '../../data/locations';

export function CustomerSelectPage() {
  const canRead = useHasPermission(Permissions.LOCATION_READ);  
  const { customers } = useCustomers();
  const params = useParams<{ locationId: string }>();
  const history = useHistory();

  const [customerSearchText, setCustomerSearchText] = React.useState('');
  const { updateLocation } = useUpdateLocation();

  function getCustomerSearchData(data: Customer) {
    return [ 
      data.name || '', 
      data.address || '' ,
      data.city || '' ,
      data.postalCode || '' ,
      data.phone || '' ,
      data.email || '' ,
      data.contact || '' ,
    ];
  }

  const filteredCustomers = React.useMemo(() => {
    const ordered = sortByString(customers, 'name', 'asc');
    return searchData(ordered, getCustomerSearchData, customerSearchText);
  }, [customerSearchText, customers]);

  if (!canRead) {
    return <></>;
  }

  function renderRow(customer: Customer, idx: number) {
    return (
      <ListItem
        key={idx} 
        onSelect={() => {
          updateCustomer(customer.id);
        }}
        title={customer.name || ''}
        subtitle={`${customer.address || ''} ${customer.city || ''} ${customer.province || ''}`}
      />
    )
  }

  async function updateCustomer(customerId: string) {
    await updateLocation(params.locationId, { customerId })
    history.goBack()
  }

  return (
    <>
      <Panes
        id="customersPane"
        backgroundColor="light"
        height="full"
      >
          <Pane
            title="Customers"
            searchText={customerSearchText}
            onSearchChange={(searchText) => setCustomerSearchText(searchText)}
            icon={<CalendarMonthIcon />}
          >
            <>
              <ListRenderer 
                render={(customer: Customer, idx: number) => renderRow(customer, idx)}
                data={filteredCustomers}
              />
              {!filteredCustomers.length && <EmptyState title={'No Customers'} subtitle={'Customers are groups of sites with billing information.'} image={'location'} />}
              <LocationsSyncStatusMessage />
            </>
          </Pane>
      </Panes>
    </>
  )
}
