import { ServiceMonth } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

const {
  useUpdateDataItem,
  useDeleteDataItem,
  useAddData,
  useDataById,
  useData
} = makeDataHooks<ServiceMonth>({
  dataName: "ServiceMonths",
});

export function useServiceMonth(params: { id: string; locationId: string }) {
  const { item } = useDataById(params.id, params.locationId);

  return {
    serviceMonth: item,
  }
}

export function useServiceMonths(locationId: string) {
  const { data, isLoading } = useData(undefined, locationId);

  return {
    serviceMonths: data,
    isLoading
  }
}

export function useAddServiceMonth() {
  const { addItem } = useAddData();

  return {
    addServiceMonth: addItem,
  }
}

export function useDeleteServiceMonth() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteServiceMonth: deleteItem,
  }
}

export function useUpdateServiceMonth() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateServiceMonth: updateItem,
  }
}
