import * as React from 'react';

const IdContext = React.createContext({ counter: 0 });

export const IdProvider = (props: { children: React.ReactNode }) => {
  const ref = React.useRef({ counter: 0 });

  return (
    <IdContext.Provider value={ref.current}>
      {props.children}
    </IdContext.Provider>
  )
}

export const useId = (options?: { prefix?: string }): string => {
  const idRef = React.useRef<null|string>(null);
  const context = React.useContext(IdContext);

  if (idRef.current === null) {
    context.counter++;
    idRef.current = `${options?.prefix ? (options.prefix + '-') : ''}${context.counter.toString()}`;
  }
  
  return idRef.current;
}
