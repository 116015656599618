import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useAddHistoryRecord } from '../../data/history';
import { useHistory, useParams } from '../../hooks';
import { HistoryForm } from '../../forms/HistoryForm';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { useHasPermission, useOrgId, useUserId } from '../../auth';
import moment from 'moment';
import { HistoryType, Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddHistoryPage() {
  const history = useHistory();
  const orgId = useOrgId();
  const userId = useUserId();
  const params = useParams<{ locationId: string; equipmentId: string }>();
  const canWrite = useHasPermission(Permissions.HISTORY_WRITE);
  const { addHistoryRecord } = useAddHistoryRecord();

  if (!canWrite || !userId || !orgId) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        orgId,
        description: '',
        date: moment().toISOString(),
        userId: userId,
        happenedAtLocationId: params.locationId,
        locationId: params.locationId,
        equipmentId: params.equipmentId,
        type: HistoryType.CUSTOM,
      }}
      onSubmit={async (historyRecord) => {
        await addHistoryRecord(historyRecord, params.locationId);
        history.goBack();
      }}
    >
      <Panes 
        backgroundColor="light"
        leftButton={<BackButton />}
      >
        <Pane 
          title="Add History"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <HistoryForm locationId={params.locationId} />
        </Pane>
      </Panes>
    </Form>
  )
}
  
