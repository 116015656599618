import { FeatureList, Organization } from "@rivison-inc/ft-types";
import { useCallback, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useCheckIsMounted } from "../hooks";
import { api } from "../api";
import { useOrgId } from '../auth';
import { getReadUrlForFile, getUploadUrlForFile } from "../utils/file";
import { Storage } from "../storage";

type BlobWithName = Blob & { name: string };

interface OrganizationWithFiles extends Omit<Organization, 'letterHead' | 'landscapeLetterHead' | 'logo'> {
  letterHead?: string|BlobWithName;
  landscapeLetterHead?: string|BlobWithName;
  logo?: string|BlobWithName;
}

export function useOrganization() {
  const orgId = useOrgId();
  
  const { data: organizationServer, isLoading } = useQuery(["Organization", orgId], async () => {
    if (!orgId) {
      return null;
    }

    const response = await api.get(`/organizations/${orgId}`, {
      params: {}
    });
    const organization = response.data.organization;

    await Storage.setItem('organization', organization);

    return organization;
  });

  const { data: organizationLocal, isLoading: isLoadingOrgLocal } = useQuery(["Organization-local", orgId], async () => {
    const organization = await Storage.getItem('organization');
    return organization;
  });

  const data = organizationServer || organizationLocal;

  const { data: readUrlResponse, isLoading: isLoadingLetterhead } = useQuery(["OrganizationLetterHead", data?.letterHead || null, orgId], async () => {
    if (!data?.letterHead) {
      return null;
    }
  
    return getReadUrlForFile(data.letterHead, 'letter-heads', { orgId: data.id })
  }, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const { data: landscapeLetterHeadReadUrlResponse, isLoading: isLoadingLandscapeLetterhead } = useQuery(["OrganizationLandscapeLetterHead", data?.landscapeLetterHead || null, orgId], async () => {
    if (!data?.landscapeLetterHead) {
      return null;
    }
  
    return getReadUrlForFile(data.landscapeLetterHead, 'letter-heads', { orgId: data.id })
  }, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const { data: logoReadUrlResponse, isLoading: isLoadingLogo } = useQuery(["OrganizationLogo", data?.logo || null, orgId], async () => {
    if (!data?.logo) {
      return null;
    }
  
    return getReadUrlForFile(data.logo, 'logos', { orgId: data.id })
  }, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const organization: Organization = useMemo(() => {
    if (!data) {
      return null;
    }

    return {
      ...data,
      letterHead: readUrlResponse?.url || '',
      landscapeLetterHead: landscapeLetterHeadReadUrlResponse?.url || '',
      logo: logoReadUrlResponse?.url || '',
    }
  }, [data, readUrlResponse, landscapeLetterHeadReadUrlResponse, logoReadUrlResponse]);

  return {
    organization,
    isLoadingOrganization: isLoading || isLoadingOrgLocal || isLoadingLetterhead || isLoadingLandscapeLetterhead || isLoadingLogo
  }
}

export function useSaveOrganization() {
  const orgId = useOrgId();
  const queryClient = useQueryClient();
  const checkIsMounted = useCheckIsMounted();

  return {
    saveOrganization: useCallback(async (organizationData: OrganizationWithFiles, params?: { onSuccess?: () => void }) => {
      if (!orgId) {
        return;
      }

      const { letterHead, landscapeLetterHead, logo, ...updates } = organizationData;

      const uploadUrl = await getUploadUrlForFile(letterHead || '', 'letter-heads', { orgId });
      if (uploadUrl && letterHead && typeof letterHead !== 'string') {
  
        const url = URL.createObjectURL(letterHead)
        const img = new Image()
        img.src = url;
        const imageData = await new Promise((resolve, reject) => {
          img.onload = (obj) => resolve(obj.target);
          img.onerror = reject;
        });
        const size =  imageData as { naturalHeight: number; naturalWidth: number }
  
        updates.letterHeadHeight = size.naturalHeight;
        updates.letterHeadWidth = size.naturalWidth;
  
        await api.upload(uploadUrl.url, letterHead);
      }
  
      const landscapeLetterHeadUploadUrl = await getUploadUrlForFile(landscapeLetterHead || '', 'letter-heads', { orgId });
      if (landscapeLetterHeadUploadUrl && landscapeLetterHead && typeof landscapeLetterHead !== 'string') {
  
        const url = URL.createObjectURL(landscapeLetterHead)
        const img = new Image()
        img.src = url;
        const imageData = await new Promise((resolve, reject) => {
          img.onload = (obj) => resolve(obj.target);
          img.onerror = reject;
        });
        const size =  imageData as { naturalHeight: number; naturalWidth: number }
  
        updates.landscapeLetterHeadHeight = size.naturalHeight;
        updates.landscapeLetterHeadWidth = size.naturalWidth;
  
        await api.upload(landscapeLetterHeadUploadUrl.url, landscapeLetterHead);
      }
  
      const logoUploadUrl = await getUploadUrlForFile(logo || '', 'logos', { orgId });
      if (logoUploadUrl && logo && typeof logo !== 'string') {
        await api.upload(logoUploadUrl.url, logo);
      }
  
      await api.patch(`/organizations/${orgId}`, { 
        data: {
          ...updates,
          letterHead: uploadUrl?.fileName,
          landscapeLetterHead: landscapeLetterHeadUploadUrl?.fileName,
          logo: logoUploadUrl?.fileName,
        } 
      });
  
      // let letterHeadResult;
      // if (uploadUrl?.fileName && orgId) {
      //   const readUrlResponse = await getReadUrlForFile(uploadUrl?.fileName, 'letter-heads', { orgId })
      //   letterHeadResult = readUrlResponse?.url || '';
      // } else {
      //   letterHeadResult = typeof letterHead === 'string' ? letterHead : '';
      // }
  
      // let landscapeLetterHeadResult;
      // if (landscapeLetterHeadUploadUrl?.fileName && orgId) {
      //   const readUrlResponse = await getReadUrlForFile(landscapeLetterHeadUploadUrl?.fileName, 'letter-heads', { orgId })
      //   landscapeLetterHeadResult = readUrlResponse?.url || '';
      // } else {
      //   landscapeLetterHeadResult = typeof landscapeLetterHead === 'string' ? landscapeLetterHead : '';
      // }
  
      // let logoResult;
      // if (uploadUrl?.fileName && orgId) {
      //   const readUrlResponse = await getReadUrlForFile(logoUploadUrl?.fileName, 'logos', { orgId })
      //   logoResult = readUrlResponse?.url || '';
      // } else {
      //   logoResult = typeof logo === 'string' ? logo : '';
      // }
  
      queryClient.setQueryData(["Organization", orgId], {
        ...updates,
        // letterHead: letterHeadResult,
        // landscapeLetterHead: landscapeLetterHeadResult,
        // logo: logoResult,
      });

      if (params?.onSuccess && checkIsMounted()) {
        params.onSuccess();
      }
    }, [orgId])
  }
}

export function useIsFeatureEnabled(feature: FeatureList) {
  const { organization } = useOrganization();
  const enabledFeatures = organization?.enabledFeatures || [];
  return enabledFeatures.findIndex(f => f === feature) !== -1;
}
