import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useParams, useHistory } from '../../hooks';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { useQuestion, useDeleteQuestion, useUpdateQuestion } from '../../data/questions';
import { QuestionsForm } from '../../forms/QuestionsForm';
import { BackButton } from '../../components/BackButton';

export function EditQuestionPage() {
  const history = useHistory();
  const params = useParams<{ questionId: string }>();

  const { question } = useQuestion(params.questionId);
  const { updateQuestion } = useUpdateQuestion();
  const { deleteQuestion } = useDeleteQuestion();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);

  if (!question) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={question}
      onSubmit={async (updates) => {
        await updateQuestion(updates.id, {
          ...updates,
        });
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Checklist Question"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" disabled={!canWrite} />}
        >
          {question && (
            <QuestionsForm />
          )}
          <DeleteButton 
            name="question"
            deleteItemNew={deleteQuestion}
            itemId={question.id}
            label="Delete Question"
            yesMessage="Yes, Delete Question"
            question="Delete Question?"
            successMessage="Question Deleted"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
