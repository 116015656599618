import * as React from 'react';
import { ListTextField } from '../../components/ListTextField';
import { TextBox } from '../../components/ListTextField/TextBox';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { Field, useFormValues } from '../../components/Form';
import { ListSwitch } from '../../components/ListSwitch';
import { Text } from '../../components/Text';
import { TextLink } from '../../components/TextLink';
import { Permissions, InvitationStatus } from '@rivison-inc/ft-types'
import { api } from '../../api';
import { TextInput } from 'react-native';
import { Row } from '../../components/Row';
import { useNetworkStatus } from '../../hooks/useNetworkStatus.web';
import { useUser } from '../../data/users';
import { ListFileUpload } from '../../components/ListFileUpload';

export function UserForm() {
  const values = useFormValues();
  const [linkStatus, setLinkStatus] = React.useState<"idle" | "creating" | "error" | "offline">("idle");
  const [inviteLink, setInviteLink] = React.useState("");
  const networkStatus = useNetworkStatus();
  const userId = values.id as string;
  const user = useUser(userId || '')

  function generateLink() {
    setLinkStatus("creating");
    api.post(`/users/${values.id}/invite-link`)
      .then((response) => {
        setLinkStatus("idle");
        setInviteLink(response.data.inviteLink);
      })
      .catch(() => {
        if (networkStatus === "online") {
          setLinkStatus("error");
        } else {
          setLinkStatus("offline");
        }
      })
  }

  return (
    <>
      
      <Field
        as={ListTextField}
        name="name"
        label="Name"
        type="text"
        required
      />
      <Field 
        as={ListFileUpload} 
        name="image" 
        label="Profile Image" 
      />
      <Field
        as={ListTextField}
        name="email"
        label="Email"
        type="email"
        required
      />
      {values.id && values.invitationStatus === InvitationStatus.PENDING && (
        <>
          {/* TODO: use first name in this message? */}
          {!inviteLink && <Text padding="sm">{user.user?.name} hasn&apos;t accepted their invite yet. You can <TextLink onPress={generateLink}>click here</TextLink> to generate another invite link.</Text>}
          {linkStatus === "creating" && <Text marginLeft="sm" marginBottom="sm">Creating invite link...</Text>}
          {linkStatus === "error" && <Text marginLeft="sm" marginBottom="sm">Sorry, an error occurred while trying to create an invite link.  Please try again later, or reach out to support</Text>}
          {linkStatus === "offline" && <Text marginLeft="sm" marginBottom="sm">Sorry, looks like you&apos;re offline.  Please try again when you have an internet connection</Text>}

          {inviteLink && linkStatus === "idle" && <Text marginLeft="sm" marginBottom="xs">An email with the reset password link below has been sent to {user.user?.email}</Text>}
          {inviteLink && linkStatus === "idle" && (
            <Row backgroundColor="grey" padding="sm" marginLeft="sm" marginRight="sm" marginBottom="sm">
              <TextInput 
                selectTextOnFocus 
                ref={input => {
                  input?.focus();
                }}
                style={{ width: "100%" }} 
                value={inviteLink} 
              />
            </Row>
          )}
        </>
      )}
      <ListSectionTitle>Permissions</ListSectionTitle>
      <Field
        as={CheckBoxList}
        name="permissions"
      />
    </>
  )
}

interface CheckBoxListProps {
  value: string[];
  onChange: (newValue: string[]) => void;
}

const CheckBoxList = (props: CheckBoxListProps) => {
  const value = props.value || [];
  const possiblePermissions = [
    { name: Permissions.MOBILE_LOGIN, label: 'Mobile Access', required: [ Permissions.LOCATION_READ, Permissions.EQUIPMENT_READ ] },
    { name: Permissions.WEB_LOGIN, label: 'Web Login' },
    { name: Permissions.EQUIPMENT_READ, label: 'View Equipment', turnOffIfNotSelected: [ Permissions.MOBILE_LOGIN ] },
    { name: Permissions.EQUIPMENT_WRITE, label: 'Edit Equipment' },
    { name: Permissions.SERVICE_READ, label: 'View Service' },
    { name: Permissions.SERVICE_WRITE, label: 'Edit Service' },
    { name: Permissions.LOCATION_READ, label: 'View Location', turnOffIfNotSelected: [ Permissions.MOBILE_LOGIN ] },
    { name: Permissions.LOCATION_WRITE, label: 'Edit Location' },
    { name: Permissions.HISTORY_READ, label: 'View History' },
    { name: Permissions.HISTORY_WRITE, label: 'Edit History' },
    { name: Permissions.DEFICIENCY_READ, label: 'View Deficiencies' },
    { name: Permissions.DEFICIENCY_WRITE, label: 'Edit Deficiencies' },
    { name: Permissions.REPORT_READ, label: 'Generate Reports' },
    { name: Permissions.SCHEDULE_READ, label: 'View Schedule' },
    { name: Permissions.SCHEDULE_WRITE, label: 'Edit Schedule' },
    { name: Permissions.SETTINGS_READ, label: 'View Settings' },
    { name: Permissions.SETTINGS_WRITE, label: 'Edit Settings' },
  ];

  return (
    <>
      <ListSwitch 
        label="All Permissions" 
        value={value.includes('*.*')}
        onChange={(checked) => {
          if (checked) {
            props.onChange(['*.*']);
          } else {
            props.onChange([]);
          }
        }}
      />
      {possiblePermissions.map((permission) => (
        <ListSwitch 
          key={permission.name}
          label={permission.label}
          onChange={(checked) => {
            let newValue = [...value];

            if (checked) {
              newValue.push(permission.name);  
              
              permission.required?.forEach(p => {
                newValue.push(p);  
              });
              
              if (newValue.length === possiblePermissions.length) {
                newValue = ['*.*'];
              }
            } else {

              permission.turnOffIfNotSelected?.forEach(p => {
                const index = newValue.indexOf(p);
                if (index > -1) {
                  newValue.splice(index, 1);
                }
              });

              if (newValue.indexOf('*.*') !== -1) {
                newValue = possiblePermissions
                  .filter((p) => p.name !== permission.name)
                  .map((p) => p.name);
              } else {
                newValue.splice(newValue.indexOf(permission.name), 1);
              }
            }

            props.onChange(newValue);
          }} 
          value={value.includes(permission.name) || value.includes('*.*')} 
        />
      ))}
    </>
  )
}
