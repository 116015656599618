import * as React from 'react';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { ListTextField } from '../../components/ListTextField';
import { CountryDropdownField } from '../../components/CountryDropdownField';
import { StateDropdownField } from '../../components/StateDropdownField';
import { Field2, useFormValue2, useSetFormValue2 } from '../../components/Form2';
import { useOrgId } from '../../auth';
import { OfflineError } from '../../errors/OfflineError';
import { useGenerateAccountNumber } from '../../data/accountNumbers';

export const CustomerForm = (props: { autoGenerateAccountNumber?: boolean }) => {
  const country = useFormValue2('country');
  const { accountNumberStatus, accountNumberError } = useAutoGenerateAccountNumber({
    enabled: props.autoGenerateAccountNumber
  });

  return (
    <>
      <Field2
        as={ListTextField}
        name="name"
        label={'Name'}
        autofocus
        required
      />
      <Field2
        as={ListTextField}
        name="accountNumber"
        label={'Account Number'}
        disabled={accountNumberStatus === 'loading'}
        loading={accountNumberStatus === 'loading'}
        errorText={getErrorMessageFromAccountNumberError(accountNumberError)}
      />
      <ListSectionTitle>Address</ListSectionTitle>
      
      <Field2 
        as={CountryDropdownField} 
        hasEmptyOption
        name="country"
      />
      <Field2
        as={StateDropdownField} 
        hasEmptyOption
        country={country as string}
        name="province"
      />
      <Field2
        as={ListTextField}
        name="city"
        label={'City'}
      />
      <Field2
        as={ListTextField}
        name="address"
        label={'Address'}
      />
      <Field2
        as={ListTextField}
        name="addressTwo"
        label={'Address Two'}
      />
      <Field2
        as={ListTextField}
        name="postalCode"
        label={'Postal Code'}
      />

      <ListSectionTitle>Contact</ListSectionTitle>
      <Field2
        as={ListTextField}
        name="contact"
        label={'Contact'}
      />
      <Field2
        as={ListTextField}
        name="phone"
        label={'Phone'}
      />
      <Field2
        as={ListTextField}
        name="cell"
        label={'Cell'}
      />
      <Field2
        as={ListTextField}
        name="email"
        label={'Email'}
      />
      <Field2
        as={ListTextField}
        name="fax"
        label={'Fax'}
      />
      
    </>
  )
}

function useAutoGenerateAccountNumber({
  enabled
}: {
  enabled?: boolean
}) {
  const orgId = useOrgId();
  const setFormValue = useSetFormValue2();
  const { generateAccountNumber, accountNumberError, accountNumberStatus } = useGenerateAccountNumber();

  React.useEffect(() => {
    if (accountNumberStatus === 'idle' && orgId && enabled) {
      generateAccountNumber(orgId, {
        onSuccess: (accountNumber) => {
          setFormValue('accountNumber', accountNumber);
        }
      });
    }
  }, [orgId, generateAccountNumber, accountNumberStatus, setFormValue, enabled]);

  return {
    accountNumberStatus,
    accountNumberError
  }
}

function getErrorMessageFromAccountNumberError(err: unknown): string | undefined {
  if (err instanceof OfflineError) {
    return 'Could not generate unique account number. Device must be connected to the internet. An account number can still be entered manually.'
  }

  if (err instanceof Error) {
    return 'Could not generate unique account number. Please try again later or enter an account number manually.'
  }

  return undefined;
}
