import * as React from 'react';
import { ListButton } from '../../components/ListButton';
import { Equipment } from '@rivison-inc/ft-types';
import { useHistory, useLastTruthyValue, useControlDialog, useQueueMessage, useOpenDialogState } from '../../hooks';
import { BarCodeScanner } from '../../components/BarCodeScanner';
import { Dialog } from '../../containers/Dialog';
import { useUserId } from '../../auth';
import { useOrgId } from '../../auth';
import { userTracker, trackingCategory } from '../../controllers/userTracker';
import { useDeleteEquipment, useGetEquipmentByBarcode, useUpdateEquipment } from '../../data/equipment';
import { CouldNotService } from '../CouldNotService';
import { useIsSimulator } from '../../hooks/useSimulator';
import { locationSwitcher } from '../../data/locationSwitcher';
import { FullScreenActivityIndicator } from '../../components/FullScreenActivityIndicator ';
import { TransferEquipment } from '../TransferEquipment';

export type HidableOptions = 'preview-details' | 'edit' | 'open-deficiency' | 'swap' | 'replace'
export function EquipmentOptions({ dialogName, subtitle, hideOptions = [], equipmentItem, simulateScan }: { dialogName: string; subtitle?: string; hideOptions?: HidableOptions[]; equipmentItem?: Equipment; simulateScan?: (barcode: string) => void }) {
  const history = useHistory();
  const { openDialog, closeDialog } = useControlDialog();
  const [isSwappingEquipment, setIsSwappingEquipment] = React.useState(false);
  const [isReplacingEquipment, setIsReplacingEquipment] = React.useState(false);
  const [isReplacingBarcode, setIsReplacingBarcode] = React.useState(false);
  const { deleteEquipment } = useDeleteEquipment();
  const { updateEquipment } = useUpdateEquipment();
  const { getEquipmentByBarcode } = useGetEquipmentByBarcode();
  const currentLocationId = useLastTruthyValue(equipmentItem?.locationId);
  const isSimulator = useIsSimulator();
  const dialogState = useOpenDialogState();
  const [loadingBarcodeSearch, setLoadingBarcodeSearch] = React.useState(false);

  const myId = useUserId();
  const orgId = useOrgId();
  const queueMessage = useQueueMessage();

  async function doSwap(scannedBarcode: string) {
    setLoadingBarcodeSearch(true);
    setIsSwappingEquipment(false)

    const foundEquipment = await getEquipmentByBarcode(scannedBarcode)
    const currentEquipment = equipmentItem;

    if (!foundEquipment) {
      queueMessage({
        type: 'message',
        message: 'Could not find equipment with that barcode'
      });
      setIsSwappingEquipment(false)
      return;
    }
    if (!currentEquipment) {
      queueMessage({
        type: 'message',
        message: 'Current equipment failed to load'
      });
      setIsSwappingEquipment(false)
      return;
    }

    await locationSwitcher.moveLocation(currentEquipment.id, currentEquipment.locationId, foundEquipment.locationId, foundEquipment.name)
    await locationSwitcher.moveLocation(foundEquipment.id, foundEquipment.locationId, currentEquipment.locationId, currentEquipment.name)

    queueMessage({
      type: 'message',
      message: 'Equipment swapped'
    });
    setLoadingBarcodeSearch(false);
  }

  async function doReplace(scannedBarcode: string) {
    const foundEquipment = await getEquipmentByBarcode(scannedBarcode)
    const currentEquipment = equipmentItem;

    if (!foundEquipment) {
      queueMessage({
        type: 'message',
        message: 'Could not find equipment with that barcode'
      });
      setIsReplacingEquipment(false)
      return;
    }
    if (!currentEquipment) {
      queueMessage({
        type: 'message',
        message: 'Current equipment failed to load'
      });
      setIsReplacingEquipment(false)
      return;
    }
    
    await locationSwitcher.moveLocation(foundEquipment.id, foundEquipment.locationId, currentEquipment.locationId, currentEquipment.name)

    queueMessage({
      type: 'message',
      message: 'Equipment replaced'
    });
    setIsReplacingEquipment(false)
  }

  if (!orgId || !myId) {
    return null;
  }

  return (
    <>

      {loadingBarcodeSearch && (
        <FullScreenActivityIndicator />
      )}

    <Dialog
      name={dialogName}
      title={hideOptions.includes('edit') ? "Equipment Options" : "Options"}
      subtitle={subtitle}
    >
      {!hideOptions.includes('preview-details') && (
        <ListButton 
          label="Preview Details"
          onPress={() => {
            userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Preview Details');
            history.push(`/sites/${equipmentItem?.locationId || ''}/equipment-tag/${equipmentItem?.id || ''}/read-only`);
            closeDialog();
          }}
        />
      )}
      {(isSimulator && !hideOptions.includes('preview-details')) && (
        <ListButton 
          label="Simulate Scan"
          onPress={() => {
            closeDialog();
            if (simulateScan) {
              simulateScan(equipmentItem?.barcode || '');
            }
          }}
        />
      )}
      {!hideOptions.includes('edit') && (
        <ListButton 
          label="Edit Equipment"
          to={`/sites/${equipmentItem?.locationId || ''}/equipment/${equipmentItem?.id || ''}`}
          onPress={() => {
            userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Edit Equipment');
            closeDialog();
          }}
        />
      )}
      <ListButton 
        label="Replace Barcode"
        onPress={() => {
          closeDialog();
          setIsReplacingBarcode(true);
          userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Replace Barcode');
        }}
      />
      <ListButton 
        label="Could Not Service"
        onPress={() => {
          closeDialog();
          openDialog({ name: 'could-not-service', state: { equipmentItem } });
          userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Could not service');
        }}
      />
      <ListButton 
        label="Transfer Equipment"
        onPress={() => {
          closeDialog();
          openDialog({ name: 'transfer-equipment', state: { equipmentItem } });
        }}
      />
      <ListButton 
        label="Replace Equipment"
        onPress={() => {
          closeDialog();
          setIsReplacingEquipment(true);
        }}
      />
      {!hideOptions.includes('open-deficiency') && (
        <ListButton 
          label="Open Deficiency"
          to={`/sites/${equipmentItem?.locationId || ''}/equipment/${equipmentItem?.id || ''}/deficiency/new`}
          onPress={() => {
            closeDialog();
            userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Open Deficiency');
          }}
        />
      )}
      {(equipmentItem?.floorPlanLocation && equipmentItem?.floorId) && (
        <ListButton 
          label="View on FloorPlan"
          to={`/sites/${equipmentItem.locationId}/equipment/${equipmentItem.id}/ReadOnlyFloorPlan`}
          onPress={() => {
            closeDialog();
            userTracker.trackEvent(trackingCategory.EQUIPMENT, 'View on Floorplan');
          }}
        />
      )}
      <ListButton 
        label="Delete Equipment"
        onPress={() => {
          closeDialog();
          openDialog({ name: 'confirm-delete', state: { equipmentItem }});
          userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Delete');
        }}
      />
      <ListButton
        label="Back"
        onPress={() => {
          closeDialog();
          userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Cancel Equipment Modal');
        }}
      />
    </Dialog>
    {isReplacingBarcode && (
      <BarCodeScanner
        onCancel={() => {
          setIsReplacingBarcode(false);
        }}
        onScan={({ data }) => {
          if (data === equipmentItem?.barcode) {
            queueMessage({
              type: 'error',
              message: 'Equipment already has this barcode'
            });
            setIsReplacingBarcode(false);
            userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Replace Barcode Failed');
          } else {
            if (equipmentItem) {
              updateEquipment(equipmentItem?.id, { barcode: data }, equipmentItem.locationId);
              queueMessage({
                type: 'message',
                message: 'Updated barcode'
              });
              setIsReplacingBarcode(false);
              userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Replace Barcode Complete');
            }
          }
        }}
      />
    )}

    {isReplacingEquipment && (
      <BarCodeScanner
        onCancel={() => {
          setIsReplacingEquipment(false);
        }}
        onScan={({ data: scannedBarcode }) => {
          doReplace(scannedBarcode);
        }}
      />
    )}

    {isSwappingEquipment && (
      <BarCodeScanner
        onCancel={() => {
          setIsSwappingEquipment(false);
        }}
        onScan={({ data: scannedBarcode }) => {
          doSwap(scannedBarcode);
        }}
      />
    )}

    <Dialog
      name={'confirm-delete'}
      title="Delete equipment?"
    >
      <ListButton
        label="Yes, Delete Equipment"
        onPress={async () => {
          if (dialogState.equipmentItem) {
            await deleteEquipment(dialogState.equipmentItem?.id, dialogState.equipmentItem.locationId)
            queueMessage({ type: 'message', message: 'Equipment Deleted' });
            closeDialog();
            history.push(`/sites/${dialogState.equipmentItem.locationId}`);
          }
        }}
      />
      <ListButton
        label="No, Go Back"
        onPress={() => {
          closeDialog();
        }}
      />
    </Dialog>

    <CouldNotService />
    {equipmentItem && <TransferEquipment />}

    </>
  );
}
