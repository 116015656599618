import { Customer } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
  SyncStatusMessage
} = makeDataHooks<Customer>({
  dataName: "Customers",
});

export function useCustomer(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    customer: item,
  }
}

export function useCustomers() {
  const { data } = useData();

  return {
    customers: data,
  }
}

export function useAddCustomer() {
  const { addItem } = useAddData();

  return {
    addCustomer: addItem,
  }
}

export function useDeleteCustomer() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteCustomer: deleteItem,
  }
}

export function useUpdateCustomer() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateCustomer: updateItem,
  }
}

export const CustomersSyncStatusMessage = SyncStatusMessage;
