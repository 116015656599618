/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { TouchableOpacity, ScrollView } from 'react-native';
import { Translate } from '../Translate';
import * as s from './styles';
import { PrivateImage } from '../PrivateImage';
import { FloorPlan } from '@rivison-inc/ft-types';

interface FloorPlanProps {
  floorPlan: FloorPlan;
  readonly?: boolean;
  onChange?: (value: string) => void;
  value: string; 
}

interface WebPressEvent {
  offsetX: number;
  offsetY: number;
}

interface NativePressEvent {
  locationX: number;
  locationY: number;
}

export function FloorPlanShower(props: FloorPlanProps) {
  const [x, y] = props.value ? props.value.split('x') : [null, null];

  return (
    <s.Container>
      {!props.readonly && (
        <s.Message>
          <Translate text="Click or tap to add location" />
        </s.Message>
      )}
      <s.ImageContainer>

        <ScrollView style={{ width: "100%", height: "100%" }}>
          <ScrollView horizontal style={{ width: "100%", height: "100%" }}>
            {(x && y) && (
              <s.Dot
                x={x}
                y={y}
              />
            )}

            <TouchableOpacity 
              onPress={(evt) => {
                if (props.onChange) {
                  const nativeEvent = evt.nativeEvent as unknown as (WebPressEvent | NativePressEvent);
                  const x = "locationX" in nativeEvent ? nativeEvent.locationX : nativeEvent.offsetX;
                  const y = "locationY" in nativeEvent ? nativeEvent.locationY : nativeEvent.offsetY;
                  props.onChange(`${x}x${y}`)
                }
              }} 
              activeOpacity={1} 
            >
              <PrivateImage 
                fileName={props.floorPlan.image}
                folderName="floor-plans"
              />
            </TouchableOpacity>
          </ScrollView>
        </ScrollView>
      </s.ImageContainer>
    </s.Container>
  )
}
