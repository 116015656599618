import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useDeleteLocationOverride, useLocationOverride, useUpdateLocationOverride } from '../../data/locationOverrides';
import { useParams, useHistory } from '../../hooks';
import { ServiceTypesLocationSettingsForm } from '../../forms/ServiceTypesLocationSettingsForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { ListButton } from '../../components/ListButton';
import { useServiceType } from '../../data/serviceTypes';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';

export function LocationOverrideEditPage() {
  const canRead = useHasPermission(Permissions.LOCATION_READ);
  
  const history = useHistory();
  const params = useParams<{ locationId: string; overrideId: string }>();
  
  const { locationOverride } = useLocationOverride({ id: params.overrideId, locationId: params.locationId });
  const { updateLocationOverride } = useUpdateLocationOverride();
  const { deleteLocationOverride } = useDeleteLocationOverride();
  const { serviceType } = useServiceType(locationOverride?.serviceTypeId)

  if (!canRead) {
    return <></>;
  }
  
  if (!locationOverride) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={{
        ...locationOverride,
        equipmentTypeId: serviceType?.equipmentTypeId
      }}
      onSubmit={async (updates) => {
        await updateLocationOverride(
          locationOverride.id,
          updates,
          params.locationId
        );
        history.push(`/sites/${params.locationId}/settings/location-overrides`);
      }}
    >
      <Panes backgroundColor="light">
        <Pane 
          title="Site Service Override"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" />}
        >
          {locationOverride && (
            <ServiceTypesLocationSettingsForm />
          )}

          <ListSectionTitle>Care</ListSectionTitle>
          <ListButton 
            label="Delete Override" 
            onPress={async () => {
              await deleteLocationOverride(
                params.overrideId,
                params.locationId
              );
              history.push(`/sites/${params.locationId}/settings/location-overrides`);
            }} 
          />

        </Pane>
      </Panes>
    </Form>
  )
}
  
