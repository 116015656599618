import * as React from 'react';
import { Form2 } from '../../components/Form2';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { PaneButton } from '../../components/PaneButton';
import { Pane, Panes } from '../../components/Panes';
import { SubmitButton2 } from '../../components/Form2';
import { useParams } from '../../hooks';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { BackButton } from '../../components/BackButton';
import { useCustomer, useDeleteCustomer, useUpdateCustomer } from '../../data/customers';
import { CustomerForm } from '../../forms/CustomerForm';

export function EditCustomerPage() {
  const params = useParams<{ customerId: string }>();
  const { customer } = useCustomer(params.customerId);
  const { updateCustomer } = useUpdateCustomer();
  const { deleteCustomer } = useDeleteCustomer();
  const canWrite = useHasPermission(Permissions.LOCATION_WRITE);

  if (!customer) {
    return null; // TODO: show loading state?
  }

  return (
    <>
      <Form2
        defaultValues={customer}
        onSubmit={(values) => {
          updateCustomer(params.customerId, values)
        }}
      >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Customer Information"
          rightButton={<SubmitButton2 as={PaneButton} label={'Save'} showFeedback disabled={!canWrite} />}
        >

          <ListSectionTitle>General</ListSectionTitle>

          {location && (
            <CustomerForm />
          )}

          <ListSectionTitle>Care</ListSectionTitle>
          <DeleteButton 
            name="customer"
            deleteItemNew={deleteCustomer}
            itemId={params.customerId}
            label="Delete Customer"
            yesMessage="Yes, Delete Customer"
            question="Delete Customer?"
            successMessage="Customer Deleted"
          />
        </Pane>
      </Panes>
      </Form2>
    </>
  )
}
  
