import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useHistoryRecord, useUpdateHistoryRecord, useDeleteHistoryRecord } from '../../data/history';
import { useParams, useHistory } from '../../hooks';
import { HistoryForm } from '../../forms/HistoryForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { BackButton } from '../../components/BackButton';

export function EditHistoryPage() {
  const history = useHistory();
  const params = useParams<{ historyId: string; locationId: string; equipmentId: string }>();
  const { historyRecord } = useHistoryRecord({ id: params.historyId, locationId: params.locationId });
  const { updateHistoryRecord } = useUpdateHistoryRecord();
  const { deleteHistoryRecord } = useDeleteHistoryRecord();
  const canWrite = useHasPermission(Permissions.HISTORY_WRITE);

  if (!historyRecord) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={historyRecord}
      onSubmit={async (updates) => {
        await updateHistoryRecord(
          historyRecord.id,
          updates,
          params.locationId
        );
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="History"
          rightButton={<SubmitButton as={PaneButton} label="Save" showFeedback disabled={!canWrite} />}
        >
          {historyRecord && (
            <HistoryForm id={params.historyId} locationId={params.locationId} />
          )}

          <ListSectionTitle>Care</ListSectionTitle>

          <DeleteButton
            name="history"
            deleteItemNew={(itemId: string) => {
              deleteHistoryRecord(
                itemId,
                params.locationId
              )
            }}
            itemId={historyRecord.id}
            question="Delete Record?"
            yesMessage="Yes, Delete Record"
            successMessage="Deleted Record"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
