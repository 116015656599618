import moment from 'moment';
import styled from 'styled-components/native';
import { theme, Theme } from '../../theme';

//todo: make these customizable 
const workDayStartHour = 8;
const workDayEndHour = 18;

export const Container = styled.View`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

interface DayContainerProps {
  selected?: boolean;
  aboveSelected?: boolean;
  isWeekday?: boolean;
}

const getBackgroundColor = (isWeekday?: boolean) => {
  if (isWeekday) return 'transparent';
  return 'rgba(0,0,0,0.05)'; 
}

const getBackgroundColorSelected = (selected?: boolean) => {
  if (selected) return theme.palette.primary;
  return 'transparent'; 
}

export const DayContainer = styled.View<DayContainerProps>`
  width: 100%;
  border-bottom-color: ${(props: DayContainerProps & { theme: Theme }) => props.selected || props.aboveSelected ? props.theme.palette.primary : props.theme.palette.lineColor};
  border-bottom-width: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => getBackgroundColor(props.isWeekday)};
`;

export const SelectedBar = styled.View<DayContainerProps>`
  width: 10px;
  height: 100%;
  min-height: 50px;
  display: flex;
  background-color: ${(props) => getBackgroundColorSelected(props.selected)};
`;

export const DayTitleContainer = styled.View`
  width: 120px;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: ${(props: { theme: Theme }) => props.theme.spacing.md};
  padding-right: ${(props: { theme: Theme }) => props.theme.spacing.sm};

`;

export const DayNumber = styled.Text`
  font-size: 35px;
  padding: ${(props: { theme: Theme }) => props.theme.spacing.xs};
`;

export const MonthName = styled.Text`
  font-size: 12px;
  color: grey;
  white-space: nowrap;
`;

export const ScheduledItemsContainer = styled.View`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100px;
  align-items: center;
`;

export const UserContainer = styled.View`
  width: 100%;
  display: flex;
  padding-top: ${(props: { theme: Theme }) => props.theme.spacing.xs};
  padding-bottom: ${(props: { theme: Theme }) => props.theme.spacing.xs};
`;

export const UserName = styled.Text`
  font-size: 12px;
  color: grey;
  white-space: nowrap;
`;

export const UserInfo = styled.View`
  width: 100px;
  display: flex;
  align-items:center;
  justify-content: center;
`;

export const EventContainer = styled.View`
  min-height: 78px;
  min-width: 50px;
  flex: 1;
  align-self: stretch;
  justify-self: stretch;
  margin-right: 10px;
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 1px;
`;

function getStartPercent(startTime: string, workDayStartHour: number, workDayEndHour: number)
{
  const startTimeMoment = moment(startTime);

  const workDayStartWithBuffer = moment(startTime).set({ hour: workDayStartHour, minute: 0 });
  const workDayEndWithBuffer = moment(startTime).set({ hour: workDayEndHour, minute: 0 });

  const totalMinutesInWorkDay = workDayEndWithBuffer.diff(workDayStartWithBuffer, 'minutes');
  const totalMinutesFromStartOfWorkDay = startTimeMoment.diff(workDayStartWithBuffer, 'minutes');

  const percentageIntoDay = (totalMinutesFromStartOfWorkDay / totalMinutesInWorkDay) * 100;

  return Math.max(0, percentageIntoDay);
}

function getEndPercent(endTime: string, workDayStartHour: number, workDayEndHour: number)
{
  const endTimeMoment = moment(endTime);

  const workDayStartWithBuffer = moment(endTime).set({ hour: workDayStartHour, minute: 0 });
  const workDayEndWithBuffer = moment(endTime).set({ hour: workDayEndHour, minute: 0 });

  const totalMinutesInWorkDay = workDayEndWithBuffer.diff(workDayStartWithBuffer, 'minutes');
  const totalMinutesFromEndOfWorkDay = workDayEndWithBuffer.diff(endTimeMoment, 'minutes');

  const percentageIntoDay = ((totalMinutesFromEndOfWorkDay / totalMinutesInWorkDay) * 100);

  return Math.max(percentageIntoDay, 0);
}

interface EventBarProps {
  startTime: string;
  endTime: string;
  color: string;
  workDayStartHour: number;
  workDayEndHour: number;
}

//#4871FE
export const EventBar = styled.View<EventBarProps>`
  height: 78px;
  background-color: ${props => props.color}; 
  border: 1px solid rgba(0,0,0,0.0);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  position: absolute;
  top: 0px;
  left: ${(props) => getStartPercent(props.startTime, props.workDayStartHour, props.workDayEndHour)}%;
  right: ${(props) => getEndPercent(props.endTime, props.workDayStartHour, props.workDayEndHour)}%;
`;

export const EventBarTitle = styled.Text`
  color: white;
  font-size: 14px;
  padding-bottom: ${(props: { theme: Theme }) => props.theme.spacing.xs};
`;

export const EventBarSubTitle = styled.Text`
  color: rgba(255,255,255,0.6);
  font-size: 10px;
`;

export const EventBarStartTime = styled.Text`
  color: rgba(255,255,255,0.6);
  font-size: 12px;
`;
