import { ReportDef } from '../types';
import moment from 'moment';

export const deficiencyReport: ReportDef = {
  reportName: 'Deficiency Report',
  description: 'List of equipment and deficiencies at the site, filtered by equipment with deficiencies that have a date opened and no closed date.',
  dialog: {
    title: 'Deficiency Report',
    message: 'Fill out the options below to prepare your report.',
    form: () => [
      {
        type: 'date',
        label: 'Start Date',
        id: 'startDate',
        default: moment().startOf('month').toISOString(),
      },
      {
        type: 'date',
        label: 'End Date',
        id: 'endDate',
        default: moment().endOf('month').toISOString(),
      },
    ],
  },
  type: 'location',
  layout: 'landscape',
  endpoint: '/reports/deficiencyReport',
};
