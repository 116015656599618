import { FrequencyType, ADVANCED_ACTION, Condition, FeatureList } from '@rivison-inc/ft-types';
import * as React from 'react';
import { Field, FormContext, useFormValues } from '../../components/Form';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { ListSwitch } from '../../components/ListSwitch';
import { ListTextField } from '../../components/ListTextField';
import { useControlDialog, useParams } from '../../hooks';
import { useServiceTypes } from '../../data/serviceTypes';
import { useSiteTypes } from '../../data/siteTypes';
import { Accordion } from '../../components/Accordion';
import { Bubble } from '../../components/Bubble';
import { ListButton } from '../../components/ListButton';
import { Box } from '../../components/Box';
import { Text } from '../../components/Text';
import { Dialog } from '../../containers/Dialog';
import { useEquipmentFields } from '../../data/equipmentFields';
import { Message } from '../../components/Message';
import { useMemo } from 'react';
import { useOrgId } from '../../auth';
import { useIsFeatureEnabled, useOrganization } from '../../data/organization';
import { shortUUID } from '../../utils/misc';

export const ServiceTypesForm = () => {
  const params = useParams<{ equipmentTypeId: string }>();
  const formValues = useFormValues();
  const { siteTypes } = useSiteTypes();
  const formRef = React.useContext(FormContext);
  const { openDialog, closeDialog } = useControlDialog();
  const orgId = useOrgId();

  const { serviceTypes } = useServiceTypes();
  const filteredServiceTypes = serviceTypes.filter((serviceType) => params.equipmentTypeId === serviceType.equipmentTypeId);
  const otherServiceTypes = filteredServiceTypes.filter((serviceType) => serviceType.id && formValues.id !== serviceType.id && !serviceType.unscheduled);

  const changeDateOfOptions = otherServiceTypes.map((serviceType) => ({ 
    value: serviceType.id, 
    label: serviceType.name, 
  }));
  
  const serviceToChangeId = formValues?.advancedServiceToChangeTypeId;
  const serviceToChange = filteredServiceTypes.find((serviceType) => serviceToChangeId === serviceType.id);
  const advancedAction = formValues?.advancedAction;
  const { equipmentFields } = useEquipmentFields({ equipmentTypeId: params.equipmentTypeId });

  const allConditions: Condition[] = (formValues.conditions || []) as Condition[];
  const conditions = allConditions.filter(condition => !condition.deleted);
  const hasConditions = conditions.length === 0;
  const unscheduled = formValues.unscheduled;
  const timeCondition = conditions.find(condition => condition.type === 'time');
  const hasATimeCondition = Boolean(timeCondition);

  const { organization } = useOrganization();
  const billingEnabled = useIsFeatureEnabled(FeatureList.BILLING) && organization.showBilling;
  
  /**
   * These effects are used to simulate time as a condition. 
   * This was done because time was hard coded on the service type. 
   * Ideally change time to be a real condition in the future.
   */ 

  // Change hard coded time into a condition on load
  useMemo(() => {
    const newConditions: Condition[] = [...conditions];
    if (formValues.frequency && !hasATimeCondition) {
      newConditions.push({
        type: 'time',
        timeFrequency: formValues.frequency as any,
        timeFrequencyType: formValues.frequencyType as any,
        id: shortUUID(),
      } as any);
      formRef.current.setValue('conditions', newConditions, true);
      formRef.current.triggerFormValuesChanged();
    }
  }, []);

  useMemo(() => {
    if (timeCondition) {
      formRef.current.setValue('frequency', timeCondition.timeFrequency, true);
      formRef.current.setValue('frequencyType', timeCondition.timeFrequencyType, true);
      formRef.current.triggerFormValuesChanged();
    }
  }, [timeCondition?.timeFrequency, timeCondition?.timeFrequencyType]);
  
  // Update unscheduled when number of contitions change
  useMemo(() => {
    formRef.current.setValue('unscheduled', !!hasConditions, true);
  }, [hasConditions]);

  const addCondition = (type: 'metered' | 'time') => {
    const newCondition: Condition = { 
      id: shortUUID(),
      lastUpdated: '',
      type, 
      serviceTypeId: '',
      orgId: orgId!,
    }
    const newConditions = [...conditions, newCondition];
    formRef.current.setValue('conditions', newConditions, true);
    formRef.current.triggerFormValuesChanged();
  }

  const removeCondition = (idx: number) => {
    const conditionToRemove = conditions[idx];
    if (conditionToRemove.type === 'time') {
      formRef.current.setValue('frequency', null, true);
      formRef.current.setValue('frequencyType', null, true);
    }
    conditions[idx].deleted = true;
    const newConditions = [...conditions];
    formRef.current.setValue('conditions', newConditions, true);
    formRef.current.triggerFormValuesChanged();
  }
  
  const frequencyTypeMap = {
    [FrequencyType.MONTH]: 'Month',
    [FrequencyType.YEAR]: 'Year',
    [FrequencyType.WEEK]: 'Week',
    [FrequencyType.DAY]: 'Day',
    [FrequencyType.HOUR]: 'Hour',
    [FrequencyType.MINUTE]: 'Minute',
  }

  return (
    <>
      
      <Field 
        as={ListTextField} 
        name="name" 
        label="Name" 
        type="text"
        required
      />

      {(hasATimeCondition) && (
        <Field 
          as={ListSwitch} 
          name="inspection" 
          label="Inspection" 
          tooltip={{
            title: 'Inspection',
            text: 'A service type with inspection enabled will show up in the "to inspect" list on mobile. It is also used in reporting.',
          }}
        />
      )}

      <Field 
        as={ListSwitch} 
        name="updateOnScan" 
        label="Perform On Scan"
      />

      <Field 
        as={ListDropdownField} 
        name={`dueWhen`}
        label="Due When" 
        options={[
          { value: 'any', label: 'Any condition is true' },
          { value: 'all', label: 'All conditions are true' },
        ]}
        required
      />

      <Box paddingBottom="md" backgroundColor="grey">

        {!conditions.length && (
          <Box paddingTop="sm">
            <Text bold size="lg" alignX="center">No Conditions</Text>
            <Text size="sm" color="faded" alignX="center">Service is Unscheduled</Text>
          </Box>
        )}

        {conditions.map((condition, idx) => {
          if (condition.deleted) {
            return <></>;
          }
          if (condition.type === 'metered') {
            return (
              <Bubble key={idx} title="Metered Condition" onClose={() => removeCondition(idx)}>
                <>

                  {equipmentFields.length === 0 && (
                    <Box marginTop="sm">
                      <Message type="error" message="No Custom Equipment Fields" />
                    </Box>
                  )}
                
                  {equipmentFields.length > 0 && (
                    <>

                      <Field 
                        as={ListDropdownField} 
                        name={`conditions[${idx}].meteredFieldId`}
                        label="Equipment Field" 
                        options={equipmentFields.map(equipmentField => ({ value: equipmentField.id, label: equipmentField.name }))}
                        required
                      />

                      <Field 
                        as={ListDropdownField} 
                        name={`conditions[${idx}].meteredMethod`}
                        label="When" 
                        options={[
                          { value: 'less than', label: 'Less than', },
                          { value: 'equal to', label: 'Equal to', },
                          { value: 'not equal to', label: 'Not equal to', },
                          { value: 'greater than', label: 'Greater than', },
                          { value: 'every', label: 'Every', },
                        ]}
                        required
                      />

                      <Field 
                        as={ListTextField} 
                        name={`conditions[${idx}].meteredAmount`}
                        label="Amount" 
                        type="number"
                        required
                      />

                    </>
                )}

                </>
            </Bubble>
            )
          }

          return (
            <Bubble key={idx} title="Time Condition" onClose={() => removeCondition(idx)}>
              <>

                <Field 
                  as={ListDropdownField} 
                  name={`conditions[${idx}].timeFrequencyType`}
                  label="Frequency Type" 
                  options={[
                    { value: `${FrequencyType.MONTH}`, label: 'Month', },
                    { value: `${FrequencyType.YEAR}`, label: 'Year', },
                    { value: `${FrequencyType.WEEK}`, label: 'Week', },
                    { value: `${FrequencyType.DAY}`, label: 'Day', },
                    { value: `${FrequencyType.HOUR}`, label: 'Hour', },
                    { value: `${FrequencyType.MINUTE}`, label: 'Minute', },
                  ]}
                  required
                />

                <Field 
                  as={ListTextField} 
                  name={`conditions[${idx}].timeFrequency`}
                  label="Frequency" 
                  type="number"
                  required
                />

              </>
          </Bubble>
          )
        })}

      </Box>
      
      {/* Remove this length condition when we have more condition types */}
      {/* {conditions.length === 0 && ( */}
        <ListButton label="Add Condition" onPress={() => {
          // addCondition('time');
          openDialog('add-condition')
        }} />
      {/* )} */}
      
      <Accordion closedTitle="Show Advanced" openedTitle="Hide Advanced">
      
        {(!unscheduled) && (
          <>
            <ListSectionTitle>Change Another Service</ListSectionTitle>
            <Field 
              as={ListDropdownField} 
              name="advancedServiceToChangeTypeId" 
              label="Change" 
              options={[
                { value: '', label: 'Do not change', },
                ...changeDateOfOptions
              ]}
            />
            {serviceToChange && <>
              <Field 
                as={ListDropdownField} 
                name="advancedAction" 
                label="Action" 
                options={[
                  { value: ADVANCED_ACTION.AVOID_SAME_DATE, label: `Avoid Same ${frequencyTypeMap[serviceToChange.frequencyType]}`, },
                  { value: ADVANCED_ACTION.KEEP_X_TIME_AWAY, label: `Keep X ${frequencyTypeMap[serviceToChange.frequencyType]}s Away`, },
                ]}
              />
            </>}
            {(serviceToChange && advancedAction === ADVANCED_ACTION.KEEP_X_TIME_AWAY) && <>
              <Field 
                as={ListTextField} 
                name="advancedAmount" 
                label={frequencyTypeMap[serviceToChange.frequencyType] ? frequencyTypeMap[serviceToChange.frequencyType] + 's' : 'Amount'} 
                required
                type="number"
              />
            </>}
          </>
        )}

        <ListSectionTitle>Disabled For</ListSectionTitle>
        
        <Field 
          as={ListSwitch} 
          name={`disabledSiteTypes.noType`}
          label={'Sites with no type'} 
        />

        {siteTypes.map(siteType => (
          <Field 
            key={siteType.id}
            as={ListSwitch} 
            name={`disabledSiteTypes.${siteType.id}`}
            label={`Sites with type ${siteType.name}`} 
          />
        ))}

        {billingEnabled && (
          <>
            <ListSectionTitle>Billing</ListSectionTitle>
                  
            <Field 
              as={ListTextField} 
              name="SKU" 
              label="SKU" 
            />
          </>
        )}

      </Accordion>

      <Dialog
        name={'add-condition'}
        title="Add Condition"
      >
        {!hasATimeCondition && (
          <ListButton
            label="Time Based Condition"
            onPress={() => {
              addCondition('time');
              closeDialog();
            }}
          />
        )}
        <ListButton
          label="Meter Based Condition"
          onPress={() => {
            addCondition('metered');
            closeDialog();
          }}
        />
        <ListButton
          label="Cancel"
          onPress={() => {
            closeDialog();
          }}
        />
      </Dialog>

    </>
  )
}
