import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useParams } from '../../hooks';
import { useAddModel } from '../../data/models';
import { useHistory } from '../../hooks';
import { ModelForm } from '../../forms/ModelForm';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { useOrgId } from '../../auth';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddModelPage() {
  const history = useHistory();
  const orgId = useOrgId();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);
  const params = useParams<{ equipmentTypeId: string }>();
  const { addModel } = useAddModel();

  if (!canWrite) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        name: '',
        orgId: orgId || '',
        equipmentTypeId: params.equipmentTypeId
      }}
      onSubmit={async (model) => {
        await addModel(model)
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Add Model"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <ModelForm hideEquipmentType />
        </Pane>
      </Panes>
    </Form>
  )
}
  
