import * as React from 'react';
import { EmptyState } from '../../components/EmptyState';
import { IconButton } from '../../components/IconButton';
import { ListItem } from '../../components/ListItem';
import { Pane, Panes } from '../../components/Panes';
import { AddIcon } from '../../icons/Add';
import { useModelFields } from '../../data/modelFields';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';

function AddModelFieldsButton() {  
  return (
    <IconButton to="/custom-fields/model/new" label="Add Model Field">
      <AddIcon />
    </IconButton>
  )
}

export function ModelFieldsListPage() {
  const canRead = useHasPermission(Permissions.SETTINGS_READ);
  const { modelFields } = useModelFields();

  if (!canRead) {
    return <></>;
  }
  
  return (
    <Panes backgroundColor="light">
      <Pane title="Model Fields" rightButton={<AddModelFieldsButton />}>
        {modelFields.map((modelField, idx) => (
          <ListItem
            key={idx} 
            to={`/custom-fields/model/${modelField.id}`}
            title={modelField.name}
          />
        ))}
        {!modelFields.length && <EmptyState title={'No Model Fields'} subtitle={'Model fields allow you to describe and report on equipment such as "Size", "Type" and "Category".'} />}
      </Pane>
    </Panes>
  )
}
