import { SiteType } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
} = makeDataHooks<SiteType>({
  dataName: "SiteTypes",
});

export function useSiteType(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    siteType: item,
  }
}

export function useSiteTypes() {
  const { data } = useData();

  return {
    siteTypes: data || [],
  }
}

export function useAddSiteType() {
  const { addItem, } = useAddData();

  return {
    addSiteType: addItem,
  }
}

export function useDeleteSiteType() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteSiteType: deleteItem,
  }
}

export function useUpdateSiteType() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateSiteType: updateItem,
  }
}
