import { Location } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
  SyncStatusMessage
} = makeDataHooks<Location>({
  dataName: "Locations",
});

export function useLocation(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    location: item,
  }
}

export function useLocations() {
  const { data } = useData();

  return {
    locations: data,
  }
}

export function useAddLocation() {
  const { addItem } = useAddData();

  return {
    addLocation: addItem,
  }
}

export function useDeleteLocation() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteLocation: deleteItem,
  }
}

export function useUpdateLocation() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateLocation: updateItem,
  }
}

export const LocationsSyncStatusMessage = SyncStatusMessage;
