import { createContext, useContext, useCallback } from 'react';

export const SnackBarContext = createContext<{ messages: Array<{ message: string, type: "error" | "message" | "warning" }>, forceRender: () => void }>({ 
  messages: [],
  forceRender: () => null
});

export function useQueueMessage() {
  const snackBarInfo = useContext(SnackBarContext);

  return useCallback((message: { message: string, type: "error" | "message" | "warning" }) => {
    snackBarInfo.messages = [
      ...snackBarInfo.messages,
      { ...message }
    ];

    snackBarInfo.forceRender();
  }, [snackBarInfo]);
}
