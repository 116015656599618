import * as React from 'react';
import { Field, useFormValues, useSetValue } from '../../components/Form';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { ListTextField } from '../../components/ListTextField';
import { useLocationFields } from '../../data/locationFields';
import { CountryDropdownField } from '../../components/CountryDropdownField';
import { StateDropdownField } from '../../components/StateDropdownField';
import { useSiteTypes } from '../../data/siteTypes';
import { Tooltip } from '../../components/Tooltip';
import moment from 'moment';
import 'moment-timezone';
import csc from 'country-state-city'
import { useIsFeatureEnabled, useOrganization } from '../../data/organization';
import { FeatureList } from '@rivison-inc/ft-types';
import { useOrgId } from '../../auth';
import { useMutation } from 'react-query';
import { api } from '../../api';
import { OfflineError } from '../../errors/OfflineError';

export const LocationForm = (props: { autoGenerateSiteNumber?: boolean }) => {
  const zones = Array.from(Array(30).keys());
  const zoneOptions = zones.map((num) => ({ label: `Zone ${num + 1}`, value: `${num + 1}`, }));
  const { locationFields } = useLocationFields();
  const formValues = useFormValues();
  const { siteTypes } = useSiteTypes();
  const countries = csc.getAllCountries();
  const selectedCountry = countries.find(country => country.name === formValues.country as string);
  const setValue = useSetValue();

  // Default to no timeZones.  Otherwise it's confusing why only some timeZones are showing up
  const timeZones = (selectedCountry && moment.tz.zonesForCountry(selectedCountry.sortname)) || [];
  const {organization} = useOrganization();
  const billingIsEnabled = useIsFeatureEnabled(FeatureList.BILLING);

  const showBilling = organization?.showBilling && billingIsEnabled;

  const {
    siteNumberStatus,
    siteNumberError
  } = useAutoGenerateSiteNumber({
    enabled: props.autoGenerateSiteNumber
  });

  return (
    <>
      <Field
        as={ListTextField}
        name="name"
        label={'Name'}
        required
      />
      {Boolean(showBilling) && (
        <Field
          as={ListTextField}
          name="customerNumber"
          label={'Site #'}
          loading={siteNumberStatus === 'loading'}
          disabled={siteNumberStatus === 'loading'}
          errorText={getErrorMessageFromSiteNumberError(siteNumberError)}
        />
      )}
      
      <ListSectionTitle>Contact</ListSectionTitle>
      <Field
        as={ListTextField}
        name="contact"
        label={'Contact'}
      />
      <Field
        as={ListTextField}
        name="phone"
        label={'Phone'}
      />
      <Field
        as={ListTextField}
        name="cell"
        label={'Cell'}
      />
      <Field
        as={ListTextField}
        name="email"
        label={'Email'}
      />
      <Field
        as={ListTextField}
        name="fax"
        label={'Fax'}
      />

      <ListSectionTitle>Address</ListSectionTitle>
      
      <Field 
        as={CountryDropdownField} 
        hasEmptyOption
        name="country"
        // This has to be required because timeZone is required and timeZone only shows up depending on country
        required
        onChange={() => {
          setValue('timeZone', null);
        }}
      />
      <Field
        as={StateDropdownField} 
        hasEmptyOption
        country={formValues.country as string}
        name="province"
      />
      <Field
        as={ListTextField}
        name="city"
        label={'City'}
      />
      <Field
        as={ListTextField}
        name="address"
        label={'Address'}
      />
      <Field
        as={ListTextField}
        name="addressTwo"
        label={'Address Two'}
      />
      <Field
        as={ListTextField}
        name="postalCode"
        label={'Postal Code'}
      />
      <Field 
        as={ListDropdownField} 
        name="zone" 
        label="Zone" 
        options={zoneOptions}
        hasEmptyOption
        tooltip={{
          title: 'Zone',
          text: 'Zones are used to group locations in the location report',
        }}
      />
      <Field 
        as={ListDropdownField} 
        name="timeZone"
        label="Time Zone"
        hasEmptyOption
        required
        options={timeZones.map(timeZone => ({ value: timeZone, label: timeZone}))}
        noOptionsHintText="Please select country first"
      />

      <ListSectionTitle>Details</ListSectionTitle>
      <Field
        as={ListTextField}
        name="note"
        label={'Note'}
        numberOfLines={4}
      />
      <Field
        as={ListTextField}
        name="popupNote"
        label={'Popup Note'}
        tooltip={{ title: 'Popup Note', text: 'The popup note is used to remind you of important information regarding the location. It will appear when opening the location screen.' }}
      />
      <Field 
        as={ListDropdownField} 
        name="status" 
        label="Status" 
        options={[
          { label: 'Active', value: 'active', },
          { label: 'Inactive', value: 'inactive', },
          { label: 'Bad', value: 'bad', },
        ]}
      />
      <Field 
        as={ListDropdownField} 
        name="typeId" 
        label="Type" 
        hasEmptyOption
        options={siteTypes.map(siteType => ({ label: siteType.name, value: siteType.id }))}
      />

      {Boolean(locationFields?.length) && (
        <>
          <ListSectionTitle>
            Custom Fields
            <Tooltip 
              title="Custom Fields"
              text="Add your own fields in Settings > Custom Fields > Site Fields"
            />
          </ListSectionTitle>
          {locationFields.map((locationField, idx) => (
            <Field 
              key={idx}
              as={ListTextField} 
              name={`fieldData.${locationField.id}`} 
              label={locationField.name} 
              type="text"
            />
          ))}
        </>
      )}
    </>
  )
}

function useAutoGenerateSiteNumber({
  enabled
}: {
  enabled?: boolean
}) {
  const orgId = useOrgId();
  const setFormValue = useSetValue();

  const { 
    mutate: generateSiteNumber, 
    status: siteNumberStatus,
    error: siteNumberError
  } = useMutation(async (orgId: string) => {
    const response = await api.post(`/organizations/${orgId}/counters/site-number`);

    const data = response.data as { data: string };
    return data.data;
  });

  React.useEffect(() => {
    if (siteNumberStatus === 'idle' && orgId && enabled) {
      generateSiteNumber(orgId, {
        onSuccess: (customerNumber) => {
          setFormValue('customerNumber', customerNumber);
        }
      });
    }
  }, [orgId, generateSiteNumber, siteNumberStatus, setFormValue, enabled]);

  return {
    siteNumberStatus,
    siteNumberError
  }
}

function getErrorMessageFromSiteNumberError(err: unknown): string | undefined {
  if (err instanceof OfflineError) {
    return 'Could not generate unique site number.  Device must be connected to the internet.  A site number can still be entered manually.'
  }

  if (err instanceof Error) {
    return 'Could not generate unique site number.  Please try again later or enter a site number manually.'
  }

  return undefined;
}
