import moment from 'moment';
import { Storage } from '../storage';
import { createHashMap } from '../utils/array';
import { Locks } from '../utils/locks';

interface RequiredFields {
  id: string; 
  lastUpdated: string;
  hasBeenChangedLocally?: boolean;
}

export class LocalData<T extends RequiredFields> {

  private type: string;

  constructor(type: string) {
    this.type = type;
  }

  private getLocalStorageName(locationId?: string) {
    return `data-v2-${this.type}-${locationId || 'global'}`;
  }

  public async get(filter?: { field: string; value: any }, locationId?: string): Promise<T[]> {
    if (filter?.field && !filter.value) {
      return [];
    }
    const localStorageName = this.getLocalStorageName(locationId);
    let storedData = await Storage.getItem(localStorageName) || [];

    if (filter?.field === 'id') {
      const record = storedData.find((row: any) => row.id === filter.value);
      return [record];
    }
    
    if (filter?.field) {
      storedData = storedData.filter((row: any) => {
        return row[filter.field] === filter.value;
      });
    }
    
    return storedData || [];
  }

  private async updateRecentlyUpdatedKey(type: string, locationId?: string) {
    await Locks.getLockBeforeRunning('previously-updated-keys', async () => {
      const key = this.getLocalStorageName(locationId);
      const dateUpdated = moment().toISOString();
      const recentlyUpdatedKeyData: { key: string, dateUpdated: string }[] = await Storage.getItem(`previously-updated-keys-${type}`) || [];
      const recentlyUpdatedKeyMinusNewKey = recentlyUpdatedKeyData.filter(keyData => keyData.key !== key)
      recentlyUpdatedKeyMinusNewKey.push({ key, dateUpdated })
      await Storage.setItem(`previously-updated-keys-${type}`, recentlyUpdatedKeyMinusNewKey)
    })
  }

  public async upsert(data: T[], changedLocally: boolean, locationId?: string): Promise<void> {
    const localStorageName = this.getLocalStorageName(locationId);
    const allRecords = await this.get(undefined, locationId);
    const hashMap = createHashMap(allRecords, 'id');
    data.forEach((row) => {
      const existingRecord = hashMap[row.id]
      if (!existingRecord || moment(row.lastUpdated).isAfter(existingRecord.lastUpdated)) {
        hashMap[row.id] = row;
        if (changedLocally) {
          hashMap[row.id].hasBeenChangedLocally = true;
        }
      }
    });
    const values = Object.values(hashMap);
    await Storage.setItem(localStorageName, values);
    if (changedLocally) {
      await this.updateRecentlyUpdatedKey(this.type, locationId);
    }
  }

  public async delete(ids: string[], locationId?: string): Promise<void> {
    const localStorageName = this.getLocalStorageName(locationId);
    const allRecords = await this.get(undefined, locationId);
    const hashMap = createHashMap(allRecords, 'id');
    for (const id of ids) {
      delete hashMap[id];
    }
    const values = Object.values(hashMap);
    await Storage.setItem(localStorageName, values);
  }

}
