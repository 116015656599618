import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useUpdateDeficiency, useDeleteDeficiency, useDeficiency } from '../../data/deficiencies';
import { useParams, useHistory } from '../../hooks';
import { DeficiencyForm } from '../../forms/DeficiencyForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { ListButton } from '../../components/ListButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';
import { useLocation } from '../../data/locations';

export function EditDeficiencyPage() {
  const history = useHistory();
  const params = useParams<{ locationId: string; equipmentId: string; deficiencyId: string }>();
  const { deficiency } = useDeficiency({ id: params.deficiencyId, locationId: params.locationId });
  const canWrite = useHasPermission(Permissions.DEFICIENCY_WRITE);
  const { updateDeficiency } = useUpdateDeficiency();
  const { deleteDeficiency } = useDeleteDeficiency();
  const { location } = useLocation(params.locationId);

  if (!deficiency) {
    return null; // TODO: return loading state?
  }

  if (!location) {
    return null;
  }

  return (
    <Form 
      initialValues={deficiency}
      onSubmit={async (updates) => {
        await updateDeficiency(
          deficiency.id, 
          updates,
          params.locationId
        );
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Deficiency"
          rightButton={<SubmitButton as={PaneButton} label="Save" showFeedback disabled={!canWrite} />}
        >
          {deficiency && (
            <DeficiencyForm timeZone={location.timeZone} />
          )}

          <ListButton
            label={'Delete'}
            onPress={async () => {
              await deleteDeficiency(
                deficiency.id,
                params.locationId
              );
              history.goBack();
            }}
          />

        </Pane>
      </Panes>
    </Form>
  )
}
  
