import * as React from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import { createPortal } from '../Portal';

/**
 * Loosely based on https://material-ui.com/components/popover/
 */
export interface PopoverProps {
  anchorRef?: React.RefObject<View>;
  children?: React.ReactNode;
  open?: boolean;
}

interface BoundingBox {
  x: number;
  y: number;
  height: number;
  width: number;
}

function PopoverBase(props: PopoverProps) {
  const [boundingBox, setBoundingBox] = React.useState<BoundingBox|null>(null);

  React.useEffect(() => {
    props.anchorRef?.current?.measure((x, y, width, height, pageX, pageY) => {
      setBoundingBox({
        width: width,
        height: height,
        x: pageX,
        y: pageY
      });
    })
  }, [props.anchorRef?.current, props.open]);

  if (boundingBox === null) {
    return null;
  }

  if (!props.open) {
    return null;
  }
  
  return (
    <PopoverView boundingBox={boundingBox}>
      {props.children}
    </PopoverView>
  )
}

const PopoverView = styled.View<{ boundingBox: BoundingBox }>`
  position: absolute;
  left: ${(props) => props.boundingBox.x};
  top: ${(props) => props.boundingBox.y + props.boundingBox.height};
  width: ${(props) => props.boundingBox.width}px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid rgb(206, 206, 206);
  z-index: ${(props) => props.theme.zIndex.dialog + 1};
`;

const portalSystem = createPortal<PopoverProps>({
  defaultProps: {},
  component: PopoverBase,
})

export const PopoverProvider = portalSystem.PortalProvider;
export const Popover = portalSystem.PortalTrigger;
