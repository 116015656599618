import { ReportDef } from '../types';
import moment from 'moment';

export const inspectionReport: ReportDef = {
  reportName: 'Inspection Report',
  description: 'List of Equipment for a site with important information such as last inspection date and next due service, highlighting equipment due for inspection in red.',
  type: 'location',
  layout: 'landscape',
  dialog: {
    title: 'Inspection Report',
    message: 'The date below is used to show history records only.',
    form: () => [
      {
        type: 'date',
        label: 'Report Date',
        required: true,
        id: 'reportDate',
        default: moment().endOf('month').toISOString(),
      },
      {
        type: 'text',
        label: 'Months of History (to start of month)',
        id: 'monthsOfHistory',
        required: true,
        default: '12',
      },
      {
        type: 'checkbox',
        label: 'Show Questions / Answers',
        id: 'showQuestionsAndAnswers',
        advanced: true,
      },
      {
        type: 'checkbox',
        label: 'Hide "added" history records',
        id: 'hideAddedHistoryRecords',
        advanced: true,
        default: true
      },
      {
        type: 'checkbox',
        label: 'Show History Time',
        id: 'showHistoryTime',
        advanced: true,
      },
    ],
  },
  endpoint: '/reports/inspectionReport',
};
