import { createContext, useContext, useCallback } from 'react';

export const DialogContext = createContext<{ name: string|null; state: any }>({ 
  name: null,
  state: {}
});

export const SetDialogContext = createContext<(params: { name: string|null; state: any }|((oldState: { name: string|null; state: any }) => { name: string|null; state: any })) => void>(() => null);

export function useControlDialog() {
  const setDialogContext = useContext(SetDialogContext);

  const openDialog = useCallback((params: { name: string; state?: any }|string) => {
    setDialogContext({
      name: typeof params === "string" ? params : params.name,
      state: typeof params === "string" ? {} : params.state
    })
  }, []);

  const closeDialog = useCallback(() => {
    setDialogContext((oldState) => ({
      name: null,
      state: oldState.state
    }));
  }, []);

  return {
    openDialog,
    closeDialog
  }
}

export function useOpenDialogName(): string | null {
  return useContext(DialogContext).name;
}

export function useOpenDialogState(): any {
  return useContext(DialogContext).state;
}
