import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useDeviceType } from '../../hooks';
import { useAddEquipment } from '../../data/equipment';
import { useHistory, useQuery, useParams } from '../../hooks';
import { EquipmentForm } from '../../forms/EquipmentForm';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { useOrgId, useHasPermission } from '../../auth';
import moment from 'moment';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddEquipmentPage() {
  const params = useParams<{ locationId: string }>();
  const history = useHistory();
  const query = useQuery<{ barcode: string }>();
  const orgId = useOrgId();
  const canWrite = useHasPermission(Permissions.EQUIPMENT_WRITE);
  const deviceType = useDeviceType();
  const { addEquipment } = useAddEquipment();

  if (!canWrite) {
    return <></>;
  }
  
  return (
    <Form 
      initialValues={{
        name: '',
        serialNumber: '',
        barcode: query.barcode || '',
        orgId: orgId || '',
        modelId: '',
        manufactureDate: moment().toISOString(),
        locationId: params.locationId
      }}
      onSubmit={async (equipment) => {
        const newItem = await addEquipment(equipment, params.locationId);
        if (deviceType !== 'desktop') {
          history.goBack();
        } else {
          history.replace(`/sites/${params.locationId}/equipment/${newItem.id}`);
        }
      }}
    >
      <Panes 
        backgroundColor="light"
        leftButton={<BackButton />}
      >
        <Pane 
          title="Add Equipment"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <EquipmentForm />
        </Pane>
      </Panes>
    </Form>
  )
}
