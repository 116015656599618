/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { ListDropdownField } from '../ListDropdownField'
import csc from 'country-state-city'

export interface CountryDropdownFieldProps {
  value?: string;
  onChange?: (newText: string) => void;
  required?: boolean;
  hasEmptyOption?: boolean;
}

export const CountryDropdownField = (props: CountryDropdownFieldProps) => {
  const countries = React.useMemo(() => csc.getAllCountries(), []);

  return (
    <ListDropdownField
      label={'Country'}
      value={props.value}
      onChange={(v) => {
        if (props.onChange) props.onChange(v || '')
      }}
      options={countries.map((c) => ({ label: c.name, value: c.name }))}
      required={props.required}
      hasEmptyOption={props.hasEmptyOption}
    />
  )
}
