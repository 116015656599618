import { useRef } from 'react';

/**
 * @desc I read somewhere that useMemo can sometimes run even if the
 * dependencies have not changed.  This is a version of useMemo that guarantees
 * only to re-calculate if the dependencies change
 */
export function useStableMemo<T extends any>(callback: () => T, deps: any[] = []): T {
  const ref = useRef<null|T>(null);
  const oldDepsRef = useRef<null|any[]>(null);

  if (!ref.current || !oldDepsRef.current || deps.length !== oldDepsRef.current.length || oldDepsRef.current.some((item, idx) => item !== deps[idx])) {
    ref.current = callback();
  }

  oldDepsRef.current = deps;

  return ref.current;
}
