import * as React from 'react';
import styled from 'styled-components';
import { TouchableOpacity } from 'react-native';
import { Link as RouterLink } from 'react-router-dom';
export { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ButtonLinkProps } from './types';
import { useCheckHasUnsavedChanges } from '../UnsavedChangesDetector';
import { useControlDialog } from '../../hooks';

const InternalLink = styled(RouterLink)`
  text-decoration: none;
  color: inherit;
  transition: all 0.25s;
  opacity: 1;

  &:active {
    opacity: 0.2;
    transition: all 0s;
  }
`;

export function ButtonLink(props: ButtonLinkProps) {
  const { to, onPress, ...otherProps } = props;
  const checkHasUnsavedChanges = useCheckHasUnsavedChanges();
  const { openDialog } = useControlDialog();

  if (to) {
    const isAbsolute = to.indexOf("http") === 0;
    return (
      <InternalLink 
        as={isAbsolute ? "a" : undefined}
        // @ts-ignore
        to={isAbsolute ? undefined : to} 
        href={isAbsolute ? to : undefined}
        target={props.newTab ? "_blank" : undefined}
        aria-label={props.accessibilityLabel}
        onClick={(e: any) => {
          if (checkHasUnsavedChanges()) {
            openDialog({ name: 'unsaved-changes-dialog', state: { to } });
            e.preventDefault();
            return;
          }

          if (onPress) {
            onPress();
          }
        }} 
        {...otherProps}
      />
    );
  }

  return <TouchableOpacity accessibilityRole="button" onPress={onPress} {...otherProps} />;
}

export const DeepLinking = () => null;
