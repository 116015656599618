/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import styled from 'styled-components/native';
import { getBackgroundStyles, getMarginStyles, getPaddingStyles, getAlignStyles } from '../../utils/style';
import { SizingCode, theme } from '../../theme';
import { Message as IntlMessage, Translate } from '../Translate';
import { DeficiencyIcon } from '../../icons/Deficiency';
import { WarningIcon } from '../../icons/Warning';
import { Box } from '../Box';
import { Row } from '../Row';
import { InfoIcon } from '../../icons/Info';
import { TooltipIcon } from '../../icons/Tooltip';

export interface BoxProps {
  type: 'message' | 'error' | 'warning';
  message: IntlMessage;
  margin?: 'none' | 'normal';
  values?: { [key: string]: string|number };
}

interface BoxViewProps {
  type: 'message' | 'error' | 'warning';
  margin?: 'none' | 'normal';
}

function getColor(type: 'message' | 'error' | 'warning') {
  const map = {
    message: theme.palette.snackbar.message,
    error: theme.palette.snackbar.error,
    warning: theme.palette.snackbar.warning,    
  }
  return map[type];
}

const MessageView = styled.View<BoxViewProps>`
  padding: ${(props) => props.margin === 'none' ? 0 : props.theme.spacing.md};
  margin: ${(props) => props.margin === 'none' ? 0 : props.theme.spacing.sm};
  margin-top: 0px;
  text-align: center;
  background-color: ${(props) => getColor(props.type)};
  border-radius: ${(props) => props.theme.borderRadius / 2}px;
  align-items: center;
`;

const MessageText = styled.Text`
  color: white;
  font-size: ${(props) => props.theme.typography.textSizes.md};
`;

export function Message(props: BoxProps) {
  return (
    <MessageView 
      type={props.type}
      margin={props.margin}
    >
      <Row>
        {props.type === 'message' && <TooltipIcon color="white" />}
        {props.type === 'error' && <DeficiencyIcon color="white" />}
        {props.type === 'warning' && <WarningIcon color="white" />}

        <Box paddingLeft="sm">
          <MessageText>
            <Translate text={props.message} values={props.values} />       
          </MessageText>
        </Box>
      </Row>
    </MessageView>
  )
}
