// import { useFormikContext } from 'formik';
import * as React from 'react';
import { useIsFormValid, useTriggerSubmit } from '../Form';
import { userTracker, trackingCategory } from '../../controllers/userTracker';

export const SubmitButton = <T extends { onPress?: () => void; disabled?: boolean }>(props: { as: React.ComponentType<T>; onPress?: (...props: any[]) => void; disabled?: boolean } & Omit<T, 'onPress'>) => {
  const { as, onPress, ...forwardedProps } = props;
  const isValid = useIsFormValid();
  const triggerSubmit = useTriggerSubmit();

  return (
    React.createElement(as, {
      ...forwardedProps,
      disabled: props.disabled || !isValid,
      onPress: (...pressProps: any[]) => { 
        if (props.onPress) {
          props.onPress(...pressProps);
        }
         
        triggerSubmit();

        userTracker.trackEvent(trackingCategory.EDIT, 'Submit')
      },
    } as any)
  )
}
