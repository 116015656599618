import { faComment } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { FontAwesomeIcon } from './helpers/FontAwesomeIcon';
import styled from 'styled-components/native';
import { theme } from '../theme';

export const CommentIcon = (props: { color?: string }) => {
  return (
    <Circle>
      <FontAwesomeIcon icon={faComment} color={props.color || theme.palette.fadedIconColor} />
    </Circle>
  )
}

const Circle = styled.View`
  width: 30px;
  height: 30px;
  background-color: ${() => theme.palette.searchBarColor};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;