/**
 * This file contains the front-end syncing algorithm.  
 * It supports the following
 * features:
 * 
 * - Save changes made while offline for syncing later
 * - Garbage collect data in RAM (via react-query)
 * - onSuccess and onError callbacks that are only called if the component is still mounted
 * - Swapping items between locations
 * - Replacing an item at one location with another one at a different location
 * - Others...

 * 
 * @see https://www.freecodecamp.org/news/naked-promises-are-not-safe-for-work/
 */

import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { HistoryRecord } from '@rivison-inc/ft-types';
import { Optional } from 'utility-types';
import { useNetworkStatus } from '../hooks/useNetworkStatus';
import { useTimeSince } from '../hooks/useTimeSince';
import { Text } from '../components/Text';
import { shortUUID } from '../utils/misc';
import { useSync } from './syncHooks'
import { LocalData } from './localData';
import { ChangeFinder } from './changeFinder';
import { useOrgId, useUserId } from '../auth';
import { useAddHistoryRecord } from './history';

export interface MakeDataParams<T extends { id: string; locationId?: string; lastUpdated: string; deleted: boolean }> {
  dataName: string;
  useAddHistoryRecord?: () => { addHistoryRecord: ReturnType<typeof useAddHistoryRecord>['addHistoryRecord'] };
  indexBy?: keyof T;
  events?: {
    onAdd?: (item: T, otherData: { locationId?: string; myId: string; addHistoryRecord: ReturnType<typeof useAddHistoryRecord>['addHistoryRecord'] }) => void;
    onUpdate?: (originalItem: T, updatedItem: T, otherData: { locationId?: string; myId: string; addHistoryRecord: ReturnType<typeof useAddHistoryRecord>['addHistoryRecord'] }) => void;
    onDelete?: (item: T, otherData: { locationId?: string; myId: string; addHistoryRecord: ReturnType<typeof useAddHistoryRecord>['addHistoryRecord'] }) => void;
  };
}

export function makeDataHooks<T extends { id: string; locationId?: string; lastUpdated: string; deleted: boolean }>(params: MakeDataParams<T>) {  
  const defaultData: T[] = [];

  function useDataById(id: string|undefined|null, locationId?: string) {
    const { data } = useQuery(
      ['useData', 'byId', params.dataName, id], 
      async (): Promise<T | null> => {
        if (!id) {
          return null;
        }
        const localData = new LocalData<T>(params.dataName);
        const data = await localData.get({ field: 'id', value: id }, locationId);
        return data[0]
      }
    );

    return {
      item: data,
    }
  }

  const useData = (filter?: { field: string; value: string|number|undefined|null }, locationId?: string) => {
    const { data, isLoading } = useQuery(
      ['useData', params.dataName, filter?.field, filter?.value], 
      async (): Promise<{ data: T[] }> => {
        if (filter?.field && !filter.value) {
          return { data: [] }
        }
        const localData = new LocalData<T>(params.dataName);
        let data = await localData.get(filter, locationId);
        // there could be old records that were transfered out but remain here as well
        // Dont remove them as they could be unsynced 
        if (locationId) {
          data = data.filter(row => row.locationId === locationId)
        }
        data = data.filter(row => !row.deleted)
        return { data }
      }
    );

    return {
      data: data?.data || defaultData,
      isLoading
    }
  }

  function useAddData() {
    const { upload } = useSync()
    const queryClient = useQueryClient();
    const myId = useUserId();
    const hook = params.useAddHistoryRecord && params.useAddHistoryRecord();
    const orgId = useOrgId();

    const addItem = React.useCallback(
      async (
        item: Omit<Optional<T, "id">, "lastUpdated" | "deleted">,
        locationId?: string
      ) => {
        const now = new Date().toISOString();

        const newItem: T = { 
          ...item as T,
          id: item.id || shortUUID(),
          lastUpdated: now,
          deleted: false,
          orgId: orgId,
        }
        if (locationId) {
          newItem.locationId = locationId
        }

        const localData = new LocalData<T>(params.dataName);
        await localData.upsert([newItem], true, locationId);

        queryClient.refetchQueries({ queryKey: 'useData' })
        queryClient.refetchQueries({ queryKey: 'numUnsyncedChanges' })

        try {
          upload();// do not use await       
        } catch(err){
          console.log('Error uploading',err)
        }

        if (params.events?.onAdd && myId && hook?.addHistoryRecord) {
          params.events.onAdd(newItem as any, {
            myId,
            locationId,
            addHistoryRecord: hook?.addHistoryRecord
          })
        }

        return newItem;
      }, 
    []);

    return { addItem };
  }

  function useDeleteDataItem() {
    const { updateItem } = useUpdateDataItem();
    const myId = useUserId();
    const hook = params.useAddHistoryRecord && params.useAddHistoryRecord();

    const deleteItem = React.useCallback(async (id: string, locationId?: string) => {
      const updatedItem = await updateItem(id, { deleted: true } as any, locationId);
      if (params.events?.onDelete && myId && hook?.addHistoryRecord) {
        params.events.onDelete(updatedItem, {
          myId,
          addHistoryRecord: hook?.addHistoryRecord,
          locationId
        })
      }
    }, [updateItem]);

    return { deleteItem }
  }

  function useUpdateDataItem() {
    const { upload } = useSync()
    const queryClient = useQueryClient();
    const myId = useUserId();
    const hook = params.useAddHistoryRecord && params.useAddHistoryRecord();

    const updateItem = React.useCallback(
      async (
        id: string, 
        updates: Partial<T>,
        locationId?: string
      ) => {
        const localData = new LocalData<T>(params.dataName);
        const [originalItem] = await localData.get({ field: 'id', value: id }, locationId)

        const now = new Date().toISOString();

        const updatedItem: T = {
          ...originalItem as T,
          ...updates as T,
          id: id,
          lastUpdated: now,
        }

        await localData.upsert([updatedItem], true, locationId);

        queryClient.refetchQueries({ queryKey: 'useData' })
        try {
          upload();// do not use await        
        } catch(err){
          console.log('Error uploading',err)
        }

        if (!updatedItem.deleted && params.events?.onUpdate && myId && hook?.addHistoryRecord) {
          params.events.onUpdate(originalItem, updatedItem, {
            myId,
            addHistoryRecord: hook.addHistoryRecord,
            locationId
          })
        }

        return updatedItem
    }, []);

    return { updateItem };
  }
  
  function SyncStatusMessage() {
    const { isDownloading, lastSuccessfulDownloadDate: upToDateAsOf } = useSync();
    const timeSinceLastFetch = useTimeSince(upToDateAsOf);
    const networkStatus = useNetworkStatus();

    let message = null;
    if (isDownloading) {
      message = 'Checking for updates...'
    } else if (upToDateAsOf) {
      message = `Up to date as of ${timeSinceLastFetch}`
    }

    if (message) {
      return <Text marginLeft='sm' marginTop='sm' color='secondary' size='sm' italics>{message}</Text>
    }
    return <></>
  }

  return {
    useData,
    useDataById,
    useAddData,
    useDeleteDataItem,
    useUpdateDataItem,
    SyncStatusMessage
  }
}
