import * as React from 'react';
import { ActivityIndicator as ActivityIndicatorNative } from "react-native";
import { theme } from '../../theme';
import { ActivityIndicator } from '../ActivityIndicator';
import styled from 'styled-components/native';

const Container = styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255,255,255,0.5);
  z-index: 99999;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.borderRadius};
`;

export const FullScreenActivityIndicator = () => {
  return (
    <Container>
      <ActivityIndicator size={'large'} color={theme.palette.primary} />
    </Container>
  );
}
