import * as React from 'react';
import { Dialog } from '../Dialog';
import { ListButton } from '../../components/ListButton';
import { useHistory, useControlDialog, useOpenDialogState } from '../../hooks';

export function UnsavedChangesDialog() {
  const state = useOpenDialogState();
  const { closeDialog } = useControlDialog();
  const history = useHistory();

  return (
    <Dialog 
      name="unsaved-changes-dialog"
      title={'Leave page?'}
      subtitle={'Your current changes will be discarded.'}
    >
      <ListButton 
        label="Leave"
        onPress={() => {
          if ((state as any).goBack) {
            history.goBack();
          } else {
            history.push((state as any).to);
          }

          closeDialog();
        }}
      />
      <ListButton 
        label="Cancel"
        onPress={() => {
          closeDialog();
        }}
      />
    </Dialog>
  )
}
