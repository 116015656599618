import { User } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
} = makeDataHooks<User>({
  dataName: "Users",
});

export function useUser(id: string|undefined|null) {
  const { item } = useDataById(id);

  return {
    user: item,
  }
}

export function useUsers() {
  const { data } = useData();

  return {
    users: data || [],
  }
}

export function useAddUser() {
  const { addItem } = useAddData();

  return {
    addUser: addItem,
  }
}

export function useDeleteUser() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteUser: deleteItem,
  }
}

export function useUpdateUser() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateUser: updateItem,
  }
}
