import { api } from '../api';

type BlobWithName = Blob & { name: string };

export async function getUploadUrlForFile(file: BlobWithName|string|undefined, folderName: string, params: { orgId: string|undefined }): Promise<{ url: string; fileName: string }|null> {

  if (!params.orgId || typeof file === 'string' || !file) { 
    return null;
  }

  const fileNameParts = file.name.split('.');
  const fileExtension = fileNameParts[fileNameParts.length - 1];

  const response = await api.post(`/organizations/${params.orgId}/signed-urls`, { 
    folderName,
    action: 'write',
    contentType: fileExtension === 'png' ? 'image/png' : 'image/jpeg'
  });

  return response.data;
}


export async function getReadUrlForFile(fileName: string|undefined|null, folderName: string, params: { orgId: string }): Promise<{ url: string }|null> {
  if (!fileName) {
    return null;
  }

  const response = await api.post(`/organizations/${params.orgId}/signed-urls`, { 
    folderName,
    action: 'read',
    fileName,
  });
  
  return response.data;
}

export function downloadBlob(fileName: string, blob: Blob) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  const fileURL = window.URL.createObjectURL(blob);
  a.href = fileURL;
  a.download = fileName;
  a.click();
}

export function downloadJSON(fileName: string, json: object) {
  const str = JSON.stringify(json);
  const blob = new Blob([str], {type: "application/json"});
  const a = document.createElement("a");
  document.body.appendChild(a);
  const fileURL = window.URL.createObjectURL(blob);
  a.href = fileURL;
  a.download = fileName;
  a.click();
}
