import { GlobalAlert } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

const {
  useData,
} = makeDataHooks<GlobalAlert>({
  dataName: "GlobalAlerts",
});

export function useGlobalAlerts() {
  const { data } = useData();

  return {
    globalAlerts: data,
  }
}
