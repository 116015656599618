import * as React from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import { Box } from '../Box';
import { ProfileIcon } from '../../icons/Profile';
import { theme } from '../../theme';
import { PrivateImage } from '../PrivateImage';

interface Props {
  name: string;
  image?: string;
  size?: 's' | 'm' | 'l';
}

function getSize(size: Props['size']) {
  if (size === 'l') {
    return 60
  }
  if (size === 's') {
    return 20
  }
  return 45
}

function getFontSize(size: Props['size']) {
  if (size === 'l') {
    return 25
  }
  if (size === 's') {
    return 10
  }
  return 18
}

export const Container = styled.View<{ size: Props['size']; }>`
  width: ${(props) => getSize(props.size)}px;
  height: ${(props) => getSize(props.size)}px;
  border-radius: 100%;
  background-color: rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const IconContainer = styled.Text<{ size: Props['size']; }>`
  font-size: ${(props) => getFontSize(props.size)}px;
  color: grey;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const MinName = styled.Text`
  font-size: 8px;
  padding-top: 2px;
  color: ${() => theme.palette.fadedIconColor};
  font-weight: bold;
`;

export const ProfileImage = (props: Props) => {
  const nameSections = props.name.split(' ');
  const name = nameSections[0];

  return (
    <Container size={props.size}>
      {props.image && (
        <PrivateImage 
          fileName={props.image}
          folderName="profile-pictures"
          width={getSize(props.size)}
          height={getSize(props.size)}
        />
      )}

      {!props.image && (
        <IconContainer size={props.size}>
          <ProfileIcon />
          <MinName>{name}</MinName>
        </IconContainer>
      )}
    </Container>
  )
}
