/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { DialogContext, SetDialogContext } from '../../hooks/useControlDialog';
import { DialogComponent, DialogProps } from '../../components/Dialog';

import { MemoizedWrapper } from '../../components/MemoizedWrapper';

const RegisteredDialogsContext = React.createContext<{ registerDialog: (name: string, dialogProps: Omit<DialogProps, 'closeDialog'>) => void }>({ registerDialog: () => null });

export function DialogProvider(props: { children: React.ReactNode }) {
  const [dialogs, setDialogs] = React.useState<{ [key: string]: Omit<DialogProps, 'closeDialog'> }>({});
  const [dialogContext, setDialogContext] = React.useState<{ name: string|null; state: any }>({ name: null, state: {} });

  const openDialogName = dialogContext?.name;
  const dialogProps = openDialogName ? dialogs[openDialogName] : null;

  const contextValue = React.useMemo(() => ({
    registerDialog: (dialogName: string, dialogProps: Omit<DialogProps, 'closeDialog'>) => {
      setDialogs((oldDialogs) => ({
        ...oldDialogs,
        [dialogName]: dialogProps
      }));
    }
  }), [setDialogs]);


  return (
    <RegisteredDialogsContext.Provider value={contextValue}>
      <DialogContext.Provider value={dialogContext}>
        <SetDialogContext.Provider value={setDialogContext}>
          <MemoizedWrapper>
            {!!props.children && props.children}
          </MemoizedWrapper>
          <DialogComponent 
            title={dialogProps?.title} 
            subtitle={dialogProps?.subtitle} 
            open={Boolean(dialogProps)}
            dontStickToBottomOnMobile={dialogProps?.dontStickToBottomOnMobile}
            closeDialog={() => {
              setDialogContext((oldState) => ({
                name: null,
                state: oldState.state
              }))
            }}
          >
            {!!dialogProps?.children && dialogProps?.children}
          </DialogComponent>
        </SetDialogContext.Provider>
      </DialogContext.Provider>
    </RegisteredDialogsContext.Provider>
  )
}

export function Dialog(props: { name: string } & Omit<DialogProps, 'closeDialog'>) {
  const { name, ...dialogProps } = props;
  const context = React.useContext(RegisteredDialogsContext);
  React.useEffect(() => {
    context.registerDialog(name, dialogProps);
  }, [name, props]);

  return null;
}
