import * as React from 'react';
import { ListSwitch } from '../../components/ListSwitch';
import { Field } from '../../components/Form';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListDateField } from '../../components/ListDateField';
import moment from 'moment';
import { useIsFeatureEnabled } from '../../data/organization';
import { FeatureList } from '@rivison-inc/ft-types';

export const PreferencesForm = () => {
  const currentTimeZone = moment.tz.guess() || 'America/Toronto';
  const billingIsEnabled = useIsFeatureEnabled(FeatureList.BILLING);
  
  return (
    <>
      <Field 
        as={ListSwitch} 
        name="allowMarketingEmails" 
        label="Receive marketing emails" 
      />

      {billingIsEnabled && (
        <Field 
          as={ListSwitch} 
          name="showBilling" 
          label="Billing and customer controls" 
        />
      )}

      <Field 
        as={ListSwitch} 
        name="showSiteSelector" 
        label="Show site selector in top corner" 
      />

      <Field 
        as={ListDropdownField} 
        name="lastDateMinimumGranularity" 
        label="Last Date Minimum Granularity" 
        options={[
          { value: 'year', label: 'Year' },
          { value: 'month', label: 'Month' },
          { value: 'day', label: 'Day' },
          { value: 'hour', label: 'Hour' },
          { value: 'minute', label: 'Minute' },
        ]}
      />

      <Field 
        as={ListDateField} 
        name="workDayStartTime" 
        label="Work Day Start Time"
        granularity={'minute'} 
        onlyTime
        timeZone={currentTimeZone}
      />

      <Field 
        as={ListDateField} 
        name="workDayEndTime" 
        label="Work Day End Time" 
        granularity={'minute'} 
        onlyTime
        timeZone={currentTimeZone}
      />

    </>
  )
}
  
