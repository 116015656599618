/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import styled from 'styled-components/native';
import { TextBox } from '../ListTextField/TextBox';
import { Popover } from '../Popover';
import { ListRenderer } from '../ListRenderer';
import { View, TouchableOpacity, Platform, ScaledSize } from 'react-native';
import { Box } from '../Box';
import { searchData } from '../../utils/filter';
import { Keyboard } from 'react-native'
import { useWindowDimensions } from 'react-native';


export interface ListAutocompleteProps {
  label: string;
  helperText?: string;
  value?: string;
  onChange: (newText: string) => void;
  options: Array<{ value: string; label: string; sublabel?: string }>;
  bottomOption?: { value?: string; label: string|React.ReactNode; onPress?: () => void };
}

const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom-color: ${(props) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
`;

const FieldLabelText = styled.Text`
  padding: ${(props) => props.theme.spacing.sm};
  display: flex;
  min-width: 120px;
`;

const OptionContainer = styled.View<{ border?: "none" }>`
  border-bottom-width: ${(props) => props.border === "none" ? "0px" : "1px"};
  border-bottom-color: ${(props) => props.theme.palette.lineColor};
  padding: ${(props) => props.theme.spacing.sm};
`;

const MaxHeightScroller = styled.ScrollView<{ popoverHeight: 'sm' | 'md' | 'lg' }>`
  max-height: ${(props) => ({ sm: '110px', md: '205px', lg: '300px' }[props.popoverHeight])}
`;

const TopTextContainer = styled.Text`
  padding: ${(props) => props.theme.spacing.xs};
  border-bottom-color: ${(props) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
  align-items: center;
  text-align: center;
`;

const TopText = styled.Text`
  align-items: center;
  text-align: center;
  font-size: ${(props) => props.theme.typography.textSizes.xs};
`;

const ItemText = styled.Text`
  font-size: ${(props) => props.theme.typography.textSizes.sm};
`;

const ItemSubText = styled.Text`
  font-size: ${(props) => props.theme.typography.textSizes.xs};
  color: grey;
`;

export const ListAutocomplete = (props: ListAutocompleteProps) => {
  const textboxRef = React.useRef<View>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [textBoxValue, setTextBoxValue] = React.useState('');
  const [originalValue, setOriginalValue] = React.useState('');
  const selectedOption = props.options.find((option) => option.value === props.value);
  const isClickingOptionRef = React.useRef(false);
  const dimensions = useWindowDimensions();
  const popoverHeight = getPopoverHeight(dimensions);

  React.useEffect(() => {
    if (selectedOption) {
      setTextBoxValue(selectedOption.label);
    }
  }, [props.value])
  
  const matchedOptions = searchData(props.options, (data) => ([ data.label, data.sublabel ]), textBoxValue);

  const selectOption = (option: { label: string|React.ReactNode; value?: string }) => {
    Keyboard.dismiss();
    setIsEditing(false);

    if (option.value) {
      const label = typeof option.label === 'string' ? option.label : '';
      setTextBoxValue(label);
      setOriginalValue(label);
      props.onChange(option.value);
    }
  }

  return (
    <Container>
      <FieldLabelText>
        {props.label}
      </FieldLabelText>

      <Box backgroundColor="grey" flexGrow={1}>
        <TextBox 
          accessibilityLabel={props.label}
          onChangeText={(newText) => {
            setIsEditing(true);
            setTextBoxValue(newText);
          }}
          value={textBoxValue}
          ref={textboxRef as any}
          onFocus={() => {
            setIsEditing(true);
            setOriginalValue(textBoxValue);
          }}
          onBlur={(e) => {
            // If TextBox is blurred but user is currently clicking one of the autocomplete 
            // options, we don't want to dismiss the popover.  This is because the onPress 
            // of each option will dismiss it for us.
            //
            // If we dismiss it here, the onPress of the option never gets called 
            // (However, this is only a problem on web, so we don't bother with the check on 
            // mobile. On web, this blur event happens before the press event.  On mobile, 
            // the press event happens before the blur event.  Isn't cross platform development fun?)
            if (Platform.OS === "web" && isClickingOptionRef.current) {
              isClickingOptionRef.current = false;
              return;
            }

            setIsEditing(false);
            setTextBoxValue(originalValue);
            isClickingOptionRef.current = false;
          }}
        />
      </Box>

      <Popover open={isEditing} anchorRef={textboxRef}>
        {props.helperText && (
          <TopTextContainer>
            <TopText>
              {props.helperText}
            </TopText>
          </TopTextContainer>
        )}
        <MaxHeightScroller keyboardShouldPersistTaps="always" popoverHeight={popoverHeight}>
          <ListRenderer 
            disableSeeMore
            render={(option, idx: number) => (
              <TouchableOpacity 
                accessibilityRole="button" 
                key={idx} 
                {...(Platform.OS === "web" ? {
                  onMouseDown:() => {
                    isClickingOptionRef.current = true;
                  }
                } : {})}
                onPress={() => {
                  selectOption(option)
                }}
              >
                <OptionContainer>
                  <ItemText>{option.label}</ItemText>
                  <ItemSubText>{option.sublabel}</ItemSubText>
                </OptionContainer>
              </TouchableOpacity>
            )}
            data={matchedOptions}
          />
        </MaxHeightScroller>
        {props.bottomOption && (
          <TouchableOpacity 
            {...(Platform.OS === "web" ? {
              onMouseDown:() => {
                isClickingOptionRef.current = true;
              }
            } : {})}
            onPress={() => {
              if (props.bottomOption) {
                selectOption(props.bottomOption);
                
                if (props.bottomOption.onPress) {
                  props.bottomOption.onPress();
                }
              }
            }}
          >
            <OptionContainer border="none">
              {props.bottomOption.label}
            </OptionContainer>
          </TouchableOpacity>
        )}
      </Popover>
    </Container>
  )
}

function getPopoverHeight(dimensions: ScaledSize): 'sm' | 'md' | 'lg' {
  if (Platform.OS !== 'web') {
    return 'sm';
  }

  if (dimensions.height > 780) {
    return 'lg';
  }

  if (dimensions.height > 680) {
    return 'md';
  }

  return 'sm';
}