import { EquipmentField } from "@rivison-inc/ft-types";
import { useMemo } from "react";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
} = makeDataHooks<EquipmentField>({
  dataName: "EquipmentFields",
});

export function useEquipmentField(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    equipmentField: item,
  }
}

export function useEquipmentFields(filters: { equipmentTypeId?: string } = {}) {
  const { data } = useData();

  const filteredData = useMemo(() => {
    if (!filters.equipmentTypeId) {
      return data;
    }

    return data.filter((item) => item.equipmentTypeId === filters.equipmentTypeId);
  }, [data, filters.equipmentTypeId])

  return {
    equipmentFields: filteredData,
  }
}

export function useAddEquipmentField() {
  const { addItem } = useAddData();

  return {
    addEquipmentField: addItem,
  }
}

export function useDeleteEquipmentField() {
  const { deleteItem, } = useDeleteDataItem();

  return {
    deleteEquipmentField: deleteItem,
  }
}

export function useUpdateEquipmentField() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateEquipmentField: updateItem,
  }
}
