import * as React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from './FontAwesomeIcon';
import { useTheme } from '../../hooks';
import { Theme } from '../../theme';
import { Platform } from 'react-native';

export type IconSizes = 'xs' | 'sm' | 'lg' | 'xl';
export interface IconProps {
  color?: string;
  size?: IconSizes;
}

export const createIconComponent = (icon: IconDefinition) => (props: IconProps) => {
  const theme = useTheme();
  const color = getColor(props.color, theme);

  const mapSizeToCode: { [key in IconSizes]: any } = {
    // On web, FA uses size codes but on native it uses numbers \_(:/)_/
    xl: Platform.OS === 'web' ? 'xl' : 48,
    lg: Platform.OS === 'web' ? 'lg' : 24,
    sm: undefined,
    xs: undefined
  }

  return (
    <FontAwesomeIcon 
      size={props.size ? mapSizeToCode[props.size] : undefined} 
      icon={icon} 
      color={color} 
    />
  )
}

function getColor(color: string|undefined, theme: Theme) {
  if (color === 'primary') {
    return theme.palette.primary;
  }

  if (color === 'secondary') {
    return theme.palette.fadedIconColor;
  }

  if (color === 'success') {
    return theme.palette.primary;
  }

  if (color === 'error') {
    return theme.palette.error;
  }

  return color || theme.palette.fadedIconColor;
}
