import { FloorPlan } from "@rivison-inc/ft-types";
import { useCallback, useState } from "react";
import { api } from "../api";
import { useOrgId } from '../auth';
import { getUploadUrlForFile } from "../utils/file";
import { makeDataHooks } from "./utils";

const {
  useUpdateDataItem,
  useDeleteDataItem,
  useAddData,
  useDataById,
  useData
} = makeDataHooks<FloorPlan>({
  dataName: "FloorPlans",
});

export function useFloorPlan(params: { id: string|undefined; locationId: string|undefined }) {
  const { item } = useDataById(params.id, params.locationId);

  return {
    floorPlan: item,
  }
}

export function useFloorPlans(locationId: string|undefined) {
  const { data } = useData(undefined, locationId);

  return {
    floorPlans: data,
  }
}

export function useAddFloorPlan() {
  const { addItem } = useAddData();
  const [isAdding, setIsAdding] = useState(false);
  const orgId = useOrgId();
  
  const addFloorPlan = useCallback(async (floorPlan: Omit<FloorPlan, 'deleted' | 'lastUpdated' | 'id' >) => {
    if (!orgId) {
      return;
    }

    setIsAdding(true);

    const image = floorPlan.image;
    const uploadUrl = await getUploadUrlForFile(image, 'floor-plans', { orgId });
    if (uploadUrl && typeof image !== 'string') {
      await api.upload(uploadUrl.url, image);
    }

    const imageURL = uploadUrl?.fileName || floorPlan.image;

    const newFloorPlan = await addItem({ ...floorPlan, image: imageURL }, floorPlan.locationId)

    return newFloorPlan
  }, [addItem, orgId]);

  return {
    addFloorPlan,
    isAddingFloorPlan: isAdding
  }
}


export function useDeleteFloorPlan() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteFloorPlan: deleteItem,
  }
}

export function useUpdateFloorPlan() {
  const { updateItem } = useUpdateDataItem();
  const [isUpdating, setIsUpdating] = useState(false);
  const orgId = useOrgId();
  
  const updateFloorPlan = useCallback(async ({ id, locationId, updates }: { id: string; locationId: string; updates: Partial<FloorPlan>}) => {
    if (!orgId) {
      return;
    }
    setIsUpdating(true);

    const image = updates.image;
    const uploadUrl = await getUploadUrlForFile(image, 'floor-plans', { orgId });

    if (uploadUrl && image && typeof image !== 'string') {
      await api.upload(uploadUrl.url, image);
    }

    const imageURL = uploadUrl?.fileName || updates.image;  

    await updateItem(
      id,
      { ...updates, image: imageURL, },
    )
    setIsUpdating(false);
  }, [updateItem, orgId]);

  return {
    updateFloorPlan,
    isUpdatingFloorPlan: isUpdating
  }
}
