import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useHistory } from '../../hooks';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { BackButton } from '../../components/BackButton';
import { useOrgId } from '../../auth';
import { UserForm } from '../../forms/UserForm';
import { useAddUser } from '../../data/users';
import { useHasPermission } from '../../auth';
import { Permissions, InvitationStatus } from '@rivison-inc/ft-types';
import { api } from '../../api';
import { getUploadUrlForFile } from '../../utils/file';
import { useOrganization } from '../../data/organization';

export function AddUserPage() {
  const { addUser } = useAddUser();
  const history = useHistory();
  const orgId = useOrgId();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);
  const organization = useOrganization().organization

  if (!canWrite) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        name: '',
        email: '',
        image: '',
        orgId: orgId || '',
        permissions: [
          Permissions.WEB_LOGIN,
          Permissions.MOBILE_LOGIN,
          Permissions.EQUIPMENT_READ,
          Permissions.EQUIPMENT_WRITE,
          Permissions.SERVICE_READ,
          Permissions.SERVICE_WRITE,
          Permissions.LOCATION_READ,
          Permissions.LOCATION_WRITE,
          Permissions.HISTORY_READ,
          Permissions.HISTORY_WRITE,
          Permissions.DEFICIENCY_READ,
          Permissions.DEFICIENCY_WRITE,
          Permissions.REPORT_READ,
          Permissions.SCHEDULE_READ,
          Permissions.SCHEDULE_WRITE,
          Permissions.SETTINGS_READ,
          Permissions.SETTINGS_WRITE,
        ],
      }}
      onSubmit={async (user) => {

        if (user.image && typeof user.image !== 'string') {
          const uploadUrl = await getUploadUrlForFile(user.image || '', 'profile-pictures', { orgId: organization.id });
          if (uploadUrl?.fileName) {
            await api.upload(uploadUrl.url, user.image);
            user.image = uploadUrl.fileName;
          }
        }

        await addUser({
          ...user,
          email: user.email.trim(),
          invitationStatus: InvitationStatus.PENDING
        });
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Add User"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <UserForm />
        </Pane>
      </Panes>
    </Form>
  )
}
  
