/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import styled from 'styled-components/native';
import { Theme } from '../../theme';
import { Text } from '../Text';

export interface ListSectionTitleProps {
  children: React.ReactNode;
}

const ListSectionTitleView = styled.View`
  align-items: center;
  justify-content: center;
  border-bottom-color: ${(props: { theme: Theme }) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
  padding: ${(props) => props.theme.spacing.md};
  padding-top: ${(props) => props.theme.spacing.lg};
  padding-bottom: ${(props) => props.theme.spacing.lg};
`;

export const ListSectionTitle = (props: ListSectionTitleProps) => {
  return (
    <ListSectionTitleView>
      <Text size="lg" weight="strong">{props.children}</Text>
    </ListSectionTitleView>
  )
}
