export function searchData<T>(data: T[], getData: (data: any) => string[], searchText: string) {
  return data.filter((dataRow) => {  
    const lowerCaseSearchText = searchText.toLowerCase();
    if (!lowerCaseSearchText) {
      return true;
    }
    const dataToUse = getData(dataRow);
    const words = lowerCaseSearchText.split(' ');

    const filteredWords = words.filter((word) => {
      let doesMatch = false;
      dataToUse.forEach((item) => {
        const lowerCaseItem = (item || '').toLowerCase();
        if (lowerCaseItem.indexOf(word) !== -1) {
          doesMatch = true;
        }
      });
      return doesMatch;
    });
    return filteredWords.length === words.length;
  });
}
