import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useAddPerformedService, usePerformedServices, useUpdatePerformedService } from '../../data/performedServices';
import { useParams, useHistory } from '../../hooks';
import { PaneButton } from '../../components/PaneButton';
import { Form2, SubmitButton2 } from '../../components/Form2';
import { useServiceType } from '../../data/serviceTypes';
import { useOrganization } from '../../data/organization';

import { PerformedServiceForm2 } from '../../forms/PerformedServiceForm2';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';
import { useHistoryRecords, useUpdateHistoryRecord } from '../../data/history';
import { HistoryRecord } from '@rivison-inc/ft-types';
import moment from 'moment';
import { HistoryType } from '@rivison-inc/ft-types';

export function EditPerformedServicePage() {
  const history = useHistory();
  const params = useParams<{ locationId: string; serviceTypeId: string; equipmentId: string }>();

  const canWrite = useHasPermission(Permissions.SERVICE_WRITE);

  const { performedServices } = usePerformedServices(params.locationId);
  const performedService = performedServices.find((performedService) => performedService.equipmentId === params.equipmentId && performedService.serviceTypeId === params.serviceTypeId);
  const { updatePerformedService } = useUpdatePerformedService();
  const { addPerformedService } = useAddPerformedService();
  const { organization } = useOrganization();
  const { historyRecords } = useHistoryRecords(params.locationId)
  const { updateHistoryRecord } = useUpdateHistoryRecord()

  const { serviceType } = useServiceType(params.serviceTypeId);

  const defaultValues = {
    serviceTypeId: params.serviceTypeId,
    equipmentId: params.equipmentId,
    orgId: organization?.id,
  };

  if (!serviceType) {
    return null;
  }

  return (
    <Form2
      defaultValues={performedService || defaultValues}
      reinitializeWhenTruthy={[performedService?.id]}
      onSubmit={async (updates) => {
        if (performedService) {
          await updatePerformedService(
            performedService.id, 
            updates,
            params.locationId
          );
        } else {
          await addPerformedService({
            id: `${updates.equipmentId}-${updates.serviceTypeId}`,
            ...updates,
          }, params.locationId);
        }

        if (performedService) {
          const lastHistoryRecordForService = historyRecords.find(record => {
            return record.relevantId === updates.serviceTypeId 
            && record.type === HistoryType.PERFORMED_SERVICE
            && record.equipmentId === updates.equipmentId 
            && moment(record.date).isSame(moment(performedService.lastDate), 'minute')
          }) as HistoryRecord

          if (lastHistoryRecordForService) {
            updateHistoryRecord(
              lastHistoryRecordForService.id, 
              {
                date: updates.lastDate
              },
              params.locationId
            )
          }
        }

        history.goBack();
      }}
    >
        <Panes backgroundColor="light" leftButton={<BackButton />}>
          <Pane 
            title="Service"
            rightButton={<SubmitButton2 as={PaneButton} showFeedback label="Save" disabled={!canWrite} />}
          >
            <PerformedServiceForm2 
              serviceType={serviceType}
            />
          </Pane>
        </Panes>
    </Form2>
  )
}
  
