import { Equipment, HistoryRecord, HistoryType, PerformedService, ServiceDef } from "@rivison-inc/ft-types";
import { useCallback, useMemo, useRef } from "react";
import { useUserId } from "../auth";
import { useAddHistoryRecord } from "./history";
import { useServiceTypes } from "./serviceTypes";
import { makeDataHooks } from "./utils";
import moment from 'moment';
import { serviceController } from "@rivison-inc/ft-logic";
import { useOrganization } from './organization';
import { useLocation, useLocations } from "./locations";

const {
  useUpdateDataItem,
  useDeleteDataItem,
  useAddData,
  useDataById,
  useData
} = makeDataHooks<PerformedService>({
  dataName: "PerformedServices",
});

export function usePerformedService(params: { id: string; locationId: string }) {
  const { item } = useDataById(params.id, params.locationId);

  return {
    performedService: item,
  }
}

export function usePerformedServices(locationId: string) {
  const { data, isLoading } = useData(undefined, locationId);

  return {
    performedServices: data,
    isLoading
  }
}

export function usePerformedServicesByEquipmentId(params: { locationId: string|undefined; equipmentId: string|undefined }) {
  const { data } = useData({ field: 'equipmentId', value: params.equipmentId }, params.locationId);

  const filteredData = useMemo(() => {
    if (!params.equipmentId) {
      return [];
    }

    return data.filter((item) => item.equipmentId === params.equipmentId)
  }, [data, params.equipmentId]);

  return {
    performedServices: filteredData,
  }
}

export function useAddPerformedService() {
  const { addItem } = useAddData();

  return {
    addPerformedService: addItem,
  }
}

export function useDeletePerformedService() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deletePerformedService: deleteItem,
  }
}

export function useUpdatePerformedService() {
  const { updateItem } = useUpdateDataItem();

  return {
    updatePerformedService: updateItem,
  }
}

export const useAdvanceService = (locationId: string) => {
  const userId = useUserId();
  const { performedServices } = usePerformedServices(locationId);
  const { serviceTypes: serviceDefs } = useServiceTypes();
  const { addHistoryRecord } = useAddHistoryRecord();
  const { updatePerformedService } = useUpdatePerformedService();
  const { addPerformedService } = useAddPerformedService();
  const { organization } = useOrganization();
  const { locations } = useLocations();

  return {
    advanceService: useCallback(async ({ serviceDefId, equipmentItem, from }: { serviceDefId: string; equipmentItem: Equipment; from: 'now' | 'scheduled' }) => {
      if (!equipmentItem?.serviceDefs) {
        throw new Error('Equipment missing service defs, etc. in advanceService function');
      }

      const location = locations.find((loc) => loc.id === equipmentItem.locationId);
      if (!location) {
        throw new Error('Missing location');
      }

      if (!location.timeZone) {
        throw new Error('Missing timeZone');
      }

      const serviceDef = serviceDefs.find((serviceDef) => serviceDef.id === serviceDefId);
      const performedServices = equipmentItem.performedServices || [];
      const performedService = performedServices.find((performedService) => performedService.serviceTypeId === serviceDefId);
      
      const performedServicesWithNextDates = serviceController.getServiceNextDatesForEquipment(equipmentItem.serviceDefs || [], performedServices || [], equipmentItem, location.timeZone, equipmentItem.serviceMonth);
      const performedServiceDates = performedServicesWithNextDates.find((performedService) => performedService.serviceTypeId === serviceDefId);

      const fieldData = equipmentItem.fieldData as { [fieldId: string]: string };

      let nextDate = moment().toDate();
      if (from === 'scheduled') {
        nextDate = moment(performedServiceDates!.nextDateFromScheduled).toDate();
      }

      if (!userId) {
        throw new Error('Not logged in');
      }

      if (!serviceDef) {
        throw new Error('Service def not found.');
      }

      if (performedService) {
        const newPerformedService: PerformedService = {
          ...performedService,
          lastDate: moment(nextDate).toISOString(),
          overidedNextDate: null,
          orgId: organization.id,
          equipmentFieldData: fieldData,
        }

        updatePerformedService(
          performedService.id,
          newPerformedService,
          equipmentItem.locationId
        );
      } else {
        const newPerformedService: Omit<PerformedService, 'deleted' | 'lastUpdated'> = {
          id: `${equipmentItem.id}-${serviceDefId}`,
          lastDate: moment(nextDate).toISOString(),
          equipmentId: equipmentItem.id,
          serviceTypeId: serviceDefId,
          orgId: organization.id,
          overidedNextDate: null,
          locationId: equipmentItem.locationId,
          equipmentFieldData: fieldData,
        }
    
        addPerformedService(newPerformedService, equipmentItem.locationId);
      }
    
      const historyRecord: Omit<HistoryRecord, 'id' | 'deleted' | 'lastUpdated'> = {
        description: serviceDef?.name,
        date: moment().toISOString(),
        userId,
        happenedAtLocationId: locationId,
        locationId: equipmentItem.locationId,
        equipmentId: equipmentItem.id,
        orgId: organization.id,
        type: HistoryType.PERFORMED_SERVICE,
        relevantId: serviceDefId,
      }
     
      const newHistoryRecord = addHistoryRecord(historyRecord, equipmentItem.locationId);
      return newHistoryRecord;
    }, [serviceDefs, performedServices])
  }
}

export function useServicesInformation(equipmentItem: Equipment|undefined|null, siteTypeId: string | undefined | null) {
  
  const oldScheduledServiceTypesRef = useRef<ServiceDef[]>([]);

  const { performedServices: allPerformedServices } = usePerformedServicesByEquipmentId({ equipmentId: equipmentItem?.id, locationId: equipmentItem?.locationId });
  const allServiceTypes = equipmentItem?.serviceDefs || [];
  const { location } = useLocation(equipmentItem?.locationId);

  const serviceTypes = serviceController.removeDisabledServiceTypes(allServiceTypes, siteTypeId);
 
  let scheduledServiceTypes = serviceTypes.filter(serviceType => !serviceType.unscheduled);
  const unscheduledServiceTypes = serviceTypes.filter(serviceType => !!serviceType.unscheduled);

  const nextDatesArr = useMemo(() => {
    if (!equipmentItem || !location) {
      return null
    }
    return serviceController.getServiceNextDatesForEquipment(scheduledServiceTypes, allPerformedServices, equipmentItem, location.timeZone, equipmentItem.serviceMonth);
  }, [scheduledServiceTypes, allPerformedServices, equipmentItem, location?.timeZone, equipmentItem?.serviceMonth]) 

  const nextDates = (nextDatesArr || []).reduce((acc, curr) => {
    acc[curr.serviceTypeId] = curr;
    return acc;
  }, {} as { [serviceTypeId: string]: Pick<PerformedService, 'affectedBy' | 'equipmentFieldData' | 'serviceTypeId' | 'equipmentId' | 'lastDate' | 'overidedNextDate' | 'nextDateFromNow' | 'nextDateFromScheduled'> });
  
  scheduledServiceTypes = maintainSortOrderIfPossible(oldScheduledServiceTypesRef.current, scheduledServiceTypes, nextDates)
  oldScheduledServiceTypesRef.current = scheduledServiceTypes;

  return {
    scheduledServiceTypes,
    unscheduledServiceTypes,
    serviceTypes,
    nextDates,
    performedServices: allPerformedServices
  }
}

function maintainSortOrderIfPossible(existingObjects: ServiceDef[], newObjects: ServiceDef[], nextDates: { [serviceTypeId: string]: { nextDateFromScheduled?: string } }) {
  if (existingObjects.length !== newObjects.length) {
    return sortByNextDate(newObjects, nextDates);
  }

  const newObjectsMap = createIdMap(newObjects);
  const newObjectsWithExistingSortOrder: ServiceDef[] = [];

  for (const existingObj of existingObjects) {
    const correspondingNewObj = newObjectsMap[existingObj.id];
    if (!correspondingNewObj) {
      return sortByNextDate(newObjects, nextDates);
    }

    newObjectsWithExistingSortOrder.push(correspondingNewObj);
  }

  return newObjectsWithExistingSortOrder;
}

function sortByNextDate(servicesTypes: ServiceDef[], nextDates: { [serviceTypeId: string]: { nextDateFromScheduled?: string } }): ServiceDef[] {
  return servicesTypes.sort((a, b) => {
    const aDueDate = nextDates[a.id].nextDateFromScheduled;
    const bDueDate = nextDates[b.id].nextDateFromScheduled;

    if (moment(aDueDate).isBefore(bDueDate)) {
      return -1;
    }

    if (moment(bDueDate).isBefore(aDueDate)) {
      return 1;
    }

    return 0;
  });
}

function createIdMap<T extends { id: string }>(objs: Array<T>) {
  const mapping: { [id: string]: T } = {};
  for (const obj of objs) {
    mapping[obj.id] = obj;
  }
  return mapping;
}
