import React from 'react';
import { useParams } from '../../hooks';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { ServiceList } from '../../containers/ServiceList';

export function EquipmentServiceListPage() {
  const canRead = useHasPermission(Permissions.SERVICE_READ);
  const params = useParams<{ locationId: string; equipmentId: string }>();

  if (!canRead) {
    return <></>;
  }

  return (
    <ServiceList locationId={params.locationId} equipmentId={params.equipmentId} />
  )
}
