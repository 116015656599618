import * as React from 'react';
import { api } from '../../api';
import { ErrorBoundary } from 'react-error-boundary'

const myErrorHandler = (error: Error, info: {componentStack: string}) => {
  api.post('/logs', {
    level: 'error',
    message: `${error.message} ${info.componentStack}`
  })
    .catch(() => {
      // do nothing
    })
}

export function TopErrorBoundary(props: { children: React.ReactNode }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      {props.children}
    </ErrorBoundary>
  )
}

function ErrorFallback() {
  return (
    <h1>An error ocurred while trying to show this page.</h1>
  )
}
