import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { EmptyState } from '../../components/EmptyState';
import { ListItem } from '../../components/ListItem';
import { IconButton } from '../../components/IconButton';
import { AddIcon } from '../../icons/Add';
import { useParams } from '../../hooks';
import { useServiceTypes } from '../../data/serviceTypes';
import { useLocationOverrides } from '../../data/locationOverrides';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { sortByString } from '../../utils/sort';

function AddModelButton(props: { locationId: string }) {  
  return (
    <IconButton to={`/sites/${props.locationId}/settings/location-overrides/new`} label="Add Location Override">
      <AddIcon />
    </IconButton>
  )
}

export function LocationOverrideListPage() {
  const canRead = useHasPermission(Permissions.LOCATION_READ);
  if (!canRead) {
    return <></>;
  }
  
  const params = useParams<{ locationId: string; overrideId: string }>();
  const { locationOverrides } = useLocationOverrides(params.locationId);
  const { serviceTypes } = useServiceTypes();

  const overridesWithServiceType = locationOverrides.map((override) => {
    const serviceType = serviceTypes.find(t => t.id === override.serviceTypeId);
    return {
      ...override,
      serviceName: serviceType?.name,
    }
  });

  const orderedLocationOverrides = sortByString(overridesWithServiceType, 'serviceName', 'asc'); 

  return (
    <Panes backgroundColor="light">
      <Pane title="Site Service Overrides" rightButton={<AddModelButton locationId={params.locationId} />}>
        {orderedLocationOverrides.map((locationOverride, idx) => (
          <ListItem
            key={idx} 
            to={`/sites/${params.locationId}/settings/location-overrides/${locationOverride.id}`}
            title={serviceTypes.find(type => type.id === locationOverride.serviceTypeId)?.name}
          />
        ))}
        {!locationOverrides.length && <EmptyState title={'No overrides for this Site'} subtitle={'Service overrides allow you to disable a service or change the frequency for a specific site'} image={'calendar'} />}
      </Pane>
    </Panes>
  )
}
