export const config = {
  billing: {
    publicKey: process.env.REACT_APP_STRIPE_KEY || 'pk_test_51HeVxhGe4QAtOgrokWSXjMlUsUq6FtEF30UKZMsxr3yz86DDINHC8rn1E2keuEvcjbWLn45TGyrXcBJm0It9kVSu00Z7gAXNLD',
  },
  analytics: {
    google: {
      trackingCode: process.env.REACT_APP_GOOGLE_TRACKING_CODE || 'UA-215610533-1',
    }
  },
  legal: {
    terms: {
      currentVersion: '1.0.1',
    }
  }
}
