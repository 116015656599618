import * as React from 'react';
import { ListTextField } from '../../components/ListTextField';
import { ListSwitch } from '../../components/ListSwitch';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { ListFileUpload } from '../../components/ListFileUpload';
import { Field, useFormValues } from '../../components/Form';
import { FeatureList } from '@rivison-inc/ft-types';

import { CountryDropdownField } from '../../components/CountryDropdownField';
import { StateDropdownField } from '../../components/StateDropdownField';
import { useIsFeatureEnabled } from '../../data/organization';

export const OrganizationForm = () => {
  const whiteLabelEnabled = useIsFeatureEnabled(FeatureList.WHITE_LABEL);
  const formValues = useFormValues();
  
  return (
    <>
      <ListSectionTitle>Company Information</ListSectionTitle>
      <Field 
        as={ListTextField} 
        name="name" 
        label="Company Name" 
        type="text"
        autofocus
        required
      />
      <Field 
        as={ListTextField} 
        name="email" 
        label="Email" 
        type="text"
        required
      />
      <Field 
        as={ListTextField} 
        name="phone" 
        label="Phone Number" 
        type="text"
      />
      <Field 
        as={CountryDropdownField} 
        hasEmptyOption
        name="country"
      />
      <Field
        as={StateDropdownField} 
        hasEmptyOption
        country={formValues.country as string}
        name="province"
      />
      <Field 
        as={ListTextField} 
        name="city" 
        label="City" 
        type="text"
      />
      <Field 
        as={ListTextField} 
        name="postalCode" 
        label="Postal Code" 
        type="text"
      />
      <Field 
        as={ListTextField} 
        name="address" 
        label="Address" 
        type="text"
      />

      {whiteLabelEnabled && (
        <>
          <ListSectionTitle>White Label</ListSectionTitle>
            <Field 
              as={ListTextField} 
              name="backgroundColor" 
              label="Background Color" 
              type="text"
            />
            <Field 
              as={ListFileUpload} 
              name="logo" 
              label="Logo" 
            />
            <Field 
              as={ListFileUpload} 
              name="letterHead" 
              label="Portrait LetterHead" 
            />
            <Field 
              as={ListFileUpload} 
              name="landscapeLetterHead" 
              label="landscape LetterHead" 
            />
        </>
      )}
    </>
  )
}
  
