import * as React from 'react';
import { FullScreenMessage } from '../../components/FullScreenMessage';
import { useOrganization } from "../../data/organization";
import { useRouteMatch } from "../../hooks";

export function CompletingSignUp() {
  const onWhitelistedPage = useRouteMatch(['/welcome']);
  const { organization } = useOrganization();

  const open = Boolean(onWhitelistedPage && !organization?.manualBilling && !organization?.hasSetUpBilling);

  return (
    <>
      <FullScreenMessage
        title="Completing Sign Up"
        subtitle="This will just take a moment"
        opened={open}
      />
    </>
  )
}