import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useParams, useHistory } from '../../hooks';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { useSiteType, useDeleteSiteType, useUpdateSiteType } from '../../data/siteTypes';
import { SiteTypeForm } from '../../forms/SiteTypeForm';
import { BackButton } from '../../components/BackButton';

export function EditSiteTypePage() {
  const history = useHistory();
  const params = useParams<{ siteTypeId: string }>();
  const { siteType } = useSiteType(params.siteTypeId);
  const { deleteSiteType } = useDeleteSiteType();
  const { updateSiteType } = useUpdateSiteType();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);

  if (!siteType) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={siteType}
      onSubmit={async (updates) => {
        await updateSiteType(siteType.id, updates)
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Site Type"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" disabled={!canWrite} />}
        >
          {siteType && (
            <SiteTypeForm />
          )}

          <DeleteButton
            name="site-type"
            deleteItemNew={deleteSiteType}
            itemId={siteType.id}
            question="Delete Site Type?"
            yesMessage="Yes, Delete Site Type"
            successMessage="Deleted Site Type"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
