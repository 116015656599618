import moment from 'moment';
import * as React from 'react';
import { Column } from '../../components/Column';
import { Columns } from '../../components/Columns';
import { ListItem } from '../../components/ListItem';
import { ListRenderer } from '../../components/ListRenderer';
import { Pane, Panes } from '../../components/Panes';
import { Schedule } from '../../containers/Schedule';
import { useUserId, useHasPermission, useOrgId } from '../../auth';
import { useLocations } from '../../data/locations';
import { useAddScheduledEvent, useUpdateScheduledEvent, useDeleteScheduledEvent, useScheduledEvents } from '../../data/scheduledEvents';
import { useUsers } from '../../data/users';
import { Permissions, Location, EquipmentType } from '@rivison-inc/ft-types';
import { searchData } from '../../utils/filter';
import { userTracker, trackingCategory } from '../../controllers/userTracker';
import { LeftArrowIcon } from '../../icons/LeftArrow';
import { Box } from '../../components/Box';
import { AddIcon } from '../../icons/Add';
import { RightArrowIcon } from '../../icons/RightArrow';
import { Row } from '../../components/Row';
import { View } from 'react-native';
import { sortByDate, sortByString } from '../../utils/sort';
import { useOrganization } from '../../data/organization';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListSwitch } from '../../components/ListSwitch';
import { useEquipment } from '../../data/equipment';
import { useEffect } from 'react';
import { api } from '../../api';
import { EmptyState } from '../../components/EmptyState';
import { useEquipmentTypes } from '../../data/equipmentTypes';
import { Text } from '../../components/Text';
import { Tag } from '../../components/Tag';

export function SchedulePage() {
  const canRead = useHasPermission(Permissions.SCHEDULE_READ);
  const canWrite = useHasPermission(Permissions.SCHEDULE_WRITE);
  const orgId = useOrgId();
  
  function getSearchData(data: Location) {
    return [ 
      data.name || '', 
      data.address || '' 
    ];
  }

  // const [dueSites, setDueSites] = React.useState<{ [siteId: string]: { [equipmentTypeId: string]: number }}>({});
  // useEffect(() => {
  //   getDueSites();
  // }, []);

  // async function getDueSites() {
  //   const response = await api.get(`/organizations/${orgId}/locations/scheduled`, {
  //     params: {}
  //   });
  //   setDueSites(response.data.sites);
  // }

  const { locations } = useLocations();
  const { users } = useUsers();
  const { scheduledEvents } = useScheduledEvents();
  const { addScheduledEvent } = useAddScheduledEvent();
  const { deleteScheduledEvent } = useDeleteScheduledEvent();
  const { updateScheduledEvent } = useUpdateScheduledEvent();
  const { organization } = useOrganization();
  const { equipmentTypes } = useEquipmentTypes();

  const [mappedEquipmentTypes, setMappedEquipmentTypes] = React.useState<{ [id: string]: EquipmentType }>({});
  useEffect(() => {
    const mapping: { [id: string]: EquipmentType } = {};
    equipmentTypes.forEach((equipmentType) => {
      mapping[equipmentType.id] = equipmentType;
    });
    setMappedEquipmentTypes(mapping);
  }, [equipmentTypes]);

  const [selectedDateIndex, setSelectedDateIndex] = React.useState(0);
  const [selectedUser, setSelectedUser] = React.useState('');
  // const [showDueAndUnscheduledSites, setShowDueAndUnscheduledSites] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');
  const [filteredLocations, setFilteredLocations] = React.useState<Location[]>([])

  const [locationList, setLocationList] = React.useState<Location[]>([]);

  // Temp disabled
  React.useEffect(() => {
    setLocationList(locations);
  }, [locations])
  // React.useEffect(() => {
  //   const today = moment();
  //   const futureScheduledEvents = scheduledEvents.filter(scheduledEvent => moment(scheduledEvent.date).isSameOrAfter(today));
  //   const scheduledLocationIds = futureScheduledEvents.map(scheduledEvent => scheduledEvent.locationId);
  //   if (showDueAndUnscheduledSites) {
  //     const due = locations.filter(location => {
  //       const hasDueService = Boolean(Object.keys(dueSites[location.id] || {}).length)
  //       const isScheduled = scheduledLocationIds.includes(location.id);
  //       return hasDueService && !isScheduled;
  //     });
  //     setLocationList(due);
  //   }else{
  //     setLocationList(locations);
  //   }
  // }, [showDueAndUnscheduledSites, dueSites, locations, scheduledEvents]);

  // Set current selected day
  React.useEffect(() => {
    const startOfMonth = moment().startOf('month');
    const today = moment();
    const daysSinceStartOfMonth = today.diff(startOfMonth, 'days');
    setSelectedDateIndex(daysSinceStartOfMonth);
  }, []);

  React.useEffect(() => {
    if (!selectedUser && users.length) {
      setSelectedUser(users[0].id);
    }
  }, [users]);
  
  React.useMemo(() => {
    const ordered = sortByString(locationList, 'name', 'asc');
    const filtered = searchData(ordered, getSearchData, searchText)
    setFilteredLocations(filtered)
  }, [searchText, locations, locationList]);

  if (!canRead) {
    return <></>;
  }

  return (
    <Columns height="full" space="md" width="full">
      {!canWrite ? <></> : (
        <Column height="full">
          <Panes
            backgroundColor="light"
            // bottomComponent={
            //   <Box borderColor="grey">
            //     <ListDropdownField 
            //       label="User"
            //       value={selectedUser}
            //       options={users.map(user => ({ value: user.id, label: user.name }))}
            //       onChange={selection => {
            //         if (selection) {
            //           setSelectedUser(selection)
            //         }
            //       }}
            //     />
            //     <ListSwitch
            //       label="Show unscheduled sites with due service"
            //       value={showDueAndUnscheduledSites}
            //       onChange={() => setShowDueAndUnscheduledSites(!showDueAndUnscheduledSites)}
            //     />
            //   </Box>
            // }
          >
              <Pane
                title="Add Sites"
                searchText={searchText}
                onSearchChange={(searchText) => setSearchText(searchText)}
              >
                <>
                  {!filteredLocations.length && <EmptyState title={'All Sites Scheduled!'} subtitle={'There are no unscheduled sites with outstanding inspections, service or deficiencies.'} image={'calendar'} />}

                  <ListRenderer 
                    render={(location: Location, idx: number) => (
                      <ListItem
                        key={idx} 
                        title={location.name}
                        subtitle={`${location.address} ${location.city} ${location.province}`}
                        // bottomComponent={
                        //   <Box marginTop="sm" flexDirection="row" flexWrap="wrap">
                        //     {Object.entries(dueSites[location.id] || {}).map(([equipmentTypeId, amount]) => {
                        //       return (
                        //         <Tag 
                        //           key={equipmentTypeId}
                        //           text={`${amount} ${mappedEquipmentTypes[equipmentTypeId]?.name || ''}`}
                        //         />
                        //       )
                        //     })}
                        //   </Box>
                        // }
                        onSelect={() => {
                          const date = moment().startOf('month').add(selectedDateIndex, 'days').toISOString();
                          const startTime = moment().startOf('month').add(selectedDateIndex, 'days').set({"hour": 9, "minute": 0}).toISOString();
                          const endTime = moment().startOf('month').add(selectedDateIndex, 'days').set({"hour": 17, "minute": 0}).toISOString();
                          addScheduledEvent({
                            date: date,
                            locationId: location.id,
                            userId: selectedUser,
                            orgId: organization.id,
                            startTime,
                            endTime,
                          });
                          userTracker.trackEvent(trackingCategory.SCHEDULE, 'Add Event');
                        }}
                        rightComponent={
                          <Row alignY="center" alignX="center" justifyContent="center">
                            <Box padding={'sm'} >
                              <RightArrowIcon />
                            </Box>
                          </Row>
                        }
                      />
                    )}
                    data={filteredLocations}
                  />
                </>
              </Pane>
          </Panes>
        </Column>
      )}

      <Column height="full" grow>
        <Panes
          backgroundColor="light"
          stretchy
        >
          <Pane
            title="Schedule"
          >
            <Schedule 
              events={scheduledEvents}
              selectedDateIndex={selectedDateIndex}
              changeDateIndex={setSelectedDateIndex}
              users={users}
              onRemove={(id) => {
                deleteScheduledEvent(id);
              }}
              onChange={(id, updatedEvent) => {
                updateScheduledEvent(id, updatedEvent);
              }}
              canWrite={canWrite}
            />
          </Pane>
        </Panes>
      </Column>
    </Columns>
  )
}
