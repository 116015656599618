import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useUsers } from '../../data/users';
import { ListItem } from '../../components/ListItem';
import { IconButton } from '../../components/IconButton';
import { BackButton } from '../../components/BackButton';
import { AddIcon } from '../../icons/Add';
import { Permissions } from '@rivison-inc/ft-types';
import { useHasPermission } from '../../auth';

export function UserListPage() {
  const { users } = useUsers();
  
  const canRead = useHasPermission(Permissions.SETTINGS_READ);
  if (!canRead) {
    return <></>;
  }

  return (
    <Panes backgroundColor="light" leftButton={<BackButton />}>
      <Pane 
        title="Users"
        rightButton={(
          <IconButton label="Add User" to="/users/new">
            <AddIcon />
          </IconButton>
        )}
      >
        {users.map((user, idx) => (
          <ListItem
            key={idx} 
            to={`/users/${user.id}`}
            title={user.name}
            subtitle={user.email}
          />
        ))}
      </Pane>
    </Panes>
  )
}
  
