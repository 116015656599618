import * as React from 'react';
import { FontAwesomeIcon } from './helpers/FontAwesomeIcon'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { theme } from '../theme';

export const AddIcon = (props: { color?: string }) => {
  return (
    <FontAwesomeIcon icon={faPlus} color={props?.color || theme.palette.primary} />
  )
}
