import * as React from 'react';
import { EmptyState } from '../../components/EmptyState';
import { IconButton } from '../../components/IconButton';
import { ListItem } from '../../components/ListItem';
import { Pane, Panes } from '../../components/Panes';
import { AddIcon } from '../../icons/Add';
import { useEquipmentFields } from '../../data/equipmentFields';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';

function AddButton() {  
  return (
    <IconButton to="/custom-fields/equipment/new" label="Add Equipment Field">
      <AddIcon />
    </IconButton>
  )
}

export function EquipmentFieldsListPage() {
  const canRead = useHasPermission(Permissions.SETTINGS_READ);
  if (!canRead) {
    return <></>;
  }
  
  const { equipmentFields } = useEquipmentFields();

  return (
    <Panes backgroundColor="light">
      <Pane title="Equipment Fields" rightButton={<AddButton />}>
        {equipmentFields.map((field, idx) => (
          <ListItem
            key={idx} 
            to={`/custom-fields/equipment/${field.id}`}
            title={field.name}
          />
        ))}
        {!equipmentFields.length && <EmptyState title={'No Equipment Fields'} subtitle={'Equipment fields allow you to add different additional fields on the equipment type, such as "Voltage" or "Km".'} />}
      </Pane>
    </Panes>
  )
}
