export function groupByKey<T>(array: T[], key: string): { [key: string]: T[] } {
  const results: { [key: string]: T[] } = {};
  for (const row of array) {
    const keyValue = (row as any)[key];
    results[keyValue] = results[keyValue] || [];
    results[keyValue].push(row)
  }
  return results;
}

export function arrayToKeyValue<T>(array: T[], key: string, valueKey: string): { [key: string]: string } {
  const results: { [key: string]: string } = {};
  (array || []).forEach((item) => {
    const anyItem = item as any;
    const itemKey = anyItem[key];
    results[itemKey] = anyItem[valueKey];
  }); 
  return results;
}

export function createHashMap<T>(array: T[], key: string): { [key: string]: T } {
  const results: { [key: string]: T } = {};
  (array || []).forEach((item: any) => {
    const keyValue = item[key];
    results[keyValue] = item;
  });
  return results;
}
