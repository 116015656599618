import * as React from 'react';
import { useControlDialog, useOpenDialogState } from '../../hooks/useControlDialog';
import { ListButton } from '../../components/ListButton';
import { Dialog } from '../Dialog';

export function EquipmentNote() {
  const { closeDialog } = useControlDialog();
  const dialogState = useOpenDialogState();

  return (
    <Dialog 
      name="equipment-note-dialog"
      title="Note"
      subtitle={dialogState.popupNote as string || ''}
    >
      <ListButton 
        label="Ok"
        onPress={closeDialog}
      />
    </Dialog>
  )
}
