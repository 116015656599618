"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CounterUpdateRequestSchema = exports.CounterSchema = exports.TransferInsertRequestSchema = exports.TransferInsertSchema = exports.TransferSchema = exports.ImportBillingSchemaRequest = exports.ImportBillingSchema = exports.ImportSchemaRequest = exports.ImportSchema = exports.ErrorCode = exports.InvitationStatus = exports.ADVANCED_ACTION = exports.Permissions = exports.AffectedBy = exports.HistoryType = exports.FrequencyType = exports.FeatureList = exports.CustomerUpsertRequestSchema = exports.CustomerUpsertSchema = exports.LocationUpsertRequestSchema = exports.LocationUpsertSchema = exports.CustomerSchema = exports.LocationSchema = void 0;
var zod_1 = require("zod");
exports.LocationSchema = zod_1.z.object({
    id: zod_1.z.string(),
    orgId: zod_1.z.string(),
    customerId: zod_1.z.string().nullish(),
    name: zod_1.z.string(),
    status: zod_1.z.union([
        zod_1.z.literal("active"),
        zod_1.z.literal("inactive"),
        zod_1.z.literal("bad"),
        zod_1.z.literal("special")
    ]),
    note: zod_1.z.string().nullish(),
    popupNote: zod_1.z.string().nullish(),
    customerNumber: zod_1.z.string().nullish(),
    typeId: zod_1.z.string().nullish(),
    contact: zod_1.z.string().nullish(),
    phone: zod_1.z.string().nullish(),
    cell: zod_1.z.string().nullish(),
    email: zod_1.z.string().nullish(),
    fax: zod_1.z.string().nullish(),
    address: zod_1.z.string().nullish(),
    addressTwo: zod_1.z.string().nullish(),
    city: zod_1.z.string().nullish(),
    country: zod_1.z.string().nullish(),
    province: zod_1.z.string().nullish(),
    postalCode: zod_1.z.string().nullish(),
    zone: zod_1.z.string().nullish(),
    /**
     * timeZone can't be optional.  We need it to properly calculate the due
     * equipment in the correct time zone.  This is because things such as
     * annually expected equipment may be due in China but not due in Toronto
     */
    timeZone: zod_1.z.string(),
    lastUpdated: zod_1.z.string(),
    deleted: zod_1.z
        .preprocess(function (val) {
        if (val === 0) {
            return false;
        }
        if (val === 1) {
            return true;
        }
        return val;
    }, zod_1.z.boolean()),
    fieldData: zod_1.z.record(zod_1.z.string()).nullish()
});
exports.CustomerSchema = zod_1.z.object({
    id: zod_1.z.string(),
    orgId: zod_1.z.string(),
    name: zod_1.z.string(),
    accountNumber: zod_1.z.string(),
    contact: zod_1.z.string().nullish(),
    phone: zod_1.z.string().nullish(),
    cell: zod_1.z.string().nullish(),
    email: zod_1.z.string().nullish(),
    fax: zod_1.z.string().nullish(),
    address: zod_1.z.string().nullish(),
    addressTwo: zod_1.z.string().nullish(),
    city: zod_1.z.string().nullish(),
    country: zod_1.z.string().nullish(),
    province: zod_1.z.string().nullish(),
    postalCode: zod_1.z.string().nullish(),
    lastUpdated: zod_1.z.string(),
    deleted: zod_1.z
        .preprocess(function (val) {
        if (val === 0) {
            return false;
        }
        if (val === 1) {
            return true;
        }
        return val;
    }, zod_1.z.boolean()),
});
exports.LocationUpsertSchema = exports.LocationSchema.extend({});
exports.LocationUpsertRequestSchema = zod_1.z.object({
    data: zod_1.z.array(exports.LocationUpsertSchema)
});
exports.CustomerUpsertSchema = exports.CustomerSchema.extend({});
exports.CustomerUpsertRequestSchema = zod_1.z.object({
    data: zod_1.z.array(exports.CustomerUpsertSchema)
});
var FeatureList;
(function (FeatureList) {
    FeatureList["SERVICE_MONTHS"] = "SERVICE_MONTHS";
    FeatureList["BILLING"] = "BILLING";
    FeatureList["WHITE_LABEL"] = "WHITE_LABEL";
    FeatureList["LOCATION_OVERRIDES"] = "LOCATION_OVERRIDES";
    FeatureList["SITE_TYPES"] = "SITE_TYPES";
})(FeatureList = exports.FeatureList || (exports.FeatureList = {}));
var FrequencyType;
(function (FrequencyType) {
    FrequencyType[FrequencyType["MINUTE"] = 2] = "MINUTE";
    FrequencyType[FrequencyType["HOUR"] = 3] = "HOUR";
    FrequencyType[FrequencyType["DAY"] = 4] = "DAY";
    FrequencyType[FrequencyType["WEEK"] = 5] = "WEEK";
    FrequencyType[FrequencyType["MONTH"] = 6] = "MONTH";
    FrequencyType[FrequencyType["YEAR"] = 7] = "YEAR";
})(FrequencyType = exports.FrequencyType || (exports.FrequencyType = {}));
var HistoryType;
(function (HistoryType) {
    HistoryType[HistoryType["PERFORMED_SERVICE"] = 2] = "PERFORMED_SERVICE";
    HistoryType[HistoryType["PERFORMED_UNSCHEDULED_SERVICE"] = 3] = "PERFORMED_UNSCHEDULED_SERVICE";
    HistoryType[HistoryType["COULD_NOT_SERVICE"] = 4] = "COULD_NOT_SERVICE";
    HistoryType[HistoryType["ADDED"] = 5] = "ADDED";
    HistoryType[HistoryType["DATA_CHANGE"] = 6] = "DATA_CHANGE";
    HistoryType[HistoryType["CUSTOM"] = 7] = "CUSTOM";
    HistoryType[HistoryType["DELETED"] = 8] = "DELETED";
    HistoryType[HistoryType["SWAP"] = 9] = "SWAP";
    HistoryType[HistoryType["REPLACED"] = 10] = "REPLACED";
    HistoryType[HistoryType["DEFICIENCY_OPENED"] = 11] = "DEFICIENCY_OPENED";
    HistoryType[HistoryType["DEFICIENCY_CLOSED"] = 12] = "DEFICIENCY_CLOSED";
})(HistoryType = exports.HistoryType || (exports.HistoryType = {}));
var AffectedBy;
(function (AffectedBy) {
    AffectedBy["LOCATION_OVERRIDE"] = "Location Override";
    AffectedBy["SERVICE_OVERRIDE"] = "Service Override";
    AffectedBy["SERVICE_MONTHS"] = "Service Months";
    AffectedBy["ANOTHER_SERVICE"] = "Another Service";
})(AffectedBy = exports.AffectedBy || (exports.AffectedBy = {}));
var Permissions;
(function (Permissions) {
    Permissions["WEB_LOGIN"] = "web.read";
    Permissions["MOBILE_LOGIN"] = "mobile.read";
    Permissions["EQUIPMENT_READ"] = "equipment.read";
    Permissions["EQUIPMENT_WRITE"] = "equipment.write";
    Permissions["SERVICE_READ"] = "service.read";
    Permissions["SERVICE_WRITE"] = "service.write";
    Permissions["LOCATION_READ"] = "location.read";
    Permissions["LOCATION_WRITE"] = "location.write";
    Permissions["HISTORY_READ"] = "history.read";
    Permissions["HISTORY_WRITE"] = "history.write";
    Permissions["DEFICIENCY_READ"] = "deficiency.read";
    Permissions["DEFICIENCY_WRITE"] = "deficiency.write";
    Permissions["REPORT_READ"] = "report.read";
    Permissions["SCHEDULE_READ"] = "schedule.read";
    Permissions["SCHEDULE_WRITE"] = "schedule.write";
    Permissions["SETTINGS_READ"] = "settings.read";
    Permissions["SETTINGS_WRITE"] = "settings.write";
})(Permissions = exports.Permissions || (exports.Permissions = {}));
var ADVANCED_ACTION;
(function (ADVANCED_ACTION) {
    ADVANCED_ACTION["AVOID_SAME_DATE"] = "2";
    ADVANCED_ACTION["KEEP_X_TIME_AWAY"] = "3";
})(ADVANCED_ACTION = exports.ADVANCED_ACTION || (exports.ADVANCED_ACTION = {}));
var InvitationStatus;
(function (InvitationStatus) {
    InvitationStatus[InvitationStatus["PENDING"] = 0] = "PENDING";
    InvitationStatus[InvitationStatus["ACCEPTED"] = 1] = "ACCEPTED";
})(InvitationStatus = exports.InvitationStatus || (exports.InvitationStatus = {}));
var ErrorCode;
(function (ErrorCode) {
    // Returned when the token used has been blacklisted by the server.  This can
    // happen when the user is deleted, the user's password is changed, or the
    // permissions are changed
    ErrorCode["AccessTokenBlackListed"] = "blacklisted-token";
    // Returned when the refresh token has been deleted.  This can happen when the
    // user is deleted or when the user's password is changed
    ErrorCode["RefreshTokenDeletedOrFake"] = "refresh-token-deleted-or-fake";
})(ErrorCode = exports.ErrorCode || (exports.ErrorCode = {}));
exports.ImportSchema = zod_1.z.object({
    id: zod_1.z.string(),
    orgId: zod_1.z.string(),
    dateStarted: zod_1.z.string(),
    dateCompleted: zod_1.z.string().nullish(),
    error: zod_1.z.string().nullish(),
    contextId: zod_1.z.string(),
    importAllSites: zod_1.z.boolean(),
    clearAllData: zod_1.z.boolean(),
    importUsers: zod_1.z.boolean(),
    importDate: zod_1.z.string().nullish(),
});
exports.ImportSchemaRequest = exports.ImportSchema
    .pick({
    orgId: true,
    importAllSites: true,
    clearAllData: true,
    importUsers: true,
    importDate: true,
})
    .extend({
    importKey: zod_1.z.string(),
    importAllSites: exports.ImportSchema.shape.importAllSites.nullish(),
    clearAllData: exports.ImportSchema.shape.clearAllData.nullish(),
    importUsers: exports.ImportSchema.shape.importUsers.nullish(),
    importDate: exports.ImportSchema.shape.importDate.nullish(),
})
    .strict();
exports.ImportBillingSchema = zod_1.z.object({
    orgId: zod_1.z.string(),
});
exports.ImportBillingSchemaRequest = exports.ImportSchema
    .pick({
    orgId: true,
})
    .extend({
    importKey: zod_1.z.string(),
})
    .strict();
exports.TransferSchema = zod_1.z.object({
    equipmentId: zod_1.z.string(),
    toLocationId: zod_1.z.string(),
    fromLocationId: zod_1.z.string(),
    date: zod_1.z.string(),
    dateServerTime: zod_1.z.string(),
    orgId: zod_1.z.string()
});
exports.TransferInsertSchema = exports.TransferSchema.omit({ date: true, dateServerTime: true });
exports.TransferInsertRequestSchema = zod_1.z.object({
    data: exports.TransferInsertSchema
});
exports.CounterSchema = zod_1.z.object({
    name: zod_1.z.string(),
    value: zod_1.z.number(),
    orgId: zod_1.z.string()
});
exports.CounterUpdateRequestSchema = zod_1.z.union([
    zod_1.z.object({
        siteNumber: zod_1.z.literal("{increment}"),
    }),
    zod_1.z.object({
        accountNumber: zod_1.z.literal("{increment}"),
    })
]);
