/// <reference path="../../styled.d.ts" />

import { ScheduledEvent, User } from '@rivison-inc/ft-types';
import moment from 'moment';
import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useLocations } from '../../data/locations';
import { ScheduleItem } from '../../components/ScheduleItem';
import { ListRenderer } from '../../components/ListRenderer';
import * as s from './styles';
import { userTracker, trackingCategory } from '../../controllers/userTracker';
import { Box } from '../../components/Box';
import { ProfileImage } from '../../components/ProfilePicture';
import { Column } from '../../components/Column';
import { Row } from '../../components/Row';
import { ScheduleDrawer } from '../ScheduleDrawer'
import { useOrganization } from '../../data/organization';
import { sortByDate } from '../../utils/sort';

interface ScheduleProps {
  events: ScheduledEvent[];
  users: User[];
  selectedDateIndex: number;
  changeDateIndex: (dateIndex: number) => void;
  onRemove: (id: string) => void;
  onChange: (id: string, updatedEvent: ScheduledEvent) => void;
  canWrite?: boolean;
}

interface CalendarRow {
  date: moment.Moment; 
  scheduledItems: ScheduledEvent[];
}

function getDates(events: ScheduledEvent[]): CalendarRow[] {
  const dateArray: CalendarRow[] = [];
  let currentDate = moment().startOf('month');
  const stopDate = moment().add(6, 'months').endOf('month');
  while (currentDate <= stopDate) {
    const scheduledItems = events.filter((event) => currentDate.isSame(event.date, 'day')) || [];

    dateArray.push({
      date: moment(currentDate),
      scheduledItems,
    })
    currentDate = currentDate.add(1, 'days');
  }
  return dateArray;
}

function randomColor() {
  const lum = -0.20;
  let hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  let rgb = '#',
      c, i;
  for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ('00' + c).substr(c.length);
  }
  return rgb;
}

function getUniqueUsers(events: ScheduledEvent[], users: User[]) {
  const mappedUsers = users.reduce<{ [id: string]: User }>(function(map, user) {
    map[user.id] = user;
    return map;
  }, {});
  const userIds = events.map(item => item.userId);
  const uniqueIdSet = new Set(userIds);
  const uniqueIds = [...uniqueIdSet];
  const uniqueUsers = uniqueIds.map(id => {
    const user = mappedUsers[id];
    return user;
  });
  return uniqueUsers;
}

export function Schedule(props: ScheduleProps) {
  const [colors, setColors] = React.useState({} as { [key: string]: string });
  const [days, setDays] =  React.useState([] as CalendarRow[]);
  const { locations } = useLocations();
  const { organization } = useOrganization();
  const workDayStartHour = moment(organization?.workDayStartTime || '2020-01-01T06:00:00').hour();
  const workDayEndHour = moment(organization?.workDayEndTime || '2020-01-01T06:00:00').hour();

  React.useEffect(() => {
    const newColors = colors; 
    props.events.forEach(event => {
      const locationId = event.locationId;
      newColors[locationId] = newColors[locationId] || randomColor();
    });
    setColors(newColors);
    setDays(getDates(props.events));
  }, [props.events]);

  function fitEventsIntoRows(events: ScheduledEvent[]) {
    const sorted = sortByDate(events, 'endTime', 'asc');
    const rows: ScheduledEvent[][] = [];
    sorted.forEach((event,idx) => {
      const lastRow = rows[rows.length - 1];
      const lastEvent = lastRow && lastRow[lastRow.length - 1];
      const lastEndTime = lastEvent && lastEvent.endTime;
      if (event.startTime >= lastEndTime) {
        rows[rows.length - 1].push(event);
      } else {
        rows[rows.length] = [event];
      }
    });
    return rows;
  }

  return (
    <s.Container>
      <ListRenderer 
        render={(calendarRow: CalendarRow, idx: number) => {
          const date = calendarRow.date;
          const isWeekday = date.weekday() !== 6 && date.weekday() !== 0;

          return (
            <TouchableOpacity key={idx} onPress={() => props.changeDateIndex(idx)} activeOpacity={1}>
              <s.DayContainer isWeekday={isWeekday} selected={props.selectedDateIndex===idx} aboveSelected={props.selectedDateIndex === (idx + 1)}>

                <Column>
                  <s.SelectedBar selected={props.selectedDateIndex===idx} />
                </Column>
                
                <s.DayTitleContainer>
                  <s.MonthName>{date.format('dddd')}</s.MonthName>
                  <s.DayNumber>{date.format('D')}</s.DayNumber>
                  <s.MonthName>{date.format('MMMM YYYY')}</s.MonthName>
                </s.DayTitleContainer> 

                <s.ScheduledItemsContainer>

                  {getUniqueUsers(calendarRow.scheduledItems, props.users).map(user => (
                    <s.UserContainer key={user?.id}>

                      <Row>
                        <Column alignX="center">
                          <s.UserInfo>
                            <Box paddingBottom={'xs'}>
                              <ProfileImage name={user?.name || ''} image={user?.image} size="l" />
                            </Box>
                            <s.UserName>{user?.name}</s.UserName>
                          </s.UserInfo>
                        </Column>

                        <s.EventContainer>

                          {fitEventsIntoRows(calendarRow.scheduledItems.filter(item => item.userId === user?.id)).map((row, idx) => {
                            return (
                              <s.EventContainer key={idx}>
                                {row.map(item => {
                                  const color = colors[item.locationId];
                                  const location = locations.find((location) => location.id === item.locationId);
                                  const startTime = moment(item.startTime).format('h:mm');
                                  const endTime = moment(item.endTime).format('h:mm');
      
                                  return (
                                    <ScheduleDrawer scheduledEvent={item} key={item.id}>
                                      <s.EventBar color={color} startTime={item.startTime} endTime={item.endTime} workDayStartHour={workDayStartHour} workDayEndHour={workDayEndHour}>
                                        <s.EventBarStartTime>{startTime}</s.EventBarStartTime>
                                        <Column alignX="center">
                                          <s.EventBarTitle>{location?.name}</s.EventBarTitle>
                                          <s.EventBarSubTitle>{`${location?.address} ${location?.city}`}</s.EventBarSubTitle>
                                        </Column>
                                        <s.EventBarStartTime>{endTime}</s.EventBarStartTime>
                                      </s.EventBar>
                                    </ScheduleDrawer>
                                  );
                                })}
                              </s.EventContainer>
                            )
                          })}

                        </s.EventContainer>
                      </Row>

                    </s.UserContainer>
                  ))}

                </s.ScheduledItemsContainer>
              </s.DayContainer>
            </TouchableOpacity>
          );
        }}
        data={days}
      />
      
    </s.Container>
  )
}
