import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { ServiceTypesLocationSettingsForm } from '../../forms/ServiceTypesLocationSettingsForm';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { useOrgId, useHasPermission } from '../../auth';
import { useParams, useHistory } from '../../hooks';
import { useAddLocationOverride } from '../../data/locationOverrides';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddLocationOverridePage() {
  const history = useHistory();
  const orgId = useOrgId();
  const params = useParams<{ locationId: string }>();
  const canWrite = useHasPermission(Permissions.LOCATION_WRITE);
  const { addLocationOverride } = useAddLocationOverride();

  if (!canWrite) {
    return <></>;
  }
  
  return (
    <Form 
      initialValues={{
        locationId: params.locationId,
        serviceTypeId: '',
        orgId: orgId || '',
      }}
      onSubmit={async (locationOverride) => {
        await addLocationOverride({
          id: `${locationOverride.locationId}-${locationOverride.serviceTypeId}`,
          ...locationOverride,
        }, params.locationId);
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Add Site Override"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <ServiceTypesLocationSettingsForm />

        </Pane>
      </Panes>
    </Form>
  )
}
  
