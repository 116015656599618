import { ReportDef } from '../types';
import moment from 'moment';

export const timeAtLocation: ReportDef = {
  reportName: 'Time at Site',
  description: 'A detailed report showing each activity and how long a technician or staff member took between and at each site with a total time at the site.',
  layout: 'landscape',
  dialog: {
    title: 'Time at Site',
    message: 'Fill out the options below to prepare your report.',
    form: ({ users }) => ([
      {
        type: 'date',
        label: 'Start Date',
        id: 'startDate',
        default: moment().startOf('month').toISOString(),
      },
      {
        type: 'date',
        label: 'End Date',
        id: 'endDate',
        default: moment().endOf('month').toISOString(),
      },
      {
        type: 'dropdown',
        label: 'User',
        id: 'user',
        options: users,
      },
      {
        type: 'checkbox',
        label: 'Detailed',
        id: 'detailed',
      },
    ]),
  },
  endpoint: '/reports/timeAtLocation',
  type: 'global',
};
