import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useAddEquipmentField } from '../../data/equipmentFields';
import { useHistory } from '../../hooks';
import { EquipmentFieldForm } from '../../forms/EquipmentFieldForm';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { useOrgId } from '../../auth';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddEquipmentFieldsPage() {
  const history = useHistory();
  const orgId = useOrgId();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);
  const { addEquipmentField } = useAddEquipmentField();

  if (!canWrite) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        name: '',
        modelId: '',
        orgId: orgId || '',
      }}
      onSubmit={async (field) => {
        await addEquipmentField(field)
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane
          title="Add Equipment Field"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <EquipmentFieldForm />
        </Pane>
      </Panes>
    </Form>
  )
}
  
