import { FrequencyType } from '@rivison-inc/ft-types';
import * as React from 'react';
import { Field, useFormValues } from '../../components/Form';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListSwitch } from '../../components/ListSwitch';
import { ListTextField } from '../../components/ListTextField';
import { useEquipmentTypes } from '../../data/equipmentTypes';
import { LocationOverride } from '@rivison-inc/ft-types';
import { sortByString } from '../../utils/sort';
import { useServiceTypesByEquipmentTypeId } from '../../data/serviceTypes';

const frequencyTypeMap: { [key: string]: string } = {
  [FrequencyType.MONTH]: 'Months',
  [FrequencyType.YEAR]: 'Years',
  [FrequencyType.WEEK]: 'Weeks',
  [FrequencyType.DAY]: 'Days',
  [FrequencyType.HOUR]: 'Hours',
  [FrequencyType.MINUTE]: 'Minutes',
}

export const ServiceTypesLocationSettingsForm = () => {
  const formValues = useFormValues() as any as LocationOverride & { equipmentTypeId?: string };
  const equipmentTypeId = formValues?.equipmentTypeId;

  const { equipmentTypes } = useEquipmentTypes();
  const sortedEquipmentTypes = sortByString(equipmentTypes, 'name', 'asc');
  const { serviceTypes } = useServiceTypesByEquipmentTypeId(equipmentTypeId);
  const sortedServiceTypes = sortByString(serviceTypes, 'name', 'asc');

  const selectedServiceType = serviceTypes.find(serviceType => serviceType.id === formValues.serviceTypeId);
  let overrideFrequency = '';
  if (selectedServiceType) {
    overrideFrequency = frequencyTypeMap[selectedServiceType?.frequencyType];
  }

  return (
    <>

      <Field 
        as={ListDropdownField} 
        name="equipmentTypeId" 
        label="Equipment Type"
        options={sortedEquipmentTypes.map(type => ({ value: type.id, label: type.name }))}
      />

      <Field 
        as={ListDropdownField} 
        name="serviceTypeId" 
        label="ServiceType"
        options={sortedServiceTypes.map(type => ({ value: type.id, label: type.name }))}
      />

      <Field 
        as={ListTextField} 
        name="overrideFrequency" 
        label={`Frequency Override (${overrideFrequency})`} 
        type="text"
      />

      <Field 
        as={ListSwitch} 
        name="disabledForLocation" 
        label="Disabled For Location" 
      />
      
    </>
  )
}
