/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { ListDropdownField } from '../ListDropdownField'
import csc from 'country-state-city'

export interface StateDropdownFieldProps {
  value?: string;
  onChange?: (newValue: string|null, markClean?: boolean) => void;
  required?: boolean;
  hasEmptyOption?: boolean;
  country?: string;
}

export const StateDropdownField = (props: StateDropdownFieldProps) => {
  const countries = React.useMemo(() => csc.getAllCountries(), []);
  const country = React.useMemo(() => countries.find(c => c.name === props.country), [props.country]);
  const countryId = country?.id;
  const states = React.useMemo(() => countryId ? csc.getStatesOfCountry(countryId) : [], [countryId]);

  return (
    <ListDropdownField
      label={'State / Province'}
      value={props.value}
      onChange={(v) => {
        if (props.onChange) props.onChange(v || '')
      }}
      options={states.map((s) => ({ label: s.name, value: s.name }))}
      required={props.required}
      hasEmptyOption={props.hasEmptyOption}
    />
  )
}
