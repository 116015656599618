"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHashMap = exports.arrayToKeyValue = exports.groupByKey = void 0;
function groupByKey(array, key) {
    let results = {};
    array.forEach((item) => {
        const anyobj = item;
        const keyValue = anyobj[key];
        results[keyValue] = results[keyValue] || [];
        results[keyValue].push(item);
    });
    return results;
}
exports.groupByKey = groupByKey;
function arrayToKeyValue(array, key, valueKey) {
    let results = {};
    array.forEach((item) => {
        const anyItem = item;
        const itemKey = anyItem[key];
        results[itemKey] = anyItem[valueKey];
    });
    return results;
}
exports.arrayToKeyValue = arrayToKeyValue;
function createHashMap(array, key) {
    let results = {};
    array.forEach((item) => {
        const keyValue = item[key];
        results[keyValue] = item;
    });
    return results;
}
exports.createHashMap = createHashMap;
