import * as React from 'react';
import { useId } from '../../hooks/useId';
import { set, get, isEqual, cloneDeep } from 'lodash';
import { __useUnsavedChangesActions } from '../UnsavedChangesDetector';
import { validateEmail } from '../../utils/validation';

export interface FormProps<T> {
  initialValues: T|undefined;
  onSubmit: (values: T) => void;
  children: React.ReactNode;
}

export function Form<T extends object>(props: FormProps<T>) {
  const formId = useId();
  const { markClean, markDirty } = __useUnsavedChangesActions();

  const formRef = React.useRef<FormState>({
    initialValues: {},
    errors: {}, 
    values: {}, 
    validation: {}, 
    forceRenders: {},
    isValidSubscribers: {},
    formValuesSubscribers: {},
    reCheckIsValid: () => {
      const newIsValid = Object.keys(formRef.current.errors).length === 0;
      Object.keys(formRef.current.isValidSubscribers).forEach((key) => {
        formRef.current.isValidSubscribers[key](newIsValid)
      });
    },
    triggerSubmit: () => null,
    triggerFormValuesChanged: () => null,
    setValue: (fieldName: string, fieldValue: unknown, makeClean?: boolean) => {
      set(formRef.current.values, fieldName, fieldValue);

      let isDirty = false;
      Object.keys(formRef.current.values).forEach((key) => {
        if (!isEqual(formRef.current.values[key], formRef.current.initialValues[key])) {
          isDirty = true;
        }
      });

      if (isDirty) {
        markDirty(formId);
      } else {
        markClean(formId);
      }

      if (makeClean) {
        markClean(formId);
      }
    }
  });

  formRef.current.initialValues = React.useMemo(() => cloneDeep(props.initialValues as any), [props.initialValues]);
  formRef.current.triggerSubmit = () => {
    if (Object.keys(formRef.current.errors).length === 0) {
      props.onSubmit(cloneDeep(formRef.current.values) as any);
      markClean(formId);
    }
  }

  formRef.current.triggerFormValuesChanged = () => {
    Object.keys(formRef.current.formValuesSubscribers).forEach((id) => {
      formRef.current.formValuesSubscribers[id]();
    });
  }

  React.useLayoutEffect(() => {
    return () => {
      markClean(formId);
    }
  }, []);

  /**
   * Be very careful changing this!!!!
   * 
   * - Check service months and make sure they still load
   * - Check performed services 
   * - Add new floor plan and make sure info doesnt revert between pressing save and the api responding
   * - Create / edit service type
   */
  React.useLayoutEffect(() => {
    if (props.initialValues) { // CAREFUL!
        initialize({ shouldForceRenders: true });
    }
  }, [props.initialValues]) // CAREFUL!

  const initializedRef = React.useRef(false);
  if (!initializedRef.current) {
    // Should init on the first render before fields render. Should not cause
    // any other renders or react will throw errors
    initialize({ shouldForceRenders: false })
    initializedRef.current = true;
  }

  function initialize(options: { shouldForceRenders: boolean }) {
    
    const initialValues = formRef.current.initialValues;
    if (initialValues) {

      const fieldsWithInitialValues = Object.keys(initialValues);
      fieldsWithInitialValues.forEach((fieldName) => {

        const alreadyHasValue = Boolean(formRef.current.values[fieldName]);
        if (!alreadyHasValue) {
          formRef.current.values[fieldName] = initialValues[fieldName];
  
          if (options.shouldForceRenders && formRef.current.forceRenders[fieldName]) {
            formRef.current.forceRenders[fieldName]();
          }
        }
  
        validate(formRef, fieldName);
      });
    }

    if (options.shouldForceRenders) {
      formRef.current.reCheckIsValid();
    }
  }

  return (
    <FormContext.Provider value={formRef}>
      {props.children}
    </FormContext.Provider>
  );
}

export function Field<T>(props: { name: string; type?: 'email' | string; as: React.ComponentType<T>; required?: boolean|string, onChange?: () => void } & Omit<T, 'value' | 'onChange'>) {
  const [, forceRender] = React.useReducer((s) => s + 1, 0);
  const formRef = React.useContext(FormContext);
  const fieldGroupName = useFieldGroupName();
  const name = fieldGroupName ? `${fieldGroupName}.${props.name}` : props.name;

  if (name) {
    formRef.current.forceRenders[name] = forceRender;
    delete formRef.current.validation[name];
    if (props.required) {
      formRef.current.validation[name] = { ...formRef.current.validation[name], required: props.required }
    }
    if (props.type === 'email') {
        formRef.current.validation[name] = { ...formRef.current.validation[name], email: true }
    }
  }

  // If this field becomes required or optional, we need to run validate and
  // update the isValid watchers
  React.useEffect(() => {
    if (validate(formRef, name)) {
      formRef.current.reCheckIsValid();
    }
  }, [props.required]);

  // Remove all form state related to this field on unmount.
  React.useEffect(() => {
    return () => {
      delete formRef.current.errors[name];
      delete formRef.current.values[name];
      delete formRef.current.validation[name];
      delete formRef.current.forceRenders[name];
      formRef.current.reCheckIsValid();
    }
  }, []);

  return React.createElement(props.as, {
    value: get(formRef.current.values, name),
    error: formRef.current.errors[name],
    ...props,
    onChange: (newValue: any, makeClean?: boolean) => {
      formRef.current.setValue(name, newValue, makeClean);
      
      if (validate(formRef, name)) {
        formRef.current.reCheckIsValid();
      }

      formRef.current.triggerFormValuesChanged();
      forceRender();

      // @ts-ignore
      if (props.onChange) {
        // @ts-ignore
        props.onChange(newValue)
      }
    },
  } as any);
}

export function useSetValue() {
  const formRef = React.useContext(FormContext);
  return React.useCallback((fieldName: string, fieldValue: string|null) => {
    formRef.current.setValue(fieldName, fieldValue);

    if (validate(formRef, fieldName)) {
      formRef.current.reCheckIsValid();
    }

    formRef.current.triggerFormValuesChanged();
  }, [formRef]);
}

function validate(formRef: { current: FormState }, fieldName: string): boolean {
  const validation = formRef.current.validation[fieldName] || {};
  const oldError = formRef.current.errors[fieldName];
  const value = get(formRef.current.values, fieldName);
  if (validation.required && isEmpty(value)) {
    formRef.current.errors[fieldName] = validation.required;
  } else if (validation.email && !validateEmail(value as string)) {
    formRef.current.errors[fieldName] = validation.email;
  } else {
    delete formRef.current.errors[fieldName];
  }

  return oldError !== formRef.current.errors[fieldName];
}

function isEmpty(newValue: unknown) {
  return typeof newValue === 'undefined' || newValue === null || newValue === '' || (Array.isArray(newValue) && newValue.length === 0);
}

export function useIsFormValid() {
  const id = useId();
  const formRef = React.useContext(FormContext);
  const [isValid, setIsValid] = React.useState(Object.keys(formRef.current.errors).length === 0);
  formRef.current.isValidSubscribers[id] = setIsValid;

  React.useEffect(() => {
    return () => {
      delete formRef.current.isValidSubscribers[id];
    }
  }, []);

  return isValid;
}

export function useTriggerSubmit() {
  const formRef = React.useContext(FormContext);
  return formRef.current.triggerSubmit;
}

export function useFormValues() {
  const id = useId();
  const formRef = React.useContext(FormContext);
  const [, forceRender] = React.useReducer((s) => s + 1, 0);
  formRef.current.formValuesSubscribers[id] = forceRender;

  React.useEffect(() => {
    return () => {
      delete formRef.current.formValuesSubscribers[id];
    }
  }, []);

  return formRef.current.values;
}

const FieldGroupContext = React.createContext('');

export function FieldGroup(props: { name: string; children: React.ReactNode }) {
  return (
    <FieldGroupContext.Provider value={props.name}>
      {props.children}
    </FieldGroupContext.Provider>
  )
}

function useFieldGroupName() {
  return React.useContext(FieldGroupContext);
}

interface ValidationSchema {
  required?: boolean|string;
  email?: boolean;
}

interface FormState {
  initialValues: { [fieldName: string]: unknown };
  values: { [fieldName: string]: unknown }; 
  errors: { [fieldName: string]: string|boolean }; 
  validation: {
    [fieldName: string]: ValidationSchema;
  }; 
  forceRenders: {
    [fieldName: string]: () => void;
  };
  isValidSubscribers: {
    [id: string]: (isValid: boolean) => void;
  };
  formValuesSubscribers: {
    [id: string]: () => void;
  };
  reCheckIsValid: () => void;
  triggerSubmit: () => void;
  triggerFormValuesChanged: () => void;
  setValue: (fieldName: string, fieldValue: unknown, makeClean?: boolean) => void;
}

export const FormContext = React.createContext<{ current: FormState }>({
  current: {
    initialValues: {},
    values: {}, 
    errors: {}, 
    validation: {}, 
    forceRenders: {},
    isValidSubscribers: {},
    formValuesSubscribers: {},
    reCheckIsValid: () => null,
    triggerSubmit: () => null,
    triggerFormValuesChanged: () => null,
    setValue: () => null 
  },
});
FormContext.displayName = 'FormContext';
