import axiosModule from 'axios';
import { OfflineError } from '../errors/OfflineError';
import { ServerError } from '../errors/ServerError';
import { Storage } from '../storage';
import { BlackListedTokenError } from '../errors/BlackListedTokenError';
import { ErrorCode } from '@rivison-inc/ft-types';
import { AuthInfo } from '../auth';
import { RefreshTokenError } from '../errors/RefreskTokenError';


export interface RequestConfig {
  params?: { 
    [key: string]: unknown; 
  };
  auth?: {
    username: string;
    password: string;
  };
  headers?: {
    Authorization?: string;
    'Content-Type'?: string;
  };
}

class ApiInteractor {
  axios = axiosModule.create({
    baseURL: process.env.REACT_APP_API_HOST || 'http://localhost:42069'
  })
  
  setBaseUrl(baseUrl: string) {
    this.axios.defaults.baseURL = baseUrl;
  }

  setState: ((newAuthInfo: AuthInfo|((oldAuthInfo: AuthInfo) => AuthInfo)) => void)|null = null;
  authInfo: AuthInfo = {
    user: null,
    token: null,
    expires: null,
    refreshToken: null,
    status: "unauthenticated",
    error: null
  }

  private lastNetworkError: ServerError|Error|null = null;

  refreshPromise: Promise<{}>|null = null;

  private setAuthInfo(newAuthInfo: AuthInfo) {
    if (this.setState) {
      this.setState(newAuthInfo);
      this.authInfo = newAuthInfo;
    }
  }

  private async getNewTokens() {
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    this.refreshPromise = new Promise((resolve, reject) => {
      if (!this.authInfo.refreshToken) {
        reject(new Error('No refresh token on file'));
        return;
      }

      const refreshToken = this.authInfo.refreshToken;

      this.axios.post('/token', {}, {
        headers: {
          Authorization: `Bearer ${refreshToken}`
        },
      })
        .then((res) => {
          resolve(res);
          this.refreshPromise = null;
        })
        .catch(this.handleError)
        .catch((err) => {
          this.refreshPromise = null;
          reject(err);
        });
    });

    return this.refreshPromise;
  }

  private async getAccessToken(options?: { forceNewTokens?: boolean }) {
    if (!this.setState) {
      throw new Error('Not integrated with store');
    }

    const token = this.authInfo.token;
    const expires = this.authInfo.expires;
    
    // Check if token is expiring in next two minutes to leave time for request
    // to go through for slow connections
    const nowPlusTwoMinutes = new Date();
    nowPlusTwoMinutes.setMinutes(nowPlusTwoMinutes.getMinutes() + 2);
    const isExpired = Boolean(expires && expires < nowPlusTwoMinutes.toISOString());

    // Return the current access token if it's not expired
    if (!isExpired && !options?.forceNewTokens) {
      return token;
    }

    // Otherwise, if the current access token is expired, then use the refresh
    // token to get a new token
    let response: any; // eslint-disable @typescript-eslint/no-explicit-any
    try {
      response = await this.getNewTokens();
      if (!response) {
        return token;
      }

      await Storage.setItem('authInfo', { 
        user: response.data.user, 
        token: response.data.token, 
        expires: response.data.expires, 
        refreshToken: response.data.refreshToken
      }, { persist: true });

      this.setAuthInfo({
        status: "authenticated",
        user: response.data.user,
        token: response.data.token,
        expires: response.data.expires,
        refreshToken: response.data.refreshToken,
        error: null
      });

      return response.data.token;
    } catch (err) {
      if (err instanceof BlackListedTokenError || err instanceof RefreshTokenError) {
        this.setAuthInfo({
          user: null,
          token: null,
          expires: null,
          refreshToken: null,
          status: "error",
          error: null
        });
  
        throw err;
      }
      
      return token;
    }
  }

  private async getAuthHeaders(url: string, config?: RequestConfig, options?: { forceNewTokens?: boolean }) {
    // Don't try to authenticate with an external url
    if (url.indexOf('http') === 0) {
      return {};
    }

    // Don't over-ride the auth config
    if (config?.auth) {
      return {};
    }
    
    return { Authorization: `Bearer ${await this.getAccessToken(options)}` };
  }

  private handleError = async (error: { response?: { data: { message?: string; code?: string; contextId?: string } }; request?: unknown }) => {
    if (error.response) {
      // If access token is blacklisted, throw a corresponding error
      if (error.response?.data?.code === ErrorCode.AccessTokenBlackListed) {
        this.lastNetworkError = new BlackListedTokenError(error.response?.data?.contextId || 'no-context-id');
        return Promise.reject(this.lastNetworkError);
      }

      // If refresh token is deleted or fake, throw a corresponding error
      if (error.response?.data?.code === ErrorCode.RefreshTokenDeletedOrFake) {
        this.lastNetworkError = new RefreshTokenError(error.response?.data?.contextId || 'no-context-id');
        return Promise.reject(this.lastNetworkError);
      }

      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      // this.lastNetworkError =  { error: new Error(error.response?.data?.message || 'No message sent from api'), contextId: error.response?.data?.contextId || null } ;
      this.lastNetworkError = new ServerError(error.response?.data?.message || 'No message sent from api', error.response?.data?.contextId || 'no-context-id');
      return Promise.reject(this.lastNetworkError);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // this.lastNetworkError = { error: new Error('Request made but no response received: ' + error.toString()), contextId: null };
      this.lastNetworkError = new OfflineError();
      return Promise.reject(this.lastNetworkError);
    } else {  
      // Something happened in setting up the request that triggered an Error
      this.lastNetworkError = new Error('Something happened in setting up the request that triggered an Error: ' + error.toString());
      return Promise.reject(this.lastNetworkError);
    }
  }

  getLastNetworkError() {
    return this.lastNetworkError;
  }

  async get(url: string, config?: { params: { [key: string]: unknown } }, options?: { responseType: 'json' | 'blob' }) {
    const headers = await this.getAuthHeaders(url, config);
    
    return this.axios.get(url, {
      headers,
      responseType: options?.responseType || 'json',
      ...config,
    })
      .catch(this.handleError)
      .catch(async (err) => {
        if (err instanceof BlackListedTokenError) {
          const newHeaders = await this.getAuthHeaders(url, config, { forceNewTokens: true });
          return this.axios.get(url, {
            headers: newHeaders,
            responseType: options?.responseType || 'json',
            ...config,
          })
            .catch(this.handleError)
        }
        
        return Promise.reject(err);
      })
  }

  async patch(url: string, data: { [key: string]: unknown }, config?: RequestConfig) {
    const headers = await this.getAuthHeaders(url, config);

    return this.axios.patch(url, data, {
      headers,
      ...config,
    })
      .catch(this.handleError)
      .catch(async (err) => {
        if (err instanceof BlackListedTokenError) {
          const newHeaders = await this.getAuthHeaders(url, config, { forceNewTokens: true });

          return this.axios.patch(url, data, {
            headers: newHeaders,
            ...config,
          })
            .catch(this.handleError)
        }
        
        return Promise.reject(err);
      })
  }

  async post(url: string, data?: {}, config?: RequestConfig) {
    const headers = await this.getAuthHeaders(url, config);

    return this.axios.post(url, data, {
      headers,
      ...config,
    })
      .catch(this.handleError)
      .catch(async (err) => {
        if (err instanceof BlackListedTokenError) {
          const newHeaders = await this.getAuthHeaders(url, config, { forceNewTokens: true });

          return this.axios.post(url, data, {
            headers: newHeaders,
            ...config,
          })
            .catch(this.handleError)
        }
        
        return Promise.reject(err);
      })
  }

  async put(url: string, data: {}, config?: RequestConfig) {
    const headers = await this.getAuthHeaders(url, config);

    return this.axios.put(url, data, {
      headers,
      ...config,
    })
      .catch(this.handleError)
      .catch(async (err) => {
        if (err instanceof BlackListedTokenError) {
          const newHeaders = await this.getAuthHeaders(url, config, { forceNewTokens: true });

          return this.axios.put(url, data, {
            headers: newHeaders,
            ...config,
          })
            .catch(this.handleError)
        }
        
        return Promise.reject(err);
      })
  }

  async upload(url: string, file: Blob & { name: string }) {
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1];
    
    // Don't use axios here, seems like it doesn't support uploading blobs on
    // react native. See: https://github.com/axios/axios/issues/2677 TODO: We
    // may want to just use `fetch` in the rest of this file instead of axios,
    // would reduce our bundle size
    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': fileExtension === 'png' ? 'image/png' : 'image/jpeg',
      },
      body: file
    });
  }
}

export const api = new ApiInteractor();
