import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useUpdateFloorPlan, useDeleteFloorPlan, useFloorPlan } from '../../data/floorPlans';
import { useParams, useHistory } from '../../hooks';
import { FloorPlansForm } from '../../forms/FloorPlansForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { BackButton } from '../../components/BackButton';

export function EditFloorPlansPage() {
  const history = useHistory();
  const params = useParams<{ floorPlanId: string; locationId: string }>();
  const { floorPlan } = useFloorPlan({ id: params.floorPlanId, locationId: params.locationId });
  const { updateFloorPlan, isUpdatingFloorPlan } = useUpdateFloorPlan();
  const { deleteFloorPlan } = useDeleteFloorPlan();
  const canWrite = useHasPermission(Permissions.LOCATION_WRITE);

  if (!floorPlan) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={floorPlan}
      onSubmit={async (updates) => {
        await updateFloorPlan({
          id: floorPlan.id,
          locationId: params.locationId,
          updates
        });
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Floor Plan"
          rightButton={<SubmitButton as={PaneButton} label="Save" showFeedback disabled={!canWrite} />}
        >
          {floorPlan && (
            <FloorPlansForm />
          )}

          <DeleteButton
            name="floorplan"
            deleteItemNew={(itemId: string) => {
              deleteFloorPlan(
                itemId,
                params.locationId
              )
            }}
            itemId={floorPlan.id}
            label="Delete"
            yesMessage="Yes, Delete Floor Plan"
            question="Delete Floor Plan?"
            successMessage="Deleted Floor Plan"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
