import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useUpdateLocationField, useDeleteLocationField, useLocationField } from '../../data/locationFields';
import { useParams, useHistory } from '../../hooks';
import { LocationFieldForm } from '../../forms/LocationFieldForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { BackButton } from '../../components/BackButton';

export function EditLocationFieldsPage() {
  const history = useHistory();
  const params = useParams<{ locationFieldId: string }>();
  const { deleteLocationField } = useDeleteLocationField();
  const { updateLocationField } = useUpdateLocationField();
  const { locationField } = useLocationField(params.locationFieldId);
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);

  if (!locationField) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={locationField}
      onSubmit={async (updates) => {
        await updateLocationField(locationField.id, updates)
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Site Field Information"
          rightButton={<SubmitButton as={PaneButton} label="Save" showFeedback disabled={!canWrite} />}
        >
          {locationField && (
            <LocationFieldForm />
          )}

          <DeleteButton
            name="location-field"
            deleteItemNew={deleteLocationField}
            itemId={locationField.id}
            question="Delete Site Field?"
            yesMessage="Yes, Delete Site Field"
            successMessage="Deleted Site Field"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
