import React, { useRef } from 'react';
import moment from 'moment';
import { dateController, serviceController } from '@rivison-inc/ft-logic';
import { useDeviceType, useHistory } from '../../hooks';
import { EmptyState } from '../../components/EmptyState';
import { ListButton } from '../../components/ListButton';
import { Row } from '../../components/Row';
import { ListItem } from '../../components/ListItem';
import { Pane, Panes } from '../../components/Panes';
import { ButtonLink } from '../../components/Router';
import { useAdvanceService, useServicesInformation } from '../../data/performedServices';
import { useHasPermission } from '../../auth';
import { Permissions, ServiceDef } from '@rivison-inc/ft-types';
import { ClockIcon } from '../../icons/Clock';
import { QuestionIcon } from '../../icons/Question';
import { Column } from '../../components/Column';
import { useDeficienciesForEquipment } from '../../data/deficiencies';
import { IconButton } from '../../components/IconButton';
import { AddIcon } from '../../icons/Add';
import { DeficiencyIcon } from '../../icons/Deficiency';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { useControlDialog, useOpenDialogState } from '../../hooks';
import { Dialog } from '../../containers/Dialog';
import { useQuestions } from '../../data/questions';
import { userTracker, trackingCategory } from '../../controllers/userTracker';
import { useEquipmentItem } from '../../data/equipment';
import { useOrganization } from '../../data/organization';
import { useLocation } from '../../data/locations';
import { Box } from '../../components/Box';
import { useEquipmentFields } from '../../data/equipmentFields';
import { Text } from '../../components/Text';
import { useSync } from '../../data/syncHooks';
import { TimeZoneWarning } from '../TimeZoneWarning';
import { UpToDateAsOf } from '../UpToDateAsOf';
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { createHashMap } from '../../utils/array';

interface ServiceListProps {
  locationId: string;
  equipmentId: string;
  mobile?: boolean;
  readonly?: boolean;
  performUpdateOnScan?: boolean;
}

export function ServiceList(props: ServiceListProps) {
  const canRead = useHasPermission(Permissions.SERVICE_READ);
  const { equipmentItem, isLoading } = useEquipmentItem({ locationId: props.locationId, id: props.equipmentId });
  const deviceType = useDeviceType();
  const { openDialog, closeDialog } = useControlDialog();
  const dialogState = useOpenDialogState();
  const history = useHistory();
  const selectedServiceType = dialogState.serviceType as ServiceDef;
  const { questions } = useQuestions();
  const organization = useOrganization();
  const { location } = useLocation(props.locationId);
  const [serviceUpdating, setServiceUpdating] = React.useState<{ [serviceTypeId: string]: boolean }>({});
  const { equipmentFields } = useEquipmentFields({ equipmentTypeId: equipmentItem?.equipmentType?.id || '' })
  const { isDownloading, lastSuccessfulUploadDate } = useSync();

  const { 
    scheduledServiceTypes, 
    unscheduledServiceTypes, 
    nextDates,
    performedServices,
  } = useServicesInformation(equipmentItem, location?.typeId);

  const performedServicesMap = createHashMap(performedServices, 'serviceTypeId');

  const { openDeficiencies, closedDeficiencies } = useDeficienciesForEquipment({ locationId: props.locationId, equipmentId: props.equipmentId });

  const { advanceService } = useAdvanceService(props.locationId);

  const performedOnScanRef = useRef(new Set());

  async function perform() {
    closeDialog();

    setServiceUpdating({ ...serviceUpdating, [selectedServiceType?.id]: true });

    try {
      var historyRecord = await advanceService({
        serviceDefId: selectedServiceType?.id,
        equipmentItem,
        from: 'now',
      });
    }catch(error){
      console.log('Failed to advance service: ', error)
      return;
    }

    setServiceUpdating({ ...serviceUpdating, [selectedServiceType?.id]: false });
    const serviceTypeQuestions = questions.filter((q) => q.serviceTypeId === selectedServiceType?.id);

    if (serviceTypeQuestions.length) {
      history.push(`/sites/${props.locationId}/equipment/${props.equipmentId}/answer-questions/${selectedServiceType?.id}/${historyRecord.id}`);
    }
    
    userTracker.trackEvent(trackingCategory.SERVICE, 'Perform Service', { additionalInfo: `Unscheduled: ${selectedServiceType.unscheduled ? 'true' : 'false'}` });
  
  }

 async function performOnScan() {
    if (location && equipmentItem && !props.readonly && props.performUpdateOnScan) {
      const servicesToUpdate = (equipmentItem.serviceDefs || []).filter((serviceDef) => Boolean(serviceDef.updateOnScan));
      for (const service of servicesToUpdate) {

        const performedService = nextDates[service.id];
        const fieldData = equipmentItem.fieldData || {};
        const isDue = serviceController.isDue(service, performedService?.nextDateFromScheduled!, fieldData, performedService.equipmentFieldData || {}, location.timeZone);

        if (isDue && !performedOnScanRef.current.has(service.id)) {
          performedOnScanRef.current.add(service.id);

          setServiceUpdating((existing) => ({ ...existing, [service.id]: true }));
          
          const historyRecord = await advanceService({
            serviceDefId: service.id,
            equipmentItem: equipmentItem,
            from: 'now',
          });

          setServiceUpdating((existing) => ({ ...existing, [service.id]: false }));
              const hasQuestions = Boolean(questions.find((q) => q.serviceTypeId === service.id));
              if (hasQuestions) {
                history.push(`/sites/${equipmentItem.locationId}/equipment/${equipmentItem.id}/answer-questions/${service.id}/${historyRecord.id}`);
              }
          }
      }
    }
  }

  React.useEffect(() => { performOnScan() }, [location, equipmentItem, props.readonly, props.performUpdateOnScan]);

  if (!canRead) {
    return <></>;
  }

  if (!equipmentItem) {
    return null;
  }

  if (!location) {
    return null;
  }

  return (
    <>
      <Panes 
        id="servicesPane"
        backgroundColor="light"
        title={'Services'}
        hideTitleBar={props.mobile}
        height={props.mobile ? 'content' : 'full'}
        bottomComponent={<></>} // This is to prevent glitchyness with the safearea, dont remove it
      >
        <Pane 
          title="Scheduled"
          icon={<ClockIcon color="#000000" />}
          loading={isDownloading}
        >
          {Boolean(scheduledServiceTypes.length) && <TimeZoneWarning />}

          <UpToDateAsOf />

          {scheduledServiceTypes.map((serviceType) => {
            const performedService = performedServicesMap[serviceType.id]
            const nextDateObj = nextDates[serviceType.id];
            const formattedNextDate = serviceController.formatDateByFrequencyType(nextDateObj?.nextDateFromScheduled!, serviceType.frequencyType, location.timeZone);
            const lastDate = isLoading ? '...' : nextDateObj?.lastDate; // this should always be to the second
            const fieldData = equipmentItem.fieldData || {};
            const isDue = serviceController.isDue(serviceType, nextDateObj?.nextDateFromScheduled!, fieldData, nextDateObj.equipmentFieldData || {}, location.timeZone);
            const conditions = serviceType.conditions || [];
            const hasTimeCondition = conditions.find(condition => condition.type === 'time');  

            const updating = serviceUpdating[serviceType.id];
            let dueString = updating ? 'Updating...' : 'Due: ';
            if (isLoading) {
              dueString = '...';
            }

            if (!updating) {
              conditions.map((condition, idx) => {
                if (condition.type === 'time') {
                  dueString += formattedNextDate;
                } else {
                  const name = equipmentFields.find(equipmentField => equipmentField.id === condition.meteredFieldId);
                  const equipmentFieldData = (nextDateObj.equipmentFieldData || {}) as { [fieldId: string]: string };
                  const lastValue = parseInt(equipmentFieldData[condition.meteredFieldId!] as string || '0');
                  const method = condition.meteredMethod;
                  const meteredAmount = parseInt(condition.meteredAmount || '')

                  if (method === 'every') {
                    const calculatedAmount = meteredAmount + lastValue;
                    dueString += `${name?.name} passes ${calculatedAmount || 0}`
                  } else {
                    dueString += `${name?.name} ${method} ${condition.meteredAmount || 0}`
                  }
                }

                if (idx !== (conditions.length - 1)) {
                  dueString += serviceType.dueWhen === 'all' ? ' and ' : ' or ';
                }

              });
            }

            return (
              <Column key={serviceType.id}>
                <Row>
                  <ButtonLink 
                    style={{ flex: 1 }} 
                    onPress={!props.readonly ? () => openDialog({ name: 'equipment-tag-service-dialog', state: { serviceType } }) : undefined}
                  >
                    <ListItem
                      key={serviceType.id} 
                      title={serviceType.name}
                      subtitle={dueString}
                      rightComponent={performedService?.hasBeenChangedLocally && moment(performedService?.lastUpdated).isAfter(moment(lastSuccessfulUploadDate)) ? <ActivityIndicator size='small' /> : null}
                      secondSubtitle={hasTimeCondition ? `Last Date: ${(lastDate && serviceType) ? dateController.formatMinimumGranularity(lastDate, serviceType.frequencyType, organization?.organization?.lastDateMinimumGranularity, location.timeZone) : 'Never'}` : undefined}
                      subtitleColor={isDue ? 'error' : undefined} 
                      bottomComponent={nextDateObj.affectedBy?.length ? <Box paddingTop='sm'><Text size='xxs' color='secondary'>Date affected by {nextDateObj.affectedBy?.join(' & ')}</Text></Box> : undefined}
                    />
                  </ButtonLink>
                </Row>
              </Column>
            )
          })}
          

          {!scheduledServiceTypes.length && <EmptyState title={'No Scheduled Services'} subtitle={'Scheduled service definitions can be added to the equipment type in settings > equipment types'} image={'calendar'} />}
        </Pane>
        <Pane 
          title="Unscheduled"
          icon={<QuestionIcon />}
          loading={isDownloading}
        >
          {unscheduledServiceTypes.map((serviceType, idx) => (
            <ButtonLink 
              key={serviceType.id}
              style={{ flex: 1 }} 
              onPress={!props.readonly ? () => openDialog({ name: 'equipment-tag-service-dialog', state: { serviceType } }) : undefined}
            >
              <ListItem
                key={idx} 
                title={serviceType.name}
              />
            </ButtonLink>
          ))}
          {!unscheduledServiceTypes.length && <EmptyState title={'No Unscheduled Services'} subtitle={'Unscheduled service definitions can be added to the equipment type in settings > equipment types'} image={'service'} />}
        </Pane>
        <Pane 
          title="Deficiencies" 
          icon={<DeficiencyIcon />}
          badgeNumber={openDeficiencies.length}
          loading={isDownloading}
          rightButton={
            <IconButton to={`/sites/${props.locationId}/equipment/${props.equipmentId}/deficiency/new`} label="Add Deficiency">
              <AddIcon />
            </IconButton>
          }
        >

          {openDeficiencies.length > 0 && <>
            <TimeZoneWarning />
            <UpToDateAsOf />
            {!props.mobile && <ListSectionTitle>Open Deficiencies</ListSectionTitle>}
            {openDeficiencies.map((deficiency, idx) => (
              <ListItem
                key={deficiency.id} 
                to={props.readonly ? undefined : `/sites/${props.locationId}/equipment/${props.equipmentId}/deficiency/${deficiency.id}`}
                title={deficiency.description}
                subtitle={moment(deficiency.dateOpened).tz(location.timeZone).format('MMMM D YYYY h:mmA')}
              />
            ))}
          </>}

          {!props.mobile && closedDeficiencies.length > 0 && <>
            <ListSectionTitle>Closed Deficiencies</ListSectionTitle>
            {closedDeficiencies.map((deficiency, idx) => (
              <ListItem
                key={deficiency.id} 
                to={`/sites/${props.locationId}/equipment/${props.equipmentId}/deficiency/${deficiency.id}`}
                title={deficiency.description}
                subtitle={moment(deficiency.dateOpened).tz(location.timeZone).format('MMMM D YYYY h:mmA')}
              />
            ))}
          </>}

          {(props.mobile && !openDeficiencies.length) && <EmptyState title={'No Open Deficiencies'} subtitle={'Open a deficiency by tapping Options > Add Deficiency'} image={'deficiency'} />}
          {(!props.mobile && !closedDeficiencies.length && !openDeficiencies.length) && <EmptyState title={'No Deficiencies'} subtitle={'Open a deficiency by clicking the plus in the top right of the pane. When the issue has been resolved, click on it and set the closed date.'} image={'deficiency'} />}
        </Pane>
      </Panes>

      <Dialog 
        name="equipment-tag-service-dialog"
        title="Service Options"
      >
        <ListButton 
          label="Perform Service"
          onPress={() => perform()}
        />
        {(deviceType === 'desktop' && !selectedServiceType?.unscheduled) && (
          <ListButton 
            label="Perform Service From Scheduled"
            onPress={async () => {
              closeDialog();

              setServiceUpdating({ ...serviceUpdating, [selectedServiceType?.id]: true });
              const historyRecord = await advanceService({
                serviceDefId: selectedServiceType?.id,
                equipmentItem,
                from: 'scheduled',
              })

              setServiceUpdating({ ...serviceUpdating, [selectedServiceType?.id]: false });
              const serviceTypeQuestions = questions.filter((q) => q.serviceTypeId === selectedServiceType?.id);

              if (serviceTypeQuestions.length) {
                history.push(`/sites/${props.locationId}/equipment/${props.equipmentId}/answer-questions/${selectedServiceType?.id}/${historyRecord.id}`);
              }
  
              userTracker.trackEvent(trackingCategory.SERVICE, 'Perform Service from Scheduled');
            }}
          />
        )}
        {!selectedServiceType?.unscheduled && (
          <ListButton 
            label="Edit"
            onPress={() => {
              closeDialog();
              history.push(`/sites/${props.locationId}/equipment/${props.equipmentId}/performed-service/${selectedServiceType?.id}`);
              userTracker.trackEvent(trackingCategory.SERVICE, 'Edit Dates');
            }}
          />
        )}
        <ListButton 
          label="Cancel"
          onPress={closeDialog}
        />
      </Dialog>
    </>
  )
}
