import styled from 'styled-components/native';
import { Theme } from '../../theme';

export const Container = styled.View`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ImageContainer = styled.View`
  width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
`;

export const InnerImageContainer = styled.View`
  width: 100%;
  height: 100%;
`;

export const Message = styled.Text`
  padding: ${(props) => props.theme.spacing.md};
  border-bottom-width: 1px;
  border-bottom-color: ${(props: { theme: Theme }) => props.theme.palette.lineColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface DotProps {
  x: string;
  y: string;
}

export const Dot = styled.View<DotProps>`
  position: absolute;
  left: ${(props) => props.x};
  top: ${(props) => props.y};
  width: 16px;
  height: 16px;
  margin-left: -8px;
  margin-top: -8px;
  background-color: ${(props: { theme: Theme }) => props.theme.palette.primary};
  border-radius: 100px;
  z-index: 100;
  border-width: 2px;
  border-color: white;
`;

