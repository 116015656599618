import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useServiceMonthCategories } from '../../data/serviceMonthsCategories';
import { ListItem } from '../../components/ListItem';
import { IconButton } from '../../components/IconButton';
import { EmptyState } from '../../components/EmptyState';
import { AddIcon } from '../../icons/Add';
import { Permissions } from '@rivison-inc/ft-types';
import { useHasPermission } from '../../auth';
import { BackButton } from '../../components/BackButton';

function AddButton() {  
  return (
    <IconButton to="/service-month-categories/new" label="Add Service Month Category">
      <AddIcon />
    </IconButton>
  )
}

export function ServiceMonthCategoriesListPage() {
  const canRead = useHasPermission(Permissions.SETTINGS_READ);
  if (!canRead) {
    return <></>;
  }
  
  const { serviceMonthCategories } = useServiceMonthCategories();

  return (
    <Panes backgroundColor="light" leftButton={<BackButton />}>
      <Pane 
        title="Service Month Categories" 
        rightButton={<AddButton />}
        tooltip={{ 
          title: 'Service Month Categories', 
          text: 'Service month categories allow you to keep different types of equipment in different months. For example, you could inspect fire extinguishers in August and May and alarms in October.',
        }}
      >
        {serviceMonthCategories.map((serviceMonthCategory, idx) => (
          <ListItem
            key={idx} 
            to={`/service-month-categories/${serviceMonthCategory.id}`}
            title={serviceMonthCategory.name}
          />
        ))}
        {!serviceMonthCategories.length && <EmptyState title={'No Service Month Categories'} subtitle={'Service months allow you to keep different types of equipment in selected months for each site. Example: Alarms in May and October and Extinguishers in December.'} image={'serviceMonth'} />}
      </Pane>
    </Panes>
  )
}
