/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { useSync } from '../../data/syncHooks';
import { Message } from '../Translate';
import * as s from './styles';

interface EmptyStateProps {
  title: Message;
  subtitle: Message;
  image?: 'equipment' | 'location' | 'inspection' | 'service' | 'model' | 'serviceMonth' | 'floorplan' | 'deficiency' | 'calendar';
}

export const EmptyState = (props: EmptyStateProps) => {
  const svgMap = {
    location: require('./clipart/Location').Location,
    equipment: require('./clipart/Equip').Equip,
    inspection: require('./clipart/Inspect').Inspect,
    service: require('./clipart/Service').Service,
    model: require('./clipart/Model').Model,
    serviceMonth: require('./clipart/ServiceMonths').ServiceMonths,
    floorplan: require('./clipart/Floorplan').Floorplan,
    deficiency: require('./clipart/Def').Def,
    calendar: require('./clipart/Calendar').Calendar
  }

  return (
    <s.Container>
      <s.InnerContainer>
        {!!props.image && svgMap[props.image] && React.createElement(svgMap[props.image])}
        <s.Title>{props.title}</s.Title>
        <s.SubTitle>{props.subtitle}</s.SubTitle>
      </s.InnerContainer>
    </s.Container>
  )
}
