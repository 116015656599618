/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import styled from 'styled-components/native';
import { Theme } from '../../theme';
import { Column } from '../Column';
import { ButtonLink } from '../Router';
import { Row } from '../Row';
import { Text } from '../Text';
import { EditIcon } from '../../icons/Edit';
import { theme } from '../../theme';
import { useDeviceType } from '../../hooks';

export interface ListItemProps {
  title?: string;
  subtitle?: string;
  secondSubtitle?: string;
  to?: string;
  onSelect?: () => void;
  bottomComponent?: React.ReactNode;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  showEditIcon?: boolean;
  subtitleColor?: 'error';
}

const ListItemView = styled.View`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  border-bottom-color: ${(props: { theme: Theme }) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
  flex-grow: 1;
  position: relative;
`;

const EditIconHolder = styled.View`
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const ListItem = (props: ListItemProps) => {
  const deviceType = useDeviceType();

  const component = (
    <ListItemView>
      <Row justifyContent={'space-between'}>
        {props.leftComponent && (
          <Column shrink>
            {props.leftComponent}
          </Column>
        )}
        <Column grow={Boolean(props.leftComponent)} shrink={!props.leftComponent}>
          <Row>
            {!!props.title?.trim() && <Text>{props.title}</Text>}
          </Row>
          {!!props.subtitle?.trim() && (
            <Text color={props.subtitleColor || 'secondary'} size="sm">
              {props.subtitle}
            </Text>
          )}
          {!!props.secondSubtitle?.trim() && (
            <Text color="secondary" size="sm">
              {props.secondSubtitle}
            </Text>
          )}
        </Column>
        {(props.showEditIcon && deviceType === 'desktop') && <EditIconHolder><EditIcon color={theme.palette.fadedIconColor} /></EditIconHolder>}
        {props.rightComponent}
      </Row>
      {props.bottomComponent}
    </ListItemView>
  )

  if (props.to || props.onSelect){
    return <ButtonLink to={props.to} onPress={props.onSelect}>{component}</ButtonLink>
  }

  return component;
}

export const RightContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
`;
