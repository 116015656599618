import moment from "moment";
import { Storage } from "../storage";
import { Syncable } from "@rivison-inc/ft-types";
import { Locks } from "../utils/locks";

export class ChangeFinder<T extends { id: string; lastUpdated: string; hasBeenChangedLocally?: boolean }> {

  private type: string;

  constructor(type: string) {
    this.type = type;
  }

  public async getUnsyncedChanges(lastSuccessfulUploadDate: string, keys: string[]): Promise<T[]> {
    const superOldDate = '1900-01-01' 
    const lastSuccessfulSyncDate = moment(lastSuccessfulUploadDate || superOldDate);

    const lastSuccessfulSyncDateWithBuffer = lastSuccessfulSyncDate.subtract(10, 'minutes')

    return await Locks.getLockBeforeRunning('previously-updated-keys', async () => {

      const recentlyUpdatedKeyData: { key: string, dateUpdated: string }[] = await Storage.getItem(`previously-updated-keys-${this.type}`) || [];
      const recentlyUpdatedKeys = recentlyUpdatedKeyData.map(keyData => keyData.key)

      let unsyncedChanges: T[] = [];

      const relevantKeys = keys.filter(key => recentlyUpdatedKeys.includes(key))

      const storedData = await Storage.bulkGet<T>(relevantKeys) || [];

      const unsyncedChangesForKey = storedData.filter((row) => {
        return row.hasBeenChangedLocally && moment(row.lastUpdated).isAfter(lastSuccessfulSyncDateWithBuffer);
      });

      unsyncedChanges = [...unsyncedChanges, ...unsyncedChangesForKey]
      
      return unsyncedChanges;
    })
  }
}
