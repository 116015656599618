import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useAddEquipmentType } from '../../data/equipmentTypes';
import { useHistory } from '../../hooks';
import { EquipmentTypesForm } from '../../forms/EquipmentTypesForm';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { useOrgId, useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddEquipmentTypePage() {
  const history = useHistory();
  const orgId = useOrgId();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);
  const { addEquipmentType } = useAddEquipmentType();

  if (!canWrite) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        name: '',
        orgId: orgId || '',
      }}
      onSubmit={async (equipmentType) => {
        const newItem = await addEquipmentType(equipmentType);
        history.replace(`/equipment-types/${newItem.id}`);
      }}
    >
      <Panes 
        backgroundColor="light"
        leftButton={<BackButton />}
      >
        <Pane 
          title="Add Equipment Type"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Add" />}
        >
          <EquipmentTypesForm />
        </Pane>
      </Panes>
    </Form>
  )
}
  
