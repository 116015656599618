import * as React from 'react';
import { EmptyState } from '../../components/EmptyState';
import { IconButton } from '../../components/IconButton';
import { ListButton } from '../../components/ListButton';
import { ListItem, RightContainer } from '../../components/ListItem';
import { ButtonLink } from '../../components/Router';
import { Pane, Panes } from '../../components/Panes';
import { ListRenderer } from '../../components/ListRenderer';
import { Dialog } from '../../containers/Dialog';
import { Box } from '../../components/Box';
import { useHistory, useParams } from '../../hooks';
import { AddIcon } from '../../icons/Add';
import { useControlDialog, useOpenDialogState } from '../../hooks';
import { useLocations, LocationsSyncStatusMessage } from '../../data/locations';
import { useHasPermission } from '../../auth';
import { Permissions, Location } from '@rivison-inc/ft-types';
import { useDeviceType } from '../../hooks';
import { CalendarIcon } from '../../icons/Calendar';
import { CalendarMonthIcon } from '../../icons/CalendarMonth';
import { LocationIcon } from '../../icons/Location';
import { CommentIcon } from '../../icons/Comment';
import { Message } from '../../components/Translate';
import { SettingsIcon } from '../../icons/Settings';
import { useScheduledEventsForThisMonth, useScheduledEventsForToday } from '../../data/scheduledEvents'
import { theme } from '../../theme';
import { Linking, Platform } from 'react-native'
import { searchData } from '../../utils/filter';
import { sortByString } from '../../utils/sort';
import SplashScreen from 'react-native-splash-screen';
import { useQueueMessage } from '../../hooks';
import moment from 'moment';
import { useSearchSaver } from '../../containers/SearchSaver';
import { useSync } from '../../data/syncHooks';

function RightButtons(props: { location: Location }) {
  const { openDialog } = useControlDialog();

  return (
    <RightContainer>
      <ButtonLink 
        onPress={() => {
          openDialog({ name: 'location-contact-dialog', state: { ...props.location } });
        }}
      >
        <Box padding="sm">
          <CommentIcon />
        </Box>
      </ButtonLink>
    </RightContainer>
  )
}

function AddLocationButton(props: { customerId?: string }) {  
  const url = props.customerId ? `/customers/${props.customerId}/sites/new` : '/sites/new'
  return (
    <IconButton to={url} label="Add Site">
      <AddIcon color={theme.palette.primary} />
    </IconButton>
  )
}  

export function LocationListPage() {
  const searchSaver = useSearchSaver();
  const canRead = useHasPermission(Permissions.LOCATION_READ);  
  const { locations } = useLocations();
  const { openDialog, closeDialog } = useControlDialog();
  const dialogState = useOpenDialogState();
  const history = useHistory();
  const deviceType = useDeviceType();
  const scheduledEventsToday = useScheduledEventsForToday();
  const scheduledEventsMonth = useScheduledEventsForThisMonth();
  const queueMessage = useQueueMessage();
  const currentTimeZone = moment.tz.guess();
  const params = useParams<{ customerId: string }>();

  const customerLocations = params.customerId ? locations.filter(location => location.customerId === params.customerId) : locations

  const scheduledLocationsToday = React.useMemo(() => customerLocations.filter(location => Boolean(scheduledEventsToday.find(scheduledEvent => scheduledEvent.locationId === location.id))), [locations, scheduledEventsToday]);
  const scheduledLocationsMonth = React.useMemo(() => customerLocations.filter(location => Boolean(scheduledEventsMonth.find(scheduledEvent => scheduledEvent.locationId === location.id))), [locations, scheduledEventsMonth]);

  const [searchText, setSearchText] = React.useState(searchSaver.getSearch());
  const [searchTextToday, setSearchTextToday] = React.useState('');
  const [searchTextMonth, setSearchTextMonth] = React.useState('');
  const [hasShownFirstTimeAlert, setHasShownFirstTimeAlert] = React.useState(false);
  const { isDownloading } = useSync();

  React.useEffect(() => {
    if (SplashScreen) {
      SplashScreen.hide();
    }
  }, []);

  React.useEffect(() => {
    if (!isDownloading && !locations.length && !hasShownFirstTimeAlert && deviceType !== 'desktop') {
      openDialog({ name: 'first-time-warning-dialog', state: {  } });
      setHasShownFirstTimeAlert(true);
    }
  }, [locations, isDownloading, hasShownFirstTimeAlert]);

  function getSearchData(data: Location) {
    return [ 
      data.name || '', 
      data.address || '' ,
      data.city || '' ,
      data.note || '' ,
      data.postalCode || '' ,
      data.phone || '' ,
      data.email || '' ,
      data.contact || '' ,
      data.customerNumber || '',
    ];
  }

  const filteredLocationsToday = React.useMemo(() => {
    const ordered = sortByString(scheduledLocationsToday, 'name', 'asc');
    return searchData(ordered, getSearchData, searchTextToday);
  }, [searchTextToday, scheduledLocationsToday]);

  const filteredLocationsMonth = React.useMemo(() => {
    const ordered = sortByString(scheduledLocationsMonth, 'name', 'asc');
    return searchData(ordered, getSearchData, searchTextMonth);
  }, [searchTextMonth, scheduledLocationsMonth]);

  const filteredLocations = React.useMemo(() => {
    const ordered = sortByString(customerLocations, 'name', 'asc');
    return searchData(ordered, getSearchData, searchText);
  }, [searchText, locations]);

  if (!canRead) {
    return <></>;
  }

  function renderRow(location: Location, idx: number) {
    return (
      <ListItem
        rightComponent={deviceType !== 'desktop' && <RightButtons location={location} />}
        key={idx} 
        to={location.popupNote ? undefined : `/sites/${location.id}`}
        onSelect={() => {
          if (location.popupNote) {
            openDialog({ name: 'location-dialog', state: { popupNote: location.popupNote, id: location.id } });
          }
        }}
        title={location.name || ''}
        subtitle={`${location.address || ''} ${location.city || ''} ${location.province || ''}`}
      />
    )
  }

  return (
    <>
      <Panes
        id="locationsPane"
        backgroundColor="light"
        height="full"
        title="Sites"
      >
        {deviceType !== 'desktop' && (
          <Pane
            title="Today"
            searchText={searchTextToday}
            onSearchChange={(searchText) => setSearchTextToday(searchText)}
            icon={<CalendarMonthIcon />}
            leftButton={<IconButton label="Settings" to="/settings"><SettingsIcon color={theme.palette.fadedIconColor} /></IconButton>}
          >
            <>
              <ListRenderer 
                render={(location: Location, idx: number) => renderRow(location, idx)}
                data={filteredLocationsToday}
              />
              {!filteredLocationsToday.length && <EmptyState title={'No Scheduled Sites'} subtitle={'Sites can help you organize equipment into places such as "Office" or "Warehouse". If you run a servicing company, you can add your clients as sites. '} image={'location'} />}
              <LocationsSyncStatusMessage />
            </>
          </Pane>
        )}
        {deviceType !== 'desktop' && (
          <Pane
            title="This Month"
            searchText={searchTextMonth}
            onSearchChange={(searchText) => setSearchTextMonth(searchText)}
            icon={<CalendarIcon />}
            leftButton={<IconButton label="Settings" to="/settings"><SettingsIcon color={theme.palette.fadedIconColor} /></IconButton>}
          >
            <>
              <ListRenderer 
                render={(location: Location, idx: number) => renderRow(location, idx)}
                data={filteredLocationsMonth}
              />
              {!filteredLocationsMonth.length && <EmptyState title={'No Scheduled Sites'} subtitle={'Sites can help you organize equipment into places such as "Office" or "Warehouse". If you run a servicing company, you can add your clients as sites. '} image={'location'} />}
              <LocationsSyncStatusMessage />
            </>
          </Pane>
        )}
        <Pane
          title="All"
          icon={<LocationIcon />}
          rightButton={deviceType === 'desktop' && <AddLocationButton customerId={params?.customerId} />}
          leftButton={deviceType !== 'desktop' && <IconButton label="Settings" to="/settings"><SettingsIcon color={theme.palette.fadedIconColor} /></IconButton>}
          searchText={searchText}
          onSearchChange={(searchText) => {
            setSearchText(searchText);
            if (Platform.OS === 'web') {
              searchSaver.saveSearch(searchText);
            }
          }}
          autoFocusSearch={Platform.OS === 'web'}
        >
          <>
            <ListRenderer 
              render={(location: Location, idx: number) => renderRow(location, idx)}
              data={filteredLocations}
            />
            {!filteredLocations.length && <EmptyState title={'No Sites'} subtitle={'Sites can help you organize equipment into places such as "Office" or "Warehouse". If you run a servicing company, you can add your clients as sites. '} image={'location'} />}
            <LocationsSyncStatusMessage />
          </>
        </Pane>
      </Panes>

      <Dialog 
        name="location-contact-dialog"
        title={'Contact Information'}
        subtitle={`Contact: ${dialogState.contact || ''} Note: ${dialogState.note || ''} Popup Note: ${dialogState.popupNote || ''}`}
      >
        {Boolean(dialogState.phone) && (
          <ListButton 
            label={`Phone: ${dialogState.phone || ''}` as Message}
            onPress={() => { Linking.openURL(`tel:${dialogState.phone}`) }}
          />
        )}
        {Boolean(dialogState.cell) && (
          <ListButton 
            label={`Cell: ${dialogState.cell || ''}` as Message}
            onPress={() => { Linking.openURL(`tel:${dialogState.cell}`) }}
          />
        )}
        {Boolean(dialogState.address) && (
          <ListButton 
            label={'Open in Apple Maps'}
            onPress={() => { Linking.openURL(`https://maps.apple.com/?daddr=${dialogState.address || ''} ${dialogState.addressTwo || ''} ${dialogState.city || ''}  ${dialogState.province || ''} `) }}
          />
        )}
        {Boolean(dialogState.address) && (
          <ListButton 
            label={'Open in Google Maps'}
            onPress={() => { Linking.openURL(`https://maps.google.com/?daddr=${dialogState.address || ''} ${dialogState.addressTwo || ''} ${dialogState.city || ''}  ${dialogState.province || ''} `) }}
          />
        )}
        
        <ListButton 
          label="Cancel"
          onPress={closeDialog}
        />
      </Dialog>

      <Dialog 
        name="location-dialog"
        title="Note"
        subtitle={dialogState.popupNote as string}
      >
        <ListButton 
          label="Ok"
          onPress={() => {
            closeDialog();
            history.push(`/sites/${dialogState.id}`)
          }}
        />
      </Dialog>

      <Dialog 
        name="first-time-warning-dialog"
        title='Locations can only be added on a desktop browser'
        subtitle={'The mobile version of FlameTask will act close to the app, the desktop version is used for configuration.'}
      >
        <ListButton 
          label="Ok"
          onPress={() => {
            closeDialog();
          }}
        />
      </Dialog>

      
    </>
  )
}
