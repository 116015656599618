import * as React from 'react';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useHasUnsavedChanges } from '../UnsavedChangesDetector';

export function MetaProvider(props: { children: React.ReactNode }) {
  const hasUnsavedChanges = useHasUnsavedChanges();
  return (
    // <HelmetProvider>
      <>
        {/* <Helmet>
          <title>FlameTask{hasUnsavedChanges ? '*' : ''}</title>
        </Helmet> */}
        {props.children}
      </>
    // </HelmetProvider>
  );
}
