import { useOrgId } from '../auth';
import { useQuery, useQueryClient } from 'react-query';
import { Uploader } from './uploader';
import { ChangeFinder } from './changeFinder';
import { Downloader } from './downloader';
import { LocalData } from './localData';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNetworkStatus } from '../hooks/useNetworkStatus';
import { Locks } from '../utils/locks';

declare const window: { timeOut: any };

const commonDefinitions = [
    { apiKey: 'equipment', localDataKey: 'Equipment', isGlobal: false },
    { apiKey: 'locations', localDataKey: 'Locations', isGlobal: true },
    { apiKey: 'performedServices', localDataKey: 'PerformedServices', isGlobal: false },
    { apiKey: 'serviceTypes', localDataKey: 'ServiceTypes', isGlobal: true },
    { apiKey: 'equipmentTypes', localDataKey: 'EquipmentTypes', isGlobal: true },
    { apiKey: 'models', localDataKey: 'Models', isGlobal: true },
    { apiKey: 'customers', localDataKey: 'Customers', isGlobal: true },
    { apiKey: 'equipmentFields', localDataKey: 'EquipmentFields', isGlobal: true },
    { apiKey: 'locationFields', localDataKey: 'LocationFields', isGlobal: true },
    { apiKey: 'modelFields', localDataKey: 'ModelFields', isGlobal: true },
    { apiKey: 'questions', localDataKey: 'Questions', isGlobal: true },
    { apiKey: 'serviceMonthCategories', localDataKey: 'ServiceMonthCategories', isGlobal: true },
    { apiKey: 'users', localDataKey: 'Users', isGlobal: true },
    { apiKey: 'history', localDataKey: 'History', isGlobal: false },
    { apiKey: 'locationOverrides', localDataKey: 'LocationOverrides', isGlobal: false },
    { apiKey: 'serviceMonths', localDataKey: 'ServiceMonths', isGlobal: false },
    { apiKey: 'deficiencies', localDataKey: 'Deficiencies', isGlobal: false },
    { apiKey: 'floorPlans', localDataKey: 'FloorPlans', isGlobal: false },
    { apiKey: 'schedule', localDataKey: 'ScheduledEvents', isGlobal: true },
    { apiKey: 'organization', localDataKey: 'organization', isGlobal: true },
];

const uploader = new Uploader();
const downloader = new Downloader();

const uploadDefinitions = [ ...commonDefinitions ];

const downloadDefinitions = [
    { apiKey: 'globalAlerts', localDataKey: 'GlobalAlerts', isGlobal: true },
    ...commonDefinitions
];

for (const definition of uploadDefinitions) {
    uploader.addDefinition({
        apiKey: definition.apiKey, 
        changeFinder: new ChangeFinder(definition.localDataKey)
    });
}

for (const definition of downloadDefinitions) {
    downloader.addDefinition({
        apiKey: definition.apiKey, 
        localData: new LocalData(definition.localDataKey),
        isGlobal: definition.isGlobal
    });
}

export function useSync() {
    const queryClient = useQueryClient();
    const orgId = useOrgId();
    const [isUploading, setIsUploading] = useState(false);
    const networkStatus = useNetworkStatus();

    useEffect(() => {
        if (orgId) {
            const endpoint = `/organizations/${orgId}/sync`;
            downloader.setEndpoint(endpoint)
            uploader.setEndpoint(endpoint)    
        }
    }, [orgId]);
 
    async function upload() {
        setIsUploading(true)    
        clearTimeout(window.timeOut)
        window.timeOut = setTimeout(doUpload, 300);
    }

    async function doUpload() {    
        await Locks.getLockBeforeRunning('upload', async () => {
            try {
                await uploader.upload();
            } catch(e) {
                console.log('Failed to upload', e)
            }
            setIsUploading(false)

            queryClient.refetchQueries({ queryKey: 'getSyncInfo' })
            queryClient.refetchQueries({ queryKey: 'numUnsyncedChanges' })
        })
    }

    const { refetch, isLoading: isDownloading } = useQuery(
        ['download', orgId], 
        async () => {
            if (!orgId) {
                return;
            }
            await Locks.getLockBeforeRunning('download', async () => {
                await downloader.download();
                queryClient.refetchQueries({ queryKey: 'useData' })
            })
        }
    );

    const { data: syncInfo } = useQuery(
        ['getSyncInfo'], 
        async () => {
            const lastSuccessfulDownloadDate = await downloader.getLastSuccessfulDownloadDate();
            const lastSuccessfulUploadDate = await uploader.getLastSuccessfulUploadDate();
            const lastTrySuccessStatus = await uploader.getLastTrySuccessStatus();
            return {
                lastSuccessfulDownloadDate, 
                lastSuccessfulUploadDate,
                lastTrySuccessStatus
            }
        }
    );

    return {
        upload,
        refetch,
        isDownloading,
        isUploading,
        lastSuccessfulDownloadDate: syncInfo?.lastSuccessfulDownloadDate,
        lastSuccessfulUploadDate: syncInfo?.lastSuccessfulUploadDate,
        lastTrySuccessStatus: syncInfo?.lastTrySuccessStatus || 'yes'
    }
}
