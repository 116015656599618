import * as React from 'react';
import { FrequencyType, ServiceDef } from '@rivison-inc/ft-types';
import { Field2 } from '../../components/Form2';
import { ListDateField } from '../../components/ListDateField';
import { useEquipmentType, useEquipmentTypes } from '../../data/equipmentTypes';
import { useParams } from 'react-router';
import { useServiceType } from '../../data/serviceTypes';
import { useEquipmentFields } from '../../data/equipmentFields';
import { convertArrayToMap } from '../../utils/structure';
import { ListTextField } from '../../components/ListTextField';
import { useLocation } from '../../data/locations';

interface Props {
  serviceType: ServiceDef;
}

export const PerformedServiceForm2 = (props: Props) => {
  const params = useParams<{ locationId: string; serviceTypeId: string }>();

  let granularity: 'year' | 'month' | 'day' | 'hour' | 'minute' = 'day';
  const frequencyType = props.serviceType?.frequencyType.toString();
  if (frequencyType === FrequencyType.HOUR.toString()) {
    granularity = 'hour';
  } else if (frequencyType === FrequencyType.MINUTE.toString()) {
    granularity = 'minute';
  } else if (frequencyType === FrequencyType.DAY.toString()) {
    granularity = 'day';
  } else if (frequencyType === FrequencyType.MONTH.toString()) {
    granularity = 'month';
  } else if (frequencyType === FrequencyType.YEAR.toString()) {
    granularity = 'year';
  }

  const { serviceType } = useServiceType(params.serviceTypeId);
  const conditions = serviceType?.conditions || []
  const { equipmentFields } = useEquipmentFields({ equipmentTypeId: serviceType?.equipmentTypeId });
  const fields = convertArrayToMap(equipmentFields, { idKey: 'id' });
  const { location } = useLocation(params.locationId);

  if (!location) {
    return null;
  }

  return (
    <>
      
      <Field2 
        as={ListDateField} 
        name="lastDate" 
        label="Last Date" 
        granularity={'minute'}
        timeZone={location.timeZone}
      />
      <Field2 
        as={ListDateField} 
        name="overidedNextDate" 
        label="Next Date (Overide)" 
        granularity={granularity}
        timeZone={location.timeZone}
      />

      {conditions.map(condition => fields[condition.meteredFieldId || '']?.name &&  (
        <Field2 
          key={condition.id}
          as={ListTextField} 
          name={`equipmentFieldData.${condition.meteredFieldId}`}
          label={'Last ' + fields[condition.meteredFieldId || ''].name} 
        />
      ))}

    </>
  )
}
