import styled from 'styled-components/native';
import { Theme } from '../../theme';
import { withDeviceType } from '../../hooks';

interface DeviceTypeProps {
  deviceType: 'mobile' | 'tablet' | 'desktop';
}

export const Container = styled.View`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const InnerContainer = withDeviceType(styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${(props: DeviceTypeProps & { theme: Theme }) => props.deviceType !== 'desktop' ? props.theme.spacing.md : props.theme.spacing.xxl};
`);

export const Title = styled.Text`
  padding-bottom: ${(props: { theme: Theme }) => props.theme.spacing.md};
  font-size: ${(props: { theme: Theme }) => props.theme.typography.textSizes.lg};
  font-weight: bold;
  text-align: center;
`;

export const SubTitle = styled.Text`
  font-size: ${(props) => props.theme.typography.textSizes.sm};
  color: ${(props) => props.theme.palette.fadedText};
  text-align: center;
  line-height: ${(props) => Number(props.theme.typography.textSizes.md.replace('px', '')) * 1.5}px;
`;

export const Image = styled.Image`
  margin-bottom: ${(props: { theme: Theme }) => props.theme.spacing.sm};
`;
