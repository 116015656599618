import styled from 'styled-components/native';
import { withDeviceType } from '../../hooks';
import { SizingCode, Theme } from '../../theme';
import { getBackgroundStyles, getMarginStyles } from '../../utils/style';

interface PaneViewProps {
  backgroundColor?: 'dark' | 'light';
  stretchy?: boolean; 
  grow?: boolean;
  deviceType: 'mobile' | 'tablet' | 'desktop';
  heightCode?: 'content' | 'full';
  width?: number;

  /** margin */
  marginTopCode?: SizingCode;
  marginBottomCode?: SizingCode;
  marginLeftCode?: SizingCode;
  marginRightCode?: SizingCode;
}

interface DeviceTypeProps {
  deviceType: 'mobile' | 'tablet' | 'desktop';
}

export const HeaderView = withDeviceType(styled.SafeAreaView<DeviceTypeProps>`
  min-height: 50px;
  padding-top: 10px; /** This is ignored if the phone as ears (like iPhone X) */
  background-color: ${(props: DeviceTypeProps & { theme: Theme }) => props.deviceType !== 'desktop' ? 'transparent' : props.theme.palette.paneColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-color: ${(props: { theme: Theme }) => props.theme.palette.lineColor};
  border-bottom-width: ${(props: DeviceTypeProps) => props.deviceType !== 'desktop' ? '1px' : '0px'};
`);

export const ScrollView = styled.ScrollView`
  height: auto;/* needs to be set to auto for login screen*/
  /* overflow-y: scroll; */ /** can't set overflow on react-native */
  flex-grow: 1;
`;

export const TitleView = styled.View<{ justifyContent: 'space-between' | 'center' }>`
  padding-top: 4px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props => props.justifyContent)};
  align-items: center;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
`;

export const Tabs = styled.View`
  display: flex;
  flex-direction: row;
  height: 70px;
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  border-top-color: ${(props: { theme: Theme }) => props.theme.palette.lineColor};
  border-top-width: 1px;
`;

interface TabProps {
  selected: boolean;
}
export const Tab = styled.View<TabProps>`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  border-radius: ${(props: { theme: Theme }) => (props.theme.borderRadius / 2)}px;
  background-color: ${(props: TabProps & { theme: Theme }) => props.selected ? props.theme.palette.primary : 'transparent' };
`;

export const SearchView = styled.View`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const SearchInput = styled.TextInput`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  padding-left: ${(props: { theme: Theme }) => props.theme.spacing.xl};
  width: 100%;
  background-color: ${(props: { theme: Theme }) => props.theme.palette.searchBarColor};
  border-radius: 10px;
  font-size: ${(props) => props.theme.typography.textSizes.md};
  height: auto;
  box-shadow:none;
  font-weight: normal;
  border-width: 0px;
  color: black;
  border: 1px solid white;
`;

export const SearchIconContainer = styled.View`
  bottom: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  z-index: 2;
`;

export const PaneView = withDeviceType(styled.View<PaneViewProps>`
  ${getMarginStyles}
  ${getBackgroundStyles}
  width: 100%;
  min-width: ${(props: PaneViewProps) => (props.stretchy || props.deviceType !== 'desktop') ? '100%' : `${props.width || 400}px`};
  max-width: ${(props: PaneViewProps) => (props.stretchy || props.deviceType !== 'desktop') ? '100%' : `${props.width || 400}px`};
  border-radius: ${(params: { theme: Theme }) => params.theme.borderRadius}px;
  overflow: hidden;
  display: flex;
  ${(props) => (!props.heightCode || props.heightCode === 'full') ? 'height: 100%;' : 'height: auto;'}
  flex-grow: ${(props) => props.heightCode === 'content' ? 0 : 1};
  flex-grow: 1;
  max-height: 100%;
`);

export const IconHolder = styled.View`
  padding-bottom: ${(props: { theme: Theme }) => props.theme.spacing.xs};
`;

export const Badge = styled.View`
  background-color: ${(props: { theme: Theme }) => props.theme.palette.snackbar.error};
  border-radius: 100px;
  position: absolute;
  top:0px;
  right:0px;
  color: white;
  font-size: ${(props) => props.theme.typography.textSizes.xxs};
  font-weight: bold;
  min-width: 18px;
  height: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BadgeText = styled.Text`
  color: white;
  font-size: ${(props) => props.theme.typography.textSizes.xxs};
  font-weight: bold;
  text-align: center;
`;

export const SyncBar = withDeviceType(styled.View<DeviceTypeProps>`
  background-color: ${(props) => 'white'};
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 0px;
  ${(props: DeviceTypeProps) => props.deviceType === 'desktop' ? 'pointer-events: none;' : ''};
`);

export const SyncMover = styled.View`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-top: -50px;
`;
