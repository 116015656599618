import moment from 'moment';
import { useEffect, useReducer, useRef } from 'react';
import { useCheckIsMounted } from '.';

export function useTimeSince(time: string|undefined): string {
    const [,forceRender] = useReducer((s: number) => s + 1, 0);
    const intervalRef = useRef<any>(null);
    const checkIsMounted = useCheckIsMounted();

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (checkIsMounted()) {
                forceRender();
            }
        }, 1000);
        
        return () => {
            clearInterval(intervalRef.current);
        }
    }, []);

    if (!time) {
        return '';
    }

    return moment(time).fromNow();
}
