import * as React from 'react';
import { PaneButton } from '../PaneButton';
import { ChooseFileButtonProps } from './types';

export const ChooseFileButton = (props: ChooseFileButtonProps) => {
  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <>
        <input 
          type="file" 
          ref={ref}
          style={{ display: 'none' }}
          onChange={(e) => {
            const file = e.target.files && e.target.files[0];
            if (file) {
              props.onPick(file);
            }
          }} 
        />
        <PaneButton 
          label="Choose File" 
          onPress={() => {
            ref.current?.click();
          }} 
        />
    </>
  )
}
