import * as React from 'react';

/** components / containers */
import { Route as PublicRoute, Switch, Redirect, RouteProps } from './components/Router';
import { Layout } from './containers/Layout';
import { Columns } from './components/Columns';
import { Column } from './components/Column';
import { Nav } from './containers/Nav';

/** hooks */
import { useLoginStatus } from './auth';
import { useDeviceType, useRouteMatch, useQuery, useLocation, useOpenDialogState, useControlDialog } from './hooks';

/** pages */
import { LoginPage } from './pages/LoginPage';
import { LocationListPage } from './pages/LocationListPage';
import { EquipmentListPage } from './pages/EquipmentListPage';
import { ReadOnlyFloorPlanPage } from './pages/ReadOnlyFloorPlanPage';
import { AddLocationPage } from './pages/AddLocationPage';
import { EditLocationPage } from './pages/EditLocationPage';
import { ReportsPage } from './pages/ReportsPage';
import { SchedulePage } from './pages/SchedulePage';
import { SettingsPage } from './pages/SettingsPage';
import { UserListPage } from './pages/UserListPage';
import { EditUserPage } from './pages/EditUserPage';
import { ModelListPage } from './pages/ModelListPage';
import { EditModelPage } from './pages/EditModelPage';
import { AddModelPage } from './pages/AddModelPage';
import { ModelFieldsListPage } from './pages/ModelFieldsListPage';
import { EditModelFieldsPage } from './pages/EditModelFieldsPage';
import { AddModelFieldsPage } from './pages/AddModelFieldsPage';
import { AddEquipmentPage } from './pages/AddEquipmentPage';
import { EditEquipmentPage } from './pages/EditEquipmentPage';
import { OrganizationPage } from './pages/OrganizationPage';
import { PreferencesPage } from './pages/PreferencesPage';
import { AddUserPage } from './pages/AddUserPage';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { QuestionsListPage } from './pages/QuestionsListPage';
import { AddQuestionPage } from './pages/AddQuestionPage';
import { EditServiceTypePage } from './pages/EditServiceTypePage';
import { AddServiceTypePage } from './pages/AddServiceTypePage';
import { ServiceTypeListPage } from './pages/ServiceTypeListPage';
import { EquipmentServiceListPage } from './pages/EquipmentServiceListPage';
import { EditPerformedServicePage } from './pages/EditPerformedServicePage';
import { EquipmentHistoryPage } from './pages/EquipmentHistoryPage';
import { AddHistoryPage } from './pages/AddHistoryPage';
import { EditHistoryPage } from './pages/EditHistoryPage';
import { EquipmentTypeListPage } from './pages/EquipmentTypeListPage';
import { AddEquipmentTypePage } from './pages/AddEquipmentTypePage';
import { EditEquipmentTypePage } from './pages/EditEquipmentTypePage';
import { AddDeficiencyPage } from './pages/AddDeficiencyPage';
import { EditDeficiencyPage } from './pages/EditDeficiencyPage';
import { EquipmentTagPage } from './pages/EquipmentTagPage';
import { AddLocationOverridePage } from './pages/AddLocationOverridePage';
import { LocationOverrideEditPage } from './pages/LocationOverrideEditPage';
import { LocationOverrideListPage } from './pages/LocationOverrideListPage';
import { EquipmentFieldsListPage } from './pages/EquipmentFieldsListPage';
import { AddEquipmentFieldsPage } from './pages/AddEquipmentFieldsPage';
import { EditEquipmentFieldsPage } from './pages/EditEquipmentFieldsPage';
import { AcceptInvitePage } from './pages/AcceptInvitePage';
import { ServiceMonthCategoriesListPage } from './pages/ServiceMonthCategoryListPage';
import { AddServiceMonthCategoryPage } from './pages/AddServiceMonthCategoryPage';
import { EditServiceMonthCategoryPage } from './pages/EditServiceMonthCategoryPage';
import { FloorPlansListPage } from './pages/FloorPlansListPage';
import { AddFloorPlansPage } from './pages/AddFloorPlansPage';
import { EditFloorPlansPage } from './pages/EditFloorPlansPage';
import { EditQuestionPage } from './pages/EditQuestionPage';
import { QuestionsAnswerPage } from './pages/QuestionsAnswerPage';
import { EditLocationFieldsPage } from './pages/EditLocationFieldsPage';
import { LocationFieldsListPage } from './pages/LocationFieldsListPage';
import { AddLocationFieldsPage } from './pages/AddLocationFieldsPage';
import { userTracker, trackingCategory } from './controllers/userTracker';
import { WelcomePage } from './pages/WelcomePage';
import { SiteTypeListPage } from './pages/SiteTypeListPage';
import { AddSiteTypePage } from './pages/AddSiteTypePage';
import { EditSiteTypePage } from './pages/EditSiteTypePage';
import { ProfilePage } from './pages/ProfilePage';
import { Dialog } from './containers/Dialog';
import { ListButton } from './components/ListButton';
import { UnsavedChangesDialog } from './containers/UnsavedChangesDialog';
import { ImportsPage } from './pages/ImportsPage';
import { AddCustomerPage } from './pages/AddCustomerPage';
import { CustomerListPage } from './pages/CustomerListPage';
import { EditCustomerPage } from './pages/EditCustomerPage';
import { CustomerSelectPage } from './pages/CustomerSelectPage';
import { useSync } from './data/syncHooks';
import { useNetworkStatus } from './hooks/useNetworkStatus';

function Route({ children, ...rest }: RouteProps) {
  const isAuthenticated = useLoginStatus() === "authenticated";

  return (
    <PublicRoute
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              search:`from=${encodeURIComponent(location.pathname)}`
            }}
          />
        )
      }
    />
  );
}

function ShowOn(props: { children: React.ReactNode; minDeviceSize?: 'mobile' | 'tablet' | 'desktop'; maxDeviceSize?: 'mobile' | 'tablet' | 'desktop' }) {
  const deviceSize = useDeviceType();
  const deviceSizes = ['mobile', 'tablet', 'desktop'];

  if (props.minDeviceSize &&  deviceSizes.indexOf(deviceSize) < deviceSizes.indexOf(props.minDeviceSize)) {
    return null;
  }

  if (props.maxDeviceSize &&  deviceSizes.indexOf(deviceSize) > deviceSizes.indexOf(props.maxDeviceSize)) {
    return null;
  }

  return (
    <>
      {props.children}
    </>
  );
}

export function Routes() {
  const isAuthenticated = useLoginStatus() === "authenticated";
  const onLoginPage = Boolean(useRouteMatch({ path: ['/', '/login', '/sign-up'], exact: true }))
  const showTagPage = Boolean(useRouteMatch({ path: ['/sites/:locationId/equipment-tag/:equipmentId','/sites/:locationId/equipment-tag/:equipmentId/read-only'] }))
  const onTagPage = Boolean(useRouteMatch({ path: ['/sites/:locationId/equipment-tag/:equipmentId'] }))
  const readOnlyTag = Boolean(useRouteMatch({ path: ['/sites/:locationId/equipment-tag/:equipmentId/read-only'] }))
  const dialogState = useOpenDialogState();
  const { closeDialog } = useControlDialog();
  const { refetch, upload, lastTrySuccessStatus } = useSync();
  const networkStatus = useNetworkStatus();


  const query = useQuery();
  const location = useLocation();

  React.useEffect(() => {
    userTracker.trackPage(location.pathname);
    if (lastTrySuccessStatus === 'no') {
      upload();
    }
    refetch();
  }, [location, networkStatus]);

  return (
    <>
      <Dialog 
        name={'tooltip'}
        title={dialogState.title}
        subtitle={dialogState.subtitle}
      >
        {dialogState.content && dialogState.content}
        <ListButton 
          label="Ok"
          onPress={closeDialog}
        />
      </Dialog>

      <UnsavedChangesDialog />

      <Layout 
        nav={<Nav />}
        isAuthenticated={isAuthenticated}
        fullScreenOnMobile={!onLoginPage}
      >
        <Switch>
          <Route path="/profile" exact>
            <ProfilePage />
          </Route>
          <Route path="/settings" exact>
            <SettingsPage />
          </Route>
          <Route path="/users" exact>
            <UserListPage />
          </Route>
          <Route path="/users/new" exact>
            <AddUserPage />
          </Route>
          <Route path="/users/:userId" exact>
            <EditUserPage />
          </Route>
          <Route path="/organization" exact>
            <OrganizationPage />
          </Route>
          <Route path="/preferences" exact>
            <PreferencesPage />
          </Route>
          <Route path="/schedule" exact>
            <SchedulePage />
          </Route>
          <Route path="/sites" exact>
            <LocationListPage />
          </Route>
          <Route path="/sites/new" exact>
            <AddLocationPage />
          </Route>
          <Route path="/customers/:customerId/sites/new" exact>
            <AddLocationPage />
          </Route>
          <Route path="/customers/new" exact>
            <AddCustomerPage />
          </Route>
          <Route path="/customers" exact>
            <CustomerListPage />
          </Route>
          <Route path="/sites/:locationId/customer-select" exact>
            <CustomerSelectPage />
          </Route>
          <Route path="/customers/:customerId" exact>
            <Columns height="full" space="md">
              <Column height="full">
                <EditCustomerPage />
              </Column>
              <Column height="full">
                <LocationListPage />
              </Column>
            </Columns>
          </Route>
          <Route path="/reports" exact>
            <ReportsPage type="global" />
          </Route>
          <Route path={['/sites/:locationId','/sites/:locationId/equipment-tag/:equipmentId', '/sites/:locationId/equipment-tag/:equipmentId/read-only']} exact>
            <ShowOn minDeviceSize="desktop">
              <Columns height="full" space="md">
                <Column height="full">
                  <EditLocationPage />
                </Column>
                <Column height="full">
                  <EquipmentListPage />
                </Column>
                <Column height="full">
                  <ReportsPage type="location" />
                </Column>
              </Columns>
            </ShowOn>
            <ShowOn maxDeviceSize="tablet">
              <EquipmentListPage showBackButton />
              <EquipmentTagPage open={showTagPage} readonly={onTagPage && readOnlyTag} />
            </ShowOn>
          </Route>
          <Route path="/welcome" exact>
            <WelcomePage />
          </Route>
          <Route path="/sites/:locationId/equipment/:equipmentId/ReadOnlyFloorPlan" exact>
            <ReadOnlyFloorPlanPage />
          </Route>
          <Route path="/sites/:locationId/equipment/new" exact>
            <AddEquipmentPage />
          </Route>
          <Route path="/sites/:locationId/equipment/:equipmentId" exact>
            <ShowOn minDeviceSize="desktop">
              <Columns height="full" space="md">
                <Column height="full">
                  <EditEquipmentPage />
                </Column>
                <Column height="full">
                  <EquipmentServiceListPage />
                </Column>
                <Column height="full">
                  <EquipmentHistoryPage />
                </Column>
              </Columns>
            </ShowOn>
            <ShowOn maxDeviceSize="tablet">
              <EditEquipmentPage />
            </ShowOn>
          </Route>
          <Route path="/sites/:locationId/equipment/:equipmentId/answer-questions/:serviceTypeId/:historyId" exact>
            <QuestionsAnswerPage />
          </Route>
          <Route path="/sites/:locationId/equipment/:equipmentId/performed-service/:serviceTypeId" exact>
            <EditPerformedServicePage />
          </Route>
          <Route path="/sites/:locationId/equipment/:equipmentId/history/new" exact>
            <AddHistoryPage />
          </Route>
          <Route path="/sites/:locationId/equipment/:equipmentId/history/:historyId" exact>
            <EditHistoryPage />
          </Route>
          <Route path="/sites/:locationId/equipment/:equipmentId/deficiency/new" exact>
            <AddDeficiencyPage />
          </Route>
          <Route path="/sites/:locationId/equipment/:equipmentId/deficiency/:deficiencyId" exact>
            <EditDeficiencyPage />
          </Route>
          <Route path="/service-month-categories" exact>
            <ServiceMonthCategoriesListPage />
          </Route>
          <Route path="/service-month-categories/new" exact>
            <AddServiceMonthCategoryPage />
          </Route>
          <Route path="/service-month-categories/:serviceMonthCategoryId" exact>
            <EditServiceMonthCategoryPage />
          </Route>
          <Route path="/site-types" exact>
            <SiteTypeListPage />
          </Route>
          <Route path="/site-types/new" exact>
            <AddSiteTypePage />
          </Route>
          <Route path="/site-types/:siteTypeId" exact>
            <EditSiteTypePage />
          </Route>
          <Route path="/sites/:locationId/settings/location-overrides" exact>
            <LocationOverrideListPage />
          </Route>
          <Route path="/sites/:locationId/settings/location-overrides/new" exact>
            <AddLocationOverridePage />
          </Route>
          <Route path="/sites/:locationId/settings/location-overrides/:overrideId" exact>
            <LocationOverrideEditPage />
          </Route>
          <Route path="/sites/:locationId/settings/floor-plans" exact>
            <FloorPlansListPage />
          </Route>
          <Route path="/sites/:locationId/settings/floor-plans/new" exact>
            <AddFloorPlansPage />
          </Route>
          <Route path="/sites/:locationId/settings/floor-plans/:floorPlanId" exact>
            <EditFloorPlansPage />
          </Route>
          <Route path="/custom-fields" exact>
            <Columns height="full" space="md">
              <Column height="full">
                <LocationFieldsListPage />
              </Column>
              <Column height="full">
                <ModelFieldsListPage />
              </Column>
              <Column height="full">
                <EquipmentFieldsListPage />
              </Column>
            </Columns>
          </Route>
          <Route path="/custom-fields/model/new" exact>
            <AddModelFieldsPage />
          </Route>
          <Route path="/custom-fields/model/:modelFieldId" exact>
            <EditModelFieldsPage />
          </Route>
          <Route path="/custom-fields/location/new" exact>
            <AddLocationFieldsPage />
          </Route>
          <Route path="/custom-fields/location/:locationFieldId" exact>
            <EditLocationFieldsPage />
          </Route>
          <Route path="/custom-fields/equipment/new" exact>
            <AddEquipmentFieldsPage />
          </Route>
          <Route path="/custom-fields/equipment/:equipmentFieldId" exact>
            <EditEquipmentFieldsPage />
          </Route>
          <Route path="/equipment-types/:equipmentTypeId/service-types/new" exact>
            <AddServiceTypePage />
          </Route>
          <Route path="/equipment-types/:equipmentTypeId/service-types/:serviceTypeId/questions/new" exact>
            <AddQuestionPage />
          </Route>
          <Route path="/equipment-types/:equipmentTypeId/service-types/:serviceTypeId/questions/:questionId" exact>
            <EditQuestionPage />
          </Route>
          <Route path="/equipment-types/:equipmentTypeId/service-types/:serviceTypeId" exact>
            <Columns height="full" space="md">
              <Column height="full">
                <EditServiceTypePage />
              </Column>
              <Column height="full">
                <QuestionsListPage />
              </Column>
            </Columns>
          </Route>
          <Route path="/equipment-types" exact>
            <EquipmentTypeListPage />
          </Route>
          <Route path="/equipment-types/new" exact>
            <AddEquipmentTypePage />
          </Route>
          <Route path="/equipment-types/:equipmentTypeId" exact>
            <Columns height="full" space="md">
              <Column height="full">
                <EditEquipmentTypePage />
              </Column>
              <Column height="full">
                <ServiceTypeListPage />
              </Column>
              <Column height="full">
                <ModelListPage />
              </Column>
            </Columns>
          </Route>
          <Route path="/equipment-types/:equipmentTypeId/models/new" exact>
            <AddModelPage />
          </Route>
          <Route path="/equipment-types/:equipmentTypeId/models/:modelId" exact>
            <EditModelPage />
          </Route>
          <Route path="/imports" exact>
            <ImportsPage />
          </Route>
          <PublicRoute path={['/', '/login']} exact>
            {isAuthenticated && <Redirect to={query.from || '/sites'} />}
            <LoginPage />
          </PublicRoute>
          <PublicRoute path={['/sign-up']} exact>
            {isAuthenticated && <Redirect to={query.from || '/sites'} />}
            <LoginPage />
          </PublicRoute>
          <PublicRoute path="/accept-invite" exact>
            <AcceptInvitePage />
          </PublicRoute>
          <PublicRoute path="/change-password" exact>
            <ChangePasswordPage />
          </PublicRoute>
        </Switch>
      </Layout>
    </>
  )
}
