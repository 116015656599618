const defaultTranslations = {
  '(From Now)': '(From Now)',
  '(From Scheduled)': '(From Scheduled)',
  '{amount} Off. Expires: {expires}.': '{amount} Off. Expires: {expires}.',
  'A detailed report showing each activity and how long a technician or staff member took between and at each site with a total time at the site.': 'A detailed report showing each activity and how long a technician or staff member took between and at each site with a total time at the site.',
  'A service type with inspection enabled will show up in the "to inspect" list on mobile. It is also used in reporting.': 'A service type with inspection enabled will show up in the "to inspect" list on mobile. It is also used in reporting.',
  'Accept Invite': 'Accept Invite',
  'Add Condition': 'Add Condition',
  'Add deficiencies to the equipment type': 'Add deficiencies to the equipment type',
  'Add Deficiency': 'Add Deficiency',
  'Add Equipment Field': 'Add Equipment Field',
  'Add Equipment Type': 'Add Equipment Type',
  'Add Equipment': 'Add Equipment',
  'Add Floor Plan': 'Add Floor Plan',
  'Add history by clicking the plus in the top right or by performing a service.': 'Add history by clicking the plus in the top right or by performing a service.',
  'Add History': 'Add History',
  'Add Model Field': 'Add Model Field',
  'Add Model': 'Add Model',
  'Add Option': 'Add Option',
  'Add Question': 'Add Question',
  'Add questions using the plus icon in the top right': 'Add questions using the plus icon in the top right',
  'Add service month categories in settings > service month categories. Service months allow you to keep different types of equipment in selected months for each location. Example: Alarms in May and October and Extinguishers in December.': 'Add service month categories in settings > service month categories. Service months allow you to keep different types of equipment in selected months for each location. Example: Alarms in May and October and Extinguishers in December.',
  'Add Service Month Category': 'Add Service Month Category',
  'Add Service Type': 'Add Service Type',
  'Add service types in the settings': 'Add service types in the settings',
  'Add service types using the plus icon in the top right': 'Add service types using the plus icon in the top right',
  'Add services to the equipment type': 'Add services to the equipment type',
  'Add site Field': 'Add site Field',
  'Add Site Override': 'Add Site Override',
  'Add Site Type': 'Add Site Type',
  'Add Site': 'Add Site',
  'Add Sites': 'Add Sites',
  'Add User': 'Add User',
  'Add your own fields in Settings > Custom Fields > Site Fields': 'Add your own fields in Settings > Custom Fields > Site Fields',
  'Add': 'Add',
  'Adding': 'Adding',
  'Address': 'Address',
  'Advance from Now': 'Advance from Now',
  'Advance from Scheduled': 'Advance from Scheduled',
  'Agree': 'Agree',
  'All Sites Scheduled!': 'All Sites Scheduled!',
  'All': 'All',
  'Already have an account? Login': 'Already have an account? Login',
  'An error happened while syncing': 'An error happened while syncing',
  'April': 'April',
  'August': 'August',
  'Back': 'Back',
  'Billing Portal': 'Billing Portal',
  'Building Reports': 'Building Reports',
  'Cancel Plan': 'Cancel Plan',
  'Cancel Subscription': 'Cancel Subscription',
  'Cancel': 'Cancel',
  'Change Model Fields': 'Change Model Fields',
  'Change Password': 'Change Password',
  'Checklist Information': 'Checklist Information',
  'Checklist Question': 'Checklist Question',
  'Checklist': 'Checklist',
  'Choose File': 'Choose File',
  'City': 'City',
  'Clear All Data': 'Clear All Data',
  'Clear': 'Clear',
  'Click or tap to add location': 'Click or tap to add location',
  'Close': 'Close',
  'Company-Wide Reports': 'Company-Wide Reports',
  'Conditions': 'Conditions',
  'Contact': 'Contact',
  'Copy Barcode': 'Copy Barcode',
  'Could not access': 'Could not access',
  'Could not find equipment with the scanned barcode': 'Could not find equipment with the scanned barcode',
  'Could Not Service': 'Could Not Service',
  'Create': 'Create',
  'Creating Your Account': 'Creating Your Account',
  'Credentials are not valid': 'Credentials are not valid',
  'CSV': 'CSV',
  'Custom Equipment Fields': 'Custom Equipment Fields',
  'Custom Fields': 'Custom Fields',
  'Custom Model Fields': 'Custom Model Fields',
  'Custom site Fields': 'Custom site Fields',
  'Date': 'Date',
  'Dates are displayed in {timeZone} time': 'Dates are displayed in {timeZone} time',
  'Debug': 'Debug',
  'December': 'December',
  'Deficiencies': 'Deficiencies',
  'Deficiency Report': 'Deficiency Report',
  'Deficiency': 'Deficiency',
  'Delete Equipment Field?': 'Delete Equipment Field?',
  'Delete Equipment Type?': 'Delete Equipment Type?',
  'Delete Equipment Type': 'Delete Equipment Type',
  'Delete Equipment?': 'Delete Equipment?',
  'Delete Equipment': 'Delete Equipment',
  'Delete Floor Plan?': 'Delete Floor Plan?',
  'Delete Model Field?': 'Delete Model Field?',
  'Delete Model?': 'Delete Model?',
  'Delete Model': 'Delete Model',
  'Delete Override': 'Delete Override',
  'Delete Question?': 'Delete Question?',
  'Delete Question': 'Delete Question',
  'Delete Record?': 'Delete Record?',
  'Delete Service Month?': 'Delete Service Month?',
  'Delete Service Type?': 'Delete Service Type?',
  'Delete Site Field?': 'Delete Site Field?',
  'Delete Site Type?': 'Delete Site Type?',
  'Delete Site?': 'Delete Site?',
  'Delete Site': 'Delete Site',
  'Delete User?': 'Delete User?',
  'Delete User': 'Delete User',
  'Delete': 'Delete',
  'Deleted Equipment Field': 'Deleted Equipment Field',
  'Deleted Equipment': 'Deleted Equipment',
  'Deleted Floor Plan': 'Deleted Floor Plan',
  'Deleted Model Field': 'Deleted Model Field',
  'Deleted Record': 'Deleted Record',
  'Deleted Service Month': 'Deleted Service Month',
  'Deleted Service Type': 'Deleted Service Type',
  'Deleted Site Field': 'Deleted Site Field',
  'Deleted Site Type': 'Deleted Site Type',
  'Detailed': 'Detailed',
  'Did not want done': 'Did not want done',
  'Done by others': 'Done by others',
  'Don\'t have an account? Sign Up': 'Don\'t have an account? Sign Up',
  'Done': 'Done',
  'Edit Dates': 'Edit Dates',
  'Edit Equipment': 'Edit Equipment',
  'Edit': 'Edit',
  'Email is not valid': 'Email is not valid',
  'End Date': 'End Date',
  'Enter your email address': 'Enter your email address',
  'Equipment already has this barcode': 'Equipment already has this barcode',
  'Equipment can be anything from a fire extinguisher to a treadmill. Set up equipment types in settings > equipment types.': 'Equipment can be anything from a fire extinguisher to a treadmill. Set up equipment types in settings > equipment types.s',
  'Equipment Deleted': 'Equipment Deleted',
  'Equipment Due Consolidated Report': 'Equipment Due Consolidated Report',
  'Equipment Due Report': 'Equipment Due Report',
  'Equipment Field Information': 'Equipment Field Information',
  'Equipment fields allow you to add different additional fields on the equipment type, such as "Voltage" or "Km".': 'Equipment fields allow you to add different additional fields on the equipment type, such as "Voltage" or "Km".',
  'Equipment Fields': 'Equipment Fields',
  'Equipment for a site with all scheduled services.': 'Equipment for a site with all scheduled services.',
  'Equipment Options': 'Equipment Options',
  'Equipment Report': 'Equipment Report',
  'Equipment Service Report': 'Equipment Service Report',
  'Equipment SKU': 'Equipment SKU',
  'Equipment Type Deleted': 'Equipment Type Deleted',
  'Equipment Type': 'Equipment Type',
  'Equipment types quickly add inspection and service definitions to equipment when a model containing it is selected. Examples: Fire Extinguisher, Treadmill, Truck': 'Equipment types quickly add inspection and service definitions to equipment when a model containing it is selected. Examples: Fire Extinguisher, Treadmill, Truck',
  'Equipment Types': 'Equipment Types',
  'Equipment': 'Equipment',
  'Export Billing Data': 'Export Billing Data',
  'Export Data': 'Export Data',
  'Export': 'Export',
  'Failed to load organization.': 'Failed to load organization.',
  'Failed to save the organization.': 'Failed to save the organization.',
  'February': 'February',
  'Fill out the options below to prepare your report.': 'Fill out the options below to prepare your report.',
  'Filter By': 'Filter By',
  'FlameTask Basic Subscription': 'FlameTask Basic Subscription',
  'FlameTask Enterprise Subscription': 'FlameTask Enterprise Subscription',
  'FlameTask Pro Subscription': 'FlameTask Pro Subscription',
  'Floor Plan Location': 'Floor Plan Location',
  'Floor Plan': 'Floor Plan',
  'Floor Plans': 'Floor Plans',
  'Force Error': 'Force Error',
  'Forgot Password': 'Forgot Password',
  'Generate': 'Generate',
  'Hide "added" history records': 'Hide "added" history records',
  'Hide Advanced': 'Hide Advanced',
  'History': 'History',
  'How long a user spent at a site with time between actions.': 'How long a user spent at a site with time between actions.',
  'If this email matched an account, a link was sent to reset your password.': 'If this email matched an account, a link was sent to reset your password.',
  'Import Data': 'Import Data',
  'Import': 'Import',
  'Inspection Due': 'Inspection Due',
  'Inspection Report': 'Inspection Report',
  'Inspection': 'Inspection',
  'Inspections are up to date': 'Inspections are up to date',
  'January': 'January',
  'July': 'July',
  'June': 'June',
  'Leave': 'Leave',
  'List of all equipment at the site with options to show services and filtering based on due service and/or inspection.': 'List of all equipment at the site with options to show services and filtering based on due service and/or inspection.',
  'List of all sites with their information. Options to filter by status and service month to find which sites are serviced in a specific month.': 'List of all sites with their information. Options to filter by status and service month to find which sites are serviced in a specific month.',
  'List of equipment and deficiencies at the site, filtered by equipment with deficiencies that have a date opened and no closed date.': 'List of equipment and deficiencies at the site, filtered by equipment with deficiencies that have a date opened and no closed date.',
  'List of equipment between a range of dates with answers to the questions asked when performing a service or inspection.': 'List of equipment between a range of dates with answers to the questions asked when performing a service or inspection.',
  'List of Equipment for a site with important information such as last inspection date and next due service, highlighting equipment due for inspection in red.': 'List of Equipment for a site with important information such as last inspection date and next due service, highlighting equipment due for inspection in red.',
  'List of equipment that fits model search criteria. Example: a list of 5 pieces of equipment with model information matching 6LB ABC DRY.': 'List of equipment that fits model search criteria. Example: a list of 5 pieces of equipment with model information matching 6LB ABC DRY.',
  'Loading Equipment': 'Loading Equipment',
  'Loading Models...': 'Loading Models...',
  'Loading Scheduled Services...': 'Loading Scheduled Services...',
  'Loading Scheduled Sites': 'Loading Scheduled Sites',
  'Loading Service Types...': 'Loading Service Types...',
  'Loading Sites': 'Loading Sites',
  'Loading Unscheduled Services...': 'Loading Unscheduled Services...',
  'Loading...': 'Loading...',
  'Location': 'Location',
  'Logging in...': 'Logging in...',
  'Login Failed': 'Login Failed',
  'Login': 'Login',
  'Logout': 'Logout',
  'March': 'March',
  'May': 'May',
  'Meter Based Condition':  'Meter Based Condition',
  'Model Deleted': 'Model Deleted',
  'Model Field Information': 'Model Field Information',
  'Model fields allow you to describe and report on equipment such as "Size", "Type" and "Category".': 'Model fields allow you to describe and report on equipment such as "Size", "Type" and "Category".',
  'Model Fields': 'Model Fields',
  'Model Information': 'Model Information',
  'Model Name': 'Model Name',
  'Models allow quickly adding information to equipment such as which equipment type/services belong to it.': 'Models allow quickly adding information to equipment such as which equipment type/services belong to it.',
  'Models': 'Models',
  'Months of History (to start of month)': 'Months of History (to start of month)',
  'More Details': 'More Details',
  'Name': 'Name',
  'Nevermind': 'Nevermind',
  'No Custom Equipment Fields': 'No Custom Equipment Fields',
  'No Deficiencies': 'No Deficiencies',
  'No Equipment Fields': 'No Equipment Fields',
  'No Equipment Types': 'No Equipment Types',
  'No Equipment': 'No Equipment',
  'No Floor Plans': 'No Floor Plans',
  'No History': 'No History',
  'No Model Fields': 'No Model Fields',
  'No Models': 'No Models',
  'No Open Deficiencies': 'No Open Deficiencies',
  'No Open or Closed Issues': 'No Open or Closed Issues',
  'No overrides for this Site': 'No overrides for this Site',
  'No Questions': 'No Questions',
  'No Scheduled Services': 'No Scheduled Services',
  'No Scheduled Sites': 'No Scheduled Sites',
  'No Service Month Categories': 'No Service Month Categories',
  'No Service Types': 'No Service Types',
  'No Site Fields': 'No Site Fields',
  'No Site Types': 'No Site Types',
  'No Sites': 'No Sites',
  'No Unscheduled Services': 'No Unscheduled Services',
  'No, Go Back': 'No, Go Back',
  'No': 'No',
  'Not in at the time of inspection': 'Not in at the time of inspection',
  'Nothing to Inspect': 'Nothing to Inspect',
  'Nothing to Service': 'Nothing to Service',
  'November': 'November',
  'October': 'October',
  'Ok': 'Ok',
  'Open a deficiency by clicking the plus in the top right of the pane. When the issue has been resolved, click on it and set the closed date.': 'Open a deficiency by clicking the plus in the top right of the pane. When the issue has been resolved, click on it and set the closed date.',
  'Open a deficiency by tapping Options > Add Deficiency': 'Open a deficiency by tapping Options > Add Deficiency',
  'Open Deficiencies': 'Open Deficiencies',
  'Open Deficiency': 'Open Deficiency',
  'Open in Apple Maps': 'Open in Apple Maps',
  'Open in Google Maps': 'Open in Google Maps',
  'Open Settings': 'Open Settings',
  'Open': 'Open',
  'Optional: Add a deficiency if this option is selected.': 'Optional: Add a deficiency if this option is selected.',
  'Optional: This can be useful to set meter values easily': 'Optional: This can be useful to set meter values easily',
  'Organization': 'Organization',
  'Outstanding Equipment': 'Outstanding Equipment',
  'PDF': 'PDF',
  'Perform From Now': 'Perform From Now',
  'Perform From Scheduled': 'Perform From Scheduled',
  'Perform Service From Scheduled': 'Perform Service From Scheduled',
  'Perform Service': 'Perform Service',
  'Perform': 'Perform',
  'Phone Number': 'Phone Number',
  'Please fill out all fields': 'Please fill out all fields',
  'Please give a reason': 'Please give a reason',
  'Please select country first': 'Please select country first',
  'Popup Note': 'Popup Note',
  'Preferences': 'Preferences',
  'Preview Details': 'Preview Details',
  'Profile': 'Profile',
  'Purchase Barcodes': 'Purchase Barcodes',
  'Question Deleted': 'Question Deleted',
  'Question Information': 'Question Information',
  'Questionnaire Report': 'Questionnaire Report',
  'Questions': 'Questions',
  'Range End': 'Range End',
  'Range Start': 'Range Start',
  'Reactivate': 'Reactivate',
  'Remove': 'Remove',
  'Replace Barcode': 'Replace Barcode',
  'Replace Equipment': 'Replace Equipment',
  'Replaced equipment': 'Replaced equipment',
  'Report Date': 'Report Date',
  'Reports': 'Reports',
  'Save time and minimize risk by plotting the location of equipment on a floor plan of this site': 'Save time and minimize risk by plotting the location of equipment on a floor plan of this site',
  'Save': 'Save',
  'Saving': 'Saving',
  'Scan barcode to make changes': 'Scan barcode to make changes',
  'Scan Barcode': 'Scan Barcode',
  'Scan': 'Scan',
  'Schedule': 'Schedule',
  'Scheduled Event': 'Scheduled Event',
  'Scheduled service definitions can be added to the equipment type in settings > equipment types': 'Scheduled service definitions can be added to the equipment type in settings > equipment types',
  'Scheduled': 'Scheduled',
  'See More': 'See More',
  'Selecting an option will add a history record and remove it from the todo list': 'Selecting an option will add a history record and remove it from the todo list',
  'Selective Equipment Report': 'Selective Equipment Report',
  'September': 'September',
  'Service Due': 'Service Due',
  'Service month categories allow you to keep different types of equipment in different months. For example, you could inspect fire extinguishers in August and May and alarms in October.': 'Service month categories allow you to keep different types of equipment in different months. For example, you could inspect fire extinguishers in August and May and alarms in October.',
  'Service Month Categories': 'Service Month Categories',
  'Service Month Category': 'Service Month Category',
  'Service Month': 'Service Month',
  'Service months allow you to keep different types of equipment in selected months for each site. Example: Alarms in May and October and Extinguishers in December.': 'Service months allow you to keep different types of equipment in selected months for each site. Example: Alarms in May and October and Extinguishers in December.',
  'Service Months': 'Service Months',
  'Service overrides allow you to disable a service or change the frequency for a specific site': 'Service overrides allow you to disable a service or change the frequency for a specific site',
  'Service Type Settings': 'Service Type Settings',
  'Service Type': 'Service Type',
  'Service Types': 'Service Types',
  'Service': 'Service',
  'Services are up to date': 'Services are up to date',
  'Services': 'Services',
  'Set answer to equipment field': 'Set answer to equipment field',
  'Settings': 'Settings',
  'Show Advanced': 'Show Advanced',
  'Show History Time': 'Show History Time',
  'Show Questions / Answers': 'Show Questions / Answers',
  'Show Services': 'Show Services',
  'Sign Up': 'Sign Up',
  'Simulate Scan': 'Simulate Scan',
  'Site Deleted': 'Site Deleted',
  'Site Field Information': 'Site Field Information',
  'Site fields will allow you to enter additional information into the site, such as "Parking Location".': 'Site fields will allow you to enter additional information into the site, such as "Parking Location".',
  'Site Fields': 'Site Fields',
  'Site Information': 'Site Information',
  'Site Report': 'Site Report',
  'Site Reports': 'Site Reports',
  'Site Service Override': 'Site Service Override',
  'Site Service Overrides': 'Site Service Overrides',
  'Site Service Settings': 'Site Service Settings',
  'Site Settings': 'Site Settings',
  'Site Type': 'Site Type',
  'Site types allow you to change service types based on the site. For example, only monthly customers would show the monthly inspection.': 'Site types allow you to change service types based on the site. For example, only monthly customers would show the monthly inspection.',
  'Site types allow you to have different service types at different types of sites. For example, you may have to do monthly inspections at one site and only an annual inspection at another.': 'Site types allow you to have different service types at different types of sites. For example, you may have to do monthly inspections at one site and only an annual inspection at another.',
  'Site Types': 'Site Types',
  'Sites can help you organize equipment into places such as "Office" or "Warehouse". If you run a servicing company, you can add your clients as sites. ': 'Sites can help you organize equipment into places such as "Office" or "Warehouse". If you run a servicing company, you can add your clients as sites. ',
  'Sites': 'Sites',
  'Start Date': 'Start Date',
  'Start Trial': 'Start Trial',
  'Status': 'Status',
  'Summary of services at a site separated by model information. Example 5 Monthly Inspections with model information 6LB DRY ABC.': 'Summary of services at a site separated by model information. Example 5 Monthly Inspections with model information 6LB DRY ABC.',
  'Swap Equipment': 'Swap Equipment',
  'Swapped equipment': 'Swapped equipment',
  'TEST': 'TEST',
  'The EMS plan is a discounted plan for non-profit or publicly funded emergency services.  Please choose another plan if you do not match this criteria': 'The EMS plan is a discounted plan for non-profit or publicly funded healthcare services.  Please choose another plan if you do not match this criteria',
  'The popup note is used to remind you of important information regarding the location. It will appear when opening the location screen.': 'The popup note is used to remind you of important information regarding the location. It will appear when opening the location screen.',
  'There are no unscheduled sites with outstanding inspections, service or deficiencies.': 'There are no unscheduled sites with outstanding inspections, service or deficiencies.',
  'There is no equipment at this site with this search criteria and due inspections': 'There is no equipment at this site with this search criteria and due inspections',
  'There is no equipment at this site with this search criteria and due service': 'There is no equipment at this site with this search criteria and due service',
  'This Month': 'This Month',
  'Time at Site Detailed': 'Time at Site Detailed',
  'Time at Site': 'Time at Site',
  'Time Based Condition': 'Time Based Condition',
  'Today': 'Today',
  'Transfer Equipment': 'Transfer Equipment',
  'Unscheduled service definitions can be added to the equipment type in settings > equipment types': 'Unscheduled service definitions can be added to the equipment type in settings > equipment types',
  'Unscheduled': 'Unscheduled',
  'Updated barcode': 'Updated barcode',
  'Used Equipment SKU': 'Used Equipment SKU',
  'Used for the billing export for new, swapped and replaced equipment.': 'Used for the billing export for new, swapped and replaced equipment.',
  'User Deleted': 'User Deleted',
  'User Information': 'User Information',
  'User': 'User',
  'Users': 'Users',
  'View on FloorPlan': 'View on FloorPlan',
  'View Terms': 'View Terms',
  'Whenever equipment is swapped the billing export will increment the quantitiy under this SKU.': 'Whenever equipment is swapped the billing export will increment the quantitiy under this SKU.',
  'Yes, Delete Equipment Field': 'Yes, Delete Equipment Field',
  'Yes, Delete Equipment Type': 'Yes, Delete Equipment Type',
  'Yes, Delete Equipment': 'Yes, Delete Equipment',
  'Yes, Delete Floor Plan': 'Yes, Delete Floor Plan',
  'Yes, Delete Model Field': 'Yes, Delete Model Field',
  'Yes, Delete Model': 'Yes, Delete Model',
  'Yes, Delete Question': 'Yes, Delete Question',
  'Yes, Delete Record': 'Yes, Delete Record',
  'Yes, Delete Service Month': 'Yes, Delete Service Month',
  'Yes, Delete Service Type': 'Yes, Delete Service Type',
  'Yes, Delete Site Field': 'Yes, Delete Site Field',
  'Yes, Delete Site Type': 'Yes, Delete Site Type',
  'Yes, Delete Site': 'Yes, Delete Site',
  'Yes, Delete User': 'Yes, Delete User',
  'Zone': 'Zone',
  'Customer': 'Customer',
  'Site': 'Site',
  'All Sites': 'All Sites',
  'Loading Customers': 'Loading Customers',
  'No Customers': 'No Customers',
  'Add Customer': 'Add Customer',
  'Customers are groups of sites with billing information.': 'Customers are groups of sites with billing information.',
  'Customers': 'Customers',
  "Customer Information": "Customer Information",
  "Delete Customer": "Delete Customer",
  "Yes, Delete Customer": "Yes, Delete Customer",
  "Delete Customer?": "Delete Customer?",
  "Customer Deleted": "Customer Deleted",
  "Create Customer": "Create Customer",
  "Assign Existing Customer": "Assign Existing Customer",
  "Edit Customer": "Edit Customer",
  'Enter a serial number': 'Enter a serial number',
  "Remove Customer": "Remove Customer",
  'Find': 'Find',
  'Sync Now': 'Sync Now',
  'Syncing...': 'Syncing...',
  'Find equipment by serial number': 'Find equipment by serial number',
  'Last successful upload was {formattedDate}': 'Last successful upload was {formattedDate}',
  'Up to date as of {formattedDate}': 'Up to date as of {formattedDate}',
  'Zones are used to group locations in the location report': 'Zones are used to group locations in the location report',
}

export type Message = keyof typeof defaultTranslations;
export type TranslationSet = { [key in Message]: string };

export const en: TranslationSet = defaultTranslations;
