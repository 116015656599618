import moment from "moment";
import { api } from "../api";
import { Storage } from "../storage";
import { ChangeFinder } from "./changeFinder";
import { Locks } from "../utils/locks";

interface Definition {
  apiKey: string;
  changeFinder: InstanceType<typeof ChangeFinder>;
}

export class Uploader {

  private endpoint: string = '';
  private definitions: Definition[] = []

  public setEndpoint(endpoint: string) {
    this.endpoint = endpoint;
  }

  public addDefinition(definition: Definition) {
    this.definitions.push(definition)
  }

  public async getLastSuccessfulUploadDate(): Promise<string> {
    const lastSuccessfulSyncDate = await Storage.getItem('last-successful-upload-date');
    return lastSuccessfulSyncDate
  }

  private async updateLastSuccessfulUploadDate(now: string): Promise<void> {
    await Storage.setItem('last-successful-upload-date', now);
  }

  private async updateLastTrySuccessStatus(wasSuccessful: 'yes' | 'no'): Promise<void> {
    await Storage.setItem('last-successful-upload-was-success', wasSuccessful);
  }

  public async getLastTrySuccessStatus(): Promise<'yes' | 'no'> {
    const wasSuccessful = await Storage.getItem('last-successful-upload-was-success');
    return wasSuccessful || 'yes'
  }

  private async removeRecentlyUpdatedKeys(type: string, lastSuccessfulUploadDate: string) {
    await Locks.getLockBeforeRunning('previously-updated-keys', async () => {
      const lastSuccessfulSyncDateWithBuffer = moment(lastSuccessfulUploadDate).subtract(1, 'hour')
      const recentlyUpdatedKeyData: { key: string, dateUpdated: string }[] = await Storage.getItem(`previously-updated-keys-${type}`) || [];
      const updatedData = recentlyUpdatedKeyData.filter(keyData => {
        return moment(keyData.dateUpdated).isAfter(lastSuccessfulSyncDateWithBuffer)
      })
      await Storage.setItem(`previously-updated-keys-${type}`, updatedData)
    })
  }

  public async upload(): Promise<void> {
    const lastSuccessfulUploadDate = await this.getLastSuccessfulUploadDate();

    const now = moment().toISOString()

    const body: { [apiKey: string]: any } = {}
    let hasChanges = false;

    const keys: string[] = await Storage.getKeys();

    let results = [];
    for (const definition of this.definitions) {
      const unsyncedChanges = await definition.changeFinder.getUnsyncedChanges(lastSuccessfulUploadDate, keys);

      results.push({ 
        apiKey: definition.apiKey, 
        unsyncedChanges: unsyncedChanges || [] 
      })
    }

    for (const result of results) {
      body[result.apiKey] = result.unsyncedChanges
      if (result.unsyncedChanges.length) {
        hasChanges = true;
      }
    }

    if (hasChanges) {
      try {
        await api.patch(this.endpoint, body);
        await this.updateLastSuccessfulUploadDate(now)
        for (const definition of this.definitions) {
          await this.removeRecentlyUpdatedKeys(definition.apiKey, lastSuccessfulUploadDate)
        }
        await this.updateLastTrySuccessStatus('yes')
      } catch(error) {
        await this.updateLastTrySuccessStatus('no')
      }
    } else {
      await this.updateLastSuccessfulUploadDate(now)
    }
  }


}
