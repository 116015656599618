import * as React from 'react';
import { ListTextField } from '../../components/ListTextField';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { Field } from '../../components/Form';
import { ListSwitch } from '../../components/ListSwitch';

export const ModelFieldForm = () => {
  return (
    <>
      
      <Field 
        as={ListTextField} 
        name="name" 
        label="Name" 
        type="text"
        required
      />
      <Field 
        as={ListSwitch} 
        name="includeInReports" 
        label="Include In Reports" 
      />
    </>
  )
}
  
