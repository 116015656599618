import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useUpdateEquipment, useEquipmentItem } from '../../data/equipment';
import { useParams, useHistory, useControlDialog, useDeviceType } from '../../hooks';
import { EquipmentForm } from '../../forms/EquipmentForm';
import { SubmitButton } from '../../components/SubmitButton';
import { Form } from '../../components/Form';
import { PaneButton } from '../../components/PaneButton';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { useHasPermission, useOrgId, useUserId } from '../../auth';
import { HistoryType, Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';
import { DeleteButton } from '../../containers/DeleteButton';
import { Message } from '../../components/Translate';
// import { ImageSearch } from '../../components/ImageSearch';
import { useModel } from '../../data/models';
// import { useEquipmentType } from '../../data/equipmentTypes';
import { useDeleteEquipment } from '../../data/equipment';
import { CouldNotService } from '../../containers/CouldNotService';
import { ListButton } from '../../components/ListButton';
import { trackingCategory, userTracker } from '../../controllers/userTracker';
import { EquipmentNote } from '../../containers/EquipmentNote';
import { useAddHistoryRecord } from '../../data/history';
import { useEquipmentFields } from '../../data/equipmentFields';
import { TransferEquipment } from '../../containers/TransferEquipment';

export function EditEquipmentPage() {
  const params = useParams<{ locationId: string; equipmentId: string }>();
  const history = useHistory();
  const canWrite = useHasPermission(Permissions.EQUIPMENT_WRITE);
  
  const { equipmentItem } = useEquipmentItem({ locationId: params.locationId, id: params.equipmentId });
  const { model } = useModel(equipmentItem?.modelId);
  const { updateEquipment } = useUpdateEquipment();
  const { deleteEquipment } = useDeleteEquipment();
  const { openDialog } = useControlDialog();
  const { addHistoryRecord } = useAddHistoryRecord();
  const { equipmentFields } = useEquipmentFields({ equipmentTypeId: equipmentItem?.equipmentType?.id || '' })
  const myId = useUserId();
  const orgId = useOrgId();

  React.useEffect(() => {
    if (equipmentItem?.note) {
      openDialog({ name: 'equipment-note-dialog', state: { popupNote: equipmentItem.note } });
    }
  }, [equipmentItem?.note]);

  if (!equipmentItem) {
    return null;
  }

  function addHistoryRecordForFieldDataChanges(fromValue: string, toValue: string, fieldId: string) {
    const equipmentField = equipmentFields.find(equipmentField => equipmentField.id === fieldId);
    addHistoryRecord({
      date: new Date().toISOString(),
      description: `${equipmentField?.name} changed from ${fromValue || 'nothing'} to ${toValue}`,
      equipmentId: equipmentItem.id,
      orgId: orgId!,
      happenedAtLocationId: params.locationId,
      locationId: params.locationId,
      userId: myId!,
      type: HistoryType.DATA_CHANGE,
      changes: {
        before: {
          fieldId: fromValue
        },
        after: {
          fieldId: toValue,
        }
      }
    }, params.locationId);
  }

  return (
    <>
    <Form 
      initialValues={equipmentItem}
      onSubmit={(updates) => {
        updateEquipment(
          params.equipmentId, 
          updates,
          params.locationId
        );

        const originalFieldData = equipmentItem.fieldData || {};
        const updatedFieldData = updates.fieldData || {};
        Object.entries(updatedFieldData).forEach(([fieldId, fieldvalue]) => {
          if (fieldvalue != originalFieldData[fieldId]) {
            addHistoryRecordForFieldDataChanges(originalFieldData[fieldId], fieldvalue, fieldId);
          }
        });
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title={'Details' as Message}
          rightButton={<SubmitButton as={PaneButton} label="Save" showFeedback disabled={!canWrite} />}
        >
          <EquipmentForm />

          <ListSectionTitle>Options</ListSectionTitle>

          <ListButton
            label="Could Not Service"
            onPress={() => {
              openDialog({ name: 'could-not-service', state: { equipmentItem } });
              userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Could not service');
            }}
          />

          <ListButton
            label="Transfer Equipment"
            onPress={() => {
              openDialog({ name: 'transfer-equipment', state: { equipmentItem } });
            }}
          />

          <ListSectionTitle>Care</ListSectionTitle>

          <DeleteButton
            deleteItemNew={(itemId: string) => {
              deleteEquipment(
                itemId,
                params.locationId
              )
            }}
            itemId={params.equipmentId}
            name="edit-equipment-delete"
            question="Delete Equipment?"
            successMessage="Deleted Equipment"
            yesMessage="Yes, Delete Equipment"
            label="Delete Equipment"
          />
        </Pane>
      </Panes>
    </Form>

    {equipmentItem && <CouldNotService />}
    {equipmentItem && <TransferEquipment />}
    <EquipmentNote />
    </>
  )
}
  
