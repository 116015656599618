import { LocationOverride } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

const {
  useUpdateDataItem,
  useDeleteDataItem,
  useAddData,
  useDataById,
  useData
} = makeDataHooks<LocationOverride>({
  dataName: "LocationOverrides",
});

export function useLocationOverride(params: { id: string; locationId: string }) {
  const { item } = useDataById(params.id, params.locationId);

  return {
    locationOverride: item,
  }
}

export function useLocationOverrides(locationId: string|undefined) {
  const { data } = useData(undefined, locationId);

  return {
    locationOverrides: data,
  }
}

export function useAddLocationOverride() {
  const { addItem } = useAddData();

  return {
    addLocationOverride: addItem,
  }
}

export function useDeleteLocationOverride() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteLocationOverride: deleteItem,
  }
}

export function useUpdateLocationOverride() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateLocationOverride: updateItem,
  }
}
