import { User, Permissions } from '@rivison-inc/ft-types';
import React, { useContext, memo, useCallback, useState, createContext } from 'react';
import { MemoizedWrapper } from '../components/MemoizedWrapper';
import { userTracker } from '../controllers/userTracker';
import { useQueueMessage } from '../hooks';
import { api } from '../api';
import { Storage } from '../storage';
import { validateEmail } from '../utils/validation';
import { loadStripe } from '@stripe/stripe-js';
import { config } from '../config';

const AuthInfoContext = createContext<AuthInfo>({
  user: null,
  token: null,
  expires: null,
  refreshToken: null,
  status: "unauthenticated",
  error: null
});

const SetAuthInfoContext = createContext<((newAuthInfo: AuthInfo|((oldAuthInfo: AuthInfo) => AuthInfo)) => void)>(() => ({}));

export type LoginStatus = "authenticating" | "authenticated" | "unauthenticated" | "error";
export type AuthInfo = {
  user: Pick<User, 'name' | 'orgId' | 'id' | 'permissions'> | null;
  token: string | null;
  expires: string | null;
  refreshToken: string | null;
  status: 'authenticating' | 'authenticated' | 'unauthenticated' | 'error';
  error: null | { reason: 'offline' | 'error' | 'refresh_invalid' | 'unknown'; message?: string };
}

export function AuthProvider(props: { defaultAuthInfo?: AuthInfo; children: React.ReactNode }) {
  const [authInfo, setAuthInfo] = React.useState<AuthInfo>(props.defaultAuthInfo || {
    user: null,
    token: null,
    expires: null,
    refreshToken: null,
    status: "unauthenticated",
    error: null
  });
  const [show, setShow] = React.useState(!!props.defaultAuthInfo);

  api.setState = setAuthInfo;
  api.authInfo = authInfo;

  React.useEffect(() => {
    if (props.defaultAuthInfo) {
      return;
    }

    Storage.getItem('apiUrlOverride', { persist: true })
      .then((authUrlOverride) => {
        if (authUrlOverride) {
          api.setBaseUrl(authUrlOverride);
        }

        return Storage.getItem('authInfo', { persist: true });
      })
      .then((authInfo) => {
        if (authInfo) {
          setAuthInfo({
            ...authInfo,
            status: "authenticated",
            error: null
          });
        }

        setShow(true);
      })
      .catch(() => {
        setShow(true);
      })
  }, []);

  if (!show) {
    return null;
  }

  return (
    <AuthInfoContext.Provider value={authInfo}>
      <SetAuthInfoContext.Provider value={setAuthInfo}>
        <MemoizedWrapper>
          {props.children}
        </MemoizedWrapper>
      </SetAuthInfoContext.Provider>
    </AuthInfoContext.Provider>
  )
}

export function useLoginStatus() {
  return React.useContext(AuthInfoContext).status;
}

export function useClearLoginError() {
  const setAuthInfo = React.useContext(SetAuthInfoContext);
  const clearLoginError = React.useCallback(() => {
    setAuthInfo((info) => ({ ...info, error: null }));
  }, [setAuthInfo]);

  return {
    clearLoginError
  }
}

export function useLogin() {
  const queueMessage = useQueueMessage();
  const setAuthInfo = React.useContext(SetAuthInfoContext);
  const login = useCallback(async (payload: { email: string; password: string; apiUrlOverride?: string }) => {
    setAuthInfo((authInfo) => ({
      ...authInfo,
      status: "authenticating"
    }));
  
    if (payload.apiUrlOverride) {
      api.setBaseUrl(payload.apiUrlOverride);
      await Storage.setItem('apiUrlOverride', payload.apiUrlOverride, { persist: true });
    }

    let user: Pick<User, 'name' | 'orgId' | 'id' | 'permissions'>;
    let token: string;
    let refreshToken: string;
    let expires: string;
    try {
      const response = await api.post('/token', {}, {
        auth: {
          username: payload.email.trim(),
          password: payload.password.trim(),
        }
      });
      
      user = response.data.user;
      token = response.data.token;
      expires = response.data.expires;
      refreshToken = response.data.refreshToken;
  
      userTracker.setUser(user.id);
      
      await Storage.setItem('authInfo', { user, token, expires, refreshToken }, { persist: true });

      setAuthInfo({
        user, 
        token, 
        expires, 
        refreshToken,
        status: "authenticated",
        error: null
      });
    } catch (err) {
      console.log("login error", err); // TODO: set up frontend error logging
      setAuthInfo((authInfo) => ({
        ...authInfo,
        status: "error"
      }));

      queueMessage({ type: 'error', message: 'Login Failed' });
    }
  }, []);

  return {
    login
  }
}

export function useOrgId() {
  return React.useContext(AuthInfoContext).user?.orgId || null;
}

export function useUserId() {
  return React.useContext(AuthInfoContext).user?.id || null;
}

export const useHasPermission = (permission: Permissions) => {
  const user = React.useContext(AuthInfoContext).user;
  const permissions = user?.permissions || [];
  const hasPermission = permissions.indexOf(permission) !== -1;
  const isSuperAdmin = permissions.indexOf('*.*') !== -1;
  return hasPermission || isSuperAdmin;
}

export function useSignUp() {
  const queueMessage = useQueueMessage();
  const setAuthInfo = React.useContext(SetAuthInfoContext);
  const [isSigningUp, setIsSigningUp] = React.useState(false);

  const signUp = React.useCallback(async (payload: { email: string; password: string; name: string; organizationName: string; flameTaskPlan: string; agreedToTerms: boolean }) => {
    setAuthInfo((authInfo) => ({
      ...authInfo,
      status: "authenticating"
    }));

    const emailIsValid = validateEmail(payload.email);
    if (!emailIsValid) {
      setAuthInfo((authInfo) => ({
        ...authInfo,
        status: "error"
      }));

      queueMessage({
        type: 'error',
        message: 'Email is not valid'
      });

      return false;
    }

    if (!payload.agreedToTerms) {
      setAuthInfo((authInfo) => ({
        ...authInfo,
        status: "error"
      }));

      queueMessage({
        type: 'error',
        message: 'You must agree to the terms and conditions'
      });

      return false;
    }

    if (!payload.email || !payload.password || !payload.name || !payload.organizationName || !payload.flameTaskPlan) {
      setAuthInfo((authInfo) => ({
        ...authInfo,
        status: "error"
      }));

      queueMessage({
        type: 'error',
        message: 'Please fill out all fields'
      });

      return false;
    }

    let user: Pick<User, 'name' | 'orgId' | 'id' | 'permissions'>;
    let token: string;
    let refreshToken: string;
    let expires: string;

    setIsSigningUp(true);
    
    try {
      const response = await api.post('/users', {
        name: payload.name,
        email: payload.email.trim(),
        password: payload.password.trim(),
        organizationName: payload.organizationName,
        flameTaskPlan: payload.flameTaskPlan,
      });
      
      user = response.data.user;
      token = response.data.token;
      expires = response.data.expires;
      refreshToken = response.data.refreshToken;
      await Storage.setItem('authInfo', { user, token, expires, refreshToken }, { persist: true });

      userTracker.setUser(user.id);

      const stripe = await loadStripe(config.billing.publicKey)
      const sessionResponse = await api.post(`/organizations/${user.orgId}/payments/session`, { }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const sessionId = sessionResponse.data.session.id;
    
      if (stripe) {
        stripe.redirectToCheckout({
          sessionId,
        });
      }

      return true;
    } catch (err) {
      console.log("sign up error", err); // TODO: add frontend error logging
      setAuthInfo((authInfo) => ({
        ...authInfo,
        status: "error"
      }));

      queueMessage({
        type: 'error',
        message: 'Sign-Up Failed. Ensure the email is not already is use.'
      })

      setIsSigningUp(false);
      return false;
    }
  }, []);

  return { signUp, isSigningUp };
}

export function useAcceptInvite() {
  const setAuthInfo = React.useContext(SetAuthInfoContext);

  const acceptInvite = React.useCallback(async (params: { userId: string; password: string; inviteToken: string }) => {
    const response = await api.patch(`/users/${params.userId}`, {
      password: params.password.trim(),
    }, {
      headers: {
        Authorization: `Bearer ${params.inviteToken}`
      }
    });

    const user = response.data.user;
    const token = response.data.token;
    const expires = response.data.expires;
    const refreshToken = response.data.refreshToken;

    await Storage.setItem('authInfo', { user, token, expires, refreshToken }, { persist: true });
    setAuthInfo({
      user, 
      token, 
      expires, 
      refreshToken,
      status: "authenticated",
      error: null
    });
  }, []);

  return {
    acceptInvite
  }
}

export function useLogout() {
  const setAuthInfo = React.useContext(SetAuthInfoContext);
  
  const logout = React.useCallback(async () => {
    await Storage.setItem('authInfo', null, { persist: true });
    setAuthInfo({
      user: null,
      error: null,
      expires: null,
      refreshToken: null,
      status: "unauthenticated",
      token: null
    });
  }, []);

  return {
    logout
  }
}
