import React from 'react';
import { LeftArrowIcon } from '../../icons/LeftArrow';
import { IconButton } from '../IconButton';
import { useGoBack } from '../../hooks/useGoBack';
import { userTracker, trackingCategory } from '../../controllers/userTracker';


export function BackButton() {
  const goBack = useGoBack();

  return (
    <IconButton label="back" onPress={() => {
      goBack();
      userTracker.trackEvent(trackingCategory.NAVIGATION, 'Back Button Press')
    }}>
      <LeftArrowIcon 
        size="lg"
        color="secondary"
      />
    </IconButton>
  )
}
