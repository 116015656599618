import * as React from 'react';
import { ListButton } from '../ListButton';

interface ListRendererProps {
  data: any[];
  disableSeeMore?: boolean;
  render: (data: any, idx: number) => React.ReactChild;
}

export const ListRenderer = (props: ListRendererProps) => {
  const increment = 50;
  const [numToRender, setNumToRender] = React.useState(increment);
  const splitData = React.useMemo(() => props.data.slice(0,numToRender), [numToRender, props.data]);
  const isShowingAllData = splitData.length >= props.data.length;
  return (
    <>

      {splitData.map((data,idx) => props.render(data,idx))}

      {!isShowingAllData && !props.disableSeeMore && (
        <ListButton 
          label="See More"
          onPress={() => setNumToRender(numToRender + increment)}
        />
      )}

    </>
  )
}
