import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useUpdateServiceType, useDeleteServiceType, useServiceType } from '../../data/serviceTypes';
import { useEquipmentType } from '../../data/equipmentTypes';
import { useParams, useHistory } from '../../hooks';
import { ServiceTypesForm } from '../../forms/ServiceTypesForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { BackButton } from '../../components/BackButton';

export function EditServiceTypePage() {
  const history = useHistory();
  const params = useParams<{ equipmentTypeId: string; serviceTypeId: string }>();
  const canWrite = useHasPermission(Permissions.SERVICE_WRITE);

  const { serviceType } = useServiceType(params.serviceTypeId); 
  const { equipmentType } = useEquipmentType(params.equipmentTypeId);
  const { updateServiceType } = useUpdateServiceType();
  const { deleteServiceType } = useDeleteServiceType();

  if (!equipmentType) {
    return null; // TODO: return loading state?
  }
  if (!serviceType) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={serviceType}
      onSubmit={async (updates) => {
        await updateServiceType(serviceType.id, updates)
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Service Type"
          rightButton={<SubmitButton as={PaneButton} label="Save" disabled={!canWrite} />}
        >
          {serviceType && (
            <ServiceTypesForm />
          )}

          <DeleteButton
            name="service-type"
            deleteItemNew={deleteServiceType}
            itemId={serviceType.id}
            question="Delete Service Type?"
            yesMessage="Yes, Delete Service Type"
            successMessage="Deleted Service Type"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
