import * as React from "react";
import { Image } from "react-native";
import { getReadUrlForFile } from "../../utils/file";
import { useOrganization } from "../../data/organization";

export function PrivateImage(props: { folderName: string; fileName: string; width?: number; height?: number; }) {
  const { organization } = useOrganization();
  const orgId = organization?.id;
  if (!orgId) {
    console.log(`Missing orgId in PrivateImage for ${props.fileName} of type ${props.folderName}`)
    return null;
  }
  const [imageInfo, setImageInfo] = React.useState<{ url: string, width: number, height: number }>({ url: "", width: 0, height: 0 });

  React.useEffect(() => {
    if (orgId && props.fileName && props.folderName) {

      getReadUrlForFile(props.fileName, props.folderName, { orgId })
        .then((result) => {
          if (result) {
            Image.getSize(result.url, (width, height) => {
              setImageInfo({
                width,
                height,
                url: result.url
              });
            }, () => {
              // do nothing
            })
          }
        })
    }
  }, [orgId, props.fileName, props.folderName]);

  if (!imageInfo.url) {
    return null;
  }

  return (
    <Image 
      source={{ uri: imageInfo.url }} 
      style={{ width: props.width || imageInfo.width, height: props.height || imageInfo.height, resizeMode: 'cover' }}
    />
  )
}