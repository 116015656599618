/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import styled from 'styled-components/native';
import { getBackgroundStyles, getMarginStyles, getPaddingStyles, getAlignStyles } from '../../utils/style';
import { SizingCode } from '../../theme';

export interface BoxProps { 
  backgroundColor?: 'dark' | 'light' | 'grey' | 'negative';
  
  /** flex */
  flexDirection?: 'row' | 'column';
  flexGrow?: 1;
  flexBasis?: 0;
  flexShrink?: 1;
  flexWrap?: 'wrap' | 'nowrap';

  /** padding */
  padding?: SizingCode;
  paddingTop?: SizingCode;
  paddingBottom?: SizingCode;
  paddingLeft?: SizingCode;
  paddingRight?: SizingCode;

  /** margin */
  margin?: SizingCode;
  marginTop?: SizingCode;
  marginBottom?: SizingCode;
  marginLeft?: SizingCode;
  marginRight?: SizingCode;

  /** align */
  align?: 'left' | 'center' | 'right';
  alignY?: 'left' | 'center' | 'right';
  alignX?: 'left' | 'center' | 'right';
  justifyContent?: 'space-between' | 'center' | 'space-around';

  /** border */
  hideBorder?: 'left' | 'top';
  borderColor?: 'grey';

  /** rounded */
  roundBottomLeft?: 'md';
  roundBottomRight?: 'md';
  roundTopLeft?: 'md';
  roundTopRight?: 'md';
  roundBottom?: 'md';
  rounded?: 'md';
  
  /** children */
  children: React.ReactNode;

  /** sizes */
  width?: 'full';
  maxWidth?: number;
  minHeight?: 'sm';
}

interface BoxViewProps {
  backgroundColor?: 'dark' | 'light' | 'grey' | 'negative';

  /** flex */
  flexDirection?: 'row' | 'column';
  flexBasis?: 0;
  flexGrow?: 1;
  flexShrink?: 1;
  flexWrap?: 'wrap' | 'nowrap';

  /** padding */
  paddingCode?: SizingCode;
  paddingTopCode?: SizingCode;
  paddingBottomCode?: SizingCode;
  paddingLeftCode?: SizingCode;
  paddingRightCode?: SizingCode;

  /** margin */
  marginCode?: SizingCode;
  marginTopCode?: SizingCode;
  marginBottomCode?: SizingCode;
  marginLeftCode?: SizingCode;
  marginRightCode?: SizingCode;

  /** align */
  align?: 'left' | 'center' | 'right';
  alignY?: 'left' | 'center' | 'right';
  alignX?: 'left' | 'center' | 'right';
  justifyContent?: 'space-between' | 'center' | 'space-around';

  /** border */
  hideBorder?: 'left' | 'top';
  borderColor?: 'grey';

  /** rounded */
  roundBottomLeft?: 'md';
  roundBottomRight?: 'md';
  roundTopLeft?: 'md';
  roundTopRight?: 'md';
  roundBottom?: 'md';
  rounded?: 'md';

  /** sizes */
  widthCode?: 'full';
  maxWidth?: number;
  minHeight?: 'sm';
}

const BoxView = styled.View<BoxViewProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  flex-wrap: ${(props) => props.flexWrap || 'nowrap'};
  ${(props) => props.maxWidth ? `max-width: ${props.maxWidth}px;` : ''}
  ${getBackgroundStyles}
  ${getPaddingStyles}
  ${getMarginStyles}
  ${getAlignStyles}

  /** border */
  ${(props) => props.borderColor ? 'border-width: 1px' : ''}
  ${(props) => props.borderColor ? `border-color: ${props.theme.palette.lineColor}` : ''}
  ${(props) => props.hideBorder === 'left' ? 'border-left-width: 0' : ''}
  ${(props) => props.hideBorder === 'top' ? 'border-top-width: 0' : ''}

  /** border-radius */
  ${(props) => props.roundBottomLeft ? `border-bottom-left-radius: ${props.theme.borderRadius}px;` : ''}
  ${(props) => props.roundBottomRight ? `border-bottom-right-radius: ${props.theme.borderRadius}px;` : ''}
  ${(props) => props.roundTopLeft ? `border-top-left-radius: ${props.theme.borderRadius}px;` : ''}
  ${(props) => props.roundTopRight ? `border-top-right-radius: ${props.theme.borderRadius}px;` : ''}
  ${(props) => props.roundBottom ? `border-bottom-left-radius: ${props.theme.borderRadius}px; border-bottom-right-radius: ${props.theme.borderRadius}px;` : ''}
  ${(props) => props.rounded ? `border-radius: ${props.theme.borderRadius}px;` : ''}

  /** flex */
  ${(props) => props.flexBasis === 0 ? `flex-basis: ${props.flexBasis};` : ''}
  ${(props) => props.flexGrow ? `flex-grow: ${props.flexGrow};` : ''}
  ${(props) => props.flexShrink ? `flex-shrink: ${props.flexShrink};` : ''}
  ${(props) => props.justifyContent ? `justify-content: ${props.justifyContent};` : ''}

  /** sizes */
  ${(props) => (props.minHeight === 'sm') ? 'min-height: 52px;' : ''}
  ${(props) => (props.widthCode === 'full') ? 'width: 100%' : ''}

`;

export function Box(props: BoxProps) {
  return (
    <BoxView 
      backgroundColor={props.backgroundColor}
      paddingCode={props.padding}
      paddingTopCode={props.paddingTop}
      paddingBottomCode={props.paddingBottom}
      paddingLeftCode={props.paddingLeft}
      paddingRightCode={props.paddingRight}
      marginCode={props.margin}
      marginTopCode={props.marginTop}
      marginBottomCode={props.marginBottom}
      marginLeftCode={props.marginLeft}
      marginRightCode={props.marginRight}
      flexDirection={props.flexDirection}
      flexGrow={props.flexGrow}
      flexShrink={props.flexShrink}
      alignX={props.alignX}
      alignY={props.alignY}
      align={props.align}
      flexWrap={props.flexWrap}
      justifyContent={props.justifyContent}
      roundBottom={props.roundBottom}
      roundBottomLeft={props.roundBottomLeft}
      roundBottomRight={props.roundBottomRight}
      roundTopLeft={props.roundTopLeft}
      roundTopRight={props.roundTopRight}
      rounded={props.rounded}
      borderColor={props.borderColor}
      minHeight={props.minHeight}
      hideBorder={props.hideBorder}
      flexBasis={props.flexBasis}
      widthCode={props.width}
      maxWidth={props.maxWidth}
    >
      {props.children}
    </BoxView>
  )
}
