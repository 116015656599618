import * as React from 'react';
import { Switch as NativeSwitch } from 'react-native';
import styled from 'styled-components/native';
import { useTheme } from '../../hooks';
import { Theme } from '../../theme';
import { Box } from '../Box';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { Message } from '../Translate';

export interface ListSwitchProps {
  label: string|React.ReactNode;
  onChange: (newValue: boolean) => void;
  value: boolean;
  tooltip?: {
    title: Message;
    text: Message;
    youtubeId?: string;
  };
}

const ListSwitchView = styled.View`
  padding: ${(props) => props.theme.spacing.md};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom-color: ${(props: { theme: Theme }) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
  flex: 0 1 auto;
  min-height: 50px;
`;

export const ListSwitch = (props: ListSwitchProps) => {  
  return (
    <ListSwitchView>
      <NativeSwitch 
        value={props.value}
        accessibilityRole="switch"
        onValueChange={() => props.onChange(!props.value)}
        accessibilityLabel={typeof props.label === "string" ? props.label : undefined}
        // thumbColor={"blue"}
        // activeThumbColor={"purple"}
        // trackColor={}
      />
      <Box marginLeft="md" flexShrink={1}>
        <Text size="sm">{props.label}</Text>
      </Box>
      {props.tooltip && (
        <Tooltip title={props.tooltip.title} text={props.tooltip.text} youtubeId={props.tooltip.youtubeId} />
      )}
    </ListSwitchView>
  )
}
