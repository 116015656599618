import styled from "styled-components/native";
import { Theme } from "../../theme";

export const SearchView = styled.View`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const SearchInput = styled.TextInput`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  padding-left: ${(props: { theme: Theme }) => props.theme.spacing.xl};
  width: 100%;
  background-color: ${(props: { theme: Theme }) => props.theme.palette.searchBarColor};
  border-radius: 10px;
  font-size: ${(props) => props.theme.typography.textSizes.md};
  height: auto;
  box-shadow:none;
  font-weight: normal;
  border-width: 0px;
  color: black;
  border: 1px solid white;
`;

export const SearchIconContainer = styled.View`
  bottom: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  z-index: 2;
`;

export const LocationsWrapper = styled.View`
  height: 300px;
  overflow: auto;
`;

export const TransferSpinnerWrapper = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;