import styled from 'styled-components/native';

export const DayBoxesContainer = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  /** This is one more px than normal spacing to account for -1 margin */
  padding-left: ${16 + 1}px;
  padding-top: 16px;

  /** Less padding to account for -1 * 7 margin */
  padding-right: ${16 - 7}px;
  padding-bottom: 16px;
`;

export const DayBox = styled.TouchableOpacity<{ selected: boolean }>`
  position: relative;
  width: ${(100 / 7) - 0.001}%;
  margin-left: -1px;
  margin-top: -1px;
  border-width: 1px;
  border-color: rgb(237, 237, 237); /** can't have alpha in color or it messes up our hack */
  background-color: ${(props) => props.selected ? props.theme.palette.primary : 'rgba(0,0,0,0.03)'};
`;

export const FakeDayBox = styled.View`
  position: relative;
  width: ${(100 / 7) - 0.001}%;
  background-color: white;
  margin-left: -1px;
  margin-top: -1px;
  border-width: 1px;
  border-color: transparent;
`;

export const FakeHeight = styled.View`
  margin-top: 100%; /** Trick to make height same as width. See: https://stackoverflow.com/a/6615994/3006989 */
`;

export const YearPickerBackground = styled.View<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 300px;
  display: ${(props) => props.show ? 'flex' : 'none'};
  flex-wrap: wrap;
  flex-direction: row;
`;

export const YearsContainer = styled.View`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const MonthsContainer = styled.View`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: ${(props) => props.theme.spacing.sm};
  padding-bottom: 0px;
  align-items: center;
  justify-content: center;
`;

export const YearButton = styled.TouchableOpacity`
  width: 100%;
  padding: 10px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const YearBox = styled.TouchableOpacity<{ selected: boolean }>`
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.selected ? props.theme.palette.primary : 'white'};
  color: ${(props) => props.selected ? 'white' : 'black'};
  padding: ${(props) => props.theme.spacing.sm};
  border-radius: 4px;
`;

export const MonthBox = styled.TouchableOpacity<{ selected: boolean }>`
  min-width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.selected ? props.theme.palette.primary : 'white'};
  color: ${(props) => props.selected ? 'white' : 'black'};
  padding: ${(props) => props.theme.spacing.sm};
  border-radius: 4px;
`;
