import * as React from 'react';
import { EmptyState } from '../../components/EmptyState';
import { IconButton } from '../../components/IconButton';
import { ListItem } from '../../components/ListItem';
import { Pane, Panes } from '../../components/Panes';
import { useParams } from '../../hooks';
import { AddIcon } from '../../icons/Add';
import { useFloorPlans } from '../../data/floorPlans';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';

export function FloorPlansListPage() {
  const canRead = useHasPermission(Permissions.LOCATION_READ);
  const params = useParams<{ locationId: string }>();
  const { floorPlans } = useFloorPlans(params.locationId);

  if (!canRead) {
    return <></>;
  }

  return (
    <Panes backgroundColor="light">
      <Pane title="Floor Plans" rightButton={<AddFloorPlanButton />}>
        {floorPlans.map((floorPlan, idx) => (
          <ListItem
            key={idx} 
            to={`/sites/${params.locationId}/settings/floor-plans/${floorPlan.id}`}
            title={floorPlan.name}
            // subtitle={user.email}
          />
        ))}
        {!floorPlans.length && <EmptyState title={'No Floor Plans'} subtitle={'Save time and minimize risk by plotting the location of equipment on a floor plan of this site'} image={'floorplan'} />}
      </Pane>
    </Panes>
  )
}

function AddFloorPlanButton() {  
  const params = useParams<{ locationId: string }>();

  return (
    <IconButton to={`/sites/${params.locationId}/settings/floor-plans/new`} label="Add Floor Plan">
      <AddIcon />
    </IconButton>
  )
}
