import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useAddLocationField } from '../../data/locationFields';
import { useHistory } from '../../hooks';
import { LocationFieldForm } from '../../forms/LocationFieldForm';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { useOrgId } from '../../auth';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

export function AddLocationFieldsPage() {
  const history = useHistory();
  const orgId = useOrgId();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);
  const { addLocationField } = useAddLocationField();

  if (!canWrite) {
    return <></>;
  }

  return (
    <Form 
      initialValues={{
        name: '',
        modelId: '',
        orgId: orgId || '',
      }}
      onSubmit={async (field) => {
        await addLocationField(field)
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane
          title="Add site Field"
          rightButton={<SubmitButton as={PaneButton} label="Add" />}
        >
          <LocationFieldForm />
        </Pane>
      </Panes>
    </Form>
  )
}
  
