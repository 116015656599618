import React, { useContext, useRef } from "react";

const SearchContext = React.createContext<SearchSaverStore|null>(null);

export function SearchSaverEquipment(props: { children: React.ReactNode }) {
    const searchSaver = useOneInstance(() => new SearchSaverStore());

    return (
        <SearchContext.Provider value={searchSaver}>
            {props.children}
        </SearchContext.Provider>
    )
}

export function useSearchSaverEquipment() {
    return useContext(SearchContext)!;
}

function useOneInstance<T>(builder: () => T): T {
    const ref = useRef<T>();
    if (!ref.current) {
        ref.current = builder();
    }

    return ref.current;
}

class SearchSaverStore {
    private search = '';

    saveSearch(text: string) {
        this.search = text;
    }

    getSearch() {
        return this.search;
    }
}