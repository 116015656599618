import { Model } from "@rivison-inc/ft-types";
import { useMemo } from "react";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
  SyncStatusMessage
} = makeDataHooks<Model>({
  dataName: "Models",
});

export function useModel(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    model: item,
  }
}

export function useModels(filters: { equipmentTypeId?: string } = {}) {
  const { data, isLoading } = useData();

  const filteredModels = useMemo(() => {
    if (!filters.equipmentTypeId) {
      return data;
    }

    return data.filter((item) => item.equipmentTypeId === filters.equipmentTypeId);
  }, [data, filters.equipmentTypeId])

  return {
    models: filteredModels,
    isLoading
  }
}

export function useAddModel() {
  const { addItem } = useAddData();

  return {
    addModel: addItem,
  }
}

export function useDeleteModel() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteModel: deleteItem,
  }
}

export function useUpdateModel() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateModel: updateItem,
  }
}

export const ModelsSyncStatusMessage = SyncStatusMessage;
