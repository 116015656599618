import styled from 'styled-components/native';
import { Theme } from '../../theme';

export const NavContainer = styled.View`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.md};
  display: flex;
  flex-direction: row;
`;

export const NavItem = styled.View`
  margin: ${(props: { theme: Theme }) => props.theme.spacing.xs};
  background-color: rgba(0,0,0,0.2);
  border-radius: ${(props: { theme: Theme }) => props.theme.borderRadius / 2}px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface NavItemTextProps {
  selected: boolean;
}

export const NavItemText = styled.Text<NavItemTextProps>`
  color: ${(props: { selected: boolean; theme: Theme }) => props.selected ? props.theme.palette.primary : props.theme.palette.fadedIconColor};
  margin-left: ${(props: { theme: Theme }) => props.theme.spacing.sm};
`;

export const TouchableOpacityCentered = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-grow: 1;
  padding: ${(props: { theme: Theme }) => props.theme.spacing.md};
`;

export const RightArrowContainer = styled.View`
  margin-left: ${(props: { theme: Theme }) => props.theme.spacing.xxs};
  margin-right: ${(props: { theme: Theme }) => props.theme.spacing.xxs};
`;

export const ButtonPadding = styled.View`
  padding: 18px;
  flex-direction: row;
`;
