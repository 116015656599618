/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import styled from 'styled-components/native';
import { getBackgroundStyles, getMarginStyles, getPaddingStyles, getAlignStyles } from '../../utils/style';
import { SizingCode } from '../../theme';
import { ListButton } from '../ListButton';
import { Message } from '../Translate';

export interface BoxProps {
  openedTitle: Message;
  closedTitle: Message;
  children: React.ReactNode;
}

const AccordionView = styled.View`
  display: flex;
`;

const ContentView = styled.View<{ open: boolean }>`
  display: ${props => props.open ? 'block' : 'none'};
`;

export function Accordion(props: BoxProps) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <AccordionView>
        <ListButton
          label={open ? props.openedTitle : props.closedTitle}
          onPress={() => {
            setOpen(!open);
          }} 
        />
      </AccordionView>
      <ContentView open={open}>
        {props.children}
      </ContentView>
    </>
  )
}
