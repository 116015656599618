import * as React from 'react';
import { FeatureList, Permissions } from '@rivison-inc/ft-types';
import { useHasPermission } from '../../auth';

/** icons */
import { LocationIcon } from '../../icons/Location';
import { CustomerIcon } from '../../icons/Customer';
import { SettingsIcon } from '../../icons/Settings';
import { ScheduleIcon } from '../../icons/Schedule';
import { EquipmentIcon } from '../../icons/Equipment';
import { BuildingIcon } from '../../icons/Building';
import { UserIcon } from '../../icons/User';
import { UsersIcon } from '../../icons/Users';
import { FingerPrintIcon } from '../../icons/FingerPrint';
import { WrenchIcon } from '../../icons/Wrench';
import { BoxesIcon } from '../../icons/Boxes';
import { CalendarMonthIcon } from '../../icons/CalendarMonth';
import { TestIcon } from '../../icons/Test';
import { HistoryIcon } from '../../icons/History';
import { DeficiencyIcon } from '../../icons/Deficiency';
import { ReportIcon } from '../../icons/Report';
import { QuestionIcon } from '../../icons/Question';
import { FloorPlanIcon } from '../../icons/FloorPlan';
import { FieldIcon } from '../../icons/Field';
import { SelectedFieldIcon } from '../../icons/SelectedField';

/** components */
import { Nav as NavComponent } from '../../components/Nav';
import { useRouteMatch } from '../../hooks';
import { useLocation } from '../../data/locations';
import { useEquipmentItem } from '../../data/equipment';

import { useUser } from '../../data/users';
import { useQuestion } from '../../data/questions';
import { useModel } from '../../data/models';
import { useEquipmentType } from '../../data/equipmentTypes';
import { useServiceType } from '../../data/serviceTypes';
import { useServiceMonthCategory } from '../../data/serviceMonthsCategories';
import { useHistoryRecord } from '../../data/history';
import { useDeficiency } from '../../data/deficiencies';
import { useLocationField } from '../../data/locationFields';
import { useModelField } from '../../data/modelFields';
import { useEquipmentField } from '../../data/equipmentFields';
import { useFloorPlan } from '../../data/floorPlans';

import { useSiteType } from '../../data/siteTypes';
import { useIsFeatureEnabled, useOrganization } from '../../data/organization';
import { useCustomer } from '../../data/customers';
import { useSync } from '../../data/syncHooks';

const trimText = (input = '', short = false) => {
  const maxLength = short ? 15 : 15;
  const lengthToTrim = maxLength; /// leave space for ...
  if (input.length > maxLength) {
    if (short) {
      return input.substr(0, lengthToTrim);
    }
    return input.substr(0, lengthToTrim) + '...';
  }
  return input;
}

export function Nav() {

  const locationPageMatch = useRouteMatch<{ locationId: string }>({ path: '/sites/:locationId' });
  const customerPageMatch = useRouteMatch<{ customerId: string }>({ path: '/customers/:customerId' });
  const equipmentPageMatch = useRouteMatch<{ equipmentId: string }>({ path: '/sites/:locationId/equipment/:equipmentId' });
  const floorPlanPageMatch = useRouteMatch<{ floorPlanId: string }>({ path: '/sites/:locationId/settings/floor-plans/:floorPlanId' });
  const locationOverridePageMatch = useRouteMatch<{ locationOverrideId: string }>({ path: '/sites/:locationId/settings/location-overrides/:locationOverrideId' });
  const usersEditPageMatch = useRouteMatch<{ userId: string }>({ path: '/users/:userId' });
  const modelsEditPageMatch = useRouteMatch<{ modelId: string }>({ path: '/equipment-types/:equipmentTypeId/models/:modelId' });
  const serviceMonthCategoriesEditPageMatch = useRouteMatch<{ serviceMonthCategoryId: string }>({ path: '/service-month-categories/:serviceMonthCategoryId' });
  const settingsEquipmentTypesEditPageMatch = useRouteMatch<{ equipmentTypeId: string }>({ path: '/equipment-types/:equipmentTypeId' });
  const settingsSiteTypesEditPageMatch = useRouteMatch<{ siteTypeId: string }>({ path: '/site-types/:siteTypeId' });
  const settingsServiceTypesEditPageMatch = useRouteMatch<{ equipmentTypeId: string; serviceTypeId: string }>({ path: '/equipment-types/:equipmentTypeId/service-types/:serviceTypeId' });
  const settingsServiceQuestionPageMatch = useRouteMatch<{ equipmentTypeId: string; serviceTypeId: string; questionId: string }>({ path: '/equipment-types/:equipmentTypeId/service-types/:serviceTypeId/questions/:questionId' });
  const performedServicePageMatch = useRouteMatch<{ locationId: string; equipmentId: string; serviceTypeId: string }>({ path: '/sites/:locationId/equipment/:equipmentId/performed-service/:serviceTypeId' });
  const historyPageMatch = useRouteMatch<{ locationId: string; equipmentId: string; historyId: string }>({ path: '/sites/:locationId/equipment/:equipmentId/history/:historyId' });
  const deficiencyPageMatch = useRouteMatch<{ locationId: string; equipmentId: string; deficiencyId: string }>({ path: '/sites/:locationId/equipment/:equipmentId/deficiency/:deficiencyId' });
  const settingsCustomFieldsModelPageMatch = useRouteMatch<{ equipmentTypeId: string; serviceTypeId: string; fieldId: string }>({ path: '/custom-fields/model/:fieldId' });
  const settingsCustomFieldsEquipmentPageMatch = useRouteMatch<{ equipmentTypeId: string; serviceTypeId: string; fieldId: string }>({ path: '/custom-fields/equipment/:fieldId' });
  const settingsCustomFieldsLocationPageMatch = useRouteMatch<{ equipmentTypeId: string; serviceTypeId: string; fieldId: string }>({ path: '/custom-fields/location/:fieldId' });
  const profilePageMatch = useRouteMatch({ path: '/profile' });

  // Ids
  const locationId = locationPageMatch?.params.locationId;
  const customerId = customerPageMatch?.params.customerId;
  const modelId = modelsEditPageMatch?.params.modelId;
  const userId = usersEditPageMatch?.params.userId;
  const equipmentTypeId = settingsEquipmentTypesEditPageMatch?.params.equipmentTypeId;
  const locationOverrideId = locationOverridePageMatch?.params.locationOverrideId;
  const siteTypeId = settingsSiteTypesEditPageMatch?.params.siteTypeId;
  const historyId = historyPageMatch?.params.historyId;
  const serviceMonthCategoryId = serviceMonthCategoriesEditPageMatch?.params.serviceMonthCategoryId;
  const serviceTypeId = settingsServiceTypesEditPageMatch?.params.serviceTypeId;
  const performedServiceTypeId = performedServicePageMatch?.params.serviceTypeId;
  const deficiencyId = deficiencyPageMatch?.params.deficiencyId;
  const equipmentId = equipmentPageMatch?.params.equipmentId;
  const questionId = settingsServiceQuestionPageMatch?.params.questionId;
  const locationFieldId = settingsCustomFieldsLocationPageMatch?.params.fieldId;
  const modelFieldId = settingsCustomFieldsModelPageMatch?.params.fieldId;
  const equipmentFieldId = settingsCustomFieldsEquipmentPageMatch?.params.fieldId;
  const floorPlanId = floorPlanPageMatch?.params.floorPlanId;

  // Data
  const { location } = useLocation(locationId);
  const { customer } = useCustomer(customerId);
  const { model } = useModel(modelId);
  const { user } = useUser(userId);
  const { equipmentType } = useEquipmentType(equipmentTypeId);
  const { siteType } = useSiteType(siteTypeId);
  const { serviceMonthCategory } = useServiceMonthCategory(serviceMonthCategoryId);
  const { serviceType } = useServiceType(serviceTypeId);
  const { serviceType: performedServiceType } = useServiceType(performedServiceTypeId);
  const { question } = useQuestion(questionId);
  const { locationField } = useLocationField(locationFieldId);
  const { modelField } = useModelField(modelFieldId);
  const { equipmentField } = useEquipmentField(equipmentFieldId);
  const { floorPlan } = useFloorPlan({ id: floorPlanId, locationId });
  const { historyRecord } = useHistoryRecord({ id: historyId, locationId });
  const { deficiency } = useDeficiency({ id: deficiencyId, locationId });
  const { equipmentItem } = useEquipmentItem({ id: equipmentId, locationId: locationId || '' });
  const { isDownloading } = useSync();

  // Permissions
  const canReadLocations = useHasPermission(Permissions.LOCATION_READ);
  const canReadReports = useHasPermission(Permissions.REPORT_READ);
  const canReadSchedule = useHasPermission(Permissions.SCHEDULE_READ);
  const canReadSettings = useHasPermission(Permissions.SETTINGS_READ);

  const { organization } = useOrganization();
  const billingEnabled = useIsFeatureEnabled(FeatureList.BILLING) && organization.showBilling;

  return (
    <NavComponent 
      items={
        [
          [
            {
              title: trimText('Sites'),
              icon: LocationIcon,
              selected: Boolean(useRouteMatch({ path: '/sites', exact: true })),
              visible: canReadLocations,
              path: '/sites',
            },
            {
              title: trimText(location?.name || (isDownloading ? 'Loading...' : 'New Site')),
              icon: BuildingIcon,
              selected: Boolean(useRouteMatch({ path: '/sites/:locationId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/sites/:locationId' })),
              path: `/sites/${locationId}`
            },
            {
              title: trimText('Location Overrides'),
              icon: SettingsIcon,
              selected: Boolean(useRouteMatch({ path: '/sites/:locationId/settings/location-overrides', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/sites/:locationId/settings/location-overrides' })),
              path: `/sites/${locationId}/settings/location-overrides`
            },
            {
              title: trimText(locationOverrideId ? 'Location Override' : 'New Location Override'),
              icon: SettingsIcon,
              selected: Boolean(useRouteMatch({ path: '/sites/:locationId/settings/location-overrides/:locationOverrideId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/sites/:locationId/settings/location-overrides/:locationOverrideId' })),
              path: `/sites/${locationId}/settings/location-overrides/${locationOverrideId}`
            },
            {
              title: trimText('Floor Plans'),
              icon: FloorPlanIcon,
              selected: Boolean(useRouteMatch({ path: '/sites/:locationId/settings/floor-plans', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/sites/:locationId/settings/floor-plans' })),
              path: `/sites/${locationId}/settings/floor-plans`
            },
            {
              title: trimText(floorPlan?.name || 'New Floor Plan'),
              icon: FloorPlanIcon,
              selected: Boolean(useRouteMatch({ path: '/sites/:locationId/settings/floor-plans/:floorPlanId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/sites/:locationId/settings/floor-plans/:floorPlanId' })),
              path: `/sites/${locationId}/settings/floor-plans/${floorPlanId}`
            },
            {
              title: trimText(equipmentItem?.name || (isDownloading ? 'Loading...' : 'New Equipment')),
              icon: EquipmentIcon,
              selected: Boolean(useRouteMatch({ path: '/sites/:locationId/equipment/:equipmentId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/sites/:locationId/equipment/:equipmentId' })),
              path: `/sites/${locationId}/equipment/${equipmentId}`
            },
            {
              title: trimText(performedServiceType?.name || ''),
              icon: TestIcon,
              selected: Boolean(useRouteMatch({ path: '/sites/:locationId/equipment/:equipmentId/performed-service/:serviceTypeId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/sites/:locationId/equipment/:equipmentId/performed-service/:serviceTypeId' })),
              path: `/sites/${locationId}/equipment/${equipmentId}/performed-service/${performedServiceType?.id}`
            },
            {
              title: trimText(historyRecord?.description || 'History'),
              icon: HistoryIcon,
              selected: Boolean(useRouteMatch({ path: '/sites/:locationId/equipment/:equipmentId/history/:historyId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/sites/:locationId/equipment/:equipmentId/history/:historyId' })),
              path: `/sites/${locationId}/equipment/${equipmentId}/history/${historyId || 'new'}`
            },
            {
              title: trimText(deficiency?.description || (isDownloading ? 'Loading...' : 'Deficiency')),
              icon: DeficiencyIcon,
              selected: Boolean(useRouteMatch({ path: '/sites/:locationId/equipment/:equipmentId/deficiency/:deficiencyId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/sites/:locationId/equipment/:equipmentId/deficiency/:deficiencyId' })),
              path: `/sites/${location?.id}/equipment/${equipmentId}/deficiency/${deficiencyId || 'new'}`
            },
          ],
          [
            {
              title: trimText('Customers'),
              icon: CustomerIcon,
              selected: Boolean(useRouteMatch({ path: '/customers', exact: true })),
              visible: Boolean(canReadLocations && billingEnabled),
              path: '/customers',
            },
            {
              title: trimText(customer?.name || (isDownloading ? 'Loading...' : 'New Customer')),
              icon: BuildingIcon,
              selected: Boolean(useRouteMatch({ path: '/customers/:customerId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/customers/:customerId' })),
              path: `/customers/${customerId}`
            },
          ],
          [
            {
              title: 'Reports',
              icon: ReportIcon,
              selected: Boolean(useRouteMatch({ path: '/reports', exact: true })),
              visible: canReadReports,
              path: '/reports',
            },
          ],
          [
            {
              title: 'Schedule',
              icon: ScheduleIcon,
              selected: Boolean(useRouteMatch({ path: '/schedule', exact: true })),
              visible: canReadSchedule,
              path: '/schedule',
            },
          ],
          [
            {
              title: trimText('Settings'),
              icon: SettingsIcon,
              selected: Boolean(useRouteMatch({ path: '/settings', exact: true })),
              visible: canReadSettings,
              path: '/settings',
            },
            {
              title: trimText('Users'),
              icon: UsersIcon,
              selected: Boolean(useRouteMatch({ path: '/users', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/users' })),
              path: '/users',
            },
            {
              title: trimText(user?.name || 'New User'),
              icon: UserIcon,
              selected: Boolean(useRouteMatch({ path: '/users/:userId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/users/:userId' })),
              path: `/users/${userId}`,
            },
            {
              title: trimText('Equipment Types'),
              icon: BoxesIcon,
              selected: Boolean(useRouteMatch({ path: '/equipment-types', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/equipment-types' })),
              path: '/equipment-types',
            },
            {
              title: trimText(equipmentType?.name || 'New Equipment Type'),
              icon: EquipmentIcon,
              selected: Boolean(useRouteMatch({ path: '/equipment-types/:equipmentTypeId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/equipment-types/:equipmentTypeId' })),
              path: `/equipment-types/${equipmentType?.id}`,
            },
            {
              title: trimText(model?.name || 'New Model'),
              icon: FingerPrintIcon,
              selected: Boolean(useRouteMatch({ path: '/equipment-types/:equipmentTypeId/models/:modelId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/equipment-types/:equipmentTypeId/models/:modelId' })),
              path: `/models/${model?.id}`,
            },
            {
              title: trimText('Site Types'),
              icon: LocationIcon,
              selected: Boolean(useRouteMatch({ path: '/site-types', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/site-types' })),
              path: '/site-types',
            },
            {
              title: trimText(siteType?.name || 'New Site Type'),
              icon: LocationIcon,
              selected: Boolean(useRouteMatch({ path: '/site-types/:siteTypeId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/site-types/:siteTypeId' })),
              path: `/site-types/${siteType?.id}`,
            },
            {
              title: trimText(serviceType?.name || 'New Service Type'),
              icon: WrenchIcon,
              selected: Boolean(useRouteMatch({ path: '/equipment-types/:equipmentTypeId/service-types/:serviceTypeId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/equipment-types/:equipmentTypeId/service-types/:serviceTypeId' })),
              path: `/equipment-types/${equipmentTypeId}/service-types/${serviceTypeId}`,
            },
            {
              title: trimText(question?.question || 'New Question'),
              icon: QuestionIcon,
              selected: Boolean(useRouteMatch({ path: '/equipment-types/:equipmentTypeId/service-types/:serviceTypeId/questions/:questionId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/equipment-types/:equipmentTypeId/service-types/:serviceTypeId/questions/:questionId' })),
              path: `/equipment-types/${equipmentType?.id}/service-types/${serviceType?.id}/questions/${question?.id}`,
            },
            {
              title: trimText('Service Month Categories'),
              icon: CalendarMonthIcon,
              selected: Boolean(useRouteMatch({ path: '/service-month-categories', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/service-month-categories' })),
              path: '/service-month-categories',
            },
            {
              title: trimText(serviceMonthCategory?.name || 'Service Month Category'),
              icon: CalendarMonthIcon,
              selected: Boolean(useRouteMatch({ path: '/service-month-categories/:serviceMonthCategoryId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/service-month-categories/:serviceMonthCategoryId' })),
              path: `/service-month-categories/${serviceMonthCategoryId}`,
            },
            {
              title: trimText('Custom Fields'),
              icon: FieldIcon,
              selected: Boolean(useRouteMatch({ path: '/custom-fields', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/custom-fields' })),
              path: '/custom-fields',
            },
            {
              title: trimText(locationField?.name || 'New Field'),
              icon: SelectedFieldIcon,
              selected: Boolean(useRouteMatch({ path: '/custom-fields/location/:fieldId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/custom-fields/location/:fieldId' })),
              path: `/custom-fields/location/${locationFieldId}`,
            },
            {
              title: trimText(modelField?.name || 'New Field'),
              icon: SelectedFieldIcon,
              selected: Boolean(useRouteMatch({ path: '/custom-fields/model/:fieldId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/custom-fields/model/:fieldId' })),
              path: `/custom-fields/model/${modelFieldId}`,
            },
            {
              title: trimText(equipmentField?.name || 'New Field'),
              icon: SelectedFieldIcon,
              selected: Boolean(useRouteMatch({ path: '/custom-fields/equipment/:fieldId', exact: true })),
              visible: Boolean(useRouteMatch({ path: '/custom-fields/equipment/:fieldId' })),
              path: `/custom-fields/equipment/${equipmentFieldId}`,
            },
            {
              title: 'Profile',
              icon: UsersIcon,
              selected: Boolean(profilePageMatch),
              visible: Boolean(profilePageMatch),
              path: '/profile',
            },
          ],
        ]
      } 
    />
  )
}
