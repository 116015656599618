import { ServiceMonthCategory } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
} = makeDataHooks<ServiceMonthCategory>({
  dataName: "ServiceMonthCategories",
});

export function useServiceMonthCategory(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    serviceMonthCategory: item,
  }
}

export function useServiceMonthCategories() {
  const { data } = useData();

  return {
    serviceMonthCategories: data,
  }
}

export function useAddServiceMonthCategory() {
  const { addItem } = useAddData();

  return {
    addServiceMonthCategory: addItem,
  }
}

export function useDeleteServiceMonthCategory() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteServiceMonthCategory: deleteItem,
  }
}

export function useUpdateServiceMonthCategory() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateServiceMonthCategory: updateItem,
  }
}
