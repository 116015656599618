import * as React from 'react';
import { ListButton } from '../../components/ListButton';
import { Equipment, TransferInsert } from '@rivison-inc/ft-types';
import { useLastTruthyValue, useControlDialog, useOpenDialogState, useHistory, useQueueMessage } from '../../hooks';
import { Dialog } from '../../containers/Dialog';
import { useUserId } from '../../auth';
import { useOrgId } from '../../auth';
import * as s from './styles';
import { SearchIcon } from '../../icons/Search';
import { ListItem } from '../../components/ListItem';
import { useLocations } from '../../data/locations';
import { ListRenderer } from '../../components/ListRenderer';
import { Text } from '../../components/Text';
import { useMutation } from 'react-query';
import { api } from '../../api';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { locationSwitcher } from '../../data/locationSwitcher';

export function TransferEquipment() {
    const { closeDialog } = useControlDialog();

    const myId = useUserId();
    const orgId = useOrgId();

    const dialogState = useOpenDialogState();

    const equipmentItem = useLastTruthyValue(dialogState.equipmentItem) as Equipment;
    const [searchText, setSearchText] = React.useState("");
    const { locations } = useLocations();
    const filteredLocations = React.useMemo(() => {
        if (!equipmentItem?.locationId) {
            return [];
        }

        return locations
            .filter((loc) => {
                return loc.id !== equipmentItem.locationId && loc.name.toLowerCase().includes(searchText.toLowerCase());
            })
            .slice(0, 20)
    }, [locations, searchText, equipmentItem?.locationId]);

    const { transferEquipment, isTransferring } = useTransferEquipment();
    const history = useHistory();
    const queueMessage = useQueueMessage();

    if (!orgId || !myId || !equipmentItem) {
        return null;
    }

    return (
        <>
            <Dialog
                name={'transfer-equipment'}
                title="Transfer Equipment"
                subtitle={'Select a site to transfer this equipment to'}
                dontStickToBottomOnMobile
            >
                <s.SearchView>
                    <s.SearchIconContainer>
                        <SearchIcon />
                    </s.SearchIconContainer>
                    <s.SearchInput
                        editable
                        value={searchText}
                        placeholder="Search Sites..."
                        onChangeText={setSearchText}
                    />
                </s.SearchView>
                <s.LocationsWrapper>
                    {isTransferring && (
                        <s.TransferSpinnerWrapper>
                            <LoadingSpinner />
                        </s.TransferSpinnerWrapper>
                    )}
                    {!isTransferring && (<>
                        {filteredLocations.map((location) => (
                            <ListItem
                                key={location.id}
                                onSelect={async () => {
                                  closeDialog();
                                        
                                  await locationSwitcher.moveLocation(equipmentItem.id, equipmentItem.locationId, location.id)

                                  queueMessage({
                                    type: 'message',
                                    message: 'Equipment swapped'
                                  });
                                  history.goBack()
                                }}
                                title={location.name || ''}
                                subtitle={`${location.address || ''} ${location.city || ''} ${location.province || ''}`}
                            />
                        ))}
                        {filteredLocations.length === 20 && <Text color='secondary' paddingLeft='sm' paddingRight='sm' paddingTop='md' paddingBottom='md'>Type more characters to narrow the search further</Text>}
                    </>)}
                </s.LocationsWrapper>
                <ListButton
                    label="Cancel"
                    onPress={() => {
                        closeDialog();
                    }}
                />
            </Dialog>
        </>
    );
}

function useTransferEquipment() {
    const { mutate, isLoading } = useMutation(async (transfer: TransferInsert) => {
        await api.post(`/organizations/${transfer.orgId}/locations/${transfer.fromLocationId}/equipment/transfers`, {
            data: transfer
        });
    });

    return {
        transferEquipment: mutate,
        isTransferring: isLoading
    }
}