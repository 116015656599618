import { ReportDef } from '../types';
import moment from 'moment';

export const questionnaireReport: ReportDef = {
  reportName: 'Questionnaire Report',
  description: 'List of equipment between a range of dates with answers to the questions asked when performing a service or inspection.',
  type: 'location',
  layout: 'landscape',
  dialog: {
    title: 'Questionnaire Report',
    message: 'Fill out the options below to prepare your report.',
    form: () => ([
      {
        type: 'date',
        label: 'Start Date',
        id: 'startDate',
        default: moment().startOf('month').toISOString(),
      },
      {
        type: 'date',
        label: 'End Date',
        id: 'endDate',
        default: moment().endOf('month').toISOString(),
      },
    ]),
  },
  endpoint: '/reports/questionnaireReport',
};
