import * as React from 'react';
import { Text } from '../../components/Text';
import { ButtonLink } from '../../components/Router';
import { PaneButton } from '../../components/PaneButton';
import { Box } from '../../components/Box';
import { Column } from '../../components/Column';
import { useOrganization, useSaveOrganization } from '../../data/organization';

import { Organization } from '@rivison-inc/ft-types';

export function WelcomePage() {
  const { organization } = useOrganization();
  const { saveOrganization } = useSaveOrganization();

  // Stripe will open this page, letting us know billing has been set up
  React.useEffect(() => {
    if (organization && !organization.hasSetUpBilling) {
      const updatedOrg: Organization = {
        ...organization,
        hasSetUpBilling: true,
      }
      
      saveOrganization(updatedOrg);
    }
  }, [organization]);

  return (
    <Box>
      
      <Column alignX="center">

        <Box >
          <Text size="xxxl" color="white">
            Welcome, {organization?.name}
          </Text>
        </Box>

        <Box paddingTop="xs" maxWidth={400}>
          <Text size="md" color="secondaryOnDark" alignX="center">
            Get started by checking out our tutorials on YouTube or setting up your account in settings.
          </Text>
        </Box>

        <Box paddingTop="lg" >
          <ButtonLink to="/settings">
            <PaneButton label="Open Settings" onPress={() => { return null; }} />
          </ButtonLink>
        </Box>

      </Column>

    </Box>
  )
}
