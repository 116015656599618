import * as React from 'react';
import { Platform } from 'react-native';
import { useQueueMessage } from '../../hooks';
import styled from 'styled-components/native';
import { ListButton } from '../../components/ListButton';
import { ListSwitch } from '../../components/ListSwitch';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListTextField } from '../../components/ListTextField';
import { ButtonLink } from '../../components/Router';
import { Row } from '../../components/Row';
import { Translate } from '../../components/Translate';
import { Box } from '../../components/Box';
import { Text } from '../../components/Text';
import { useHistory } from '../../hooks';
import { useClearLoginError, useLogin, useLoginStatus, useSignUp } from '../../auth';
import { Logo } from '../../components/Logo';
import { Column } from '../../components/Column';
import { api } from '../../api';
import SplashScreen from 'react-native-splash-screen';
import { TextLink } from '../../components/TextLink';
import { useEnviroment } from '../../hooks/useEnviroment';

const BottomText = styled.Text`
  color: rgba(255,255,255,0.5);
`;

const Container = styled.View`
  border-radius: ${(props) => props.theme.borderRadius}px;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  background-color: white;
`;

export function LoginPage() {
  const history = useHistory();
  const allowSignUp = Platform.OS === 'web';
  const isSignUp = history.location.pathname.indexOf('/sign-up') === 0;
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [agreedToTerms, setAgreedToTerms] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [allowMarketingEmails, setAllowMarketingEmails] = React.useState(false);
  const [organizationName, setOrganizationName] = React.useState('');
  const [flameTaskPlan, setFlameTaskPlan] = React.useState('Basic');
  const loginStatus = useLoginStatus();
  const queueMessage = useQueueMessage();
  const { isBeta } = useEnviroment();
  const apiUrlOverride = isBeta ? 'https://api.dev.flametask.com' : '';

  const { login } = useLogin();
  const { signUp, isSigningUp } = useSignUp();
  const { clearLoginError } = useClearLoginError();

  React.useEffect(() => {
    if (SplashScreen) {
      SplashScreen.hide();
    }
  }, []);

  return (
    <Box width="full" alignY="center" alignX="center">
      <Row align="center" paddingBottom="md">
        <Logo />
      </Row>
      {apiUrlOverride !== '' && (
        <Text color="white" paddingBottom="md">Dev Environment</Text>
      )}
      <Container>
        <ListTextField 
          label={'Email'}
          value={email} 
          onChange={(value) => {
            setEmail(value);
            clearLoginError();
          }} 
          autofocus
          type='email'
        />

        <ListTextField 
          label={'Password'}
          value={password} 
          onChange={(value) => {
            setPassword(value);
            clearLoginError();
          }} 
          onEnter={() => {
            login({ email, password, apiUrlOverride });
          }}
          type='password'
        />

        {isSignUp && (<>
          <ListTextField 
            label={'Name'}
            value={name} 
            onChange={setName} 
            type='name'
          />
        
          <ListTextField 
            label={'Company Name'}
            value={organizationName} 
            onChange={setOrganizationName} 
          />

          <ListDropdownField 
            label="Plan" 
            options={[
              {
                value: 'Basic',
                label: 'Basic'
              },
              {
                value: 'Enterprise',
                label: 'Enterprise'
              }
            ]}
            value={flameTaskPlan}
            onChange={(val) => val && setFlameTaskPlan(val)}
            annotation={
              flameTaskPlan === 'EMS' ?
              'The EMS plan is a discounted plan for non-profit or publicly funded emergency services.  Please choose another plan if you do not match this criteria' :
              undefined
            }
          />

          <ListSwitch 
            label={"Send me release notes, announcements, and other marketing emails" }
            value={allowMarketingEmails}
            onChange={setAllowMarketingEmails}
          />

          <ListSwitch 
            label={<Text size="sm">Agree to <TextLink newTab underline to="https://flametask.com/terms">terms and conditions</TextLink></Text>}
            value={agreedToTerms}
            onChange={setAgreedToTerms}
          />
        </>)}
        
        {isSignUp && (
          <ListButton 
            label={'Sign Up'}
            loading={isSigningUp}
            onPress={() => {
              signUp({
                email, 
                password, 
                name, 
                organizationName, 
                flameTaskPlan,
                agreedToTerms
              });
            }}
          />
        )}

        {!isSignUp && (
          <ListButton 
            label={loginStatus === 'authenticating' ? 'Logging in...' : 'Login'}
            onPress={() => {
              login({ email, password, apiUrlOverride });
            }}
          />
        )}
      </Container>

      <Row align="center" paddingTop={'md'}>
        {isSignUp && (
          <ButtonLink to="/">
            <BottomText>
              <Translate text={'Already have an account? Login'} />
            </BottomText>
          </ButtonLink>
        )}

        {!isSignUp && allowSignUp && (
          <Column alignX="center">
            <ButtonLink to="/sign-up">
              <BottomText>
                <Translate text={'Don\'t have an account? Sign Up'} />
              </BottomText>
            </ButtonLink>
            <ButtonLink 
              onPress={async () => {
                if (!email) {
                  queueMessage({ message: 'Enter your email address', type: 'error' });
                  return;
                }
                
                await api.post('/users/reset-password', { params: { email } });
                queueMessage({ message: 'If this email matched an account, a link was sent to reset your password.', type: 'message' });
              }}
            >
              <BottomText>
                <Translate text={'Forgot Password'} />
              </BottomText>
            </ButtonLink>
          </Column>
        )}
      </Row>
    </Box>
  )
}
