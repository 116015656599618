import * as React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components'
import { App } from '@rivison-inc/ft-app'

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #root {
    height: 100%;
  }
`;

function Root() {
  return (
    <>
      <GlobalStyle />
      <App />
    </>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

