import * as React from 'react';
import { ListButton } from '../../components/ListButton';
import { Equipment, HistoryType } from '@rivison-inc/ft-types';
import { useHistory, useLastTruthyValue, useControlDialog, useQueueMessage, useOpenDialogState } from '../../hooks';
import { Dialog } from '../../containers/Dialog';
import { useAddHistoryRecord } from '../../data/history';
import { useUserId } from '../../auth';
import { useOrgId } from '../../auth';
import { userTracker, trackingCategory } from '../../controllers/userTracker';

export function CouldNotService() {
  const { closeDialog } = useControlDialog();
  const { addHistoryRecord } = useAddHistoryRecord();

  const myId = useUserId();
  const orgId = useOrgId();

  const dialogState = useOpenDialogState();

  const equipmentItem = useLastTruthyValue(dialogState.equipmentItem) as Equipment;

  if (!orgId || !myId) {
    return null;
  }

  return (
    <>
    <Dialog
      name={'could-not-service'}
      title="Could Not Service"
      subtitle={'Selecting an option will add a history record and remove it from the todo list'}
    >
      <ListButton 
        label="Could not access"
        onPress={() => {
          if (equipmentItem) {
            addHistoryRecord( {
              date: new Date().toISOString(),
              description: 'Could not access',
              equipmentId: equipmentItem.id,
              orgId,
              happenedAtLocationId: equipmentItem.locationId,
              locationId: equipmentItem.locationId,
              userId: myId,
              type: HistoryType.COULD_NOT_SERVICE,
            }, equipmentItem.locationId);
            closeDialog();
            userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Could not service');
          }
        }}
      />
      <ListButton 
        label="Did not want done"
        onPress={() => {
          if (equipmentItem) {
            addHistoryRecord({
              date: new Date().toISOString(),
              description: 'Did not want done',
              equipmentId: equipmentItem.id,
              orgId,
              happenedAtLocationId: equipmentItem.locationId,
              locationId: equipmentItem.locationId,
              userId: myId,
              type: HistoryType.COULD_NOT_SERVICE,
            } , equipmentItem.locationId);
            closeDialog();
            userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Did not want done');
          }
        }}
      />
      <ListButton 
        label="Not in at the time of inspection"
        onPress={() => {
          if (equipmentItem) {
            addHistoryRecord({
              date: new Date().toISOString(),
              description: 'Not in at the time of inspection',
              equipmentId: equipmentItem.id,
              orgId,
              happenedAtLocationId: equipmentItem.locationId,
              locationId: equipmentItem.locationId,
              userId: myId,
              type: HistoryType.COULD_NOT_SERVICE,
            }, equipmentItem.locationId);
            closeDialog();
            userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Not in at the time of inspection');
          }
        }}
      />
      <ListButton 
        label="Done by others"
        onPress={() => {
          if (equipmentItem) {
            addHistoryRecord({
              date: new Date().toISOString(),
              description: 'Done by others',
              equipmentId: equipmentItem.id,
              orgId,
              happenedAtLocationId: equipmentItem.locationId,
              locationId: equipmentItem.locationId,
              userId: myId,
              type: HistoryType.COULD_NOT_SERVICE,
            }, equipmentItem.locationId);
            closeDialog();
            userTracker.trackEvent(trackingCategory.EQUIPMENT, 'Done by others');
          }
        }}
      />
      <ListButton 
        label="Cancel"
        onPress={() => {
          closeDialog();
        }}
      />
    </Dialog>
    </>
  );
}
