export type SizingCode = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
export type HeadingLevelCode = '1' | '2' | '3' | '4' | '5' | '6';
export type TextSizingCode = SizingCode;
export type WeightSizingCode = 'normal' | 'medium' | 'strong' | 'extra';

export interface Theme {
  spacing: {
    [key in SizingCode]: string;
  };
  borderRadius: number;
  palette: {
    primary: string;
    darkBackground: string;
    fadedButton: string;
    lightBackground: string;
    negativeBackground: string;
    lineColor: string;
    highlightColor: string;
    fadedIconColor: string;
    searchIconColor: string;
    paneColor: string;
    fadedText: string;
    searchBarColor: string;
    snackbar: {
      message: string;
      warning: string;
      error: string;      
    };
    error: string;
    darkError: string;
  };
  typography: {
    headingSizes: {
      [key in HeadingLevelCode]: string;
    };
    textSizes: {
      [key in TextSizingCode]: string;
    };
    weights: {
      [key in WeightSizingCode]: string;
    };
  };
  zIndex: {
    dialog: number;
    snackBar: number;
  };
}

export const theme: Theme = {
  spacing: {
    xxs: '2px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
    xxxl: '70px',
  },
  borderRadius: 12,
  palette: {
    primary: '#86bf57',
    fadedButton: 'rgba(0,0,0,0.025)',
    darkBackground: '#3E4047',
    lightBackground: 'white',
    negativeBackground: '#FFD9D6',
    lineColor: 'rgba(0,0,0,0.06)',
    highlightColor: '#83BC56',
    fadedIconColor: '#b5b5b5',
    searchIconColor: 'rgba(0,0,0,0.3)',
    searchBarColor: '#F7F7F7',
    fadedText: 'rgba(0,0,0,0.7)',
    paneColor: '#E5E5E5',
    snackbar: {
      message: '#65a232',
      warning: '#F7CD50',
      error: '#D76055',    
    },
    error: '#ff0000',
    darkError: '#b51b1b',
  },
  typography: {
    headingSizes: {
      '1': '20px',
      '2': '16px',
      '3': '14px',
      '4': '12px',
      '5': '12px',
      '6': '12px',
    },
    textSizes: {
      xxxl: '65px',
      xxl: '40px',
      xl: '25px',
      lg: '20px',
      md: '16px',
      sm: '14px',
      xs: '12px',
      xxs: '10px',
    },
    weights: {
      normal: '400',
      medium: '500',
      strong: '700',
      extra: '900',
    }
  },
  zIndex: {
    dialog: 500,
    snackBar: 600
  }
}
