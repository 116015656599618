import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { ListItem } from '../../components/ListItem';
import { IconButton } from '../../components/IconButton';
import { EmptyState } from '../../components/EmptyState';
import { AddIcon } from '../../icons/Add';
import { Permissions } from '@rivison-inc/ft-types';
import { useHasPermission } from '../../auth';
import { useSiteTypes } from '../../data/siteTypes';
import { BackButton } from '../../components/BackButton';

function AddButton() {  
  return (
    <IconButton to="/site-types/new" label="Add Site Type">
      <AddIcon />
    </IconButton>
  )
}

export function SiteTypeListPage() {
  const canRead = useHasPermission(Permissions.SETTINGS_READ);
  if (!canRead) {
    return <></>;
  }
  
  const { siteTypes } = useSiteTypes();

  return (
    <Panes backgroundColor="light" leftButton={<BackButton />}>
      <Pane 
        title="Site Types" 
        rightButton={<AddButton />}
        tooltip={{ title: 'Site Types', text: 'Site types allow you to have different service types at different types of sites. For example, you may have to do monthly inspections at one site and only an annual inspection at another.' }}
      >
        {siteTypes.map((siteType, idx) => (
          <ListItem
            key={idx} 
            to={`/site-types/${siteType.id}`}
            title={siteType.name}
          />
        ))}
        {!siteTypes.length && <EmptyState title={'No Site Types'} subtitle={'Site types allow you to change service types based on the site. For example, only monthly customers would show the monthly inspection.'} image={'location'} />}
      </Pane>
    </Panes>
  )
}
