import * as React from 'react';
import { ListTextField } from '../../components/ListTextField';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { Field } from '../../components/Form';

export const ServiceMonthCategoriesForm = () => {
  return (
    <>
      
      <Field 
        as={ListTextField} 
        name="name" 
        label="Name" 
        type="text"
        required
      />
    </>
  )
}
  
