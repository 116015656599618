import * as React from 'react';
import { Field, Form } from '../../components/Form';
import { PaneButton } from '../../components/PaneButton';
import { Pane, Panes } from '../../components/Panes';
import { SubmitButton } from '../../components/SubmitButton';
import { useUserId } from '../../auth';
import { BackButton } from '../../components/BackButton';
import { useUpdateUser, useUser } from '../../data/users';
import { ListTextField } from '../../components/ListTextField';
import { useHistory } from 'react-router';
import { useQueueMessage } from '../../hooks';
import { ListFileUpload } from '../../components/ListFileUpload';
import { getUploadUrlForFile } from '../../utils/file';
import { useOrganization } from '../../data/organization';
import { api } from '../../api';

export function ProfilePage() {
  const myId = useUserId();
  const { user } = useUser(myId);
  const { updateUser } = useUpdateUser();
  const history = useHistory();
  const queueMessage = useQueueMessage();
  const organization = useOrganization().organization;

  if (!myId || !user) {
    return null;
  }

  return (
    <Form 
      initialValues={user}
      onSubmit={async (updates) => {

        if (updates.image && typeof updates.image !== 'string') {
          const uploadUrl = await getUploadUrlForFile(updates.image || '', 'profile-pictures', { orgId: organization.id });
          if (uploadUrl?.fileName) {
            await api.upload(uploadUrl.url, updates.image);
            updates.image = uploadUrl.fileName;
          }
        }

        await updateUser(myId, updates);
        history.goBack();

        queueMessage({ message: "Successfully updated profile", type: "message" });

      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title={user.name as any}
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" />}
        >
          <Field
            name="name"
            as={ListTextField}
            label="Name"
            required
          />
          <Field
            name="email"
            type="email"
            as={ListTextField}
            label="Email"
            required
          />
          <Field
            name="password"
            as={ListTextField}
            label="Password"
            type="password"
            placeholder="(Hidden)"
          />
          <Field 
            as={ListFileUpload} 
            name="image" 
            label="Profile Image" 
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
