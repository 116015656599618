import * as React from 'react';
import { useParams, useHistory } from '../../hooks';
import { Pane, Panes } from '../../components/Panes';
import { useQuestions } from '../../data/questions';
import { useHasPermission, useOrgId, useUserId } from '../../auth';
import { EquipmentFieldData, HistoryType, Permissions, QuestionOption } from '@rivison-inc/ft-types';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListTextField } from '../../components/ListTextField';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { SubmitButton } from '../../components/SubmitButton';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { Field } from '../../components/Form';
import { useAddHistoryRecord, useUpdateHistoryRecord } from '../../data/history';
import { useAddDeficiency } from '../../data/deficiencies';
import { useOrganization } from '../../data/organization';
import moment from 'moment';
import { useEquipment, useEquipmentItem, useUpdateEquipment } from '../../data/equipment';
import { useEquipmentFields } from '../../data/equipmentFields';

export function QuestionsAnswerPage() {
  const canRead = useHasPermission(Permissions.SERVICE_WRITE);
  const params = useParams<{ serviceTypeId: string; locationId: string; historyId: string; equipmentId: string }>();
  const history = useHistory();
  const { questions } = useQuestions({ serviceTypeId: params.serviceTypeId });
  const { updateHistoryRecord } = useUpdateHistoryRecord();
  const { addDeficiency } = useAddDeficiency();
  const { organization } = useOrganization();
  const { updateEquipment } = useUpdateEquipment();
  const { equipmentItem } = useEquipmentItem({ id: params.equipmentId, locationId: params.locationId });
  const { addHistoryRecord } = useAddHistoryRecord();
  const orgId = useOrgId();
  const myId = useUserId();
  const { equipmentFields } = useEquipmentFields();

  if (!canRead) {
    return <></>;
  }

  return (
    <Form 
      initialValues={questions.map(question => ({ orgId: orgId!, historyRecordId: params.historyId, question: question.question, questionId: question.id, textAnswer: '', multipleChoiceAnswer: '', }))}
      onSubmit={async (questionnaireAnswers) => {

        Object.entries(questionnaireAnswers).forEach(([idx, answer]) => {
          const question = questions.find(question => question.id === answer.questionId);
          const options: QuestionOption[] = question?.options || [];
          const selectedOption = options.find(option => option.id === answer.multipleChoiceAnswer);

          // Add deficiency if there is one
          if (selectedOption?.deficiencyToAdd) {
            addDeficiency({
              orgId: organization.id,
              description: selectedOption.deficiencyToAdd,
              dateOpened: moment().toISOString(),
              equipmentId: params.equipmentId,
              happenedAtLocationId: params.locationId,
            }, params.locationId)
          }

          if (question?.fieldToSet && answer.textAnswer) {
            const updatedFieldData = {
              ...equipmentItem.fieldData || {},
            };
            updatedFieldData[question?.fieldToSet!] = answer.textAnswer
            updateEquipment(
              params.equipmentId,
              {
                fieldData: updatedFieldData,
              },
              params.locationId
            );

            const fieldData: { [key: string]: string } = equipmentItem?.fieldData || {};
            const previousFieldValue = fieldData[question?.fieldToSet!]
            if (answer.textAnswer !== previousFieldValue) {
              const equipmentField = equipmentFields.find(equipmentField => equipmentField.id === question?.fieldToSet);
              addHistoryRecord({
                date: new Date().toISOString(),
                description: `${equipmentField?.name} changed from ${previousFieldValue || 'nothing'} to ${answer.textAnswer}`,
                equipmentId: equipmentItem.id,
                orgId: orgId!,
                happenedAtLocationId: params.locationId,
                locationId: params.locationId,
                userId: myId!,
                type: HistoryType.DATA_CHANGE,
                changes: {
                  before: {
                    fieldId: previousFieldValue
                  },
                  after: {
                    fieldId: answer.textAnswer,
                  }
                }
              }, params.locationId);
            }
          }

          // Change question id to answer text
          questionnaireAnswers[idx as any] = {
            ...answer,
            multipleChoiceAnswer: selectedOption?.description || '',
          }
        });
        
        await updateHistoryRecord(
          params.historyId,
          {
            questionnaireAnswers,
          },
          params.locationId
        );
        history.goBack();
      }}
    >
      <Panes backgroundColor="light">
        <Pane 
          title="Checklist" 
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" />}
        >
          {questions.map((question, idx) => {
            const options = question.options || [];
            return (
            <>
              <ListSectionTitle>
                {question.question}
              </ListSectionTitle>
              {Boolean(options.length) && (
                <Field 
                  as={ListDropdownField} 
                  name={`${idx}.multipleChoiceAnswer`} 
                  options={options.map((option) => ({ value: option.id, label: option.description }))}
                  required={Boolean(options.length)}
                  label={'Answer'}
                  hasEmptyOption
                />
              )}
              {Boolean(question.allowComments) && (
                <Field 
                  as={ListTextField} 
                  name={`${idx}.textAnswer`} 
                  label={options.length ? "Comments" : "Answer"}
                  required={!options.length}
                />
              )}
            </>
          )})}
        </Pane>
      </Panes>
    </Form>
  )
}
