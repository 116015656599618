import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useDeleteUser, useUpdateUser, useUser } from '../../data/users';
import { useParams, useHistory } from '../../hooks';
import { UserForm } from '../../forms/UserForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { BackButton } from '../../components/BackButton';
import { api } from '../../api';
import { getUploadUrlForFile } from '../../utils/file';
import { useOrganization } from '../../data/organization';

export function EditUserPage() {
  const history = useHistory();
  const params = useParams<{ userId: string }>();
  const { user } = useUser(params.userId);
  const { updateUser } = useUpdateUser();
  const { deleteUser } = useDeleteUser();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);
  const organization = useOrganization().organization

  if (!user) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={user} 
      onSubmit={async (updates) => {

        if (updates.image && typeof updates.image !== 'string') {
          const uploadUrl = await getUploadUrlForFile(updates.image || '', 'profile-pictures', { orgId: organization.id });
          if (uploadUrl?.fileName) {
            await api.upload(uploadUrl.url, updates.image);
            updates.image = uploadUrl.fileName;
          }
        }

        await updateUser(user.id, updates);
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="User Information"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" disabled={!canWrite} />}
        >
          <UserForm />
          <DeleteButton 
            name="user"
            deleteItemNew={deleteUser}
            itemId={user.id}
            label="Delete User"
            yesMessage="Yes, Delete User"
            question="Delete User?"
            successMessage="User Deleted"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
