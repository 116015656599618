import { useRef } from 'react';

export function useLastTruthyValue<T extends any>(value: T): T {
  const valueRef = useRef(value);
  if (value) {
    valueRef.current = value;
  }

  return valueRef.current;
}


export { useLocation } from './useLocation';
export { useHistory } from './useHistory';
export { useQuery } from './useQuery';
export { useParams } from './useParams';
export { useDeviceType, withDeviceType } from './useDeviceType';
export { useRouteMatch } from './useRouteMatch';
export { useTheme } from './useTheme';
export { useToggle } from './useToggle';
export { useToday } from './useToday';
export { useCheckIsMounted } from './useCheckIsMounted';
export { useQueueMessage } from './useQueueMessage';
export { useControlDialog, useOpenDialogName, useOpenDialogState } from './useControlDialog';
