import * as React from 'react';
import styled from 'styled-components/native';
import { TouchableWithoutFeedback, Animated } from 'react-native';
import { createPortal } from '../Portal';
import { DelayUnmount } from '../DelayUnmount';
import { Panes, Pane } from '../Panes';
import { LeftArrowIcon } from '../../icons/LeftArrow';
import { IconButton } from '../IconButton';
import { Message } from '../Translate';
import { Platform } from 'react-native';

export interface DrawerProps {
  open?: boolean;
  title: Message;
  rightButton?: React.ReactNode;
  onRequestClose?: () => void;
  children: React.ReactNode;
  disableScroll?: boolean;
}

export function DrawerBase(props: DrawerProps) {
  const [slideAnimation] = React.useState(new Animated.Value(-1000)) 
  const [fadeAnimation] = React.useState(new Animated.Value(0)) 

  React.useEffect(() => {
    if (props.open) {
      Animated.timing(
        slideAnimation,
        {
          toValue: 1,
          duration: 300,
          useNativeDriver: true
        }
      ).start();
    } else {
      Animated.timing(
        slideAnimation,
        {
          toValue: 1000,
          duration: 300,
          useNativeDriver: true
        }
      ).start();
    }
  }, [props.open]);

  React.useEffect(() => {
    if (props.open) {
      Animated.timing(
        fadeAnimation,
        {
          toValue: 1,
          duration: 300,
          useNativeDriver: true
        }
      ).start();
    } else {
      Animated.timing(
        fadeAnimation,
        {
          toValue: 0,
          duration: 300,
          useNativeDriver: true
        }
      ).start();
    }
  }, [props.open])

  return (
    <DelayUnmount mounted={props.open} delay={400}>
      <TouchableWithoutFeedback onPress={props.onRequestClose}>
        <BlackFade 
          as={Animated.View} 
          style={{ opacity: fadeAnimation }} 
        />
      </TouchableWithoutFeedback>
      <DrawerView 
        as={Animated.View}
        style={{ transform : [{ 
          translateX : slideAnimation,
        }] }}
        accessibilityRole={(Platform.OS === "web" ? "dialog" : "none") as any}
        accessibilityLabel={props.title}
      >
        <Panes 
          backgroundColor="light"
          title={props.title}
          leftButton={(
            <IconButton label="Back" onPress={props.onRequestClose}>
              <LeftArrowIcon size="lg" color="secondary" />
            </IconButton>
          )}
          rightButton={props.rightButton}
          disableScroll={props.disableScroll}
          width={1000}
        >
          <Pane>
            {props.children}
          </Pane>
        </Panes>
      </DrawerView>
    </DelayUnmount>
  );
}

const DrawerView = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(1000px);
  width: 95%; 
  max-width: 1000px;
  border-left-width: 1px;
  border-left-color: ${(props) => props.theme.palette.lineColor};
`;

const BlackFade = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6);
`;

const portalSystem = createPortal<DrawerProps>({
  defaultProps: {
    title: '' as any,
    children: null,
  },
  component: DrawerBase,
});

export const DrawerProvider = portalSystem.PortalProvider;
export const Drawer = portalSystem.PortalTrigger;
