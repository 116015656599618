import { Message } from '../../../translations';
import { ReportDef } from '../types';

export const selectiveEquipmentReport: ReportDef = {
  reportName: 'Selective Equipment Report',
  description: 'List of equipment that fits model search criteria. Example: a list of 5 pieces of equipment with model information matching 6LB ABC DRY.',
  layout: 'landscape',
  dialog: {
    title: 'Selective Equipment Report',
    message: 'Fill out the options below to prepare your report.',
    form: ({ modelFields }) => {

      const searchFields = modelFields.map((modelField) => ({
        type: 'text' as 'text',
        label: modelField.label as Message,
        id: `modelFields.${modelField.value}`,
        default: '',
      }));
      
      return [
        {
          type: 'text',
          label: 'Model Name',
          id: 'modelName',
          default: '',
        },
        ...searchFields,
      ]
    },
  },
  endpoint: '/reports/selectiveEquipmentReport',
  type: 'global',
};
