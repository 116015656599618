import * as React from 'react';

/** components */
import { ListTextField } from '../../components/ListTextField';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { Field } from '../../components/Form';
import { ListDateField } from '../../components/ListDateField';

/** data */
import { useUsers } from '../../data/users';
import { useLocation, useLocations } from '../../data/locations';
import { useHistoryRecord } from '../../data/history';

interface Props {
  id?: string;
  locationId: string;
}

export function HistoryForm(props: Props) {
  const { users } = useUsers();
  const { locations } = useLocations();
  const { historyRecord } = useHistoryRecord({ id: props.id, locationId: props.locationId });
  const { location } = useLocation(props.locationId);

  if (!location) {
    return null;
  }

  return (
    <>
      
      <Field 
        as={ListTextField} 
        name="description" 
        label="Description" 
        type="text"
        required
      />
      <Field 
        as={ListDateField} 
        name="date" 
        label="Date"
        required
        granularity={'minute'}
        timeZone={location.timeZone}
      />
      <Field 
        as={ListDropdownField} 
        name="userId" 
        label="User" 
        options={users.map((user) => ({ label: user.name, value: user.id }))}
      />
      <Field 
        as={ListDropdownField} 
        name="happenedAtLocationId" 
        label="Location" 
        options={locations.map((location) => ({ label: location.name, value: location.id }))}
      />

      {Boolean(historyRecord?.questionnaireAnswers) && (<>
        {Object.entries(historyRecord?.questionnaireAnswers!).map(([idx, answer]) => (
          <>
            {Boolean(answer.question) && (
              <>
                <ListSectionTitle>{answer.question}</ListSectionTitle>
                <Field 
                  as={ListTextField} 
                  name={`questionnaireAnswers.${idx}.multipleChoiceAnswer`} 
                  label='Option Selected' 
                  type="text"
                />
                <Field 
                  as={ListTextField} 
                  name={`questionnaireAnswers.${idx}.textAnswer`} 
                  label='Text Input' 
                  type="text"
                />
              </>
            )}
          </>
        ))}
      </>)}
      
    </>
  )
}
