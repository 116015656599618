import styled from 'styled-components/native';
import { Theme } from '../../theme';

export const Container = styled.View<{ fromBottom?: boolean }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.theme.zIndex.dialog};
  background-color: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: ${(props) => props.fromBottom ? 'flex-end' : 'center'};
`;

export const DialogWindows = styled.View`
  max-width: 500px;
  width: 100%;
  text-align: center;
  background-color: white;
  border-radius: ${(props) => props.theme.borderRadius}px;
`;

export const TitleContainer = styled.View`
  padding: ${(props) => props.theme.spacing.lg};
  border-color: ${(props: { theme: Theme }) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
`;

export const Title = styled.Text`
  font-size: 20px;
  text-align: center;
`;

export const Message = styled.Text`
  font-size: 15px;
  text-align: center;
  color: ${(props) => props.theme.palette.fadedText};
  padding-top: ${(props) => props.theme.spacing.sm};
`;

export const DialogOptions = styled.View`
  
`;
