import * as React from 'react';

/** components */
import { ListTextField } from '../../components/ListTextField';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { Field } from '../../components/Form';
import { ListDateField } from '../../components/ListDateField';

/** data */
import { useUsers } from '../../data/users';
import { useLocations } from '../../data/locations';
import { useHistoryRecord } from '../../data/history';
import moment from 'moment';

interface Props {
  id: string;
}

export function ScheduledEventForm(props: Props) {
  const { users } = useUsers();
  const { locations } = useLocations();
  const currentTimeZone = moment.tz.guess() || 'America/Toronto';

  return (
    <>
      <Field 
        as={ListDateField} 
        name="date" 
        label="Date"
        required
        granularity={'day'}
        timeZone={currentTimeZone}
      />
      <Field 
        as={ListDateField} 
        name="startTime" 
        label="Start Time"
        required
        granularity={'minute'}
        onlyTime
        timeZone={currentTimeZone}
      />
      <Field 
        as={ListDateField} 
        name="endTime" 
        label="End Time"
        required
        granularity={'minute'}
        onlyTime
        timeZone={currentTimeZone}
      />
      <Field 
        as={ListDropdownField} 
        name="userId" 
        label="User" 
        options={users.map((user) => ({ label: user.name, value: user.id }))}
      />
      <Field 
        as={ListDropdownField} 
        name="locationId" 
        label="Location" 
        options={locations.map((location) => ({ label: location.name, value: location.id }))}
      />
      
    </>
  )
}
