import styled from 'styled-components/native';
import { SizingCode } from '../../theme';
import { getAlignStyles, getBackgroundStyles, getMarginStyles, getPaddingStyles } from '../../utils/style';

interface RowProps {
  /** flex */
  flexGrow?: 1;
  wrap?: boolean;

  /** color */
  backgroundColor?: 'grey';

  /** padding */
  paddingCode?: SizingCode;
  paddingTopCode?: SizingCode;
  paddingBottomCode?: SizingCode;
  paddingLeftCode?: SizingCode;
  paddingRightCode?: SizingCode;

  /** margin */
  marginCode?: SizingCode;
  marginTopCode?: SizingCode;
  marginBottomCode?: SizingCode;
  marginLeftCode?: SizingCode;
  marginRightCode?: SizingCode;

  align?: 'left' | 'center' | 'right';
  alignY?: 'left' | 'center' | 'right';
  alignX?: 'left' | 'center' | 'right';

  justifyContent?: 'space-between' | 'auto' | 'space-around';
}

export const Row = styled.View<RowProps>`
  ${getPaddingStyles}
  ${getMarginStyles}
  ${getAlignStyles}
  ${getBackgroundStyles}
  display: flex;
  flex-direction: row;
  ${(props) => props.wrap ? 'flex-wrap: wrap;' : ''}
  ${(props) => props.flexGrow ? `flex-grow: ${props.flexGrow};` : ''}
  ${(props) => props.justifyContent ? `justify-content: ${props.justifyContent};` : ''}
`;

