import { ReportDef } from '../types';

export const locationReport: ReportDef = {
  reportName: 'Site Report',
  description: 'List of all sites with their information. Options to filter by status and service month to find which sites are serviced in a specific month.',
  type: 'global',
  layout: 'landscape',
  dialog: {
    title: 'Site Report',
    message: 'Fill out the options below to prepare your report.',
    form: () => [
      {
        type: 'dropdown',
        label: 'Service Month',
        id: 'serviceMonth',
        options: [
          { value: 'all', label: 'Show All' },
          { value: 'january', label: 'january' },
          { value: 'february', label: 'february' },
          { value: 'march', label: 'march' },
          { value: 'april', label: 'april' },
          { value: 'may', label: 'may' },
          { value: 'june', label: 'june' },
          { value: 'july', label: 'july' },
          { value: 'august', label: 'august' },
          { value: 'september', label: 'september' },
          { value: 'october', label: 'october' },
          { value: 'november', label: 'november' },
          { value: 'december', label: 'december' },
        ],
      },
      {
        type: 'dropdown',
        label: 'Status',
        id: 'status',
        options: [
          { value: 'active', label: 'Active' },
          { value: 'inactive', label: 'Inactive' },
          { value: 'bad', label: 'Bad' },
        ],
      },
    ],
  },
  endpoint: '/reports/locationReport',
};
