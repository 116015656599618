import * as React from 'react';
import { useParams } from '../../hooks';
import { Drawer } from '../../components/Drawer';
import { FloorPlanShower } from '../../components/FloorPlanShower';
import { ListButton } from '../../components/ListButton';
import { useFloorPlan } from '../../data/floorPlans';
import { userTracker } from '../../controllers/userTracker';
import { Message } from '../../translations';

export function FloorPlanDrawer(props: { floorId: string; value: string; onChange: (newValue: string) => void }) {
  const [isAdding, setIsAdding] = React.useState(false);
  const params = useParams<{ locationId: string }>();
  const { floorPlan } = useFloorPlan({ id: props.floorId, locationId: params.locationId });
  
  if (!floorPlan) {
    return null;
  }

  return (
    <>
      <ListButton
        label={'Floor Plan Location'}
        onPress={() => {
          setIsAdding(true);
          userTracker.trackModal('Floor plan drawer');
        }}
      />
      
      <Drawer 
        open={isAdding}
        title={(floorPlan.name as Message) || 'Floor Plan' }
        onRequestClose={() => {
          setIsAdding(false);
        }}
        disableScroll
      >
        <FloorPlanShower
          onChange={props.onChange}
          value={props.value}
          floorPlan={floorPlan}
        />
      </Drawer>
    </>
  )
} 
