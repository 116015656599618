/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import styled from 'styled-components/native';
import { Popover } from '../Popover';
import { ListDropdownField } from '../ListDropdownField';
import { Text } from '../Text';
import { DatePicker } from '../DatePicker';
import { View, TouchableOpacity } from 'react-native';
import moment from 'moment';
import { Theme } from '../../theme';
import { RequiredIcon } from '../../icons/Required';

export interface ListDateFieldProps {
  label: string;
  value?: string;
  onChange: (newText: string) => void;
  granularity: 'year' | 'month' | 'day' | 'hour' | 'minute';
  required?: boolean;
  onlyTime?: boolean;
  timeZone: string;
}

const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom-color: ${(props) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
`;

const FieldLabelText = styled.Text`
  padding: ${(props) => props.theme.spacing.sm};
  display: flex;
  min-width: 120px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ValueContainer = styled.View`
  background-color: rgba(0,0,0,0.03);
  border-left-width: 1px;
  border-left-color: ${(props) => props.theme.palette.lineColor};
  flex-grow: 1;
`;

const InnerContainer = styled.View`
  padding: ${(props) => props.theme.spacing.md};
`;

const RequiredContainer = styled.View`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const RequiredText = styled.Text`
  color: ${(props: { theme: Theme }) => props.theme.palette.snackbar.error};
  font-size: 10px;
`;

const XButton = styled.TouchableOpacity`
  position: absolute;
  right: 10px;
  top: 16px;
`;

export const ListDateField = (props: ListDateFieldProps) => {
  // TODO: this can probably be removed since timeZone is a required property.
  // There should be a compile-time error if timeZone is not defined
  if (!props.timeZone) {
    return null;
  }

  return (
    <ListDateFieldImpl {...props} />
  )
}

export const ListDateFieldImpl = (props: ListDateFieldProps) => {
  const ref = React.useRef<View>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  let format = 'MMM Do, YYYY h:mmA';
  const timeZone = props.timeZone;

  if (props.granularity === 'year' ) format = 'YYYY'
  if (props.granularity === 'month' ) format = 'MMM YYYY'
  if (props.granularity === 'day' ) format = 'MMM Do, YYYY'
  if (props.granularity === 'hour' ) format = 'MMM Do, YYYY hA'
  if (props.granularity === 'minute' ) format = 'MMM Do, YYYY h:mmA'
  if (props.onlyTime) format = 'h:mmA'

  return (
    <Container>
      {Boolean(props.required && !props.value) && (
        <RequiredContainer>
          <RequiredIcon />
          {/* <RequiredText>Required</RequiredText> */}
        </RequiredContainer>
      )}

      <FieldLabelText>
        {props.label}
      </FieldLabelText>

      {/* TODO: fix this */}
      <ValueContainer ref={ref as any}>
        {/* TODO: Change accessibilityLabel to accessibilityLabelBy after upgrading react-native and react-native-web*/}
        <TouchableOpacity accessibilityRole="button" accessibilityLabel={props.label} onPress={() => setIsEditing(!isEditing)}>
          <InnerContainer>
            <Text size="sm">{props.value ? moment(props.value).tz(timeZone).format(format) : ' '}</Text>
          </InnerContainer>
        </TouchableOpacity>
      </ValueContainer>

      {/* {Boolean(props.value && !isEditing) && (
        <XButton onPress={() => props.onChange('')}>
          <XIcon color={'grey'} />
        </XButton>
      )} */}

      {/* {Boolean(isEditing) && (
        <XButton onPress={() => setIsEditing(false)}>
          <UpIcon color={'grey'} />
        </XButton>
      )} */}

      <Popover open={isEditing} anchorRef={ref}>
        <DatePicker 
          granularity={props.granularity}
          value={props.value}
          onChange={props.onChange}
          onDone={() => {
            setIsEditing(false);
          }}
          onlyTime={props.onlyTime}
          timeZone={timeZone}
        />
      </Popover>
    </Container>
  )
}
