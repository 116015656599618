/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { SizingCode, theme } from '../../theme';
import { TouchableOpacity, KeyboardAvoidingView, Animated } from 'react-native';
import { SearchIcon } from '../../icons/Search';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { Ghost } from '../Ghost';
import * as s from './styles';
import { Translate, Message } from '../Translate';
import { SafeAreaBottomPadding } from '../SafeAreaBottomPadding';
import { userTracker, trackingCategory } from '../../controllers/userTracker';
import { SyncIndicator } from "../../components/SyncIndicator";
import { useHistory, useLocation } from '../../hooks';
import { Tooltip } from '../Tooltip';
import { ActivityIndicator } from '../ActivityIndicator';
import { useSync } from '../../data/syncHooks';

interface PaneProps {
  title?: Message;
  icon?: React.ReactElement;
  children: React.ReactNode;
  searchText?: string;
  onSearchChange?(searchText: string): void;
  autoFocusSearch?: boolean;
  rightButton?: React.ReactNode;
  leftButton?: React.ReactNode;
  badgeNumber?: number;
  loading?: boolean;
  tooltip?: {
    title: Message;
    text: Message;
    youtubeId?: string;
  };
}

export interface PanesProps {
  id?: string;
  backgroundColor?: 'dark' | 'light';
  stretchy?: boolean;
  grow?: boolean;
  ignoreSafeArea?: boolean;
  height?: 'content' | 'full';
  title?: Message;
  icon?: React.ReactElement;
  hideTitleBar?: boolean;
  bottomComponent?: React.ReactNode;
  rightButton?: React.ReactNode;
  leftButton?: React.ReactNode;
  disableScroll?: boolean;
  width?: number; 
  paneChanged?: (pane: number) => void;

  /** margin */
  margin?: SizingCode;
  marginTop?: SizingCode;
  marginBottom?: SizingCode;
  marginLeft?: SizingCode;
  marginRight?: SizingCode;

  /** panes */
  children: (React.ReactElement<PaneProps> | boolean)[] | React.ReactElement<PaneProps>;
}

export const Pane = (_props: PaneProps) => {
  return null;
}

export const Panes = (props: PanesProps) => {
  const location = useLocation();
  const history = useHistory();
  const { isDownloading } = useSync();
  const previousState = (location.state || {}) as { [key: string]: number };
  const previousPane = props.id ? previousState[props.id] : null;
  const [currentPane, setCurrentPane] = React.useState(previousPane || 0);
  const children = Array.isArray(props.children) ? props.children : [props.children];
  const filteredPanes = children.filter((child) => Boolean(child)) as React.ReactElement<PaneProps>[];
  const panes = filteredPanes.map((child) => child.props);
  const pane = panes[currentPane];
  const title = props.title || (pane && pane.title);
  const rightButton = (pane && pane.rightButton) || props.rightButton;
  const leftButton = (pane && pane.leftButton) || props.leftButton;
  const [syncOpacity] = React.useState(new Animated.Value(isDownloading ? 1 : 0)) 

  React.useEffect(() => {
    const animationDuration = 400;
    if (isDownloading) {
      Animated.timing(
        syncOpacity,
        {
          toValue: 1,
          duration: animationDuration,
          useNativeDriver: false
        }
      ).start();
    } else {
      Animated.timing(
        syncOpacity,
        {
          toValue: 0,
          duration: animationDuration,
          useNativeDriver: false
        }
      ).start();
    }
  }, [isDownloading])

  function changePane(idx: number) {
    if (idx > panes.length - 1) {
      return;
    }
    setCurrentPane(idx);
    if (props.paneChanged) {
      props.paneChanged(idx);
    }
    if (!props.id) {
      return;
    }
    const previousState: { [key: string]: number } = (location.state || {}) as { currentPane: number }; 
    previousState[props.id] = idx;
    history.replace(
      location.pathname, 
      previousState
    );
  }

  return (
    <s.PaneView 
      marginTopCode={props.marginTop}
      marginBottomCode={props.marginBottom}
      backgroundColor={props.backgroundColor}
      stretchy={props.stretchy}
      heightCode={props.height}
      width={props.width}
      accessibilityLabel={title}
    >

      {!props.hideTitleBar && (
        <s.HeaderView>
          <s.TitleView justifyContent={(rightButton || leftButton) ? 'space-between' : 'center'}>
            {leftButton}
            {rightButton && !leftButton && (
              <Ghost>
                {rightButton}
              </Ghost>
            )}
            <Heading level="1" weight="medium">
              {title && <Translate text={title} />}
              {pane.tooltip && (
                <Tooltip title={pane.tooltip.title} text={pane.tooltip.text} youtubeId={pane.tooltip.youtubeId} />
              )}
            </Heading>
            {rightButton}
            {!rightButton && leftButton && (
              <Ghost>
                {leftButton}
              </Ghost>
            )}
          </s.TitleView>
        </s.HeaderView>
      )}

      {pane && pane.onSearchChange && (
        <s.SearchView>
          <s.SearchIconContainer>
            <SearchIcon />
          </s.SearchIconContainer>
          <s.SearchInput
            editable
            value={pane.searchText}
            placeholder="Search"
            onChangeText={(text) => {
              if (pane.onSearchChange) {
                pane.onSearchChange(text)
              }
            }}
            autoFocus={pane.autoFocusSearch}
          />
        </s.SearchView>
      )}

      <KeyboardAvoidingView style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }} behavior="padding" enabled>
        {!props.disableScroll && (
          <s.ScrollView >
            {pane && pane.children}
          </s.ScrollView>
        )}
        {!!props.disableScroll && pane.children}
      </KeyboardAvoidingView>

      <s.SyncBar>
        <s.SyncMover
          as={Animated.View}
          style={{ opacity: syncOpacity }}
        >
          <ActivityIndicator size={'large'} color={theme.palette.primary} />
        </s.SyncMover>
      </s.SyncBar>

      {panes.length >= 2 && (
        <s.Tabs>
          {panes.map((tab, idx) => {
            const icon = tab.icon ? React.cloneElement(
              tab.icon, 
              { color: idx === currentPane ? 'white' : theme.palette.fadedText }
            ) : <></>;
            return (
              <TouchableOpacity 
                onPress={() => {
                  changePane(idx);
                  userTracker.trackEvent(trackingCategory.NAVIGATION, 'Pane Button Pressed', { additionalInfo: (tab.title || 'Failed to get title') })
                }} 
                style={{ flex:1, }}
                key={idx}
              >
                <s.Tab selected={idx === currentPane}>
                  {Boolean(tab.badgeNumber) && <s.Badge><s.BadgeText>{tab.badgeNumber}</s.BadgeText></s.Badge>}
                  {tab.icon && <s.IconHolder >{icon}</s.IconHolder>}
                  <Text size="sm" color={idx === currentPane ? 'white' : 'faded'}>{tab.title}</Text>
                </s.Tab>
              </TouchableOpacity>
            )
          })}
        </s.Tabs>
      )}

      {/* Putting this below bottomComponent will make the scan barcode button look weird*/}
      {!props.ignoreSafeArea && <SafeAreaBottomPadding default={props.bottomComponent ? 'off' : 'on'} />}

      {props.bottomComponent}
    </s.PaneView>
  )
}
