import * as React from 'react';
import { ListButton } from '../../components/ListButton';
import { useHistory, useQueueMessage, useControlDialog } from '../../hooks';
import { Dialog } from '../Dialog';
import { Message } from '../../translations';
import { userTracker, trackingCategory } from '../../controllers/userTracker';

export interface DeleteButtonProps {
  name: string;
  itemId: string;
  label?: Message;
  question: Message;
  yesMessage: Message;
  successMessage: Message;
  deleteItemNew?: (itemId: string) => any;
}

export function DeleteButton(props: DeleteButtonProps) {
  const { openDialog, closeDialog } = useControlDialog();
  const history = useHistory();
  const queueMessage = useQueueMessage();

  return (
    <>
      <Dialog
        name={`confirm-${props.name}-delete`}
        title={props.question}
      >
        <ListButton
          label={props.yesMessage}
          onPress={async () => {
            if (props.deleteItemNew) {
              await props.deleteItemNew(props.itemId);
              history.goBack();
              closeDialog();
              queueMessage({ type: 'message', message: props.successMessage });
              userTracker.trackEvent(trackingCategory.EDIT, 'Delete Button', { additionalInfo: props.name })
            }
          }}
        />
        <ListButton
          label="No, Go Back"
          onPress={closeDialog}
        />
      </Dialog>
      <ListButton 
        label={props.label || 'Delete'}
        onPress={() => {
          openDialog(`confirm-${props.name}-delete`);
        }} 
      />
    </>
  )
}
