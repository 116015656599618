import { EquipmentType } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
} = makeDataHooks<EquipmentType>({
  dataName: "EquipmentTypes",
});

export function useEquipmentType(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    equipmentType: item,
  }
}

export function useEquipmentTypes() {
  const { data, isLoading } = useData();

  return {
    equipmentTypes: data,
    isLoading
  }
}

export function useAddEquipmentType() {
  const { addItem } = useAddData();

  return {
    addEquipmentType: addItem,
  }
}

export function useDeleteEquipmentType() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteEquipmentType: deleteItem,
  }
}

export function useUpdateEquipmentType() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateEquipmentType: updateItem,
  }
}
