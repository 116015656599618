/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { XIcon } from '../../icons/X';
import * as s from './styles';
import { Message } from '../Translate';
import { Text } from '../Text';
import { ButtonLink } from '../Router';

interface Props {
  type: 'message' | 'error' | 'warning' | undefined;
  message: string | undefined;
  buttonLabel?: string;
  showing: boolean;
  closeMessage: () => void;
  actionLabel?: Message;
  actionLink?: string;
  onAction?: () => void;
}

export function SnackbarComponent(props: Props) {
  return (
    <s.Container showing={props.showing}>
      <s.Message type={props.type || 'message'}>

        <s.MessageText>{props.message || ''}</s.MessageText>

        {!!props.actionLabel && (
          <SnackbarActionButton 
            label={props.actionLabel} 
            to={props.actionLink} 
            onClick={() => {
              props.closeMessage();
              if (props.onAction) {
                props.onAction();
              }
            }} 
          />
        )}

        <TouchableOpacity onPress={() => props.closeMessage()}>
          <XIcon color="white" />
        </TouchableOpacity>

      </s.Message>
    </s.Container>
  )
}

function SnackbarActionButton(props: { label: string; to?: string; onClick?: () => void }) {
  return (
    <s.SnackbarButtonOuterContainer>
      <ButtonLink to={props.to} onPress={() => props.onClick && props.onClick()}>
        <s.SnackbarButtonInnerContainer>
          <Text color="white" size="sm">{props.label}</Text>
        </s.SnackbarButtonInnerContainer>
      </ButtonLink>
    </s.SnackbarButtonOuterContainer>
  )
}
