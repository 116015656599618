import { Deficiency, HistoryType, LocationOverride } from "@rivison-inc/ft-types";
import { useMemo } from "react";
import { useAddHistoryRecord } from "./history";
import { makeDataHooks } from "./utils";

const {
  useUpdateDataItem,
  useDeleteDataItem,
  useAddData,
  useDataById,
  useData
} = makeDataHooks<Deficiency>({
  dataName: "Deficiencies",
  useAddHistoryRecord,
  events: {
    onAdd: (deficiency, { myId, locationId, addHistoryRecord }) => {
      if (!locationId) {
        throw new Error('Location id not set when adding deficiencies');
      }
      addHistoryRecord({
        date: new Date().toISOString(),
        description: 'Deficiency opened: ' + deficiency.description,
        equipmentId: deficiency.equipmentId,
        orgId: deficiency.orgId,
        happenedAtLocationId: deficiency.happenedAtLocationId,
        locationId: locationId,
        userId: myId,
        type: HistoryType.DEFICIENCY_OPENED,
      }, locationId);
    },
    onUpdate: (originalDeficiency, updatedDeficiency, { myId, locationId, addHistoryRecord }) => {
      if (!originalDeficiency.dateClosed && updatedDeficiency.dateClosed) {
        if (!locationId) {
          throw new Error('Location id not set when adding deficiencies');
        }
        addHistoryRecord({
          date: new Date().toISOString(),
          description: 'Deficiency closed: ' + updatedDeficiency.description,
          equipmentId: updatedDeficiency.equipmentId,
          locationId: locationId,
          orgId: updatedDeficiency.orgId,
          // TODO: get from equipment (see: https://github.com/Rivison-Inc/FlameTask/issues/649)
          // happenedAtLocationId: updatedDeficiency.locationId,
          userId: myId,
          type: HistoryType.DEFICIENCY_CLOSED,
        }, locationId);
      }
    }
  }
});

export function useDeficiency(params: { id: string|undefined; locationId: string|undefined }) {
  const { item } = useDataById(params.id, params.locationId);

  return {
    deficiency: item,
  }
}

export function useDeficiencies(locationId: string) {
  const { data, isLoading } = useData(undefined, locationId);

  return {
    deficiencies: data,
    isLoading
  }
}

export function useDeficienciesForEquipment(params: { locationId: string; equipmentId: string }) {
  const { data } = useData(undefined, params.locationId);
  const deficiencies = useMemo(() => data.filter((d) => d.equipmentId === params.equipmentId), [params.equipmentId, data]);

  const openDeficiencies = useMemo(() => deficiencies.filter((d) => !d.dateClosed), [deficiencies]);
  const closedDeficiencies = useMemo(() => deficiencies.filter((d) => Boolean(d.dateClosed)), [deficiencies]);

  return {
    deficiencies,
    openDeficiencies,
    closedDeficiencies,
  }
}

export function useAddDeficiency() {
  const { addItem } = useAddData();

  return {
    addDeficiency: addItem,
  }
}

export function useDeleteDeficiency() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteDeficiency: deleteItem,
  }
}

export function useUpdateDeficiency() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateDeficiency: updateItem,
  }
}
