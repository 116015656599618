import * as React from 'react';
import styled from 'styled-components/native';
import { getMarginStyles, getPaddingStyles } from '../../utils/style';
import { SizingCode } from '../../theme';

interface ColumnViewProps {
  height?: 'full';
  width?: 'full';
  grow?: boolean;
  shrink?: boolean;

  /** padding */
  paddingCode?: SizingCode;
  paddingTopCode?: SizingCode;
  paddingBottomCode?: SizingCode;
  paddingLeftCode?: SizingCode;
  paddingRightCode?: SizingCode;

  /** margin */
  marginCode?: SizingCode;
  marginTopCode?: SizingCode;
  marginBottomCode?: SizingCode;
  marginLeftCode?: SizingCode;
  marginRightCode?: SizingCode;

  /** align */
  alignX?: 'left' | 'right' | 'center';
}

export interface ColumnProps {
  height?: 'full';
  width?: 'full';
  grow?: boolean;
  shrink?: boolean;

  /** padding */
  padding?: SizingCode;
  paddingTop?: SizingCode;
  paddingBottom?: SizingCode;
  paddingLeft?: SizingCode;
  paddingRight?: SizingCode;

  /** margin */
  margin?: SizingCode;
  marginTop?: SizingCode;
  marginBottom?: SizingCode;
  marginLeft?: SizingCode;
  marginRight?: SizingCode;

  /** align */
  alignX?: 'left' | 'right' | 'center';

  /** children */
  children: React.ReactNode;
}

const ColumnView = styled.View<ColumnViewProps>`
  height: ${(props) => props.height === 'full' ? '100%' : 'auto'};
  width: ${(props) => props.width === 'full' ? '100%' : 'auto'};
  flex: ${(props) => props.grow ? '1' : 'none'};
  flex-shrink: ${(props) => props.shrink ? '1' : '0'};
  max-width: 100%;
  ${getAlign}
  ${getPaddingStyles}
  ${getMarginStyles}
`;

function getAlign(props: ColumnViewProps) {
  if (props.alignX === 'left') {
    return 'align-items: flex-start;';
  }

  if (props.alignX === 'right') {
    return 'align-items: flex-end;';
  }

  if (props.alignX === 'center') {
    return 'align-items: center;';
  }

  return '';
}

export const Column = (props: ColumnProps) => {
  return (
    <ColumnView 
      height={props.height}
      width={props.width}
      grow={props.grow}
      shrink={props.shrink}
      paddingCode={props.padding}
      paddingTopCode={props.paddingTop}
      paddingBottomCode={props.paddingBottom}
      paddingLeftCode={props.paddingLeft}
      paddingRightCode={props.paddingRight}
      marginCode={props.margin}
      marginTopCode={props.marginTop}
      marginBottomCode={props.marginBottom}
      marginLeftCode={props.marginLeft}
      marginRightCode={props.marginRight}
      alignX={props.alignX}
    >
      {props.children}
    </ColumnView>
  )
}
