import * as React from 'react';
import styled from 'styled-components/native';
import { getBackgroundStyles, getAlignStyles } from '../../utils/style';
import { ColumnProps, Column } from '../Column';
import { SizingCode } from '../../theme';

export interface ColumnsProps {
  align?: 'left' | 'center' | 'right';
  alignY?: 'left' | 'center' | 'right';
  alignX?: 'left' | 'center' | 'right';
  height?: 'full';
  backgroundColor?: 'dark' | 'light';
  children: React.ReactElement<ColumnProps>[] | React.ReactElement<ColumnProps>;
  space?: SizingCode;
  width?: 'full';
}

const ColumnsView = styled.View<Omit<ColumnsProps, 'space'>>`
  display: flex;
  flex-direction: row;
  height: ${(props) => props.height === 'full' ? '100%' : 'auto'};
  width: ${(props) => props.width === 'full' ? '100%' : 'auto'};
  ${getBackgroundStyles};
  ${getAlignStyles};
`;

export const Columns = (props: ColumnsProps) => {
  const rawChildren = Array.isArray(props.children) ? props.children : [props.children];
  const children = rawChildren.map((child, idx) => (
    <Column 
      key={idx}
      marginRight={idx === rawChildren.length - 1 ? undefined : props.space} 
      width={child.props.width}
      {...child.props} 
    />
  ));

  return (
    <ColumnsView {...props}>
      {children}
    </ColumnsView>
  );
}
