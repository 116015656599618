import * as React from 'react';
import { Optional } from 'utility-types';
import { ServiceMonth } from '@rivison-inc/ft-types';
import { DrawerForm } from '../../components/Drawer';
import { FieldGroup } from '../../components/Form';
import { ListButton } from '../../components/ListButton';
import { ListItem } from '../../components/ListItem';
import { PaneButton } from '../../components/PaneButton';
import { EmptyState } from '../../components/EmptyState';
import { SubmitButton } from '../../components/SubmitButton';
import { ServiceMonthForm } from '../../forms/ServiceMonthForm';
import { useParams } from '../../hooks';
import { useOrgId } from '../../auth';
import { useServiceMonths, useAddServiceMonth, useUpdateServiceMonth } from '../../data/serviceMonths';
import { useServiceMonthCategories } from '../../data/serviceMonthsCategories';
import { useSync } from '../../data/syncHooks';

export function ServiceMonthDrawer() {
  const [isAdding, setIsAdding] = React.useState(false);
  const params = useParams<{ locationId: string }>();
  const orgId = useOrgId();

  const { serviceMonthCategories } = useServiceMonthCategories();
  const { serviceMonths } = useServiceMonths(params.locationId);
  const { addServiceMonth } = useAddServiceMonth();
  const { updateServiceMonth } = useUpdateServiceMonth();
  const { isDownloading } = useSync();

  const [initialValues, setInitialValues] = React.useState<{ [serviceMonthCategoryId: string]: Omit<Optional<ServiceMonth, "id">, "lastUpdated" | "deleted"> } | undefined>(undefined);

  const computedValues = React.useMemo(() => {
    return serviceMonthCategories.reduce((acc, serviceMonthCategory) => {
      const serviceMonth = serviceMonths.find((serviceMonth) => serviceMonth.serviceMonthCategoryId === serviceMonthCategory.id && serviceMonth.locationId === params.locationId) || {};
  
      acc[serviceMonthCategory.id] = {
        serviceMonthCategoryId: serviceMonthCategory.id,
        locationId: params.locationId,
        january: false,
        february: false,
        march: false,
        april: false,
        may: false,
        june: false,
        july: false,
        august: false,
        september: false,
        october: false,
        november: false,
        december: false,
        orgId: orgId || '',
        ...serviceMonth,
      };
  
      return acc;
    }, {} as { [serviceMonthCategoryId: string]: Omit<Optional<ServiceMonth, 'id'>, 'lastUpdated' | 'deleted'> })
  }, [serviceMonthCategories, serviceMonths, params.locationId, isDownloading]);

  React.useEffect(() => {
    console.log('effect')
    if (!isAdding || !initialValues) {
      setInitialValues(computedValues);
    }
  }, [computedValues, isAdding]);

  return (
    <>
      <ListButton
        label={'Service Months'}
        onPress={() => {
          setIsAdding(true);
          setInitialValues(computedValues);
        }}
      />
      
      <DrawerForm 
        open={isAdding}
        title={'Service Months'}
        rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" />}
        onRequestClose={() => {
          setIsAdding(false);
        }}
        initialValues={isAdding ? initialValues : undefined}
        onSubmit={async (serviceMonths) => {
          let numCompleted = 0;
          const serviceMonthCategoryIds = Object.keys(serviceMonths);
          for (const serviceMonthCategoryId of serviceMonthCategoryIds) {
            const serviceMonth = serviceMonths[serviceMonthCategoryId];
            if (serviceMonth.id) {
              await updateServiceMonth(
                serviceMonth.id,
                serviceMonth,
                params.locationId
              );
              numCompleted++;
              if (numCompleted === serviceMonthCategoryIds.length) {
                setIsAdding(false);
              }
            } else {
              await addServiceMonth(serviceMonth, params.locationId);
              numCompleted++;
              if (numCompleted === serviceMonthCategoryIds.length) {
                setIsAdding(false);
              }
            }
          }
        }}
      >
        {!isDownloading && initialValues && serviceMonthCategories.map((serviceMonthCategory) => (
          <FieldGroup key={serviceMonthCategory.id} name={serviceMonthCategory.id}>
            <ListItem
              title={serviceMonthCategory.name}
              subtitle={`${serviceMonthCategory.name} next dates will move to the closest month below within the year.`}
            />
            <ServiceMonthForm />
          </FieldGroup>
        ))}

        {!serviceMonthCategories.length && <EmptyState title={'No Service Month Categories'} subtitle={'Add service month categories in settings > service month categories. Service months allow you to keep different types of equipment in selected months for each location. Example: Alarms in May and October and Extinguishers in December.'} image={'serviceMonth'} />}

      </DrawerForm>
    </>
  )
} 
