/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import styled from 'styled-components/native';
import { getBackgroundStyles, getMarginStyles, getPaddingStyles, getAlignStyles } from '../../utils/style';
import { SizingCode, theme } from '../../theme';
import { ListButton } from '../ListButton';
import { Message } from '../Translate';
import { XCircleIcon } from '../../icons/XCircle';
import { TouchableOpacity } from 'react-native';

export interface BoxProps {
  children: React.ReactNode;
  title: string;
  onClose?: () => void;
}

const BubbleView = styled.View`
  display: flex;
  margin: ${theme.spacing.md};
  margin-bottom: 0px;
  border-radius: 10px;
  border: 1px solid ${theme.palette.darkBackground};
  overflow: hidden;
  background-color: white;
`;

const HeaderView = styled.Text`
  padding: ${theme.spacing.md};
  font-size: ${theme.typography.textSizes.md};
  background-color: ${theme.palette.darkBackground};
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
`;

export function Bubble(props: BoxProps) {
  return (
    <>
      <BubbleView>
        <HeaderView>
          {props.title}
          {props.onClose && (
            <TouchableOpacity onPress={() => props.onClose && props.onClose()}>
              <XCircleIcon />
            </TouchableOpacity>
          )}
        </HeaderView>
        {props.children}
      </BubbleView>
    </>
  )
}
