import * as React from 'react';
import { EmptyState } from '../../components/EmptyState';
import { IconButton } from '../../components/IconButton';
import { ListItem } from '../../components/ListItem';
import { ListRenderer } from '../../components/ListRenderer';
import { Pane, Panes } from '../../components/Panes';
import { AddIcon } from '../../icons/Add';
import { useModelFields } from '../../data/modelFields';
import { ModelsSyncStatusMessage, useModels } from '../../data/models';
import { useHasPermission } from '../../auth';
import { Permissions, Model } from '@rivison-inc/ft-types';
import { searchData } from '../../utils/filter';
import { useParams } from '../../hooks';
import { sortByString } from '../../utils/sort';

export function ModelListPage() {
  const canRead = useHasPermission(Permissions.SETTINGS_READ);

  const params = useParams<{ equipmentTypeId: string }>();

  const { models } = useModels({ equipmentTypeId: params.equipmentTypeId });
  const { modelFields } = useModelFields();

  function getSearchData(data: Model) {
    const fields = [ 
      data.name || '', 
    ];
    return [
      ...fields,
      ...Object.values(data.fieldData || []),
    ];
  }

  const [searchText, setSearchText] = React.useState('');

  // TODO: move this to inside useModels
  const filteredModels = React.useMemo(() => {
    const ordered = sortByString(models, 'name', 'asc');
    return searchData(ordered, getSearchData, searchText)
  }, [searchText, models]);

  if (!canRead) {
    return <></>;
  }

  return (
    <Panes backgroundColor="light">
      <Pane 
        title="Models" 
        rightButton={<AddModelButton equipmentTypeId={params.equipmentTypeId} />}
        searchText={searchText}
        onSearchChange={(searchText) => setSearchText(searchText)}
      >
        <ListRenderer 
          render={(model: Model, idx: number) => (
            <ListItem
              key={idx} 
              to={`/equipment-types/${params.equipmentTypeId}/models/${model.id}`}
              title={model.name}
              subtitle={(modelFields.map((modelField) => `${modelField.name}: ${(model.fieldData && model.fieldData[modelField.id]) || ''}`).join(' '))}
            />
          )}
          data={filteredModels}
        />
        <ModelsSyncStatusMessage />
        {!filteredModels.length && (
          <EmptyState 
            title={'No Models'} 
            subtitle={'Models allow quickly adding information to equipment such as which equipment type/services belong to it.'} 
            image={'model'} 
          />
        )}
      </Pane>
    </Panes>
  )
}

function AddModelButton(props: { equipmentTypeId: string }) {  
  return (
    <IconButton to={`/equipment-types/${props.equipmentTypeId}/models/new`} label="Add Model">
      <AddIcon />
    </IconButton>
  )
}
