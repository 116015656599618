import * as React from 'react';
import { EmptyState } from '../../components/EmptyState';
import { IconButton } from '../../components/IconButton';
import { ListItem } from '../../components/ListItem';
import { Pane, Panes } from '../../components/Panes';
import { AddIcon } from '../../icons/Add';
import { useLocationFields } from '../../data/locationFields';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';

function AddButton() {  
  return (
    <IconButton to="/custom-fields/location/new" label="Add Site Field">
      <AddIcon />
    </IconButton>
  )
}

export function LocationFieldsListPage() {
  const canRead = useHasPermission(Permissions.SETTINGS_READ);
  const { locationFields } = useLocationFields();

  if (!canRead) {
    return <></>;
  }
  
  return (
    <Panes backgroundColor="light" leftButton={<BackButton />}>
      <Pane title="Site Fields" rightButton={<AddButton />}>
        {locationFields.map((locationField, idx) => (
          <ListItem
            key={idx} 
            to={`/custom-fields/location/${locationField.id}`}
            title={locationField.name}
          />
        ))}
        {!locationFields.length && <EmptyState title={'No Site Fields'} subtitle={'Site fields will allow you to enter additional information into the site, such as "Parking Location".'} />}
      </Pane>
    </Panes>
  )
}
