import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

export function useToday() {
  const [today, setToday] = useState(moment());
  const intervalRef = useRef<any>();
  
  useEffect(() => {
    if (typeof intervalRef.current !== 'undefined') {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      const now = moment();
      if (now.isAfter(today, 'day')) {
        setToday(now);
      }
    }, 1000);
  }, [today]);

  return today;
}
