import * as React from 'react';
import { ListTextField } from '../../components/ListTextField';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { Field } from '../../components/Form';
import { ListFileUpload } from '../../components/ListFileUpload';

export const FloorPlansForm = () => {
  return (
    <>
      
      <Field 
        as={ListTextField} 
        name="name" 
        label="Name" 
        type="text"
        required
      />
      <Field 
        as={ListFileUpload} 
        name="image" 
        label="Floor Plans" 
      />
    </>
  )
}
  
