import * as React from 'react';
import { ListTextField } from '../../components/ListTextField';
import { ListDropdownField } from '../../components/ListDropdownField';
import { Field } from '../../components/Form';
import { useEquipmentTypes } from '../../data/equipmentTypes';

export const EquipmentFieldForm = () => {
  const { equipmentTypes } = useEquipmentTypes();
  const equipmentTypeFormatted = equipmentTypes.map((equipmentType) => ({ value: equipmentType.id, label: equipmentType.name }));

  return (
    <> 
      <Field 
        as={ListTextField} 
        name="name" 
        label="Name" 
        type="text"
        required
      />
      <Field 
        as={ListDropdownField} 
        name="equipmentTypeId" 
        label="Equipment Type" 
        options={equipmentTypeFormatted || []}
      />
    </>
  )
}
  
