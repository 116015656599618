import * as React from 'react';
import { FontAwesomeIcon } from './helpers/FontAwesomeIcon'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { theme } from '../theme';

interface Props {
  color?: string;
}

export const SearchIcon = (props: Props) => {
  return (
    <FontAwesomeIcon icon={faSearch} color={props.color || theme.palette.searchIconColor} />
  )
}
