import * as React from 'react';
import { useParams } from '../../hooks';
import { EmptyState } from '../../components/EmptyState';
import { IconButton } from '../../components/IconButton';
import { ListItem } from '../../components/ListItem';
import { Pane, Panes } from '../../components/Panes';
import { AddIcon } from '../../icons/Add';
import { useQuestions } from '../../data/questions';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';

export function QuestionsListPage() {
  const canRead = useHasPermission(Permissions.SERVICE_WRITE);
  const params = useParams<{ equipmentTypeId: string; serviceTypeId: string }>();
  
  const { questions } = useQuestions({ serviceTypeId: params.serviceTypeId });
  
  if (!canRead) {
    return <></>;
  }

  return (
    <Panes backgroundColor="light">
      <Pane 
        title="Checklist" 
        rightButton={
            <IconButton to={`/equipment-types/${params.equipmentTypeId}/service-types/${params.serviceTypeId}/questions/new`} label="Add Question">
            <AddIcon />
          </IconButton>
        }
      >
        {questions.map((question, idx) => (
          <ListItem
            key={idx}
            to={`/equipment-types/${params.equipmentTypeId}/service-types/${params.serviceTypeId}/questions/${question.id}`}
            title={question.question}
          />
        ))}
        {!questions.length && <EmptyState title={'No Questions'} subtitle={'Add questions using the plus icon in the top right'} />}
      </Pane>
    </Panes>
  )
}
