import * as React from 'react';
import { useId } from '../../hooks/useId';

/**
 * The app needs to be wrapped in this Provider in order to use
 * `useUnsavedChangesActions` and `useHasUnsavedChanges`
 * @param props 
 */
export function UnsavedChangesDetector(props: { children: React.ReactNode }) {
  const ref = React.useRef<UnsavedChangesState>({
    dirtyForms: {},
    subscribers: {}
  });

  return (
    <UnsavedChangesContext.Provider value={ref}>
      {props.children}
    </UnsavedChangesContext.Provider>
  )
}

const UnsavedChangesContext = React.createContext<{ current: UnsavedChangesState }>({
  current: {
    dirtyForms: {},
    subscribers: {}
  }
});
UnsavedChangesContext.displayName = 'UnsavedChangesContext';

/**
 * @internal Please do not use this
 */
export function __useUnsavedChangesActions() {
  const ref = React.useContext(UnsavedChangesContext);
  return {
    markDirty(formId: string) {
      ref.current.dirtyForms[formId] = true;
      Object.keys(ref.current.subscribers).forEach((subscriberId) => {
        ref.current.subscribers[subscriberId](true);
      });
    },
    markClean(formId: string) {
      delete ref.current.dirtyForms[formId];

      const hasUnsavedChanges = Object.keys(ref.current.dirtyForms).length > 0;
      Object.keys(ref.current.subscribers).forEach((subscriberId) => {
        ref.current.subscribers[subscriberId](hasUnsavedChanges);
      });
    }
  }
}

interface UnsavedChangesState {
  dirtyForms: {
    [formId: string]: boolean;
  };
  subscribers: {
    [subscriberId: string]: (newIsDirty: boolean) => void;
  };
}

export function useHasUnsavedChanges() {
  const id = useId();
  const ref = React.useContext(UnsavedChangesContext);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  ref.current.subscribers[id] = setHasUnsavedChanges;

  React.useLayoutEffect(() => {
    return () => {
      delete ref.current.subscribers[id];
    }
  }, []);

  return hasUnsavedChanges;
}

/**
 * Returns a function that when called will return a boolean indicating if
 * there's unsaved changes. This can be more performant than
 * useHasUnsavedChanges because it doesn't cause a re-render when a form becomes
 * dirty
 */
export function useCheckHasUnsavedChanges() {
  const ref = React.useContext(UnsavedChangesContext);
  return React.useMemo(() => () => Object.keys(ref.current.dirtyForms).length > 0, [ref]);
}
