import { Question } from "@rivison-inc/ft-types";
import { useMemo } from "react";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
} = makeDataHooks<Question>({
  dataName: "Questions",
});

export function useQuestion(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    question: item,
  }
}

export function useQuestions(filters: { serviceTypeId?: string } = {}) {
  const { data } = useData();

  const filteredData = useMemo(() => {
    if (!filters.serviceTypeId) {
      return data;
    }

    return data.filter((item) => item.serviceTypeId === filters.serviceTypeId);
  }, [data, filters.serviceTypeId])

  return {
    questions: filteredData,
  }
}

export function useAddQuestion() {
  const { addItem } = useAddData();

  return {
    addQuestion: addItem,
  }
}

export function useDeleteQuestion() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteQuestion: deleteItem,
  }
}

export function useUpdateQuestion() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateQuestion: updateItem,
  }
}
