class LocksClass {
  private locks: { [lockName: string]: Promise<any>|undefined } = {};

  async getLockBeforeRunning<T extends any>(lockName: string, callback?: () => Promise<T>): Promise<T> {
    while (this.locks[lockName]) {
      await this.locks[lockName];
    }
    
    this.locks[lockName] = new Promise<T>((resolve, reject) => {
      if (!callback) {
        return;
      }
      callback()
        .then((data) => {
          delete this.locks[lockName];
          resolve(data);
        })
        .catch((err) => {
          delete this.locks[lockName];
          reject(err);
        })
    });

    return await this.locks[lockName];
  }
}

export const Locks = new LocksClass();
