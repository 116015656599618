import { ReportDef } from '../types';
import moment from 'moment';

export const equipmentReport: ReportDef = {
  reportName: 'Equipment Report',
  description: 'List of all equipment at the site with options to show services and filtering based on due service and/or inspection.',
  type: 'location',
  layout: 'landscape',
  dialog: {
    title: 'Equipment Report',
    message: 'Fill out the options below to prepare your report.',
    form: () => ([
      {
        type: 'checkbox',
        label: 'Show Services',
        id: 'showServices',
      },
      {
        type: 'dropdown',
        label: 'Filter By',
        id: 'filterByService',
        showIfEnabled: 'showServices',
        options: [
          { value: 'all', label: 'Show All' },
          { value: 'serviceOrInspection', label: 'Service or Inspection Due' },
          { value: 'service', label: 'Service Due' },
          { value: 'inspection', label: 'Inspection Due' },
        ],
        default: 'serviceOrInspection',
      },
      {
        type: 'date',
        label: 'Range Start',
        showIfEnabled: 'showServices',
        id: 'startDate',
      },
      {
        type: 'date',
        label: 'Range End',
        showIfEnabled: 'showServices',
        id: 'endDate',
        default: moment().endOf('month').toISOString(),
      },
    ]),
  },
  endpoint: '/reports/equipmentReport',
};
