import { FeatureList } from '@rivison-inc/ft-types';
import * as React from 'react';
import { Field } from '../../components/Form';
import { ListDropdownField } from '../../components/ListDropdownField';
import { ListSectionTitle } from '../../components/ListSectionTitle';
import { ListTextField } from '../../components/ListTextField';
import { useEquipmentTypes } from '../../data/equipmentTypes';
import { useModelFields } from '../../data/modelFields';
import { useIsFeatureEnabled, useOrganization } from '../../data/organization';

export function ModelForm(props: { hideEquipmentType?: boolean }) {
  const { modelFields } = useModelFields();
  const { equipmentTypes } = useEquipmentTypes();
  const equipmentTypesFormatted = equipmentTypes.map((equipmentType) => ({ value: equipmentType.id, label: equipmentType.name }));
  const { organization } = useOrganization();
  const billingEnabled = useIsFeatureEnabled(FeatureList.BILLING) && organization.showBilling;

  return (
    <>
      <Field 
        as={ListTextField} 
        name="name" 
        label="Model Number" 
        type="text"
        required
      />

      {!props.hideEquipmentType && (
        <Field 
          as={ListDropdownField} 
          name="equipmentTypeId" 
          label="Equipment Type" 
          options={equipmentTypesFormatted || []}
        />
      )}

      <ListSectionTitle>Model Information</ListSectionTitle>

      {modelFields.map((modelField, idx) => (
        <Field 
          key={idx}
          as={ListTextField} 
          name={`fieldData.${modelField.id}`} 
          label={modelField.name} 
          type="text"
        />
      ))}

      {billingEnabled && (
        <>
          <ListSectionTitle>Billing</ListSectionTitle>

          <Field 
            as={ListTextField} 
            name="sku" 
            label="SKU" 
            tooltip={{
              title: 'Equipment SKU',
              text: 'Used for the billing export for new, swapped and replaced equipment.'
            }}
          />
        </>
      )}

    </>
  )
}
  
