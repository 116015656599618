import * as React from 'react';
import { Platform, NativeModules } from 'react-native'
import { TranslationSet } from '../../translations';

/** locales */
import { en } from '../../translations/en';
import { fr } from '../../translations/fr';

export const TranslationsContext = React.createContext<TranslationSet>(en);
const Provider = TranslationsContext.Provider;

declare const window: { navigator: { language: string } };

function getLocale(): string {
  if (Platform.OS === 'ios') {
    return NativeModules.SettingsManager.settings.AppleLocale || NativeModules.SettingsManager.settings.AppleLanguages[0] || 'en';
  }

  if (Platform.OS === 'android') {
    return NativeModules.I18nManager.localeIdentifier || 'en';
  }

  if (Platform.OS === 'web') {
    return (window.navigator.language || 'en');
  }

  return 'en';
}

export const TranslationsProvider = (props: { children: React.ReactNode }) => {
  const locale: string = getLocale();
  const currentLanguage: string = locale.split('-')[0].split('_')[0];

  // TODO: we probably want to code-split the translations somehow so we're not
  // loading all the translations for everyone
  const mapTranslationsToLocale = {
    en,
    fr
  }

  const translations = currentLanguage in mapTranslationsToLocale ? mapTranslationsToLocale[currentLanguage as keyof typeof mapTranslationsToLocale] : en;
  
  return (
    <Provider value={{ ...en, ...translations }}>
      {props.children}
    </Provider>
  )
}

export const useTranslations = () => {
  return React.useContext(TranslationsContext);
}
