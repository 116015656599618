import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useHistory } from '../../hooks';
import { CustomerForm } from '../../forms/CustomerForm';
import { Form2, SubmitButton2 } from '../../components/Form2';
import { useOrgId, useHasPermission } from '../../auth';
import { useOrganization } from '../../data/organization';
import { PaneButton } from '../../components/PaneButton';
import { Permissions } from '@rivison-inc/ft-types';
import { BackButton } from '../../components/BackButton';
import { useAddCustomer } from '../../data/customers';

export function AddCustomerPage() {
  const history = useHistory();
  const orgId = useOrgId();
  const { organization: org } = useOrganization();
  const { addCustomer } = useAddCustomer();

  const country = org?.country || '';
  const canWrite = useHasPermission(Permissions.LOCATION_WRITE);

  if (!canWrite || !org || !orgId) {
    return <></>;
  }

  return (
    <Form2
      defaultValues={{
        name: '',
        address: '',
        city: '',
        orgId: orgId,
        contact: '',
        phone: '',
        cell: '',
        email: '',
        fax: '',
        addressTwo: '',
        postalCode: '',
        country: country,
        province: org.province || '',
        accountNumber: '',
      }}
      onSubmit={async (customer) => {
        const newCustomer = await addCustomer(customer)
        history.replace(`/customers/${newCustomer.id}`);
      }}
    >
      <Panes 
        backgroundColor="light"
        leftButton={<BackButton />}
      >
        <Pane 
          title="Add Customer"
          rightButton={<SubmitButton2 as={PaneButton} showFeedback label="Add" />}
        >
          <CustomerForm 
            autoGenerateAccountNumber
          />
        </Pane>
      </Panes>
    </Form2>
  )
}

