import * as React from 'react';
import { FullScreenMessage } from '../../components/FullScreenMessage';
import { useOrganization } from "../../data/organization";
import { useRouteMatch } from "../../hooks";
import styled from 'styled-components/native';
import { ListButton } from '../../components/ListButton';
import { loadStripe } from '@stripe/stripe-js';
import { api } from '../../api';
import { config } from '../../config';

const BtnContainer = styled.View`
  border-radius: ${(props) => props.theme.borderRadius}px;
  overflow: hidden; 
  width: 100%;
  max-width: 400px;
  background-color: white;
`;

const openCheckout = async (orgId: string) => {
  const stripe = await loadStripe(config.billing.publicKey)
  const response = await api.post(`/organizations/${orgId}/payments/session`, { });
  const sessionId = response.data.session.id;

  if (stripe) {
    stripe.redirectToCheckout({
      sessionId,
    });
  }
} 

export function BillingBlocker() {
  const onWhitelistedPage = useRouteMatch(['/welcome', '/login', '/sign-up']);
  const { organization: preferences } = useOrganization();

  const needsToSetupBilling = Boolean(!onWhitelistedPage && preferences?.id && !preferences.manualBilling && !preferences?.hasSetUpBilling);

  return (
    <>
      <FullScreenMessage
        title="Looks like you never finished signing up"
        subtitle="Please click the button below to start your free 3 month trial"
        opened={needsToSetupBilling}
        component={
          <BtnContainer>
            <ListButton 
              label="Start Trial"
              onPress={() => {
                openCheckout(preferences?.id!);
              }}
            />
          </BtnContainer>
        }
      />
      <FullScreenMessage
        title="Your Subscription Has Been Canceled"
        subtitle="We hope to see you again."
        opened={Boolean(preferences) && !preferences?.active}
        component={
          <BtnContainer>
            <ListButton 
              label="Reactivate"
              onPress={() => {
                openCheckout(preferences?.id!);
              }}
            />
          </BtnContainer>
        }
      />
    </>
  )
}
