import * as React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as NativeStyledThemeProvider } from 'styled-components/native';
import { theme as defaultTheme, Theme } from '../../theme';

export const ThemeProvider = (props: { theme?: Theme; children: React.ReactNode }) => {
  return (
    <StyledThemeProvider theme={props.theme || defaultTheme}>
      <NativeStyledThemeProvider theme={props.theme || defaultTheme}>
        {props.children}
      </NativeStyledThemeProvider>
    </StyledThemeProvider>
  )
}
