import * as React from 'react';
import { ListTextField } from '../../components/ListTextField';
import { ListDateField } from '../../components/ListDateField';
import { Field } from '../../components/Form';

export const DeficiencyForm = (props: { timeZone: string; }) => {
  return (
    <>
      <Field 
        as={ListTextField} 
        name="description" 
        label="Description" 
        type="text"
        required
      />
      <Field 
        as={ListDateField} 
        name="dateOpened" 
        label="Opened Date" 
        granularity={'minute'}
        required
        timeZone={props.timeZone}
      />
      <Field 
        as={ListDateField} 
        name="dateClosed" 
        label="Closed Date" 
        granularity={'minute'}
        timeZone={props.timeZone}
      />
    </>
  )
}
  
