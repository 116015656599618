/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import styled from 'styled-components/native';
import { PaddingProps, getPaddingStyles } from '../../utils/style';
import { Theme } from '../../theme';
import { useDeviceType, useToday } from '../../hooks';
import { Text } from '../../components/Text';
import { Box } from '../../components/Box';
import { Message as TranslationMessage } from '../../components/Translate';
import { Message } from '../../components/Message'
import { Row } from '../../components/Row';
import { ButtonLink } from '../../components/Router';
import { useOrganization } from '../../data/organization';
import { useGlobalAlerts } from '../../data/globalAlerts';
import { Image, View } from 'react-native';
import { Logo } from '../../components/Logo';
import { useRouteMatch } from '../../hooks';
import { useLocations } from '../../data/locations';
import { useLastTruthyValue } from '../../hooks';
import { Organization } from '@rivison-inc/ft-types';

export interface LayoutProps {
  children?: React.ReactNode;
  nav: React.ReactNode;
  isAuthenticated: boolean;
  fullScreenOnMobile?: boolean;
}

interface LayoutViewProps {
  backgroundColor?: string;
}

const LayoutView = styled.View<LayoutViewProps>`
  background-color: ${(props) => props.backgroundColor || props.theme.palette.darkBackground};
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Header = styled.View`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-left: ${(props: { theme: Theme }) => props.theme.spacing.md};
  padding-right: ${(props: { theme: Theme }) => props.theme.spacing.md};
  height: 92px;
`;

const Content = styled.View<PaddingProps>`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex: 1;
  padding-left: ${(props: { theme: Theme }) => props.theme.spacing.md};
  padding-right: ${(props: { theme: Theme }) => props.theme.spacing.md};
  ${getPaddingStyles};
`;

const MobileContent = styled.View`
  width: 100%;
  height: 100%;
`;

const Footer = styled.View`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.md};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const MessageHolder = styled.View`
  padding: ${(props: { theme: Theme }) => props.theme.spacing.sm};
  display:flex;
  flex-direction: row;
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

function calculateAspectRatioFit(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

const NavHolder = styled.View`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  left: 0;
`;

export const Layout = (props: LayoutProps) => {
  const today = useToday();
  const deviceType = useDeviceType();
  const { organization: preferences } = useOrganization();
  const { globalAlerts } = useGlobalAlerts();

  if (props.fullScreenOnMobile && deviceType !== 'desktop') {
    return (
      <MobileContent>
        {props.children}
      </MobileContent>
    );
  }

  return (
    <LayoutView 
      backgroundColor={preferences?.backgroundColor}
    >
      {globalAlerts.length > 0 && (
        <MessageHolder>
          {globalAlerts.map((alert) => (
            <Message margin={"none"} key={alert.id} type={alert.type} message={alert.message as TranslationMessage} />
          ))}
        </MessageHolder>
      )}

      {props.isAuthenticated && (
        <>
          <Header>
            <TopLeftLogo />
            <SiteName preferences={preferences} />
            <NavHolder>
              {props.nav}
            </NavHolder>
          </Header>
        </>
      )}

      <Content paddingLeftCode="md" paddingRightCode="md">
        {props.children}
      </Content>

      <Footer>
        
        <Text size="xs" color="secondary">© {today.get('year')} Rivison Inc.</Text>

        {!!preferences?.logo && (
          <Row alignY="center">
            <Text size="xs" color="secondary">Powered By</Text>
            <View style={{ marginTop: '-8px', marginLeft: '4px', marginRight: '2px' }}><Logo size="xs" /></View>
            <Text size="xs" color="secondary">Preventive Maintenance</Text>
          </Row>
        )}

        <Row>
          {/* <ButtonLink to="/debug"><Text size="xs" color="secondary">Debug</Text></ButtonLink> */}
          <Box marginLeft="sm">
            <Text size="xs" color="secondary">Version: {process.env.REACT_APP_VERSION || 'v0'}</Text>
          </Box>
        </Row>

      </Footer>

    </LayoutView>
  )
}

function TopLeftLogo() {
  const { organization: preferences } = useOrganization();
  const [imageWidth, setImageWidth] = React.useState(165);
  const [imageHeight, setImageHeight] = React.useState(32);

  React.useEffect(() => {
    if (preferences?.logo) {
      Image.getSize(preferences?.logo.toString(), (width, height) => {
          setImageWidth(width);
          setImageHeight(height);
      }, () => {}); // eslint-disable-line @typescript-eslint/no-empty-function
    }
  }, [preferences?.logo]);

  const updatedImageSize = calculateAspectRatioFit(imageWidth || 1000, imageHeight || 1000, 165, 33);

  return (
    <>
      {!preferences?.logo && (
        <View style={{ marginTop: -16 }}>
          <Logo size="sm" />
        </View>
      )}
      {!!preferences?.logo && (
        <Image source={{ uri: preferences?.logo.toString() }} style={{ width: updatedImageSize.width, height: updatedImageSize.height, resizeMode: 'contain' }} />
      )}
    </>
  )
}

function SiteName(props: { preferences: Organization }) {
  const { locations } = useLocations();
  const locationPageMatch = useRouteMatch<{ locationId: string }>({ path: '/sites/:locationId' });
  const locationId = locationPageMatch?.params.locationId;
  const location = React.useMemo(() => locations.find((loc) => loc.id === locationId), [locations, locationId]);
  const lastLocation = useLastTruthyValue(location);
  
  if (!lastLocation || !props.preferences?.showSiteSelector) {
    return (
      <Text>{""}</Text>
    )
  }

  return (
    <SiteNameWrapper>
      <Text color="white" size="md">{lastLocation?.name || ""}</Text>
      <ChooseSiteButton>
        <ButtonLink to="/sites">
          <ButtonPadding>
            <ButtonText>
                Choose Site
            </ButtonText>
          </ButtonPadding>
        </ButtonLink>
      </ChooseSiteButton>
    </SiteNameWrapper>
  )
}

const SiteNameWrapper = styled.View`
  position: relative;
  z-index: 1;
  margin-right: ${(props: { theme: Theme }) => props.theme.spacing.md};
  align-items: flex-end;
`

const ButtonPadding = styled.View`
  padding: 4px;
`;

const ButtonText = styled.Text`
  color: ${(props: { theme: Theme }) => props.theme.palette.fadedIconColor};
`;

const ChooseSiteButton = styled.View`
  margin: ${(props: { theme: Theme }) => props.theme.spacing.xs};
  background-color: rgba(0,0,0,0.2);
  border-radius: ${(props: { theme: Theme }) => props.theme.borderRadius / 2}px;
`;
