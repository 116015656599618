import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { FontAwesomeIcon } from './helpers/FontAwesomeIcon';
import { theme } from '../theme';

export const RequiredIcon = (props: { color?: string }) => {
  return (
    <FontAwesomeIcon icon={faExclamationCircle} color={props.color || theme.palette.snackbar.error} />
  )
}
