import styled from 'styled-components/native';

export const TextBox = styled.TextInput`
  padding: ${(props) => props.theme.spacing.md};
  display: flex;
  flex-grow: 1;
  box-shadow: none;
  border-color: transparent;
  outline-style: none;
`;
