import * as React from 'react';
import moment from 'moment';
import { Box } from '../../components/Box';
import { useLocation } from '../../data/locations';
import { useDeviceType, useRouteMatch } from '../../hooks';
import { Message } from '../../components/Message';
import { Translate } from '../../components/Translate';
import { Text } from '../../components/Text';
import { useSync } from '../../data/syncHooks';

export function UpToDateAsOf() {
  const { isDownloading, lastSuccessfulDownloadDate, lastSuccessfulUploadDate } = useSync()

  const formattedDate = lastSuccessfulDownloadDate ? moment(lastSuccessfulDownloadDate).format('MMM D, h:mma') : 'Never'

    return (
      <Box padding='sm'>
        <Text size='xs' alignX='center' color='secondary'>
          <Translate text={'Up to date as of {formattedDate}'} values={{ formattedDate }} />
        </Text>   
      </Box>
    )
  
}
