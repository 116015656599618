import * as React from 'react';
import { useUserId } from '../../auth';
import { FullScreenMessage } from '../../components/FullScreenMessage';
import { ListButton } from '../../components/ListButton';
import { PrimaryButton } from '../../components/PrimaryButton';
import { ButtonLink } from '../../components/Router/index.web';
import { config } from '../../config';
import { useOrganization } from "../../data/organization";
import { useUpdateUser, useUser } from '../../data/users';
import { useRouteMatch } from "../../hooks";
import styled from 'styled-components/native';
import { Text } from '../../components/Text';

const Container = styled.View`
  border-radius: ${(props) => props.theme.borderRadius}px;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  background-color: white;
`;


export function TermsAgreement() {
  const dontShowOn = useRouteMatch(['/welcome', '/login', '/sign-up']);
  const myId = useUserId();
  const { user } = useUser(myId);
  const { updateUser } = useUpdateUser();

  const currentTermsVersion = config.legal.terms.currentVersion;

  const open = Boolean(!dontShowOn && user && currentTermsVersion !== user?.acceptedTermsVersion);

  const message = user?.acceptedTermsVersion ? "Our terms have been updated" : "Please accept our terms"

  return (
    <>
      <FullScreenMessage
        title={message}
        subtitle="Please review the terms below"
        component={
          <Container>
            <ListButton 
              label={'View Terms'}
              to='https://flametask.com/terms'
              newTab
            />
            <ListButton 
              label={'Agree'}
              onPress={() => {
                if (myId) {
                  updateUser(myId, { acceptedTermsVersion: currentTermsVersion });
                }
              }}
            />
          </Container>
        }
        opened={open}
      />
    </>
  )
}
