import * as React from 'react';
import styled from 'styled-components/native';
import { SizingCode } from '../../theme';
import { Translate, Message, useTranslate } from '../Translate';
import { ButtonLink } from '../Router';
import { getMarginStyles, getPaddingStyles } from '../../utils/style';
import { Box } from '../Box';
import { SafeAreaBottomPadding } from '../SafeAreaBottomPadding';
import { Row } from '../Row';
import { LoadingSpinner } from '../LoadingSpinner';

export interface ListButtonProps {
  label: Message;
  sublabel?: Message;
  to?: string;
  newTab?: boolean;
  grow?: boolean;
  fullBorder?: boolean;
  rightBorder?: boolean;
  leftBorder?: boolean;
  onPress?: () => void;
  color?: 'primary';
  startIcon?: React.ReactNode;
  safeArea?: 'bottom';
  loading?: boolean;

  /** padding */
  padding?: SizingCode;
  paddingTop?: SizingCode;
  paddingBottom?: SizingCode;
  paddingLeft?: SizingCode;
  paddingRight?: SizingCode;

  /** margin */
  margin?: SizingCode;
  marginTop?: SizingCode;
  marginBottom?: SizingCode;
  marginLeft?: SizingCode;
  marginRight?: SizingCode;
}

interface ContainerProps { 
  grow?: boolean;
  color?: 'primary' | 'grey';

  /** padding */
  paddingCode?: SizingCode;
  paddingTopCode?: SizingCode;
  paddingBottomCode?: SizingCode;
  paddingLeftCode?: SizingCode;
  paddingRightCode?: SizingCode;

  /** margin */
  marginCode?: SizingCode;
  marginTopCode?: SizingCode;
  marginBottomCode?: SizingCode;
  marginLeftCode?: SizingCode;
  marginRightCode?: SizingCode;
}

const Container = styled.View<ContainerProps>`
  flex-grow: ${(props) => props.grow ? 1 : 0};
  ${getPaddingStyles}
  ${getMarginStyles}
  background-color: ${(props) => {
    if (props.color === 'primary') {
      return props.theme.palette.primary;
    }
    if (props.color === 'grey') {
      return props.theme.palette.fadedButton;
    }
    return 'transparent';
  }};
`;

interface ButtonViewProps {
  fullBorder?: boolean;
  rightBorder?: boolean;
  leftBorder?: boolean;
}

const ButtonView = styled.View<ButtonViewProps>`
  padding: ${(props) => props.theme.spacing.md};
  border-color: ${(props) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
  border-top-width: ${(props) => props.fullBorder ? 1 : 0}px;
  border-left-width: ${(props) => (props.fullBorder || props.leftBorder) ? 1 : 0}px;
  border-right-width: ${(props) => (props.fullBorder || props.rightBorder) ? 1 : 0}px;
`;

const Label = styled.Text<{ backgroundColor?: 'primary' }>`
  color: ${(props) => props.backgroundColor === 'primary' ? 'white' : 'black'}; /** TODO: use a function that auto-detects contrast and picks white or black */
  font-size: 16px;
`;

const Sublabel = styled.Text<{ backgroundColor?: 'primary' }>`
  color: ${(props) => props.backgroundColor === 'primary' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'}; /** TODO: use a function that auto-detects contrast and picks white or black */
  font-size: 12px;
  text-align: center;
  padding-top: ${(props) => props.theme.spacing.xxs};
`;

export const ListButton = (props: ListButtonProps) => {
  const translate = useTranslate();

  return (
    <Container 
      grow={props.grow}
      color={props.label === 'Cancel' ? 'grey' : props.color}
      paddingCode={props.padding}
      paddingTopCode={props.paddingTop}
      paddingBottomCode={props.paddingBottom}
      paddingLeftCode={props.paddingLeft}
      paddingRightCode={props.paddingRight}
      marginCode={props.margin}
      marginTopCode={props.marginTop}
      marginBottomCode={props.marginBottom}
      marginLeftCode={props.marginLeft}
      marginRightCode={props.marginRight}
    >
      <ButtonLink 
        to={props.to} 
        onPress={props.onPress}
        accessibilityLabel={translate(props.label)}
        newTab={props.newTab}
      >
        <ButtonView fullBorder={props.fullBorder} rightBorder={props.rightBorder} leftBorder={props.leftBorder}>
          <Row align="center">
            {!!props.startIcon && <Box marginRight="sm">{props.startIcon}</Box>}
            <Label backgroundColor={props.color}>
              <Translate text={props.label} />
            </Label>
            {!!props.loading && <LoadingSpinner color="primary" marginLeft="sm" />}
          </Row>
          {!!props.sublabel && <Sublabel><Translate text={props.sublabel} /></Sublabel>}
          {!!props.safeArea && <SafeAreaBottomPadding />}
        </ButtonView>
      </ButtonLink>
    </Container>
  )
}
