import { LocationField } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
} = makeDataHooks<LocationField>({
  dataName: "LocationFields",
});

export function useLocationField(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    locationField: item,
  }
}

export function useLocationFields() {
  const { data } = useData();

  return {
    locationFields: data,
  }
}

export function useAddLocationField() {
  const { addItem } = useAddData();

  return {
    addLocationField: addItem,
  }
}

export function useDeleteLocationField() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteLocationField: deleteItem,
  }
}

export function useUpdateLocationField() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateLocationField: updateItem,
  }
}
