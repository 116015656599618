import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useUpdateServiceMonthCategory, useDeleteServiceMonthCategory, useServiceMonthCategory } from '../../data/serviceMonthsCategories';
import { useParams, useHistory } from '../../hooks';
import { ServiceMonthCategoriesForm } from '../../forms/ServiceMonthCategoriesForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { DeleteButton } from '../../containers/DeleteButton';
import { BackButton } from '../../components/BackButton';

export function EditServiceMonthCategoryPage() {
  const history = useHistory();
  const params = useParams<{ serviceMonthCategoryId: string }>();
  const { deleteServiceMonthCategory } = useDeleteServiceMonthCategory();
  const { updateServiceMonthCategory } = useUpdateServiceMonthCategory();
  const { serviceMonthCategory } = useServiceMonthCategory(params.serviceMonthCategoryId);
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);

  if (!serviceMonthCategory) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={serviceMonthCategory}
      onSubmit={async (updates) => {
        await updateServiceMonthCategory(serviceMonthCategory.id, updates)
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Service Month Category"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" disabled={!canWrite} />}
        >
          {serviceMonthCategory && (
            <ServiceMonthCategoriesForm />
          )}

          <DeleteButton
            name="service-month-category"
            deleteItemNew={deleteServiceMonthCategory}
            itemId={serviceMonthCategory.id}
            question="Delete Service Month?"
            yesMessage="Yes, Delete Service Month"
            successMessage="Deleted Service Month"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
