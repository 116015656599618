import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { ListButton } from '../ListButton';
import { ListTextField } from '../ListTextField';

export const requestCameraPermission = () => Promise.resolve({ status: 'granted' });

export const BarCodeScanner = (props: { onScan: (params: { type: string; data: string }) => void }) => {
  const [barcode, setBarcode] = React.useState("123456");
  return (
    <View style={{ ...StyleSheet.absoluteFillObject, zIndex: 999999999, backgroundColor: "rgba(255,255,255,0.5)" }}>
      <ListTextField 
        label="Barcode"
        onChange={setBarcode}
        value={barcode}
      />
      <ListButton label="Save" onPress={() => props.onScan({ type: 'barcode', data: barcode })} />
    </View>
  )
}
