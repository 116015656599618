import * as React from 'react';
import styled from 'styled-components/native';
import { HeadingLevelCode, TextSizingCode, Theme, theme } from '../../theme';

const StyledText = styled.Text<{ level: HeadingLevelCode; size?: TextSizingCode; weight?: 'normal' | 'medium'; subdued?: boolean }>`
  font-size: ${getFontSize};
  font-weight: ${(props) => props.theme.typography.weights[props.weight || 'normal']};
  color: ${(props) => props.subdued ? theme.palette.fadedText : 'black'};
`;

function getFontSize(props: { level: HeadingLevelCode; size?: TextSizingCode; theme: Theme }) {
  if (props.size) {
    return props.theme.typography.textSizes[props.size];
  }

  return props.theme.typography.headingSizes[props.level];
}

export interface HeadingProps {
  level: HeadingLevelCode;
  size?: TextSizingCode;
  color?: 'subdued';
  weight?: 'normal' | 'medium';
  children: React.ReactNode;
}

export function Heading(props: HeadingProps) {
  return (
    <StyledText 
      aria-level={props.level} // This is undocumented but causes tag to be replaced with h1, h2, etc. @see: https://github.com/necolas/react-native-web/issues/401
      accessibilityRole="header" 
      size={props.size}
      level={props.level}
      weight={props.weight}
      subdued={props.color === 'subdued'}
    >
      {props.children}
    </StyledText>
  );
}
