import { ModelField } from "@rivison-inc/ft-types";
import { makeDataHooks } from "./utils";

export const {
  useData,
  useAddData,
  useDeleteDataItem,
  useUpdateDataItem,
  useDataById,
} = makeDataHooks<ModelField>({
  dataName: "ModelFields",
});

export function useModelField(id: string|undefined) {
  const { item } = useDataById(id);

  return {
    modelField: item,
  }
}

export function useModelFields() {
  const { data } = useData();

  return {
    modelFields: data,
  }
}

export function useAddModelField() {
  const { addItem } = useAddData();

  return {
    addModelField: addItem,
  }
}

export function useDeleteModelField() {
  const { deleteItem } = useDeleteDataItem();

  return {
    deleteModelField: deleteItem,
  }
}

export function useUpdateModelField() {
  const { updateItem } = useUpdateDataItem();

  return {
    updateModelField: updateItem,
  }
}
