import styled from 'styled-components/native';
import { Theme } from '../../theme';
import { withDeviceType } from '../../hooks';

interface ContainerProps {
  showing: boolean;
  deviceType: 'mobile' | 'tablet' | 'desktop';
}

export const Container = withDeviceType(styled.View<ContainerProps>`
  /** 
   * display:none does not work on android when position is absolute 
   * @see https://github.com/facebook/react-native/issues/18415
   */
  position: ${(props) => props.showing ? 'absolute' : 'relative'};
  ${(props) => props.deviceType !== 'desktop' ? '' : `bottom: ${props.theme.spacing.lg};`} /** need to leave enough space for iPhone X indicator */
  ${(props) => props.deviceType !== 'desktop' ? `top: ${props.theme.spacing.lg};` : ''} /** need to leave enough space for iPhone X indicator */
  left: 0px;
  right: 0px;
  z-index: ${(props) => props.theme.zIndex.snackBar};
  display: ${(props) => props.showing ? 'flex' : 'none'};
  justify-content: center;
  flex-direction: row;

  /** was having weird bug with react-native where changing the transform didn't work */
  /* transform: translateY(${(props) => props.showing ? '0px': '100px'}); */
`)

interface MessageProps {
  type: 'error' | 'warning' | 'message';
}

const getMessageColor = (type: MessageProps['type'], theme: Theme) => {
  if (type === 'error') return theme.palette.snackbar.error;
  if (type === 'warning') return theme.palette.snackbar.warning;
  return theme.palette.snackbar.message;
}

export const Message = styled.View<MessageProps>`
  width: 90%;
  max-width: 400px;
  background-color: ${(props) => getMessageColor(props.type, props.theme)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.borderRadius / 2}px;
  padding: ${(props) => props.theme.spacing.md};
`;

export const MessageText = styled.Text`
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
  font-size: 14px;
  color: white;
`; 

export const SnackbarButtonOuterContainer = styled.View`
  flex-shrink: 0;
  margin-top: -8px;
  margin-bottom: -8px;
`;

export const SnackbarButtonInnerContainer = styled.View`
  border: 1px solid white;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 8px;
`;
