import * as React from 'react';
import { ListTextField } from '../../components/ListTextField';
import { ListSwitch } from '../../components/ListSwitch';
import { Field, FormContext, useFormValues } from '../../components/Form';
import { ListButton } from '../../components/ListButton';
import { Box } from '../../components/Box';
import { Text } from '../../components/Text';
import { QuestionOption } from '@rivison-inc/ft-types'
import { Bubble } from '../../components/Bubble';
import { useOrganization } from '../../data/organization';
import { useParams } from '../../hooks';
import { ListDropdownField } from '../../components/ListDropdownField';
import { useEquipmentFields } from '../../data/equipmentFields';
import { shortUUID } from '../../utils/misc';

export const QuestionsForm = () => {
  const formValues = useFormValues();
  const formRef = React.useContext(FormContext);
  const options: QuestionOption[] = (formValues.options || []) as QuestionOption[];
  const { organization } = useOrganization();
  const orgId = organization?.id;
  const params = useParams<{ questionId: string; equipmentTypeId: string }>();
  const { equipmentFields} = useEquipmentFields({ equipmentTypeId: params.equipmentTypeId })
  
  const addOption = () => {
    const newOptions: QuestionOption[] = [...options];
    newOptions.push({
      description: '',
      orgId,
      deleted: false,
      id: shortUUID(),
      deficiencyToAdd: '',
      questionId: params.questionId,
    })

    formRef.current.setValue('options', newOptions, true);
    formRef.current.triggerFormValuesChanged();
  }

  const removeOption = (idx: number) => {
    const newOptions: QuestionOption[] = [...options];
    newOptions.splice(idx, 1);
    formRef.current.setValue('options', newOptions, true);
    formRef.current.triggerFormValuesChanged();
  }

  return (
    <>
      
      <Field 
        as={ListTextField} 
        name="question" 
        label="Question" 
        type="text"
        required
      />
      
      <Field 
        as={ListSwitch} 
        name="allowComments" 
        label="Allow Text Input" 
      />

      <Field 
        as={ListDropdownField} 
        name={`fieldToSet`}
        label="Use text answer for" 
        tooltip={{
          title: 'Set answer to equipment field',
          text: 'Optional: This can be useful to set meter values easily',
        }}
        options={[
          { label: '', value: '' },
          ...equipmentFields.map(field => ({ label: field.name, value: field.id, })),
        ]}
      />

        <Box padding="sm" paddingBottom="lg" backgroundColor="grey">

          {!options.length && (
            <Text bold size="lg" paddingTop="md" alignX="center">No Options</Text>
          )}

          {options.map((option, idx) => (
            <Bubble key={idx} title="Option" onClose={() => removeOption(idx)}>
              <Field 
                as={ListTextField} 
                name={`options[${idx}].description`}
                label="Description" 
                required
              />
              <Field 
                as={ListTextField} 
                name={`options[${idx}].deficiencyToAdd`}
                label="Deficiency" 
                tooltip={{
                  title: 'Add Deficiency',
                  text: 'Optional: Add a deficiency if this option is selected.',
                }}
                placeholder="Example: Broken Handle"
              />
            </Bubble>
          ))}

        </Box>

      <ListButton 
        label="Add Option"
        onPress={() => addOption()}
      />

    </>
  )
}
  
