import * as React from 'react';
import { Panes, Pane } from '../../components/Panes';
import { useUpdateModel, useModel } from '../../data/models';
import { useParams, useHistory } from '../../hooks';
import { ModelForm } from '../../forms/ModelForm';
import { PaneButton } from '../../components/PaneButton';
import { Form } from '../../components/Form';
import { SubmitButton } from '../../components/SubmitButton';
import { useHasPermission } from '../../auth';
import { Permissions } from '@rivison-inc/ft-types';
import { useDeleteModel } from '../../data/models';
import { DeleteButton } from '../../containers/DeleteButton';
import { BackButton } from '../../components/BackButton';

export function EditModelPage() {
  const history = useHistory();
  const params = useParams<{ modelId: string }>();
  const { model } = useModel(params.modelId);
  const { updateModel } = useUpdateModel();
  const { deleteModel } = useDeleteModel();
  const canWrite = useHasPermission(Permissions.SETTINGS_WRITE);

  if (!model) {
    return null; // TODO: return loading state?
  }

  return (
    <Form 
      initialValues={model}
      onSubmit={async (updates) => {
        await updateModel(model.id, updates);
        history.goBack();
      }}
    >
      <Panes backgroundColor="light" leftButton={<BackButton />}>
        <Pane 
          title="Model Information"
          rightButton={<SubmitButton as={PaneButton} showFeedback label="Save" disabled={!canWrite} />}
        >
          {model && (
            <ModelForm hideEquipmentType />
          )}
          <DeleteButton 
            name="model"
            deleteItemNew={deleteModel}
            itemId={model.id}
            label="Delete Model"
            yesMessage="Yes, Delete Model"
            question="Delete Model?"
            successMessage="Model Deleted"
          />
        </Pane>
      </Panes>
    </Form>
  )
}
  
