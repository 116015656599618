/// <reference path="../../styled.d.ts" />

import * as React from 'react';
import { SizingCode } from '../../theme';
import { Box } from '../Box';

interface RowProps {
  children: React.ReactNode;

  /** color */
  backgroundColor?: 'grey';

  /** flex */
  flexGrow?: 1;
  flexBasis?: 0;

  /** padding */
  padding?: SizingCode;
  paddingTop?: SizingCode;
  paddingBottom?: SizingCode;
  paddingLeft?: SizingCode;
  paddingRight?: SizingCode;

  /** margin */
  margin?: SizingCode;
  marginTop?: SizingCode;
  marginBottom?: SizingCode;
  marginLeft?: SizingCode;
  marginRight?: SizingCode;

  /** align */
  align?: 'left' | 'center' | 'right';
  alignY?: 'left' | 'center' | 'right';
  alignX?: 'left' | 'center' | 'right';
  justifyContent?: 'space-between' | 'center' | 'space-around';
}

export const Row = (props: RowProps) => {
  return (
    <Box
      flexDirection="row"
      {...props}
    />
  )
}
