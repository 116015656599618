import { LocalData } from "./localData";
import { Deficiency, Equipment, HistoryRecord, PerformedService } from "@rivison-inc/ft-types";

export class LocationSwitcher {

  public async moveLocation(equipmentId: string, currentLocation: string, newLocationId: string, newName?: string): Promise<void> {

    const now = new Date().toISOString();
          
    const equipmentLocalData = new LocalData<Equipment>('Equipment');
    const [equipmentRecord] = await equipmentLocalData.get({ field: 'id', value: equipmentId }, currentLocation);
    const newEquipmentRecord = { ...equipmentRecord, name: newName || equipmentRecord.name, locationId: newLocationId, lastUpdated: now }
    await equipmentLocalData.upsert([newEquipmentRecord], true, newLocationId);
    await equipmentLocalData.delete([equipmentId], currentLocation);

    const historyLocalData = new LocalData<HistoryRecord>('History');
    const history = await equipmentLocalData.get({ field: 'equipmentId', value: equipmentId }, currentLocation);
    const historyIds = history.map(record => record.id)
    const newHistoryRecords = history.map(record => {
      return { ...record, locationId: newLocationId, lastUpdated: now }
    }) 
    await equipmentLocalData.upsert(newHistoryRecords, true, newLocationId);
    await historyLocalData.delete(historyIds, currentLocation);

    const performedServiceLocalData = new LocalData<PerformedService>('PerformedServices');
    const performedService = await equipmentLocalData.get({ field: 'equipmentId', value: equipmentId }, currentLocation);
    const performedServiceIds = performedService.map(record => record.id)
    const newPerformedServiceRecords = performedService.map(record => {
      return { ...record, locationId: newLocationId, lastUpdated: now }
    }) 
    await equipmentLocalData.upsert(newPerformedServiceRecords, true, newLocationId);
    await performedServiceLocalData.delete(performedServiceIds, currentLocation);

    const deficienciesLocalData = new LocalData<Deficiency>('Deficiencies');
    const deficiencies = await equipmentLocalData.get({ field: 'equipmentId', value: equipmentId }, currentLocation);
    const deficienciesIds = deficiencies.map(record => record.id)
    const newDeficienciesRecords = deficiencies.map(record => {
      return { ...record, locationId: newLocationId, lastUpdated: now }
    }) 
    await equipmentLocalData.upsert(newDeficienciesRecords, true, newLocationId);
    await deficienciesLocalData.delete(deficienciesIds, currentLocation);

  }

}

export const locationSwitcher = new LocationSwitcher()
