import * as React from 'react';
import styled from 'styled-components/native';
import { Box } from '../Box';
import { PreviewImage } from '../PreviewImage';
import { Row } from '../Row';
import { ChooseFileButton } from './ChooseFileButton';
import { BlobWithName } from './types';

export interface ListFileUpload {
  label: string;
  value: BlobWithName;
  onChange: (value: BlobWithName) => void;
}

const ContainerView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom-color: ${(props) => props.theme.palette.lineColor};
  border-bottom-width: 1px;
`;

const FieldLabelText = styled.Text`
  padding: ${(props) => props.theme.spacing.md};
  display: flex;
  min-width: 120px;
`;

export function ListFileUpload(props: ListFileUpload) {
  return (
    <ContainerView>
      <FieldLabelText>{props.label}</FieldLabelText>
      <Row alignY="center">
        {props.value && (<PreviewImage file={props.value} />)}
        <Box marginLeft="sm">
          <ChooseFileButton 
            onPick={(file) => {
              props.onChange(file);
            }} 
          />
        </Box>
      </Row>
    </ContainerView>
  )
}
