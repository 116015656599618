import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { Theme } from '../../theme';
import { Translate, Message } from '../Translate';
import { ButtonLink } from '../Router';
import { LoadingSpinner } from '../LoadingSpinner';

export interface PrimaryButtonProps {
  label: Message;
  onPress?: () => void;
  to?: string;
  disabled?: boolean;
  loading?: boolean;
}

interface ButtonProps {
  disabled?: boolean;
}

const ButtonView = styled.View<ButtonProps>`
  padding-top: ${(props) => props.theme.spacing.sm};
  padding-bottom: ${(props) => props.theme.spacing.sm};
  padding-left: ${(props) => props.theme.spacing.md};
  padding-right: ${(props) => props.theme.spacing.md};
  border-bottom-width: 1px;
  border-bottom-color: ${(props) => props.theme.palette.lineColor};
  background-color: ${(props) => props.disabled ? 'grey' : props.theme.palette.primary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.borderRadius / 2}px;
`;

const TextView = styled.Text`
  color: white;
`;

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const button = (
    <ButtonView disabled={props.disabled || props.loading}>
      <TextView>
        {!props.loading && <Translate text={props.label} />}
        {props.loading && ' '}
      </TextView>
      {props.loading && <LoadingSpinner marginLeft="sm" />}
    </ButtonView>
  );

  if (props.disabled || props.loading) {
    return button;
  }

  return (
    <ButtonLink onPress={props.onPress} to={props.to}>
      {button}
    </ButtonLink>
  )
}
