import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { FontAwesomeIcon } from './helpers/FontAwesomeIcon';
import { theme } from '../theme';
import { Text, View } from 'react-native';
import { Box } from '../components/Box';

export const TooltipIcon = (props: { color?: string }) => {
  return (
    <Box paddingLeft={"sm"}>
      <Text style={{ fontSize: 14, }}>
        <FontAwesomeIcon icon={faQuestionCircle} color={props.color || theme.palette.fadedIconColor} />
      </Text>
    </Box>
  )
}
