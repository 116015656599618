/** eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

export function sortByDate<T>(array: T[], key: string, direction: 'asc' | 'desc') {
  return array.sort((a: any, b: any) => {
    const firstDate = moment(b[key]).unix();
    const secondDate = moment(a[key]).unix();
    if(direction === 'asc') return secondDate - firstDate;
    return firstDate - secondDate;
  });
}

export function sortByString<T>(array: T[], key: string, direction: 'asc' | 'desc') {
  return array.sort((a: any, b: any) => {
    if ((a[key] || '').toLowerCase() < (b[key] || '').toLowerCase()) return direction === 'asc' ? -1 : 1;
    if ((a[key] || '').toLowerCase() > (b[key] || '').toLowerCase()) return direction === 'asc' ? 1 : -1;
    return 0;
  });
}
