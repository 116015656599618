import * as React from 'react';
import { useHistory } from './useHistory';
import { useCheckHasUnsavedChanges } from '../components/UnsavedChangesDetector';
import { useControlDialog } from './useControlDialog';

export function useGoBack() {
  const history = useHistory();
  const checkUnsavedChanges = useCheckHasUnsavedChanges();
  const { openDialog } = useControlDialog();

  return React.useMemo(() => () => {
    if (checkUnsavedChanges()) {
      openDialog({ name: 'unsaved-changes-dialog', state: { goBack: true } });
    } else {
      history.goBack();
    }
  }, []);
}
