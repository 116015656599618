import React from 'react';
import NativeSlider from '@react-native-community/slider';
import { theme } from "../../theme";

export function Slider(props: { min: number; max: number; onChange: (newValue: number) => void; value: number }) {
  return (
    <NativeSlider
      value={props.value}
      minimumValue={props.min}
      maximumValue={props.max}
      step={1}
      minimumTrackTintColor={theme.palette.lineColor}
      maximumTrackTintColor={theme.palette.lineColor}
      thumbTintColor={theme.palette.primary}
      onValueChange={(val) => {
        props.onChange(val);
      }}
    />
  );
}
